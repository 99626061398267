import { put, takeLatest } from "redux-saga/effects";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { normalize } from "normalizr";
import { createSelector } from "reselect";

import { defaultFilterDlo, defaultFilterDloCard } from "./mixins/filter";
import {
  getDLOs,
  getDLO,
  getBotList,
  getStageList,
  getFeedbackCustom,
  deleteDLO,
  updateDLO,
  updateVersionDLO,
  getCards,
  addCardDLO,
  updateCardDLO,
  updateCardsDLO,
  deleteCardDLO,
  getCommentsDLO,
  addCommentDLO,
  getVersionsDLO,
  addVersionDLO,
  deleteVersionDLO,
  reorderCardsDLO,
} from "../crud/DLO.crud";
import { actionTypes as actionAuth } from "./auth.duck";
import { PERSIST_DLOS, REDUCER_DLOS } from "./conf";
import { getCardResponse } from "./mixins/response";
import { dlosSchema } from "../crud/normalizers/schemas/dlos.schema";

export const statusDlosTypes = {
  IN_PROCESS: "in process",
  REVIEW_NEEDED: "review needed",
  //  CHANGE_NEEDED: "change needed",
  APPROVAL_NEEDED: "approval needed",
  APPROVED: "approved",
};

export const statusDlos = [
  { dlo_status: statusDlosTypes.IN_PROCESS, label: "In Process", color: "in-process" },
  { dlo_status: statusDlosTypes.REVIEW_NEEDED, label: "Review Needed", color: "review-needed" },
  //  { dlo_status: statusDlosTypes.CHANGE_NEEDED, label: "Change Needed", color: "change-needed" },
  { dlo_status: statusDlosTypes.APPROVAL_NEEDED, label: "Approval Needed", color: "approval-needed" },
  { dlo_status: statusDlosTypes.APPROVED, label: "Approved", color: "approved" },
];

export const actionTypes = {
  GetDLOs: "DLO/GET_DLOS",
  SetDLOs: "DLO/SET_DLOS",
  UpdateDLO: "DLO/UPDATE_DLO",
  DeleteDLO: "DLO/DELETE_DLO",
  RemoveDLO: "DLO/REMOVE_DLO",
  ReplaceDLO: "DLO/REPLACE_DLO",
  FilterDLOs: "DLO/FILTER_DLOS",
  SetFilteredDLOs: "DLO/SET_FILTERED_DLOS",
  GetDetailDLO: "DLO/GET_DLO",
  SetDetailDLO: "DLO/SET_DLO",
  SetPreviewDLO: "DLO/PREVIEW_DLO", // cuando desde una lista seleccionamos uno
  CleanDetailDLO: "DLO/CLEAN_DLO",
  GetBots: "DLO/GET_BOTS",
  SetBots: "DLO/SET_BOTS",
  GetStages: "DLO/GET_STAGES",
  SetStages: "DLO/SET_STAGES",
  GetFeedbackCustom: "DLO/GET_FEEDBACK_CUSTOM",
  SetFeedbackCustom: "DLO/SET_FEEDBACK_CUSTOM",
  AddCardDLO: "DLO/ADD_CARD_DLO",
  AddCardDLOSuccess: "DLO/ADD_CARD_DLO_SUCCESS",
  UpdateCardDLO: "DLO/UPDATE_CARD_DLO",
  UpdateCardDLOSuccess: "DLO/UPDATE_CARD_DLO_SUCCESS",
  UpdateCardsDLO: "DLO/UPDATE_CARDS_DLO",
  UpdateCardsDLOSuccess: "DLO/UPDATE_CARDS_DLO_SUCCESS",
  DeleteCardDLO: "DLO/DELETE_CARD_DLO",
  DeleteCardDLOSuccess: "DLO/DELETE_CARD_DLO_SUCCESS",
  GetCommentsDLO: "DLO/GET_COMMENTS_DLO",
  SetCommentsDLO: "DLO/SET_COMMETS_DLOS",
  AddCommentDLO: "DLO/ADD_COMMENT_DLO",
  AddCommentDLOSuccess: "DLO/ADD_COMMENT_DLO_SUCCESS",
  UpdateCommentDLO: "DLO/UPDATE_COMMENT_DLO",
  DeleteCommentDLO: "DLO/GET_COMMENT_DLO",
  GetVersionsDLO: "DLO/GET_VERSIONS_DLO",
  SetVersionsDLO: "DLO/SET_VERSIONS_DLO",
  AddVersionDLO: "DLO/ADD_VERSION_DLO",
  AddVersionDLOSuccess: "DLO/ADD_VERSION_DLO_SUCCESS",
  UpdateVersionDLO: "DLO/UPDATE_VERSION_DLO",
  UpdateVersionDLOSuccess: "DLO/UPDATE_VERSION_DLO_SUCCESS",
  DeleteVersionDLO: "DLO/DELETE_VERSION_DLO",
  DeleteVersionDLOSuccess: "DLO/DELETE_VERSION_DLO_SUCCESS",
  SelectedVersion: "DLO/SELECTED_VERSION_DLO",
  SetPagination: "DLO/SET_PAGINATION",
  CurrentEditDLO: "DLO/CURRENT_EDIT",
  ReorderCardsDLO: "DLO/REORDER_CARDS_CLO",
  ReorderCardsDLOSuccess: "DLO/REORDER_CARDS_CLO_SUCCESS",
};

const namesState = {
  dlos: "dlos",
  currentSearch: "currentSearch",
  searching: "searching",
  pagination: "pagination",
};

const namesStateDlos = {
  data: "data",
  result: "result",
  loading: "loading",
  total: "total",
};

const namesStateCurrentSearch = {
  search: "search",
  result: "result",
};

const namesStatePagination = {
  pageActive: "pageActive",
  total: "total",
};

const initialState = {
  [namesState.dlos]: {},
  loadingDLOs: false,
  bots: [],
  stages: [],
  feedbackCustom: [],
  [namesState.currentSearch]: { [namesStateCurrentSearch.search]: {}, [namesStateCurrentSearch.result]: [] },
  [namesState.searching]: false,
  currentDLO: { versionSelected: "", versionIsUpdated: true },
  currentDLOEdit: {},
  currentDLOVersions: [],
  loadingDLO: false,
  commentsDLO: [],
  [namesState.pagination]: { [namesStatePagination.pageActive]: 1, [namesStatePagination.total]: null },
};

export const reducer = persistReducer({ storage, key: PERSIST_DLOS, whitelist: ["dlos", "bots", "stages"] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetDLOs: {
      return { ...state, [namesState.dlos]: { [namesStateDlos.loading]: true } };
    }

    case actionTypes.SetDLOs: {
      const { dlos, result, total, page, pageSize } = action.payload;
      return {
        ...state,
        [namesState.dlos]: { [namesStateDlos.data]: dlos, [namesStateDlos.result]: result, [namesStateDlos.loading]: false, [namesStateDlos.total]: total },
        [namesState.pagination]: { [namesStatePagination.pageActive]: page, [namesStatePagination.total]: total },
      };
    }

    case actionTypes.UpdateDLO: {
      return { ...state };
    }

    case actionTypes.DeleteDLO: {
      return { ...state };
    }

    case actionTypes.ReplaceDLO: {
      const { dlo } = action.payload;
      if (dlo) {
        let dlosSearch = { ...state[namesState.currentSearch] };
        //checking search results if EDITED DLO STATE must belong or not to search results
        // TODO: Ahora mismo si hay una edicion vamos a ir al servidor, para simplificar los filtrados, por lo que no lo adapto a lo nuevo
        /*dlosSearch.results = dlosSearch.results.filter((d) => {
          if (d.guid !== dlo.guid) {
            return d;
          } else {
            let belongsSearch = true;
            if (dlosSearch.search) {
              if (
                dlosSearch.search.collection &&
                dlosSearch.search.collection[0] &&
                dlo.collections &&
                dlo.collections[0] &&
                dlo.collections[0].guid !== dlosSearch.search.collection[0]
              ) {
                belongsSearch = false;
              }
              if (dlosSearch.search.dloStatus && dlosSearch.search.dloStatus[0] && dlo.dlo_status && dlo.dlo_status !== dlosSearch.search.dloStatus[0]) {
                belongsSearch = false;
              }
              if (dlosSearch.search.topic && dlosSearch.search.topic[0] && dlo.topics && dlo.topics[0] && dlo.topics[0].guid !== dlosSearch.search.topic[0]) {
                belongsSearch = false;
              }
            }

            if (belongsSearch) {
              return { ...d, ...dlo };
            }
          }
        });*/

        // == Si en currentDLO tengo dicha dlo que se ha actualizado la actualizo tambien ahí
        let currentDLO = null;
        if (state.currentDLO && state.currentDLO.guid && dlo && state.currentDLO.guid == dlo.guid) {
          currentDLO = {
            ...state.currentDLO,
            ...dlo,
          };
        }

        return {
          ...state,
          [namesState.dlos]: {
            ...state[namesState.dlos],
            [namesStateDlos.data]: {
              ...state[namesState.dlos][namesStateDlos.data],
              [dlo.guid]: dlo,
            },
          },
          [namesState.currentSearch]: dlosSearch,
          ...(currentDLO ? { currentDLO: currentDLO } : {}),
        };
      }
      return { ...state };
    }

    case actionTypes.RemoveDLO: {
      const { dlo } = action.payload;
      if (dlo) {
        let dlosResultAll = state.dlos.result.filter((d) => {
          return d !== dlo.guid[0];
        });
        let dlosSearch = { ...state.currentSearch };
        dlosSearch.result = dlosSearch.result.filter((d) => {
          return d !== dlo.guid[0];
        });
        const guidDloRemove = dlo.guid[0];
        let { [guidDloRemove]: value, ...newData } = state[namesState.dlos][namesStateDlos.data];
        // == En new data tengo lo que habia en state[namesState.dlos][data] pero sin la propiedad dlo.guid[0]
        return {
          ...state,
          [namesState.dlos]: {
            ...state[namesState.dlos],
            [namesStateDlos.data]: newData,
            [namesStateDlos.result]: dlosResultAll,
          },
          currentSearch: dlosSearch,
        };
      }
      return { ...state };
    }

    case actionTypes.FilterDLOs: {
      return { ...state, [namesState.searching]: true };
    }

    case actionTypes.SetFilteredDLOs: {
      const { dlos, result, search, total, page, pageSize } = action.payload;
      return {
        ...state,
        [namesState.dlos]: {
          ...state[namesState.dlos],
          [namesStateDlos.data]: {
            ...state[namesState.dlos][namesStateDlos.data],
            ...dlos,
          },
          /*
          TODO: con la paginacion no vamos a poder hacer esto
          [namesStateDlos.result]: [
            ...state[namesState.dlos][namesStateDlos.result],
            ...result.filter((r) => !state[namesState.dlos][namesStateDlos.result].includes(r)),
          ],*/
        },
        [namesState.currentSearch]: {
          [namesStateCurrentSearch.search]: search,
          [namesStateCurrentSearch.result]: result,
        },
        [namesState.searching]: false,
        [namesState.pagination]: { [namesStatePagination.pageActive]: page, [namesStatePagination.total]: total },
      };
    }

    case actionTypes.GetDetailDLO: {
      return { ...state, loadingDLO: true };
    }

    // preguntar cambio agustion
    case actionTypes.SetDetailDLO: {
      return { ...state, currentDLO: { ...state.currentDLO, ...action.payload }, loadingDLO: false };
    }

    case actionTypes.SetPreviewDLO: {
      return { ...state, currentDLO: action.payload };
    }

    case actionTypes.CleanDetailDLO: {
      return { ...state, currentDLO: undefined, currentDLOEdit: undefined };
    }

    case actionTypes.SetBots: {
      const bots = action.payload;
      return { ...state, bots: bots };
    }

    case actionTypes.SetStages: {
      const stages = action.payload;
      return { ...state, stages: stages };
    }

    case actionTypes.SetFeedbackCustom: {
      const feedbacks = action.payload;
      return { ...state, feedbackCustom: feedbacks };
    }

    case actionTypes.AddCardDLO: {
      return { ...state, loadingDLO: true };
    }

    case actionTypes.AddCardDLOSuccess: {
      const { card } = action.payload;
      return {
        ...state,
        currentDLO: { ...state.currentDLO, cards: [...state.currentDLO.cards, card] },
        loadingDLO: false,
      };
    }

    case actionTypes.UpdateCardDLO: {
      return { ...state, loadingDLO: true };
    }

    case actionTypes.UpdateCardDLOSuccess: {
      const { card: cardUpdate } = action.payload;
      return {
        ...state,
        currentDLO: { ...state.currentDLO, cards: state.currentDLO.cards.map((card) => (card.guid === cardUpdate.guid ? cardUpdate : card)) },
        currentDLOEdit: {},
        loadingDLO: false,
      };
    }

    case actionTypes.UpdateCardsDLOSuccess: {
      const { cards: cardsUpdate } = action.payload;
      var cardsUpdateToFind = {};
      cardsUpdate && cardsUpdate.forEach((cardUpdate)=>{
        cardsUpdateToFind[cardUpdate.guid] = cardUpdate;
      });
      return {
        ...state,
        currentDLO: { ...state.currentDLO, cards: state.currentDLO.cards.map((card) => (cardsUpdateToFind[card.guid] ? cardsUpdateToFind[card.guid] : card)) },
        currentDLOEdit: {},
        loadingDLO: false,
      };
    }

    case actionTypes.DeleteCardDLO: {
      return { ...state, loadingDLO: true };
    }

    case actionTypes.DeleteCardDLOSuccess: {
      const { card: cardDelete } = action.payload;
      return {
        ...state,
        currentDLO: { ...state.currentDLO, cards: state.currentDLO.cards.filter((card) => card.guid !== cardDelete.guid) },
        loadingDLO: false,
      };
    }

    case actionTypes.UpdateVersionDLOSuccess: {
      const { params } = action.payload;
      return {
        ...state,
        currentDLO: { ...state.currentDLO, ...params },
        loadingDLO: false,
      };
    }

    case actionTypes.ReorderCardsDLO: {
      return { ...state, loadingDLO: true };
    }

    case actionTypes.ReorderCardsDLOSuccess: {
      const { dloGuid, reorder } = action.payload;
      var newCards = [];
      if(reorder){
        reorder.map((guidCard)=>{
          const card = getCardByGuid(state.currentDLO, guidCard);
          if(card) newCards = [...newCards, card]
        })
      }
      return {
        ...state,
        currentDLO: { ...state.currentDLO, cards: getCardsUpdateOrder(newCards) },
        loadingDLO: false,
      };
    }

    // COMMENTS
    case actionTypes.GetCommentsDLO: {
      return { ...state };
    }

    case actionTypes.SetCommentsDLO: {
      const commentsDLO = action.payload;
      return {
        ...state,
        currentDLO: { ...state.currentDLO, commentsDLO: commentsDLO },
        loadingDLO: false,
      };
    }

    case actionTypes.AddCommentDLO: {
      return { ...state, loadingDLO: true };
    }

    case actionTypes.AddCommentDLOSuccess: {
      const { commentDLO } = action.payload;
      let comments = [...state.currentDLO.commentsDLO];
      if (commentDLO.parent_guid) {
        comments = comments.map((item) => {
          if (item.guid === commentDLO.parent_guid) {
            item.childs = [commentDLO, ...item.childs];
            return item;
          } else {
            return item;
          }
        });
      } else {
        comments = [commentDLO, ...comments];
      }
      return {
        ...state,
        currentDLO: { ...state.currentDLO, commentsDLO: comments },
        loadingDLO: false,
      };
    }

    case actionTypes.UpdateCommentDLO: {
      return { ...state };
    }

    case actionTypes.DeleteCommentDLO: {
      return { ...state };
    }
    // END COMMENTS

    // VERSIONS
    case actionTypes.GetVersionsDLO: {
      return { ...state, loadingDLOs: true };
    }

    case actionTypes.SetVersionsDLO: {
      return { ...state, currentDLOVersions: action.payload, loadingDLOs: false };
    }

    case actionTypes.AddVersionDLO: {
      return { ...state, loadingDLO: true };
    }

    case actionTypes.AddVersionDLOSuccess: {
      const currentDLO = { ...state.currentDLO, versionSelected: action.payload.guid };
      const currentDLOVersions = state.currentDLOVersions.map((item) => {
        item.is_last = 0;
        return item;
      });
      return { ...state, currentDLO, currentDLOVersions: [...currentDLOVersions, action.payload], loadingDLO: false };
    }

    case actionTypes.DeleteVersionDLO: {
      return { ...state, loadingDLO: true };
    }

    case actionTypes.DeleteVersionDLOSuccess: {
      return {
        ...state,
        // hay que volver a cargar si se ha borrado la actual seleccionada
        currentDLO:
          state.currentDLO.versionSelected === action.payload
            ? { ...state.currentDLO, versionSelected: state.currentDLO.current_version, versionIsUpdated: false }
            : state.currentDLO,
        currentDLOVersions: state.currentDLOVersions.filter((item) => item.guid !== action.payload),
        loadingDLO: false,
      };
    }

    case actionTypes.SelectedVersion: {
      const versionIsUpdated = state.currentDLOVersions.find((item) => item.is_last).guid === action.payload;
      const currentDLO = { ...state.currentDLO, versionSelected: action.payload, versionIsUpdated };
      return { ...state, currentDLO };
    }
    // END VERSIONS

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    case actionTypes.SetPagination: {
      return { ...state, [namesState.pagination]: action.payload };
    }

    case actionTypes.CurrentEditDLO: {
      const cardUpdate = action.payload;
      return {
        ...state,
        currentDLOEdit: { ...state.currentDLO, cards: [...state.currentDLO.cards.map((card) => (card.guid === cardUpdate.guid ? cardUpdate : card))] },
      };
    }

    /*case actionUI.SearchHeaderbar:{ // limpiar si no hay búsqueda
            if (!action.payload.value)
              return initialState;
        }*/

    default:
      return state;
  }
});

const getDlosDataS = (state) => (state.entities ? state.entities[REDUCER_DLOS][namesState.dlos][namesStateDlos.data] : null);
const getDlosResultsS = (state) => {
  const { result, loading, total } = state.entities[REDUCER_DLOS][namesState.dlos];
  return { result, loading, total };
};
const getCurrentSearchS = (state) => (state.entities ? state.entities[REDUCER_DLOS][namesState.currentSearch] : null);
const getCurrentSearchResultS = (state) => (state.entities ? state.entities[REDUCER_DLOS][namesState.currentSearch][namesStateCurrentSearch.result] : null);
const getSearchingS = (state) => (state.entities ? state.entities[REDUCER_DLOS][namesState.searching] : null);
const getVersionsDLOS = (state) => (state.entities ? state.entities[REDUCER_DLOS].currentDLOVersions : []);
const getVersionDLOSelectedS = (state) =>
  state.entities && state.entities[REDUCER_DLOS].currentDLO ? state.entities[REDUCER_DLOS].currentDLO.versionSelected : undefined;
const getPaginationS = (state) => (state.entities ? state.entities[REDUCER_DLOS][namesState.pagination] : 1);

export const selectors = {
  getDLOs: createSelector([getDlosDataS, getDlosResultsS], (data, info) => {
    const dlos = info.result ? info.result.map((guid) => data[guid]) : [];
    return { dlos: dlos, loadingDLOs: info.loading, total: info.total };
  }),
  getCurrentSearch: createSelector([getDlosDataS, getCurrentSearchResultS, getCurrentSearchS, getSearchingS], (data, result, currentSearch, searching) => {
    const dlos = result ? result.map((guid) => data[guid]) : [];
    return {
      currentSearch: {
        search: currentSearch ? currentSearch[namesStateCurrentSearch.search] : {},
        results: dlos,
      },
      searching: searching,
    };
  }),
  getDetailDLO: (state) => {
    const { currentDLO, loadingDLO } = state.entities[REDUCER_DLOS];
    return { currentDLO, loadingDLO };
  },
  getBots: (state) => {
    const { bots } = state.entities[REDUCER_DLOS];
    return bots;
  },
  getStages: (state) => {
    const { stages } = state.entities[REDUCER_DLOS];
    return stages;
  },
  getFeedbackCustom: (state) => {
    const { feedbackCustom } = state.entities[REDUCER_DLOS];
    return feedbackCustom;
  },
  getCommentsDLO: (state, itemWithGuid) => {
    const { currentDLO } = state.entities[REDUCER_DLOS];
    let comments = currentDLO.commentsDLO ? [...currentDLO.commentsDLO] : [];
    if (itemWithGuid) {
      comments = comments.filter((comment) => {
        return (comment.card || comment.interaction || {}).guid === (itemWithGuid.card || itemWithGuid.interaction).guid;
      });
    }
    return currentDLO.commentsDLO ? currentDLO.commentsDLO : [];
  },
  getVersionsDLO: (state) => {
    return getVersionsDLOS(state);
  },
  getVersionDLOSelected: (state) => {
    return getVersionDLOSelectedS(state);
  },
  getVersionDLOIsUpdated: (state) => {
    if (state.entities[REDUCER_DLOS].currentDLO) {
      const { versionIsUpdated } = state.entities[REDUCER_DLOS].currentDLO;
      return versionIsUpdated !== undefined ? versionIsUpdated : true;
    }
    return undefined;
  },
  getVersionNumberDLOSelected: createSelector([getVersionsDLOS, getVersionDLOSelectedS], (versions, versionSelected) => {
    const versionFound = versions.filter((v) => (versionSelected ? v.guid === versionSelected : v.is_last)); // si no hay seleccionada devuelve la última
    return versionFound && versionFound.length > 0 ? versionFound[0].version : 1;
  }),
  getPagination: (state) => {
    return getPaginationS(state);
  },
  getEditDLO: (state) => {
    const { currentDLOEdit } = state.entities[REDUCER_DLOS];
    return { currentDLOEdit };
  },
};

export const actions = {
  getDLOs: (filters, page, pageSize) => ({ type: actionTypes.GetDLOs, payload: { filters: filters, page: page, pageSize: pageSize } }),

  fulfillDLOs: (params) => ({ type: actionTypes.SetDLOs, payload: params }),

  getBots: () => ({ type: actionTypes.GetBots }),

  getStages: () => ({ type: actionTypes.GetStages }),

  getFeedbackCustom: () => ({ type: actionTypes.GetFeedbackCustom }),

  updateDLO: (params) => ({ type: actionTypes.UpdateDLO, payload: { params } }),

  replaceOneDLO: (dlo) => ({ type: actionTypes.ReplaceDLO, payload: { dlo } }),

  deleteDLO: (params) => ({ type: actionTypes.DeleteDLO, payload: { params } }),

  removeDLO: (dlo) => ({ type: actionTypes.RemoveDLO, payload: { dlo } }),

  fulfillBots: (bots) => ({ type: actionTypes.SetBots, payload: bots }),

  fulfillStages: (stages) => ({ type: actionTypes.SetStages, payload: stages }),

  fulfillFeedbackCustom: (feedbacks) => ({ type: actionTypes.SetFeedbackCustom, payload: feedbacks }),

  filterDLOs: (filters, page, pageSize) => ({ type: actionTypes.FilterDLOs, payload: { filters: filters, page: page, pageSize: pageSize } }),

  fulfillFilteredDLOs: (params) => ({ type: actionTypes.SetFilteredDLOs, payload: params }),

  getDetailDLO: (guid, versionGuid = null, isCards = true) => ({ type: actionTypes.GetDetailDLO, payload: { guid, versionGuid, isCards } }),

  fulfillDetailDLO: (dlo) => ({ type: actionTypes.SetDetailDLO, payload: dlo }),

  selectDLO: (dlo) => ({ type: actionTypes.SetDetailDLO, payload: dlo }), // cuando desde una lista seleccionamos uno

  cleanDetailDLO: () => ({ type: actionTypes.CleanDetailDLO }),

  cleanNewDLOCreated: () => ({ type: actionTypes.CleanNewDLOCreated }),

  addCardDLO: (dloGuid, card) => ({ type: actionTypes.AddCardDLO, payload: { dloGuid, card } }),

  addCardDLOSuccess: (dloGuid, card) => ({ type: actionTypes.AddCardDLOSuccess, payload: { dloGuid, card } }),

  updateCardDLO: (dloGuid, card) => ({ type: actionTypes.UpdateCardDLO, payload: { dloGuid, card } }),

  updateCardDLOSuccess: (dloGuid, card) => ({ type: actionTypes.UpdateCardDLOSuccess, payload: { dloGuid, card } }),

  updateCardsDLO: (dloGuid, cards) => ({ type: actionTypes.UpdateCardsDLO, payload: { dloGuid, cards } }),

  updateCardsDLOSuccess: (dloGuid, cards) => ({ type: actionTypes.UpdateCardsDLOSuccess, payload: { dloGuid, cards } }),

  deleteCardDLO: (dloGuid, card) => ({ type: actionTypes.DeleteCardDLO, payload: { dloGuid, card } }),

  deleteCardDLOSuccess: (dloGuid, card) => ({ type: actionTypes.DeleteCardDLOSuccess, payload: { dloGuid, card } }),

  getCommentsDLO: (guid) => ({ type: actionTypes.GetCommentsDLO, payload: { guid: guid } }),

  fulfillCommentsDLO: (comments) => ({ type: actionTypes.SetCommentsDLO, payload: comments }),

  addCommentDLO: (dloGuid, comment, userSessionGuid, itemWithGuid, parentGuid) => ({
    type: actionTypes.AddCommentDLO,
    payload: { dloGuid, comment, userSessionGuid, itemWithGuid, parentGuid },
  }),

  addCommentDLOSuccess: (dloGuid, commentDLO) => ({ type: actionTypes.AddCommentDLOSuccess, payload: { dloGuid, commentDLO } }),

  updateCommentDLO: (dloGuid, comment, cardGuid) => ({ type: actionTypes.UpdateCommentDLO, payload: { dloGuid, comment, cardGuid } }),

  deleteCommentDLO: (params) => ({ type: actionTypes.DeleteCommentDLO, payload: { params } }),

  updateVersionDLO: (dloGuid, version, params) => ({ type: actionTypes.UpdateVersionDLO, payload: { dloGuid, version, params } }),

  updateVersionDLOSuccess: (dloGuid, version, params) => ({ type: actionTypes.UpdateVersionDLOSuccess, payload: { dloGuid, version, params } }),

  getVersionsDLO: (params) => ({ type: actionTypes.GetVersionsDLO, payload: { params } }),
  fulfillVersionsDLO: (data) => ({ type: actionTypes.SetVersionsDLO, payload: data }),
  addVersionDLO: (params) => ({ type: actionTypes.AddVersionDLO, payload: { params } }),
  addVersionDLOSuccess: (params) => ({ type: actionTypes.AddVersionDLOSuccess, payload: params }),
  deleteVersionDLO: (guid) => ({ type: actionTypes.DeleteVersionDLO, payload: guid }),
  deleteVersionDLOSuccess: (guid) => ({ type: actionTypes.DeleteVersionDLOSuccess, payload: guid }),
  selectedVersion: (versionGuid) => ({ type: actionTypes.SelectedVersion, payload: versionGuid }),
  setPagination: (pageActive, total) => ({
    type: actionTypes.SetPagination,
    payload: { [namesStatePagination.pageActive]: pageActive, [namesStatePagination.total]: total },
  }),
  editCardDLO: (messages) => ({ type: actionTypes.CurrentEditDLO, payload: messages }),
  reorderCardsDLO: (dloGuid, versionGuid, reorder) => ({ type: actionTypes.ReorderCardsDLO, payload: { dloGuid, versionGuid, reorder } }),
  reorderCardsDLOSuccess: (dloGuid, reorder) => ({ type: actionTypes.ReorderCardsDLOSuccess, payload: { dloGuid, reorder } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetDLOs, function* getDLOsSaga(action) {
    const { filters, page, pageSize } = action.payload;
    const params = {
      ...defaultFilterDlo(filters, page, pageSize),
      type: ["CTTY_16"],
      orderBy: (filters && filters.orderBy) || "created_at desc",
    };
    const { data } = yield getDLOs(params);
    if (data && data.status === "success" && data.data && data.data.contents) {
      let dlosNormalized = normalize(data.data.contents, [dlosSchema]);
      yield put(
        actions.fulfillDLOs({ dlos: dlosNormalized.entities.dlos, result: dlosNormalized.result, total: data.data.total, page: page, pageSize: pageSize })
      );
    } else yield put(actions.fulfillDLOs([]));
  });

  yield takeLatest(actionTypes.FilterDLOs, function* filterDLOsSaga(action) {
    const { filters, page, pageSize } = action.payload;
    const params = {
      ...defaultFilterDlo(filters, page, pageSize),
    };
    if (params.search) params.excludeApiSearch = 1;
    const { data } = yield getDLOs(params);
    if (data && data.status === "success" && data.data && data.data.contents) {
      let dlosNormalized = normalize(data.data.contents, [dlosSchema]);
      yield put(
        actions.fulfillFilteredDLOs({
          dlos: dlosNormalized.entities.dlos,
          result: dlosNormalized.result,
          search: filters,
          total: data.data.total,
          page: page,
          pageSize: pageSize,
        })
      );
    } else yield put(actions.fulfillDetailDLO([]));
  });

  yield takeLatest(actionTypes.GetDetailDLO, function* getDLOSaga(action) {
    const { data } = yield getDLO(action.payload.guid);
    if (data && data.status === "success" && data.data) {
      var dloData = data.data;
      if (action.payload.isCards){
        // == Ahora vamos a por las cards
        const { data: dataCard } = yield getCards(action.payload.guid, action.payload.versionGuid);
        if (dataCard && dataCard.status === "success" && dataCard.data) {
          dloData.cards = dataCard.data;
          /*if (dataCard.data.length > 0)
            dloData.versionSelected = dataCard.data[0].version_guid;*/
        }
      }
      yield put(actions.fulfillDetailDLO(dloData));
    } else yield put(actions.fulfillDetailDLO({}));
  });

  yield takeLatest(actionTypes.GetBots, function* getBotsaga(action) {
    const { data } = yield getBotList();

    if (data && data.status === "success" && data.data && data.data.bots) yield put(actions.fulfillBots(data.data.bots));
    else yield put(actions.fulfillBots([]));
  });

  yield takeLatest(actionTypes.GetStages, function* getStagesaga(action) {
    const { data } = yield getStageList();
    if (data && data.status === "success" && data.data && data.data.regs) yield put(actions.fulfillStages(data.data.regs));
    else yield put(actions.fulfillStages([]));
  });

  yield takeLatest(actionTypes.GetFeedbackCustom, function* getFeedbackssaga(action) {
    const { data } = yield getFeedbackCustom();

    if (data && data.status === "success" && data.data && data.data.regs) yield put(actions.fulfillFeedbackCustom(data.data.regs));
    else yield put(actions.fulfillFeedbackCustom([]));
  });

  yield takeLatest(actionTypes.UpdateDLO, function* updateDLOSaga(action) {
    const { data } = yield updateDLO(action.payload.params);
    if (data && data.status === "success" && data.data) {
      if (action.payload.params && action.payload.params.callSuccess) action.payload.params.callSuccess();
      yield put(actions.replaceOneDLO(data.data));
    }
  });

  yield takeLatest(actionTypes.UpdateVersionDLO, function* updateDLOSaga(action) {
    const { data } = yield updateVersionDLO(action.payload.version, action.payload.params);
    if (data && data.status === "success" && data.data)
      yield put(actions.updateVersionDLOSuccess(action.payload.dloGuid, action.payload.version, action.payload.params));
  });

  yield takeLatest(actionTypes.DeleteDLO, function* deleteDLOSaga(action) {
    const { data } = yield deleteDLO(action.payload.params);
    if (data && data.status === "success" && data.data) yield put(actions.removeDLO(data.data));
  });

  yield takeLatest(actionTypes.AddCardDLO, function* getDLOSaga(action) {
    const { card, dloGuid } = action.payload;
    var dataJson = { ...card };
    if (dataJson.guid) delete dataJson.guid;
    if (dataJson.name) delete dataJson.name;
    const params = {
      guid: card.guid,
      dlo_guid: dloGuid,
      name: card.name,
      ...defaultFilterDloCard(dataJson),
    };
    const { data } = yield addCardDLO(dloGuid, card.guid, params);
    if (data && data.status === "success" && data.data) yield put(actions.addCardDLOSuccess(dloGuid, getCardResponse(data.data)));
  });

  function getParamsUpdateCardDlo(card){
    var dataJson = { ...card };
    if (dataJson.guid) delete dataJson.guid;
    if (dataJson.name) delete dataJson.name;
    const params = {
      name: card.name,
      ...defaultFilterDloCard(dataJson),
    };
    return params;
  }

  yield takeLatest(actionTypes.UpdateCardDLO, function* getDLOSaga(action) {
    const { card, dloGuid } = action.payload;
    const params = getParamsUpdateCardDlo(card)
    const { data } = yield updateCardDLO(dloGuid, card.guid, params);
    if (data && data.status === "success" && data.data) yield put(actions.updateCardDLOSuccess(dloGuid, card));
  });

  yield takeLatest(actionTypes.UpdateCardsDLO, function* updateCardsDLOSaga(action) {
    // TODO: Cambiarlo cuando haya un servicio que podamos actualizar cards en lote en la misma llamada
    const { cards, dloGuid } = action.payload;
    var cardsGuidArray = [];
    var paramsArray = [];
    cards && cards.forEach(card => {
      paramsArray.push(getParamsUpdateCardDlo(card))
      cardsGuidArray.push(card.guid)
    });
    const responseArray = yield updateCardsDLO(dloGuid, cardsGuidArray, paramsArray);
    if(responseArray){
      var allValid = true;
      responseArray.forEach(response => {
        const {data} = response;
        if (data && data.status === "success" && data.data){
          // OK
        }else allValid = false;
      });
      if(allValid){
        yield put(actions.updateCardsDLOSuccess(dloGuid, cards));
      }
    }
  });

  yield takeLatest(actionTypes.DeleteCardDLO, function* getDLOSaga(action) {
    const { card, dloGuid } = action.payload;
    const params = {
      guid: [card.guid],
    };
    const { data } = yield deleteCardDLO(dloGuid, card.guid, params);
    if (data && data.status === "success" && data.data) yield put(actions.deleteCardDLOSuccess(dloGuid, card));
  });

  yield takeLatest(actionTypes.GetCommentsDLO, function* getCommentsSaga(action) {
    const { data } = yield getCommentsDLO(action.payload.guid);

    if (data && data.status === "success" && data.data) yield put(actions.fulfillCommentsDLO(data.data));
    else yield put(actions.fulfillCommentsDLO([]));
  });

  yield takeLatest(actionTypes.AddCommentDLO, function* getDLOSaga(action) {
    const { dloGuid, comment, userSessionGuid, itemWithGuid, parentGuid } = action.payload;

    const params = {
      content_guid: dloGuid,
      comment,
      author_guid: userSessionGuid,
      ...itemWithGuid,
    };

    if (parentGuid) params.parent_guid = parentGuid;
    const { data } = yield addCommentDLO(params);
    if (data && data.status === "success" && data.data) yield put(actions.addCommentDLOSuccess(dloGuid, data.data));
  });

  yield takeLatest(actionTypes.GetVersionsDLO, function* getDLOsSaga(action) {
    const { data } = yield getVersionsDLO(action.payload.params);
    if (data && data.status === "success" && data.data) yield put(actions.fulfillVersionsDLO(data.data));
    else yield put(actions.fulfillVersionsDLO([]));
  });

  yield takeLatest(actionTypes.AddVersionDLO, function* getDLOsSaga(action) {
    const { data } = yield addVersionDLO(action.payload.params);
    if (data && data.status === "success" && data.data) yield put(actions.addVersionDLOSuccess(data.data));
  });

  yield takeLatest(actionTypes.DeleteVersionDLO, function* getDLOsSaga(action) {
    const { data } = yield deleteVersionDLO({ guid: [action.payload] });
    if (data && data.status === "success" && data.data) yield put(actions.deleteVersionDLOSuccess(action.payload));
  });

  yield takeLatest(actionTypes.ReorderCardsDLO, function* reorderCardsDloSaga(action) {
    const { dloGuid, reorder, versionGuid } = action.payload;
    const { data } = yield reorderCardsDLO(dloGuid, versionGuid, reorder);
    if (data && data.status === "success" && data.data) yield put(actions.reorderCardsDLOSuccess(dloGuid, reorder));
  });
}

/** Utils ***/
function getCardByGuid(dlo, guidCard){
  return dlo ? getCard(dlo.cards, guidCard) : null;
}
function getCard(cards, guidCard) {
  var toRet = null;
  if (cards && guidCard) {
    toRet = cards.find((card) => card.guid == guidCard);
  }
  return toRet;
}
/**
 * Devuelve las secciones actualizando el campo order, segun la posicion actual del array
 * @param {array} sections
 */
export function getCardsUpdateOrder(cards){
  var cardsFinal = cards ? cards : [];
  return cardsFinal.map((card, index) => {
      var tempCard = {...card};
      tempCard.order = index;
      return tempCard;
  });
}