import { getBasicUserInfo } from '_core/crud/gamified.crud';
import { put, takeLatest } from 'redux-saga/effects';
import { REDUCER_GAMIFICATION } from './conf';

export const actionTypes = {
  AddNotification: 'gamification/ADD_NOTIFICATION',
  RemoveNotification: 'gamification/REMOVE_NOTIFICATION',
  SetDialogLevel: 'gamification/SET_DIALOG_LEVEL',
  EditUserInfoBasic: 'gamification/EDIT_USER_INFO_BASIC',
  EditError: 'gamification/EDIT_ERROR',
  FillUserGamification: 'gamification/FULL_USER_GAMIFICATION',
};

const initialState = {
  notificationsExp: [],
  dialogLevel: { open: false },
  userInfoBasic: {},
  error: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AddNotification:
      return {
        ...state,
        notificationsExp: [...state.notificationsExp, { id: Date.now(), message: action.payload }],
      };

    case actionTypes.RemoveNotification:
      return {
        ...state,
        notificationsExp: state.notificationsExp.filter((notification) => notification.id !== action.payload),
      };

    case actionTypes.SetDialogLevel:
      return {
        ...state,
        dialogLevel: { level: action.payload.level, open: action.payload.open },
      };

    case actionTypes.EditUserInfoBasic: {
      return { ...state, userInfoBasic: action.payload.gamification, error: false };
    }

    case actionTypes.editError: {
      return { ...state, error: true };
    }

    default:
      return state;
  }
};

export const selectors = {
  getNotificationsExp: (state) => {
    return state[REDUCER_GAMIFICATION].notificationsExp;
  },
  getOpenedDialogLevel: (state) => {
    return state[REDUCER_GAMIFICATION].dialogLevel;
  },
  getUserInfoBasic: (state) => {
    return state[REDUCER_GAMIFICATION].userInfoBasic;
  },
};

export const actions = {
  addNotification: (message) => ({ type: actionTypes.AddNotification, payload: message }),
  removeNotification: (id) => ({ type: actionTypes.RemoveNotification, payload: id }),
  setDialogLevel: (open, level) => ({ type: actionTypes.SetDialogLevel, payload: { open, level } }),
  fillUserGamification: (userGuid) => ({ type: actionTypes.FillUserGamification, payload: { userGuid } }),
  editUserInfoBasic: (gamification) => ({ type: actionTypes.EditUserInfoBasic, payload: { gamification } }),
  editError: () => ({ type: actionTypes.EditError, payload: {} }),
};

export function* saga() {
  yield takeLatest(actionTypes.FillUserGamification, function* FillUserGamification(action) {
    try {
      const { data } = yield getBasicUserInfo({ userGuid: action.payload.userGuid });
      yield put(actions.editUserInfoBasic(data));
    } catch (e) {
      yield put(actions.editError());
    }
  });
}
