import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '_core/modules/atoms/Icon';
import CardGeneric from '_core/modules/components/cards/components/CardGeneric';
import ProgramImage from '_core/modules/components/general/ProgramImage/ProgramImage';
import { getDateFormat } from '_core/utils/date';

const LicenseItem = ({ name, thumbnail, level, education_level, code, start, end, guid, program, t, onDelete, typeView, configProgramPicture }) => {
  return (
    <CardGeneric>
      <div className={`license-item license-item--typeview-${typeView}`}>
        <div className="license-item__image">
          <ProgramImage course_picture={thumbnail} configProgramPicture={true} />
        </div>
        <div className="license-item__content">
          <div className="license-item__content-texts">
            <span className="license-item__content-name">{name}</span>
            <span className="license-item__content-level">
              {education_level && education_level.guid ? t(`education-levels:${education_level.guid}`) : level}

              {program.isbn && (
                <>
                  <> -</>
                  <span className="license-item__content-isbn">
                    {' '}
                    {t('common:ISBN')} {program.isbn}
                  </span>
                </>
              )}
            </span>
          </div>
          {start && typeView === 'default' && (
            <div className="license-item__content-meta-info">
              <span className="license-item__content-code">{code}</span>
              <span className="license-item__content-range">
                <span className="license-item__content-range-text">{t('From')} </span>
                <span className="license-item__content-range">{getDateFormat(start)} </span>
                <span className="license-item__content-range-text">{t('To').toLowerCase()}</span>
                <span className="license-item__content-range"> {getDateFormat(end)} </span>
              </span>
            </div>
          )}
          {onDelete && (
            <div className="license-item__content-delete" onClick={onDelete}>
              <Icon type="trash" />
            </div>
          )}
        </div>
      </div>
    </CardGeneric>
  );
};

LicenseItem.propTypes = {
  typeView: PropTypes.oneOf(['default', 'simple']),
};

LicenseItem.defaultProps = {
  typeView: 'default',
  onDelete: () => {},
};

export default withTranslation(['common', 'placeholder'])(LicenseItem);
