import PropTypes from 'prop-types';
import React from 'react';

const LineWithText = ({ text }) => {
  return (
    <div className="line-with-text">
      <div className="line-with-text__line">
        <span className="line-with-text__text">{text}</span>
      </div>
    </div>
  );
};

LineWithText.propTypes = {
  text: PropTypes.string,
};

LineWithText.defaultProps = {
  text: 'Title',
};

export default LineWithText;
