import { put, takeLatest } from 'redux-saga/effects';
import { getTasks } from '../crud/tasks.crud';
import { isArray } from '../utils/validation';
import { actionTypes as actionAuth } from './auth.duck';
import { REDUCER_TASKS } from './conf';

export const actionTypes = {
  GetTasks: 'cms/GET_TASKS',
  SetTasks: 'cms/SET_TASKS',
};

const initialState = {
  list: [],
  loading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTasks:
      return { ...state, loading: true };

    case actionTypes.SetTasks: {
      const { tasks } = action.payload;
      return {
        ...state,
        loading: false,
        list: tasks.tasks
          ? tasks.tasks.map((item) => {
              return { ...item, title: item.name };
            })
          : [],
      };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    default:
      return state;
  }
};

export const selectors = {
  getTasks: (state) => {
    return state.entities[REDUCER_TASKS] && state.entities[REDUCER_TASKS].list && isArray(state.entities[REDUCER_TASKS].list)
      ? state.entities[REDUCER_TASKS].list
      : [];
  },
};

export const actions = {
  getTasks: (params) => ({ type: actionTypes.GetTasks, payload: { params } }), //{pageSize:10, offset:0}
  fulfillTasks: (tasks) => ({ type: actionTypes.SetTasks, payload: { tasks } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetTasks, function* getTasksSaga(action) {
    const { data } = yield getTasks(action.payload.params);
    if (data && data.status === 'success' && data.data) yield put(actions.fulfillTasks(data.data));
    else yield put(actions.fulfillTasks([]));
  });
}
