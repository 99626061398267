import React from 'react';

function Arrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
      <g filter="url(#filter0_i_3438_9504)">
        <path
          d="M2.3784 9.64429L7.39243 6.29644C8.30113 5.6902 8.2714 4.40277 7.39243 3.79718L2.3784 0.388572C1.22733 -0.398626 0 0.0402148 0 1.43365V8.61408C0 9.96227 1.28744 10.3714 2.3784 9.64429Z"
          fill="#6E30BC"
        />
      </g>
      <defs>
        <filter id="filter0_i_3438_9504" x="0" y="0" width="8.06287" height="10" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.560784 0 0 0 0 0.270588 0 0 0 0 0.929412 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3438_9504" />
        </filter>
      </defs>
    </svg>
  );
}

export default Arrow;
