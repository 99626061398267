import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Bubble = ({ text, size, color }) => {
  return <div className={clsx('bubble', `bubble--color-${color}`, { 'bubble--text': text })}>{text && <span className="bubble-text">{text}</span>}</div>;
};

Bubble.propTypes = {
  size: PropTypes.oneOf(['default', 'medium']),
  color: PropTypes.oneOf(['default', 'blue']),
};

Bubble.defaultProps = {
  size: 'default',
  color: 'default',
};

export default Bubble;
