import * as microsoftTeams from '@microsoft/teams-js';
import { useTranslate } from '_core/hooks/useTranslate';
import Loading from '_core/modules/atoms/Loading/Loading';
import { getClientIdMicrosoft } from 'app/config/config';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const TabAuthStart = () => {
  const { t } = useTranslate();
  // Build an Azure AD request that authenticate the user and ask them to
  // and to consent to any missing permissions
  // const tenant_id = 'common';
  const client_id = getClientIdMicrosoft(window.location.origin);

  // Initialize the Microsoft Teams SDK
  microsoftTeams.app.initialize().then(() => {
    // Get the user context in order to extract the tenant ID
    microsoftTeams.app.getContext().then((context) => {
      let tenant_id = context.user.tenant.id; //Tenant ID of the logged in user

      //Form a query for the Azure implicit grant authorization flow
      //https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
      let queryParams = {
        tenant: `${tenant_id}`,
        client_id: `${client_id}`,
        response_type: 'token', //token_id in other samples is only needed if using open ID
        scope:
          'openid profile email https://graph.microsoft.com/GroupMember.Read.All https://graph.microsoft.com/Team.ReadBasic.All https://graph.microsoft.com/User.Read',
        redirect_uri: window.location.origin + '/tab-auth-end',
        nonce: uuidv4(),
      };

      let url = `https://login.microsoftonline.com/${tenant_id}/oauth2/v2.0/authorize?`;
      queryParams = new URLSearchParams(queryParams).toString();
      let authorizeEndpoint = url + queryParams;

      //Redirect to the Azure authorization endpoint. When that flow completes, the user will be directed to auth-end
      //Go to TabAuthEnd.js
      window.location.assign(authorizeEndpoint);
    });
  });

  return (
    <div className="tab-auth-start">
      <Loading loadingText={t('teams:No cierres esta ventana, en unos segundos serás redigirido a Teams')} />
    </div>
  );
};

export default TabAuthStart;
