import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import * as coreEntities from '_core/store/index';
import { loginOauth } from '../../../crud/auth.crud';
import * as auth from '../../../store/auth.duck';
import Button from '../../atoms/Button';
import { loginOraculo, onClickLogin } from './serviceOraculo.js';

function LoginOraculo({ login, text }) {
  const { t } = useTranslation(['login']);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  React.useEffect(() => {
    const interval = setInterval(() => {
      loginOraculo(interval, onSubmit);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function onSubmit(ssoToken) {
    const identityProvider = 'oraculo';

    loginOauth(ssoToken, identityProvider)
      .then((data) => {
        if (data.data.data.token) {
          dispatch(coreEntities.auth.actions.login(data.data.data));
        } else {
          showError(data);
        }
      })
      .catch((error) => {
        showError(error);
      });
  }

  function showError(error) {
    const errorMsg = error.error && error.error.data ? t(error.error.data.error.errorKey) : t('login:Invalid login');
    addToast(t(`login:${errorMsg}`), { appearance: 'error', autoDismiss: true });
  }

  return <Button testid="btn-sso-oraculo" color="white" text={text} icon="polp" iconPosition="left" onClick={onClickLogin} />;
}

export default connect(null, auth.actions)(LoginOraculo);
