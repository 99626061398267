import { Tooltip } from '@material-ui/core';
import { useComingSoon } from '_core/lite/hooks/useComingSoon';
import Avatar from '_core/modules/atoms/Avatar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '../../../atoms/Icon';
import ProgramImage from '../../general/ProgramImage/ProgramImage';

const BookCard = ({
  program_guid,
  isEdited,
  versionAuthorAvatar,
  versionName,
  book_guid,
  image,
  title,
  progress,
  type,
  view,
  t,
  courseName,
  selected,
  onChangeSelected,
  guid,
  borderRadiusSize,
  onClick,
  subtext,
  customRight,
  language,
  configProgramPicture,
  isAccessible,
  ...props
}) => {
  const { showComingSoon } = useComingSoon();
  const [animation, setAnimation] = React.useState(0);
  function handleClick(e) {
    if (isAccessible) {
      setAnimation(1);
      onChangeSelected(e);
    } else {
      showComingSoon();
    }
  }

  return (
    <div
      className={clsx(`book-card book-card--${view}`, { 'book-card--selected': selected }, `book-card--border-radius-${borderRadiusSize}`)}
      onClick={view === 'selectable' ? handleClick : () => onClick(program_guid, isAccessible || props.isAccessible)}
    >
      <div className="book-card__image">
        <ProgramImage course_picture={image} configProgramPicture={configProgramPicture} />
        {isEdited && <div className="program-card__edit">{t('course:Edited')}</div>}
      </div>
      <div className="book-card__info">
        {/* <span className="book-card__responsive">{subtext}</span> */}
        {language && <span className="book-card__language">{t(`languages:${language}`)}</span>}
        <span className="book-card__title" title={title}>
          <Tooltip title={title} placement="top">
            <div>{title}</div>
          </Tooltip>
        </span>
        {/* {view === 'default' && (
          <span className="book-card__progressbar">
            <ProgressBar progress={progress} />
          </span>
        )} */}
        {(versionAuthorAvatar || versionName) && (
          <div className="program-card__info-bottom">
            <div className="program-card__version-avatar">
              <Avatar size="small" image={versionAuthorAvatar} />
            </div>
            {versionName && <span className="program-card__version-name">{t('course:My version')}</span>}
          </div>
        )}

        {view === 'selectable' &&
          (isAccessible ? (
            <div className="book-card__selects-icon" onAnimationEnd={() => setAnimation(0)} animation={animation}>
              <Icon type={selected ? 'check-checked-round-fill' : 'add-circle'} color={'primary'} size="xl" />
            </div>
          ) : (
            <div className="book-card__coming-soon">{t('common:Coming soon')}</div>
          ))}

        {view === 'custom-right' && <div className="book-card__button"> {customRight} </div>}
      </div>
    </div>
  );
};

BookCard.propTypes = {
  image: PropTypes.string,
  responsive: PropTypes.bool,
  title: PropTypes.string,
  view: PropTypes.oneOf(['default', 'selectable', 'custom-right', 'selectable disabled']),
  selected: PropTypes.bool,
  onChangeSelected: PropTypes.func,
  borderRadiusSize: PropTypes.oneOf(['default', 'big']),
  onClick: PropTypes.func,
};

BookCard.defaultProps = {
  isEdited: false,
  responsive: true,
  title: '',
  view: 'default',
  selected: false,
  onChangeSelected: function () {},
  borderRadiusSize: 'default',
  onClick: function () {
    console.log('onclick bookcard');
  },
};

export default withTranslation(['course', 'types-resources', 'languages'])(BookCard);
