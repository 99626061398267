import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getEducationLevels } from '../crud/educationLevels.crud';
import { getEducationYears } from '../utils/contentCms';
import { getOptionByGuid } from '../utils/utils';
import { PERSIST_EDUCATION_LEVELS, REDUCER_EDUCATION_LEVELS } from './conf';

export const actionTypes = {
  GetEducationLevels: 'cms/GET_EDUCATION_LEVELS',
  SetEducationLevels: 'cms/SET_EDUCATION_LEVELS',
};

const initialState = {
  levels: [],
  years: [],
  disciplines: [],
  loading: false,
  educationCountries: [],
};

export const reducer = persistReducer({ storage, key: PERSIST_EDUCATION_LEVELS, whitelist: ['data'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetEducationLevels:
      return { ...state, loading: true };

    case actionTypes.SetEducationLevels: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return response.data.educationLevels
          ? {
              ...state,
              disciplines: response.data.disciplines,
              levels: response.data.educationLevels,
              loading: false,
              years: getEducationYears([...response.data.educationLevels]),
            }
          : { ...state, educationCountries: response.data.levels };
      }

      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getEducationLevels: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].levels : null;
  },
  getEducationYears: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].years : null;
  },
  getDisciplines: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].disciplines : null;
  },
  getEducationCountries: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].educationCountries : null;
  },
};

export const utils = {
  isEducationYearInsideEducationLevel: (educationLevels, educationLevelGuid, educationYearGuid) => {
    var inside = false;
    if (educationLevels) {
      const educationLevelFind = getOptionByGuid(educationLevels, educationLevelGuid);
      if (educationLevelFind && educationLevelFind.educationYears) {
        const educationYearFind = getOptionByGuid(educationLevelFind.educationYears, educationYearGuid);
        inside = educationYearFind ? true : false;
      }
    }
    return inside;
  },
};

export const actions = {
  getEducationLevels: (country) => ({ type: actionTypes.GetEducationLevels, payload: country }),
  fulfillEducationLevels: (response) => ({ type: actionTypes.SetEducationLevels, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetEducationLevels, function* getEducationLevelsSaga(action) {
    const { payload } = action;
    const { data } = yield getEducationLevels(payload?.country, payload?.client);

    yield put(actions.fulfillEducationLevels(data));
  });
}
