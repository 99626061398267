import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getBooks } from "../crud/contentsBooks.crud";
import {actionTypes as actionAuth} from "./auth.duck";
import { normalizeContentsBooks } from "../crud/normalizers/content.normalizer";
import { PERSIST_CONTENTS_BOOKS, REDUCER_CONTENTS_BOOKS } from "./conf";

export const actionTypes = {
  GetBooks: "content/GET_BOOKS",
  SetBooks: "content/SET_BOOKS"  
};

const initialState = {
  books: undefined,
  loadingBooks:false  
};

export const reducer = persistReducer(
    { storage, key: PERSIST_CONTENTS_BOOKS, whitelist: ["books"] },
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.GetBooks: 
          return {...state, loadingBooks: true}          
        
        case actionTypes.SetBooks: {
          const { response } = action.payload;
          if (response && response.status === "success")
            return { books: normalizeContentsBooks(response.data), loadingBooks:false };
          return {...state, loadingBooks: false};
        }

        case actionAuth.Logout: { //si logout, borrar estado redux
          return initialState;
        }

        default:
          return state;
      }
    }
);

export const selectors = {
  getBooks: (state) => {
      return state.entities[REDUCER_CONTENTS_BOOKS]
  }
}

export const actions = {
  getBooks: (page, pageSize, groupsWithContent) => ({type:actionTypes.GetBooks, payload:{page: page, pageSize: pageSize, groupsWithContent: groupsWithContent}}),
  fulfillBooks: response => ({ type: actionTypes.SetBooks, payload:{response} }),  
};

export function* saga() {  

  yield takeLatest(actionTypes.GetBooks, function* getBook(action) {
    const { page, pageSize, groupsWithContent } = action.payload;
    const { data } = yield getBooks(page, pageSize, groupsWithContent);
    yield put(actions.fulfillBooks(data));
  });
}
