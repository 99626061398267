import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const Hr = ({ size }) => {
  return <div className={clsx('hr', `hr--size-${size}`)}></div>;
};

Hr.propTypes = {
  size: PropTypes.oneOf(['default', 'small']),
};

Hr.defaultProps = {
  size: 'default',
};

export default Hr;
