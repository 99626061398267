import Exp from '_core/modules/components/gamified/icons/exp/Exp';
import React from 'react';

function GamifiedExpProgress({ exp }) {
  return (
    <div className="gamified-exp-progress">
      <div className="gamified-exp-progress__icon">
        <Exp />
      </div>
      <span>{exp}</span>
    </div>
  );
}

export default GamifiedExpProgress;
