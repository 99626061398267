import { useMemo } from 'react';

export default function useIconBubble({ colorIcon, variant, size }) {
  const sizeIconState = useMemo(() => getIconSize(size), [size]);
  const colorIconState = useMemo(() => getIconColor(colorIcon, variant), [colorIcon, variant]);

  function getIconSize(size) {
    let sizeToIcon = '';

    switch (size) {
      case 'xl':
        sizeToIcon = 'xl';
        break;

      case 'big':
        sizeToIcon = 'xl';
        break;

      case 'large':
        sizeToIcon = 'xl';
        break;

      case 'l':
        sizeToIcon = 'l';
        break;

      case 'medium':
        sizeToIcon = 'big';
        break;

      case 'small':
        sizeToIcon = 'big';
        break;

      default:
        sizeToIcon = 'default';
        break;
    }

    return sizeToIcon;
  }

  function getIconColor(colorIcon, variant) {
    let colorToIcon = colorIcon;

    if (!colorIcon) {
      colorToIcon = variant === 'inverse' ? 'primary' : 'white';
    }

    return colorToIcon;
  }

  return { sizeIconState, colorIconState };
}
