import Icon from '_core/modules/atoms/Icon';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import React from 'react';
import useDialogGlobalPost from './useDialogGlobalPost';

const DialogGlobalPost = () => {
  const { t, open, message, onClose } = useDialogGlobalPost();

  return (
    <DialogDefault
      title={
        <div className="dialog-global-post__header">
          <Icon type="megaphone" color="primary" size="big" /> <div className="dialog-global-post__header-title">{t('course:Global statement')}</div>
        </div>
      }
      open={open}
      className={'dialog-global-post'}
      buttonTextSucess={t('actions:Accept')}
      onSuccess={onClose}
      size="small"
    >
      <div className="dialog-global-post__message">{message}</div>
    </DialogDefault>
  );
};

export default DialogGlobalPost;
