import Socket from '../socket';

export default class SocketNotificationss extends Socket {
  constructor(authToken, onAction, onBroadcast) {
    super(authToken, onAction, onBroadcast, 'notifications');

    this.token = authToken;
    this.onAction = onAction;
  }
}
