import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
// import { normalize } from "normalizr";

import { getQuestions, getQuestionsTypes, getTestQuestions, reorderQuestions, submitTestAnswers, updateTestQuestions } from '_core/crud/tests.crud';
import { PERSIST_TESTS, REDUCER_TESTS } from '../conf';

export const actionTypes = {
  GetTestQuestions: 'Tests/GET_TEST_QUESTIONS',
  SetTestQuestions: 'Tests/SET_QUESTIONS',
  GetFilteredQuestions: 'Tests/GET_FILTERED_QUESTIONS',
  SetFilteredQuestions: 'Tests/SET_FILTERED_QUESTIONS',
  GetQuestionTypes: 'Tests/GET_QUESTION_TYPES',
  SetQuestionTypes: 'Tests/SET_QUESTION_TYPES',
  AddToSelectedQuestions: 'Tests/SET_SELECTED_QUESTIONS',
  GetSelectedQuestions: 'Tests/GET_SELECTED_QUESTIONS',
  UpdateTestQuestions: 'Tests/UPDATE_TEST_QUESTIONS',
  SetTestDetails: 'Tests/SET_TEST_DETAILS',
  ReorderQuestions: 'Tests/REORDER_QUESTIONS',
  SubmitTestAnswers: 'Tests/SUBMIT_TEST_ANSWERS',
};

//TO HAVE A DEFAULT NAMING CONVENTION
const namesState = {
  name: 'tests',
  currentSearch: 'currentSearch',
  loading: 'loading',
  pagination: 'pagination',
};

const namesStateCurrentSearch = {
  search: 'search',
  result: 'result',
};

const initialState = {
  [namesState.name]: {},
  [namesState.currentSearch]: { [namesStateCurrentSearch.search]: {}, [namesStateCurrentSearch.result]: [] },
  [namesState.searching]: false,
  [namesState.loading]: false,
  selectedQuestions: [],
  questionsTypes: [],
  loading: false,
  current: {},
};

export const reducer = persistReducer({ storage, key: PERSIST_TESTS, whitelist: ['current', 'selectedQuestions'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTestQuestions: {
      return { ...state, loading: true };
    }

    case actionTypes.SetTestQuestions: {
      const { response } = action.payload;
      //todo add state selected previeous
      return {
        ...state,
        selectedQuestions: response.data.data.questions.map((q) => {
          return { ...q, name: q.content_name || q.name, description: q.content_description || q.description, guid: q.content_guid || q.guid };
        }),
        loading: false,
      };
    }

    case actionTypes.GetFilteredQuestions: {
      return { ...state, loading: true };
    }

    case actionTypes.SetFilteredQuestions: {
      const { request, response } = action.payload;
      if (response.status == 'success') {
        if (state.filteredQuestions && request.offset) {
          return {
            ...state,
            filteredQuestions: {
              ...response.data,
              contents: [...state.filteredQuestions.contents, ...response.data.contents],
            },
            loading: false,
          };
        } else {
          return {
            ...state,
            filteredQuestions: response.data,
            loading: false,
          };
        }
      }

      return { ...state, loading: false };
    }

    case actionTypes.GetQuestionTypes: {
      return { ...state, loading: true };
    }

    case actionTypes.SetQuestionTypes: {
      const { response } = action.payload;
      if (response.status == 'success') {
        return {
          ...state,
          questionsTypes: response.data,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.GetSelectedQuestions: {
      return { ...state, loading: false };
    }

    case actionTypes.AddToSelectedQuestions: {
      return {
        ...state,
        selectedQuestions: [...action.payload.params.questions],
      };
    }

    case actionTypes.UpdateTestQuestions: {
      return { ...state, loading: true };
    }

    case actionTypes.SetTestDetails: {
      const { response } = action.payload;
      return {
        ...state,
        current: response.data,
        loading: false,
      };
    }

    case actionTypes.ReorderQuestions: {
      return { ...state, loading: false };
    }

    default:
      return { ...state, loading: false };
  }
});

export const selectors = {
  getTestQuestions: (state) => {
    return state.entities && state.entities[REDUCER_TESTS] ? state.entities[REDUCER_TESTS].questions : null;
  },
  getFilteredQuestions: (state) => {
    return state.entities && state.entities[REDUCER_TESTS] ? state.entities[REDUCER_TESTS].filteredQuestions : null;
  },
  getQuestionTypes: (state) => {
    return state.entities && state.entities[REDUCER_TESTS] ? state.entities[REDUCER_TESTS].questionsTypes : null;
  },
  getSelectedQuestions: (state) => {
    return state.entities && state.entities[REDUCER_TESTS] ? state.entities[REDUCER_TESTS].selectedQuestions : null;
  },
  getCurrentTest: (state) => {
    return state.entities && state.entities[REDUCER_TESTS] ? state.entities[REDUCER_TESTS].current : null;
  },
  getIsLoading: (state) => {
    return state.entities && state.entities[REDUCER_TESTS] ? state.entities[REDUCER_TESTS].loading : true;
  },
};

export const actions = {
  getTestQuestions: (params) => ({ type: actionTypes.GetTestQuestions, payload: { params } }),
  setTestQuestions: (response) => ({ type: actionTypes.SetTestQuestions, payload: { response } }),
  getFilteredQuestions: (params) => ({ type: actionTypes.GetFilteredQuestions, payload: { params } }),
  setFilteredQuestions: (request, response) => ({ type: actionTypes.SetFilteredQuestions, payload: { request, response } }),
  getQuestionTypes: (params) => ({ type: actionTypes.GetQuestionTypes, payload: { params } }),
  setQuestionTypes: (response) => ({ type: actionTypes.SetQuestionTypes, payload: { response } }),
  getSelectedQuestions: (params) => ({ type: actionTypes.GetSelectedQuestions, payload: { params } }),
  addToSelectedQuestions: (params) => ({ type: actionTypes.AddToSelectedQuestions, payload: { params } }),
  updateTestQuestions: (params) => ({ type: actionTypes.UpdateTestQuestions, payload: { params } }),
  setTestDetails: (response) => ({ type: actionTypes.SetTestDetails, payload: { response } }),
  reorderQuestions: (params) => ({ type: actionTypes.ReorderQuestions, payload: params }),
  submitTestAnswers: (params) => ({ type: actionTypes.SubmitTestAnswers, payload: { params } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetTestQuestions, function* getQuestionsSaga(action) {
    const { data } = yield getTestQuestions(action.payload.params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setTestDetails(data));
      yield put(actions.setTestQuestions(data));
    }
  });

  yield takeLatest(actionTypes.UpdateTestQuestions, function* updateTestQuestionsSaga(action) {
    const { data } = yield updateTestQuestions(action.payload.params);
    // yield actions.addToSelectedQuestions(action.payload.params.data);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setTestQuestions(data));
    }
  });

  yield takeLatest(actionTypes.GetQuestionTypes, function* getQuestionTypesSaga(action) {
    const { data } = yield getQuestionsTypes(action.payload.params);

    if (data && data.status === 'success' && data) {
      yield put(actions.setQuestionTypes(data));
    }
  });

  yield takeLatest(actionTypes.GetFilteredQuestions, function* getFilteredQuestionsSaga(action) {
    const { data } = yield getQuestions(action.payload.params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setFilteredQuestions(action.payload.params, data));
    }
  });

  yield takeLatest(actionTypes.ReorderQuestions, function* reorderQuestionsSaga(action) {
    const reOrderData = yield reorderQuestions(action.payload);
    if (reOrderData.data && reOrderData.data.status === 'success') {
      const { data } = yield getTestQuestions({ guid: action.payload.guid });
      yield put(actions.setTestQuestions(data));
    }
  });

  yield takeLatest(actionTypes.SubmitTestAnswers, function* submitTestAnswersSaga(action) {
    const { data } = yield submitTestAnswers(action.payload.params);
  });
}
