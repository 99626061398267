import React from 'react';

import Button from '_core/modules/atoms/Button';
import InputText from '_core/modules/atoms/InputText';

const RecoverForm = () => {
  // deprecated
  return (
    <div className="login-form">
      <div className="login-form__item">
        <h3 className="login-form__title">Recover Password</h3>
      </div>
      <div className="login-form__item">
        <InputText label="Email" />
      </div>
      <div className="login-form__item">
        <Button text="Recover Password" />
      </div>
    </div>
  );
};

export default RecoverForm;
