import * as coreEntities from '_core/store/index';
import { systemRequirements as requirements, systemRequirementsWithApps as requirementsApps } from '_core/variables/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useLoginPage() {
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));
  const [legal, setLegal] = useState(null);
  const [privacy, setPrivacy] = useState(null);
  const [terms, setTerms] = useState(null);
  const [apps, setApps] = useState(null);
  const [supportUrl, setSupportUrl] = useState(null);
  const [credits, setCredits] = useState(null);

  const systemRequirements = requirements[language] ? requirements[language] : requirements.en;
  const systemRequirementsWithApps = requirementsApps[language] ? requirementsApps[language] : requirementsApps.en;

  function getSystemRequirements() {
    if (apps) {
      return systemRequirementsWithApps;
    } else {
      return systemRequirements;
    }
  }

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      let legalScope = scopes.filter((scope) => scope.scope === 'legal');
      let privacyScope = scopes.filter((scope) => scope.scope === 'privacy');
      let scopeApps = scopes && scopes.filter((item) => item.scope === 'apps');
      let support = scopes && scopes.filter((item) => item.scope === 'support');
      let credits = scopes && scopes.filter((item) => item.scope === 'credits');

      if (scopeApps && scopeApps.length > 0) {
        let parsedApps;
        try {
          scopeApps = JSON.parse(scopeApps[0].json);
          parsedApps = { WINDOWS: scopeApps['win'], LINUX: scopeApps['linux'], MAC: scopeApps['mac'] };
        } catch (e) {}
        setApps(parsedApps);
      }
      if (legalScope && legalScope.length > 0) {
        setLegal(legalScope[0].json);
        setTerms(legalScope[0].json);
      }
      if (privacyScope && privacyScope.length > 0) {
        setPrivacy(privacyScope[0].json);
      }
      if (support && support.length > 0) {
        setSupportUrl(support[0].json);
      }
      if (credits && credits.length > 0) {
        setCredits(credits[0].json);
      }
    }
  }, [scopes]);

  return {
    legal,
    privacy,
    terms,
    apps,
    credits,
    supportUrl,
    systemRequirements: getSystemRequirements(),
  };
}
