export const string = val => typeof val === 'string';
export const hasData = val => val && val.toString().length > 0;
export const minLength = (length, val) => val.length >= length;
export const isEmail = val => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.toLowerCase());
export const isNumber = val => !isNaN(val);
export const isURL = val => /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(val.toLowerCase());
export const isBlankSpace = val => /^\s+$/.test(val);
export const isArray = val => {
  if (!Array.isArray) {
      return Object.prototype.toString.call(val) === '[object Array]';
  }else{
     return Array.isArray(val);
  }
}
/**
 * Devuelve true si el objeto esta vacio o es null
 * @param {object} obj 
 */
export const isEmptyObj = obj => {
  if(obj){
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
    }
  }
  return true;
}
