import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

import { getContentTypes } from '../crud/contentTypes.crud';
import { PERSIST_CONTENT_TYPES, REDUCER_CONTENT_TYPES } from './conf';

export const actionTypes = {
  GetContentTypes: 'cms/GET_CONTENT_TYPES',
  SetContentTypes: 'cms/SET_CONTENT_TYPES',
};

const initialState = {
  types: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_CONTENT_TYPES, whitelist: ['types'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetContentTypes:
      return { ...state, loading: true };

    case actionTypes.SetContentTypes: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { types: response.data };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getContentTypes: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT_TYPES] ? state.entities[REDUCER_CONTENT_TYPES].types : null;
  },
};

export const actions = {
  getContentTypes: () => ({ type: actionTypes.GetContentTypes }),
  fulfillContentTypes: (response) => ({ type: actionTypes.SetContentTypes, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetContentTypes, function* getContentTypesSaga() {
    const { data } = yield getContentTypes();

    yield put(actions.fulfillContentTypes(data));
  });
}
