import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslate } from '_core/hooks/useTranslate';
import * as coreEntities from '_core/store/index';

export function useComingSoon() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { addToast } = useToasts();

  function showComingSoon(text) {
    if (text) {
      dispatch(coreEntities.ui.actions.setShowInfoDialog({ text: text }));
    } else {
      addToast(t('common:Coming soon'), {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }
  }

  return { showComingSoon };
}
