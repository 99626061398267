export const normalizeBook = (book) =>{
    return {
        name: book.name,
        guid: book.guid,
        description: book.description,
        thumbnail: book.thumbnail,
        url: book.url,
        publication_year: book.publication_year,
        is_teacher_only: book.is_teacher_only,
        erp_id: book.erp_id,
        education_level: book.educationLevels && book.educationLevels.length > 0 ? book.educationLevels[0].name : null,
        education_year: book.educationYears && book.educationYears.length >0 ? book.educationYears[0].name : null,
        progress: book.consumption ? book.consumption.consumption_progress : 0
    }
}

export const normalizeMultimedia = (media) =>{
    return {
        name: media.name,
        guid: media.guid,
        type_guid: media.type_guid,
        type_name: media.type_name,
        description: media.description,
        thumbnail: media.thumbnail,
        reference_id: media.reference_id,
        reference_name: media.reference_name,
        references: media.reference_path ? media.reference_path.split('/') : [media.reference_id], // array de jerarquia de referencias
        url: media.url_public_viewer,
        publication_year: media.publication_year,
        is_teacher_only: media.is_teacher_only               
    }
}

export const normalizeContentsBooks = (data) => {
    let dataNormalized = {...data};    
    if (dataNormalized.educationLevels){
        dataNormalized.educationLevels.forEach(el => {
            if (el.contents)
                el.contents = el.contents.map(book => {return normalizeBook(book)});            
        });
    }
    if (dataNormalized.recentlyConsumed){
        dataNormalized.recentlyConsumed = dataNormalized.recentlyConsumed.map(book => {return normalizeBook(book)});
    }
    if (dataNormalized.uncategorized){
        dataNormalized.uncategorized = dataNormalized.uncategorized.map(book => {return normalizeBook(book)});
    }
    return dataNormalized;
}

export const normalizeContents = (data) => {    
    return data.map(book => {return normalizeBook(book)});      
}

export const normalizeMultimediaContents = (data) => {    
    return data.map(media => {return normalizeMultimedia(media)});      
}