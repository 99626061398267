import axios from 'axios';

export const GET_BOOKS = 'front/contents-books';
export const GET_CONTENTS = 'front/contents';
export const GET_TEACHER_BOOKS = 'front/v2/teacher-books';

export function getBooks(page = 1, pageSize = 100, groupsWithContent = 20, orderBy = 't.name asc') {
  return axios.get(GET_BOOKS, {
    params: {
      page: page,
      pageSize: pageSize,
      offset: (page - 1) * pageSize,
      groupsWithContent: groupsWithContent,
      orderBy: orderBy,
    },
  });
}

export function getBooksYear(educationLevel, educationYear) {
  const paramsOpcional = {};
  if (educationLevel) paramsOpcional.educationLevel = [educationLevel];
  if (educationYear) paramsOpcional.educationYear = [educationYear];
  return axios.get(GET_CONTENTS, {
    params: {
      ...paramsOpcional,
      educationYear: [educationYear],
      isBook: 1,
    },
  });
}

export function getTeacherBooks(params) {
  return axios.get(GET_TEACHER_BOOKS, { params });
}
