import { useTranslate } from '_core/hooks/useTranslate';
import LoadingDots from '_core/modules/atoms/LoadingDots/LoadingDots';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import GamificationBadge from '_core/modules/components/gamified/GamificationBadge/GamificationBadge';
import Trophy from '_core/modules/components/gamified/icons/icons/Trophy';
import PropTypes from 'prop-types';
import React from 'react';
import { getTextInLanguage } from '../gamified.service';
import GamificationDialogBadgesTitle from './GamificationDialogBadgesTitle/GamificationDialogBadgesTitle';
import useGamificationDialogBadges from './useGamificationDialogBadges';

const GamificationDialogBadges = ({ open, onClose, level }) => {
  const { t } = useTranslate();
  const { badges, loading } = useGamificationDialogBadges({ open });

  function getSection(sectionName, badges) {
    return (
      <div>
        <GamificationDialogBadgesTitle title={sectionName} />
        <div className="gamification-dialog-badges__section-lists">
          {!loading && (
            <>
              {badges
                .sort((a, b) => a.badge.id - b.badge.id)
                .map((item) => {
                  return (
                    <div key={item.badge.id} className="gamification-dialog-badges__badge-wrapper">
                      <GamificationBadge
                        obtained={item.obtained}
                        progress={item.progress}
                        image={item.badge.imageUrl}
                        name={getTextInLanguage(item.badge.name)}
                      />
                    </div>
                  );
                })}
            </>
          )}
          {loading && (
            <>
              <LoadingDots />
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <DialogDefault
      open={open}
      className="gamification-dialog-badges"
      onClose={onClose}
      title={
        <div className="gamification-dialog-badges__title">
          <div className="gamification-dialog-badges__trophy">
            <Trophy />
          </div>
          <span className="gamification-dialog-badges__text">{t('gamification:Your achievements')}</span>
        </div>
      }
    >
      <div className="gamification-dialog-badges__badges-wrapper">
        {getSection(
          t('gamification:Tasks'),
          badges.filter((item) => [5, 6, 7, 8].includes(item.badge.id))
        )}
        {getSection(
          t('gamification:Quizzes'),
          badges.filter((item) => [1, 2, 3, 4].includes(item.badge.id))
        )}
        {getSection(
          t('gamification:Videolessons'),
          badges.filter((item) => [9, 10, 11, 12, 13].includes(item.badge.id))
        )}
        {getSection(
          t('gamification:Your medals'),
          badges.filter((item) => ![1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(item.badge.id))
        )}
      </div>
    </DialogDefault>
  );
};

GamificationDialogBadges.propTypes = {
  open: PropTypes.bool,
};

GamificationDialogBadges.defaultProps = {
  open: false,
};

export default GamificationDialogBadges;
