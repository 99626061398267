import React from "react";
import { useSelector } from "react-redux";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

/*import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";*/


export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);  
  i18n.changeLanguage(locale)
  return (
      <I18nextProvider i18n={i18n}>
        {children}
      </I18nextProvider>
  );
}
