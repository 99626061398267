import { getUserBadges } from '_core/crud/gamified.crud';
import { useUser } from '_core/lite/hooks/useUser';
import { useEffect, useState } from 'react';

const useGamificationDialogBadges = ({ open }) => {
  const { user } = useUser();
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      getBadges();
    }
  }, [open]);

  async function getBadges() {
    setLoading(true);
    const data = await getUserBadges({ userGuid: user.guid });
    if (data.status === 200) {
      setBadges(data?.data);
      setLoading(false);
    }
  }

  return { badges, loading };
};

export default useGamificationDialogBadges;
