import ProgressBar from '_core/modules/atoms/ProgressBar';
import React from 'react';
import MedalEmpty from '../icons/medals/MedalEmpty';

const GamificationBadge = ({ name, image, obtained, progress }) => {
  return (
    <div className="gamification-badge">
      {obtained && <img className="gamification-badge__image" src={image} alt={name} />}
      {!obtained && (
        <div className="gamification-badge__no-obteiner">
          <MedalEmpty />
          <div className="gamification-badge__progress">
            <ProgressBar progress={progress} withLabel={false} />
          </div>
        </div>
      )}
      <span className="gamification-badge__name">{name}</span>
    </div>
  );
};

export default GamificationBadge;
