import React from 'react';

function MedalEmpty() {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_76_12589)">
        <path
          d="M29.8575 21.3515H19.94C18.5582 21.3515 17.5524 20.5491 17.9101 19.7322L27.8189 0.693601C27.9979 0.284479 28.6036 0 29.2955 0H40.3991C41.4043 0 42.1359 0.583671 41.8757 1.17796L31.8875 20.398C31.6414 20.9604 30.8087 21.3515 29.8575 21.3515Z"
          fill="#E2E2E2"
        />
        <path
          d="M32.1488 31.1893H17.7342C16.9561 31.1893 16.2774 30.6578 16.0859 29.8987L14.1441 22.199C13.7094 20.4754 14.0822 18.6816 15.1669 17.278C16.2516 15.8743 17.8868 15.0693 19.6535 15.0693H30.2296C31.9962 15.0693 33.6316 15.8743 34.7163 17.278C35.801 18.6818 36.1736 20.4754 35.7389 22.199L33.7971 29.8987C33.6056 30.6576 32.9267 31.1893 32.1488 31.1893ZM19.0571 27.7656H30.826L32.4424 21.3565C32.6169 20.6642 32.4673 19.9439 32.0315 19.3801C31.5958 18.8162 30.9391 18.4929 30.2295 18.4929H19.6534C18.9439 18.4929 18.2871 18.8162 17.8513 19.3801C17.4156 19.9439 17.2661 20.6642 17.4406 21.3566L19.0571 27.7656Z"
          fill="#E2E2E2"
        />
        <path
          d="M30.156 15.0693H29.1405V18.493H30.156C30.8748 18.493 31.5401 18.8164 31.9814 19.3802C32.4226 19.944 32.5743 20.6643 32.3975 21.3566L30.7603 27.7656H29.1405V31.1893H32.1001C32.8883 31.1893 33.5758 30.6578 33.7697 29.8987L35.7365 22.1991C36.1768 20.4755 35.7994 18.6819 34.7007 17.2782C33.6019 15.8743 31.9455 15.0693 30.156 15.0693Z"
          fill="#E2E2E2"
        />
        <path
          d="M19.9472 21.3515H29.8647C31.2465 21.3515 31.2878 20.5491 30.9301 19.7322L21.1097 0.693601C20.9307 0.284479 20.325 0 19.6331 0H8.52952C7.52437 0 6.79277 0.583671 7.05293 1.17796L17.9172 20.398C18.1633 20.9604 18.996 21.3515 19.9472 21.3515Z"
          fill="#E2E2E2"
        />
      </g>
      <g filter="url(#filter1_i_76_12589)">
        <path
          d="M24.1278 64.0002C13.0575 64.0002 4 54.8916 4 43.7589C4 32.6262 13.0575 23.5176 24.1278 23.5176C35.198 23.5176 44.2555 32.6262 44.2555 43.7589C44.2555 54.8916 35.198 64.0002 24.1278 64.0002Z"
          fill="#E2E2E2"
        />
        <path
          d="M24.1278 60.6941C34.54 60.6941 42.9808 52.2057 42.9808 41.7347C42.9808 31.2638 34.54 22.7754 24.1278 22.7754C13.7156 22.7754 5.27478 31.2638 5.27478 41.7347C5.27478 52.2057 13.7156 60.6941 24.1278 60.6941Z"
          fill="#E2E2E2"
        />
        <path
          d="M9.27313 43.9511C9.27313 34.5058 16.9009 26.8277 26.2842 26.8277C30.4613 26.8277 34.2751 28.3512 37.2415 30.8496C34.1541 27.1324 29.4927 24.7559 24.2864 24.7559C14.9031 24.7559 7.27539 32.434 7.27539 41.8792C7.27539 47.1198 9.63635 51.812 13.3291 54.9807C10.7866 51.9948 9.27313 48.1558 9.27313 43.9511Z"
          fill="#E2E2E2"
        />
        <path
          d="M44.3989 41.6913C44.3989 52.8993 35.3622 61.9852 24.2148 61.9852C13.0674 61.9852 4.03064 52.8993 4.03064 41.6913C4.03064 30.4833 13.0674 21.3975 24.2148 21.3975C35.3622 21.3975 44.3989 30.4833 44.3989 41.6913ZM8.1344 41.6913C8.1344 50.6205 15.3338 57.8591 24.2148 57.8591C33.0958 57.8591 40.2952 50.6205 40.2952 41.6913C40.2952 32.7621 33.0958 25.5235 24.2148 25.5235C15.3338 25.5235 8.1344 32.7621 8.1344 41.6913Z"
          fill="#E2E2E2"
        />
      </g>
      <defs>
        <filter id="filter0_i_76_12589" x="7" y="0" width="34.9286" height="31.1895" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_76_12589" />
        </filter>
        <filter id="filter1_i_76_12589" x="4" y="21.3975" width="40.3989" height="42.6025" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_76_12589" />
        </filter>
      </defs>
    </svg>
  );
}

export default MedalEmpty;
