const MINT_WRAPPER_CLASS = 'mint-lesson';

function createScriptTags({ id, src }) {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.id = id;
    s.src = src;
    // s.async = true;
    s.onerror = function (err) {
      reject(err, s);
    };
    s.onload = s.onreadystatechange = function () {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        // console.log('se ha cargado Mathjax correctamente', window.MathJax);
        r = true;
        resolve();
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
}

function createInlineScript({ id, content, attrs = {} }) {
  if (document.getElementById(id)) return Promise.resolve();

  return new Promise((resolve) => {
    const tag = document.createElement('script');
    tag.id = id;
    tag.innerHTML = content;
    Object.entries(attrs).forEach((entry) => (tag[entry[0]] = entry[1]));
    document.head.append(tag);
    resolve();
  });
}

function createLinkTags({ id, src }) {
  return new Promise(function (resolve, reject) {
    const lnk = document.createElement('link');
    lnk.href = src;
    lnk.rel = 'stylesheet';
    lnk.type = 'text/css';
    (document.head || document.documentElement).appendChild(lnk);

    let r = false;

    // s.async = true;
    lnk.onerror = function (err) {
      reject(err, lnk);
    };
    lnk.onload = lnk.onreadystatechange = function () {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        // console.log('se ha cargado Mathjax correctamente', window.MathJax);
        r = true;
        resolve();
      }
    };
    const t = document.getElementsByTagName('link')[0];
    t.parentElement.insertBefore(lnk, t);
  });
}

function addMathJax() {
  const mathJaxConfig = `
    function configMathJax () {
      try{
        MathJax.Hub.Config({"fast-preview": {disabled: true},asciimath2jax: { processClass: "fr-math", ignoreClass: "no-mathjax|editor-page"},"TeX": { extensions: ["color.js","cancel.js"] },"HTML-CSS": { scale: 100, linebreaks: { automatic: true }, availableFonts : ["TeX"], preferredFont : "TeX", webFont : "STIX-Web"},SVG: { scale: 100, linebreaks: { automatic:true }, font: "STIX-Web"}, displayAlign: "left",showMathMenu: false,showMathMenuMSIE: false,showProcessingMessages: false, messageStyle: "none"});
       }catch(e){ /* setTimeout (configMathJax, 300) */}
    }
    configMathJax();`;
  const cdnPath = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_SVG'; // TeX-MML-AM_HTMLorMML // TeX-MML-AM_SVG // TeX-MML-AM_CHTML No renderiza los acentos, descartado

  if (window && !window.MathJax && !window.loadingMathLib) {
    window.loadingMathLib = true;
    return createScriptTags({ id: 'MathJax-script', src: cdnPath })
      .then(() => {
        return createInlineScript({
          id: 'MathJax-config',
          content: mathJaxConfig,
          attrs: { type: 'text/x-mathjax-config' },
        });
      })
      .catch((e) => console.log('error cargando Mathjax', e));
  } else {
    return Promise.resolve();
  }
}

function addAutoRenderKatex() {
  const autoRendercdnPath = 'https://cdn.jsdelivr.net/npm/katex@0.15.1/dist/contrib/auto-render.min.js';
  if (window && !window.renderMathInElement) {
    return createScriptTags({ id: 'Katex-auto-render', src: autoRendercdnPath });
  } else {
    return Promise.resolve();
  }
}

function addKatex() {
  const cdnPath = 'https://cdn.jsdelivr.net/npm/katex@0.15.1/dist/katex.min.js';
  if (window && !window.katex) {
    return createScriptTags({ id: 'Katex-script', src: cdnPath });
  } else {
    return Promise.resolve();
  }
}

function addKatexCss() {
  const cdnStylePath = 'https://cdn.jsdelivr.net/npm/katex@0.15.1/dist/katex.min.css';
  createLinkTags({ id: 'Katex-style', src: cdnStylePath });
}

function firstRender() {
  const lemonadeContainer = document.getElementsByClassName(MINT_WRAPPER_CLASS);
  if (lemonadeContainer.length > 0)
    if (window.renderMathInElement) {
      window.renderMathInElement(lemonadeContainer[0]);
    } else if (window.MathJax) {
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, lemonadeContainer[0]]);
    }
}

export function addLatex(mathEngine) {
  console.log('ADD MATH ENGINE BackOffice ', mathEngine);
  if (mathEngine === 'katex') {
    addKatex().then(() => {
      addKatexCss();
      addAutoRenderKatex().then(firstRender);
    });
  } else {
    addMathJax().then(firstRender);
  }
}
