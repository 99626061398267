import { normalize } from 'normalizr';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { createCollection, deleteCollection, getCollection, getCollections, updateCollection } from '../crud/collections.crud';
import { PERSIST_COLLECTIONS, REDUCER_COLLECTIONS } from './conf';

import { collectionsSchema } from '../crud/schema';
import { actionTypes as actionAuth } from './auth.duck';
import { actions as actionsDLO } from './DLO.duck';

export const actionTypes = {
  GetCollections: 'cms/GET_COLLECTIONS',
  SetCollections: 'cms/SET_COLLECTIONS',
  GetCollection: 'cms/GET_COLLECTION',
  SetCollection: 'cms/SET_COLLECTION',
  CreateCollection: 'cms/CREATE_COLLECTION',
  UpdateCollection: 'cms/UPDATE_COLLECTION',
  DeleteCollection: 'cms/DELETE_COLLECTION',
  AddCollection: 'cms/ADD_COLLECTION',
  ReplaceCollection: 'cms/REPLACE_COLLECTION',
  RemoveCollection: 'cms/REMOVE_COLLECTION',
};

const initialState = {
  data: {},
  result: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_COLLECTIONS, whitelist: ['data', 'result'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetCollections:
    case actionTypes.GetCollection:
      return { ...state, loading: true };

    case actionTypes.SetCollections: {
      const { collections, result } = action.payload;
      return { ...state, data: collections, result: result, loading: false };
    }

    case actionTypes.SetCollection: {
      const { collections } = action.payload;
      return { ...state, data: { ...state.data, ...collections }, loading: false };
    }

    case actionTypes.CreateCollection: {
      return { ...state };
    }
    case actionTypes.UpdateCollection: {
      return { ...state };
    }
    case actionTypes.DeleteCollection: {
      return { ...state };
    }

    case actionTypes.AddCollection: {
      const { coll } = action.payload;
      if (coll) {
        let data = Object.values(state.data).map((val) => {
          return val;
        });
        data.unshift(coll);
        return { ...state, data, loading: false };
      }
      return { ...state, loading: false };
    }

    case actionTypes.ReplaceCollection: {
      const { coll } = action.payload;
      if (coll) {
        let data = Object.values(state.data).map((c) => {
          return c.guid !== coll.guid ? c : { ...c, ...coll };
        });

        return { ...state, data };
      }
      return { ...state };
    }

    case actionTypes.RemoveCollection: {
      const { coll } = action.payload;
      if (coll) {
        let data = Object.values(state.data).filter((c) => {
          return c.guid !== coll.guid;
        });

        return { ...state, data };
      }
      return { ...state };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    default:
      return state;
  }
});

const getResultS = (state) => (state.entities ? state.entities[REDUCER_COLLECTIONS].result : null);
const getCollectionsS = (state) => (state.entities ? state.entities[REDUCER_COLLECTIONS].data : null);

export const selectors = {
  getCollections: (state) => {
    return getCollectionsS(state);
  },
  getCollectionsGUIDs: (state) => {
    return getResultS(state);
  },
  getCollectionByGUID: (state, guid) => {
    return guid ? state.entities[REDUCER_COLLECTIONS].data[guid] : null;
  },
  /*getCollectionByGUID: createSelector([getCollectionsS], (data, guid) =>
    data[guid]
  ),*/
  //Al hacer un map en un selector normal, se actualiza el estado de redux tantas veces como result haya, no se porque carajo
  //De esta manera memoriza el resultado y si cambia ahí es solo cuando se actualiza
  getCollectionsArray: createSelector([getCollectionsS, getResultS], (data, result) => (result ? result.map((guid) => data[guid]) : [])),
  getCollectionsItems: createSelector([getCollectionsS, getResultS], (data, result) =>
    result
      ? result.map((c) => {
          const { guid, icon, collection } = data[c];
          return { guid, icon, collection };
        })
      : []
  ),
};

export const actions = {
  getCollections: (params) => ({ type: actionTypes.GetCollections, payload: { params } }),
  fulfillCollections: (collections, result) => ({ type: actionTypes.SetCollections, payload: { collections, result } }),

  getCollection: (guid) => ({ type: actionTypes.GetCollection, payload: { params: { collections: [guid] } } }),
  fulfillCollection: (collections) => ({ type: actionTypes.SetCollection, payload: { collections } }),

  createCollection: (params, dloToAddToNewCollection) => ({ type: actionTypes.CreateCollection, payload: { params, dloToAddToNewCollection } }),
  updateCollection: (params) => ({ type: actionTypes.UpdateCollection, payload: { params } }),
  deleteCollection: (params) => ({ type: actionTypes.DeleteCollection, payload: { params } }),
  addOneCollection: (coll) => ({ type: actionTypes.AddCollection, payload: { coll } }),
  replaceOneCollection: (coll) => ({ type: actionTypes.ReplaceCollection, payload: { coll } }),
  removeCollection: (coll) => ({ type: actionTypes.RemoveCollection, payload: { coll } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetCollections, function* getCollectionsSaga(action) {
    const { data } = yield getCollections(action.payload);

    if (data && data.status === 'success' && data.data) {
      let dataNormalized = normalize(data.data.collections, [collectionsSchema]);
      //Rellenamos las colecciones
      yield put(actions.fulfillCollections(dataNormalized.entities.collections, dataNormalized.result));
    } else {
      yield put(actions.fulfillCollections({}));
    }
  });

  yield takeLatest(actionTypes.GetCollection, function* getCollectionSaga(action) {
    const { data } = yield getCollection(action.payload);
    if (data && data.status === 'success' && data.data) {
      let dataNormalized = normalize(data.data, [collectionsSchema]);
      yield put(actions.fulfillCollection(dataNormalized.entities.collections));
    } else {
      yield put(actions.fulfillCollection({}));
    }
  });

  yield takeLatest(actionTypes.CreateCollection, function* updateCollectionSaga(action) {
    const { data } = yield createCollection(action.payload.params);
    if (data && data.status === 'success' && data.data) yield put(actions.addOneCollection(data.data));
    if (data && data.status === 'success' && data.data && action.payload.dloToAddToNewCollection) {
      const dlo = { ...action.payload.dloToAddToNewCollection };
      dlo.collections = [data.data.guid];
      yield put(actionsDLO.updateDLO(dlo));
    }
  });
  yield takeLatest(actionTypes.UpdateCollection, function* updateCollectionSaga(action) {
    const { data } = yield updateCollection(action.payload.params);
    if (data && data.status === 'success' && data.data) yield put(actions.replaceOneCollection(data.data));
  });
  yield takeLatest(actionTypes.DeleteCollection, function* deleteCollectionSaga(action) {
    const { data } = yield deleteCollection(action.payload.params);
    if (data && data.status === 'success' && data.data) yield put(actions.removeCollection(data.data));
  });
}
