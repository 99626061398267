import React from 'react';
import { getUrlParam, guidGenerator } from '_core';
import { useTranslate } from '_core/hooks/useTranslate';
import LayoutDefault from '_core/layout/LayoutDefault';
import Icon from '_core/modules/atoms/Icon';

function SamlError() {
  const { t } = useTranslate();
  let username = getUrlParam('username');

  function onCloseSession() {
    if (document.cookie.indexOf('PHPSESSID') !== -1) document.cookie = 'PHPSESSID=' + guidGenerator() + '; Path=/;';
    let referrer = getUrlParam('referer');
    window.location.href = referrer;
  }

  return (
    <LayoutDefault>
      <div className="saml-error__wrapper">
        <div className="saml-error_header">
          <div className="saml-error__close-session" onClick={onCloseSession}>
            <div className="saml-error__close-session-icon">
              <Icon type="exit" color="primary" />
            </div>
            <div className="saml-error__close-session-text">{t('dropdow-profile:Sign out')}</div>
          </div>
        </div>
        <div className="saml-error__text">{t('login:Error saml sync alfa', { name: username })}</div>
        <div></div>
      </div>
    </LayoutDefault>
  );
}

export default SamlError;
