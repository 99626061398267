// Messaging Protocol
export const IDENT = 'identify';
export const CONNECT = 'connect';
export const DISCONNECT = 'disconnect';
export const CONNECT_ERR = 'connect_error';
export const RECONNECT_ERR = 'reconnect_error';
export const RECONNECT_ATTEMPT = 'reconnect_attempt';
export const ACTION = 'action';
export const ACTION_BRODCAST = 'personal-broadcast';
export const ACTION_NOTIFICATION = 'personal-notification';
