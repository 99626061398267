export const COURSE_MODE = {
  default: 'default',
  seeAsStudent: 'seeAsStudent',
};

export const ENTRY_POINTS = {
  stream: 'stream',
  program: 'program',
  grades: 'grades',
};

export const COURSE_TYPES = {
  NO_REFERENCED: 'no-referenced',
  REFERENCED: 'referenced',
  TEMPLATE_1: 'template-1',
  TEMPLATE_2: 'template-2',
};
