import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

import { getLessonItems } from '_core/crud/lessons.crud';
import { getContent, getLessonItem, searchBooks } from '../crud/contents.crud';
import { normalizeBook, normalizeContents } from '../crud/normalizers/content.normalizer';
import { getProject, getProjectPublic, postProjectCourses } from '../crud/projects.crud';
import { getProjectFromServer } from '../utils/project/projectDataFromServer';
import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_CONTENTS, REDUCER_CONTENT, REDUCER_CONTENTS } from './conf';
import { customTagsTypes, selectorsUtils as selectorsUtilsCustomTags } from './customTags.duck';
import { actionTypes as actionUI } from './ui.duck';

export const contentTypes = {
  IMAGE: 'CTTY_07',
  VIDEO: 'CTTY_03',
  AUDIO: 'CTTY_04',
  PDF: 'CTTY_05',
};

export function getTypeFromMime(mimeType) {
  var type = null;
  switch (mimeType) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
      type = contentTypes.IMAGE;
      break;
    case 'video/mp4':
    case 'video/ogg':
    case 'video/webm':
      type = contentTypes.VIDEO;
      break;
    case 'audio/mp3':
    case 'audio/webm':
    case 'audio/ogg':
      type = contentTypes.AUDIO;
      break;
    case 'application/pdf':
      type = contentTypes.PDF;
      break;
    default:
      break;
  }
  return type;
}

export const actionTypes = {
  SearchBooks: 'content/SEARCH_BOOKS',
  SetSearchBooks: 'content/SET_SEARCH_BOOKS',
  GetDetailBook: 'content/GET_BOOK',
  SetDetailBook: 'content/SET_BOOK',
  CleanDetailBook: 'content/CLEAN_BOOK',
  SetPreviewBook: 'content/PREVIEW_BOOK',
  //GetProjects: "content/GET_PROJECTS",
  SetProjects: 'content/SET_PROJECTS', //TEST
  GetDetailProject: 'content/GET_PORJECT', //TEST
  SetProject: 'content/SET_PROJECT', //TEST
  SetDraftDataProject: 'content/SET_DRAFT_PROJECT',
  PostProjectCourses: 'courses/POST_PROJECT_COURSES',
  PostProjectCoursesSuccess: 'courses/POST_PROJECT_COURSES_SUCCESS',
  // Lesson Items
  GetLessonItems: 'lms/GET_LESSON_ITEMS',
  SetLessonItems: 'lms/SET_LESSON_ITEMS',
  GetLessonItem: 'lms/GET_LESSON_ITEM',
  SetLessonItem: 'lms/SET_LESSON_ITEM',
};

const initialState = {
  currentSearch: { term: '', results: [] },
  searching: false,
  currentBook: undefined,
  projects: {},
  loadingProject: false,
  items: [],
  selectedLessonItem: {},
  //currentProject: null
};

export const reducer = persistReducer({ storage, key: PERSIST_CONTENTS, whitelist: ['projects'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SearchBooks:
      return { ...state, searching: true };
    case actionTypes.SetSearchBooks: {
      const { response } = action.payload;

      if (response && response.status === 'success' && response.data.contents)
        return { currentSearch: { term: action.payload.search, results: normalizeContents(response.data.contents) }, searching: false };
      return { ...state, searching: false };
    }

    case actionTypes.GetDetailBook:
      return { ...state, searching: true };

    case actionTypes.SetDetailBook: {
      const { response } = action.payload;

      if (response && response.status === 'success' && response.data) return { ...state, currentBook: normalizeBook(response.data), searching: false };
      return { ...state, searching: false };
    }

    case actionTypes.SetPreviewBook: {
      return { ...state, currentBook: action.payload };
    }

    case actionTypes.CleanDetailBook: {
      return { ...state, currentBook: undefined };
    }

    /*case actionTypes.GetProjects: {
          return state;
        }*/

    case actionTypes.SetProjects: {
      const projects = action.payload;
      const projectsFinal = {};
      for (var projectId in projects) {
        if (projects.hasOwnProperty(projectId)) {
          projectsFinal[projectId] = {
            ...state.projects[projectId],
            ...getProjectFromServer(projects[projectId]),
          };
        }
      }
      return { ...state, projects: { ...state.projects, ...projectsFinal } };
      //return {...state, projects: _merge(state.projects, projects)}
    }

    //TEST
    case actionTypes.GetDetailProject:
      return { ...state, loadingProject: true };

    //Revisar, cuando se hace un actionTypes.SetProjects se pierden las sections que aquí había
    case actionTypes.SetProject: {
      const projectPayload = action.payload;
      // == Al proyecto que añado si no trae el atributo de sections se lo creo como un array vacio
      const projectFinal = getProjectFromServer(projectPayload);
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectFinal.guid]: {
            ...state.projects[projectFinal.guid],
            ...projectFinal,
            sections: projectFinal && projectFinal.sections ? projectFinal.sections : [],
          },
        },
        loadingProject: false,
      };
    }

    case actionTypes.SetDraftDataProject: {
      const { guid, draftData } = action.payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          [guid]: {
            ...state.projects[guid],
            project_draft_data: draftData,
          },
        },
        loadingProject: false,
      };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      const { reducersNoEmpty } = action.payload;
      if (reducersNoEmpty && reducersNoEmpty.indexOf(REDUCER_CONTENTS) > -1) {
        return state; // == No limpio contents
      } else return initialState; // == Limpio contents
    }

    case actionUI.SearchHeaderbar: {
      // limpiar si no hay búsqueda
      if (!action.payload.value) return initialState;
    }

    case actionTypes.PostProjectCourses:
      return { ...state, loading: true };

    case actionTypes.PostProjectCoursesSuccess: {
      const { courses, projectGuid } = action.payload;
      const project = { ...state.projects[projectGuid], courses: [...state.projects[projectGuid].courses, ...courses] };
      const projects = { ...state.projects, [projectGuid]: project };

      return { ...state, loading: false, projects };
    }

    // Lesson Items
    case actionTypes.GetLessonItems: {
      return { ...state, loading: true };
    }

    case actionTypes.SetLessonItems: {
      const { params } = action.payload;
      if (params && params.status === 'success' && params.data) {
        return {
          ...state,
          items: params.data.items,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getCurrentSearch: (state) => {
    return state.entities[REDUCER_CONTENTS];
  },
  getDetailBook: (state) => {
    const { currentBook, searching } = state.entities[REDUCER_CONTENTS];
    return { currentBook, searching };
  },
  getProjectByGUID: (state, guid) => (guid ? state.entities[REDUCER_CONTENTS].projects[guid] : null),
  getLessonItems: (state) => (state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].items : null),
  getLessonItem: (state) => (state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].selectedItem : null),
  //getDetailProjectByGUID: (state, guid) => ( guid ? state.entities[REDUCER_CONTENTS].projects[guid] : null)
};

export const selectorsUtils = {
  /**
   * Devuelve true si dentro de los customTags del projecto existe dicho tipo
   */
  isExistCustomTagProject: (project, idCustomTag) => {
    var exists = false;
    if (project && project.customTags && project.customTags.length > 0) {
      const findCustomTag = project.customTags.find((c) => c.id + '' == idCustomTag + ''); // == Le sumo + "" para pasarlo a string en caso de que no sea, porque cuando viene del servidor viene como string, pero los datos que yo tengo de custom tags en redux vienen como integer. Y al servidor luego la lista que hay que enviarle es de integer aunque si le envias string tambien lo hace bien
      exists = findCustomTag ? true : false;
    }
    return exists;
  },
  /**
   * Devuelve true si el projecto tiene un customTags de Desigh for Change
   */
  isDesingForChangeProject: (project, customTags) => {
    var exists = false;
    const customTagsDesignForChange = selectorsUtilsCustomTags.getCustomTagsType(customTags, customTagsTypes.design_for_change);
    if (customTagsDesignForChange && customTagsDesignForChange.length > 0 && customTagsDesignForChange[0] && customTagsDesignForChange[0].id) {
      exists = selectorsUtils.isExistCustomTagProject(project, customTagsDesignForChange[0].id);
    }
    return exists;
  },
  /**
   * Devuelve todos los custom tags del projecto
   */
  getCustomTagsProject: (project) => {
    var customTagsProject = [];
    if (project && project.customTags && project.customTags.length > 0) {
      customTagsProject = project.customTags;
    }
    return customTagsProject;
  },
  /**
   * Devuelve todos los custom tags del projecto que sean del tipo type. Si no tiene devuelve un array vacio
   */
  getCustomTagsProjectType: (project, type) => {
    var customTagsProjectType = [];
    const customTagsProject = selectorsUtils.getCustomTagsProject(project);
    if (customTagsProject) {
      customTagsProjectType = customTagsProject.filter((c) => c.type == type);
    }
    return customTagsProjectType;
  },
};

export const actions = {
  getSearchBooks: (search) => ({ type: actionTypes.SearchBooks, payload: { search: search } }),
  fulfillSearchBooks: (response, search) => ({ type: actionTypes.SetSearchBooks, payload: { response, search } }),
  getDetailBook: (guid) => ({ type: actionTypes.GetDetailBook, payload: { guid: guid } }),
  fulfilDetailBook: (response) => ({ type: actionTypes.SetDetailBook, payload: { response } }),
  selectBook: (book) => ({ type: actionTypes.SetPreviewBook, payload: book }), // cuando desde una lista seleccionamos uno
  cleanCurrentBook: () => ({ type: actionTypes.CleanDetailBook }),
  //TEST
  fulfilProjects: (projects) => ({ type: actionTypes.SetProjects, payload: projects }),
  getDetailProject: (guid, isPublic = false) => ({ type: actionTypes.GetDetailProject, payload: { guid: guid, isPublic: isPublic } }),
  fulfilProject: (project) => ({ type: actionTypes.SetProject, payload: project }),
  fulfillDraftDataProject: (guid, draftData) => ({ type: actionTypes.SetDraftDataProject, payload: { guid: guid, draftData: draftData } }),
  postProjectCourses: (projectGuid, courses = []) => ({ type: actionTypes.PostProjectCourses, payload: { projectGuid, courses } }),
  postProjectCoursesSuccess: (data) => ({ type: actionTypes.PostProjectCoursesSuccess, payload: data }),
  // Lesson Items
  getLessonItems: (guid, courseGuid) => {
    return { type: actionTypes.GetLessonItems, payload: guid, courseGuid };
  },
  setLessonItems: (params) => ({ type: actionTypes.SetLessonItems, payload: { params } }),
  getLessonItem: (guid) => ({ type: actionTypes.GetLessonItem, payload: guid }),
  setLessonItem: (guid) => ({ type: actionTypes.SetLessonItem, payload: guid }),
};

export function* saga() {
  yield takeLatest(actionTypes.SearchBooks, function* searchBook(action) {
    const { data } = yield searchBooks(action.payload.search);

    yield put(actions.fulfillSearchBooks(data, action.payload.search));
  });

  yield takeLatest(actionTypes.GetDetailBook, function* searchBook(action) {
    const { data } = yield getContent(action.payload.guid);

    yield put(actions.fulfilDetailBook(data));
  });

  yield takeLatest(actionTypes.GetDetailProject, function* getProjectSaga(action) {
    const { guid, isPublic } = action.payload;

    const { data } = isPublic ? yield getProjectPublic(guid) : yield getProject(guid);
    if (data.status === 'success' && data.data) yield put(actions.fulfilProject(data.data));
  });

  yield takeLatest(actionTypes.PostProjectCourses, function* postProjectCoursesSaga(action) {
    const { projectGuid, courses } = action.payload;
    const params = { guid: courses.map((item) => item.guid) };
    const { data } = yield postProjectCourses(projectGuid, params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.postProjectCoursesSuccess({ projectGuid, courses }));
    } else {
      yield put(actions.postProjectCoursesSuccess([]));
    }
  });

  yield takeLatest(actionTypes.GetLessonItems, function* getLessonItemsSaga(action) {
    const { data } = yield getLessonItems(action.payload, action.courseGuid);

    yield put(actions.setLessonItems(data));
  });

  yield takeLatest(actionTypes.GetLessonItem, function* getLessonItemSaga(action) {
    const { data } = yield getLessonItem(action.payload);
    yield put(actions.setLessonItem(data));
  });
}
