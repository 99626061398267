import PropTypes from 'prop-types';
import React from 'react';
import Icon from './../Icon';

function customIconDefault(iconType, checked) {
  return <Icon type={iconType} className="input-checkbox__icon" color={checked ? 'primary' : 'default'} />;
}

const InputCheckbox = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      onClick,
      checked,
      disabled,
      iconChecked,
      iconUnchecked,
      customIconChecked = customIconDefault(iconChecked, true),
      customIconUnchecked = customIconDefault(iconUnchecked, false),
      ...props
    },
    ref
  ) => (
    <label className={`input-checkbox ${disabled ? 'input-checkbox--disabled' : ''}`} onClick={onClick}>
      <input type="checkbox" className="input-checkbox__input" value={value} onChange={onChange} checked={checked} disabled={disabled} {...props} />
      <div className={`input-checkbox__icon-container input-checkbox__icon-container--${checked ? 'checked' : 'unchecked'} `}>
        {checked ? customIconChecked : customIconUnchecked}
      </div>
      <span className="input-checkbox__label">{label}</span>
    </label>
  )
);

InputCheckbox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  icon: PropTypes.object,
  iconCheck: PropTypes.object,
  disabled: PropTypes.bool,
};

InputCheckbox.defaultProps = {
  text: '',
  onChange: () => {},
  checked: false,
  disabled: false,
  iconChecked: 'check-checked',
  iconUnchecked: 'check-unchecked',
};

export default InputCheckbox;
