import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getItemsOfLesson } from '_core/crud/lesson.crud';
import { createLesson, deleteLesson, editLesson, getLesson, getLessons, getProgramDetails, getTokenForThumbUpload, reorderLessons } from '../crud/lessons.crud';
import { PERSIST_LESSONS, REDUCER_LESSONS } from './conf';

export const actionTypes = {
  CreateLesson: 'lms/CREATE_LESSON',
  AddOneLesson: 'lms/ADD_LESSON',
  GetLessons: 'lms/GET_LESSONS',
  SetLessons: 'lms/SET_LESSONS',
  DeleteLesson: 'lms/DELETE_LESSON',
  RemoveLesson: 'lms/REMOVE_LESSON',
  EditLesson: 'lms/EDIT_LESSON',
  UpdateLesson: 'lms/UPDATE_LESSON',
  ReplaceLesson: 'lms/REPLACE_LESSON',
  ReorderLessons: 'lms/REORDER_LESSON',
  GetProgramDetails: 'lms/GET_CURRENT_PROGRAM_DETAILS',
  SetProgramDetails: 'lms/SET_CURRENT_PROGRAM_DETAILS',
  GetUploadTicket: 'lms/GET_LESSON_THUMBNAIL_TICKET',
  SetUploadTicket: 'lms/SET_LESSON_THUMBNAIL_TICKET',
  GetCurrentLesson: 'lms/GET_CURRENT_LESSON',
  SetCurrentLesson: 'lms/SET_CURRENT_LESSON',
  GetLessonItems: 'lms/GET_LESSON_ITEMS',
  SetLessonItems: 'lms/SET_LESSON_ITEMS',
};

const initialState = {
  programDetails: {},
  lessons: [],
  current: {},
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_LESSONS, whitelist: ['data', 'current'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CreateLesson:
      return { ...state, loading: true };

    case actionTypes.AddOneLesson:
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        let newItemsList = [...state.lessons[0].items, response.data];
        state.lessons[0].items = [...newItemsList];

        return {
          ...state,
          lessons: [...state.lessons],
          loading: false,
        };
      }
      return { ...state, loading: false };

    case actionTypes.GetLessons:
      return { ...state, loading: true };

    case actionTypes.SetLessons: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return {
          ...state,
          lessons: response.data.items,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.DeleteLesson: {
      return { ...state, loading: true };
    }

    case actionTypes.RemoveLesson: {
      const { response } = action.payload;
      if (response && response.status === 'success') {
        let lessons = [...state.lessons];
        if (lessons.length > 0) {
          let updatedLessonsList = lessons[0].items.filter((lesson) => response.lesson_guid !== lesson.lesson_guid);
          lessons[0].items = updatedLessonsList;

          let current = { ...state.current };
          if (current.lesson_guid == response.lesson_guid) {
            current = {};
          }

          return {
            ...state,
            lessons: lessons,
            current,
            loading: false,
          };
        }
      }
      return { ...state, loading: false };
    }

    case actionTypes.GetProgramDetails: {
      return { ...state, loading: true };
    }

    case actionTypes.SetProgramDetails: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return {
          ...state,
          programDetails: response.data,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.ReorderLessons: {
      return { ...state, loading: true };
    }

    case actionTypes.SetUploadTicket: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return {
          ...state,
          uploadTicket: response.data.data.upload,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.EditLesson: {
      return { ...state, loading: false };
    }

    case actionTypes.SetCurrentLesson: {
      const { response } = action.payload;
      let updatedLesson = response.data;

      return { ...state, current: updatedLesson };
    }

    case actionTypes.UpdateLesson: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        let lessons = [...state.lessons];
        if (lessons.length > 0) {
          lessons[0].items = lessons[0].items.map((lesson) => (lesson.lesson_guid == response.data.lesson_guid ? response.data : lesson));

          let current = { ...state.current };
          if (state.current.lesson_guid == response.data.lesson_guid) {
            current = response.data;
          }

          return {
            ...state,
            lessons,
            current,
            loading: false,
          };
        }
      }

      return { ...state, loading: false };
    }

    case actionTypes.GetLessonItems: {
      return { ...state, loading: true };
    }

    case actionTypes.SetLessonItems: {
      const { params } = action.payload;
      if (params && params.status === 'success' && params.data) {
        return {
          ...state,
          items: params.data.items,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    default:
      return { ...state, loading: false };
  }
});

export const selectors = {
  getLessons: (state) => {
    return state.entities && state.entities[REDUCER_LESSONS] ? state.entities[REDUCER_LESSONS].lessons : null;
  },
  getLessonsLoading: (state) => {
    return state.entities && state.entities[REDUCER_LESSONS] ? state.entities[REDUCER_LESSONS].loading : true;
  },
  getProgramDetails: (state) => {
    return state.entities && state.entities[REDUCER_LESSONS] ? state.entities[REDUCER_LESSONS].programDetails : null;
  },
  getUploadTicket: (state) => {
    return state.entities && state.entities[REDUCER_LESSONS] ? state.entities[REDUCER_LESSONS].uploadTicket : null;
  },
  getCurrentLesson: (state) => {
    return state.entities && state.entities[REDUCER_LESSONS] ? state.entities[REDUCER_LESSONS].current : null;
  },
};

export const actions = {
  getLessons: (guid) => ({ type: actionTypes.GetLessons, payload: { guid } }),
  fulfillLessons: (response) => ({ type: actionTypes.SetLessons, payload: { response } }),
  createLesson: (params) => ({ type: actionTypes.CreateLesson, payload: params }),
  addOneLesson: (response) => ({ type: actionTypes.AddOneLesson, payload: { response } }),
  deleteLesson: (payload) => ({ type: actionTypes.DeleteLesson, payload }),
  removeLesson: (response) => ({ type: actionTypes.RemoveLesson, payload: { response } }),
  editLesson: (params) => ({ type: actionTypes.EditLesson, payload: params }),
  updateLesson: (response) => ({ type: actionTypes.UpdateLesson, payload: { response } }),
  reorderLessons: (params) => ({ type: actionTypes.ReorderLessons, payload: params }),
  replaceLesson: (guid) => ({ type: actionTypes.ReplaceLesson, payload: { guid } }),
  getProgramDetails: (guid) => ({ type: actionTypes.GetProgramDetails, payload: { guid } }),
  setProgramDetails: (response) => ({ type: actionTypes.SetProgramDetails, payload: { response } }),
  getUploadTicket: (guid) => ({ type: actionTypes.GetUploadTicket, payload: { guid } }),
  setUploadTicket: (response) => ({ type: actionTypes.SetUploadTicket, payload: { response } }),
  getCurrentLesson: (guid) => ({ type: actionTypes.GetCurrentLesson, payload: guid }),
  setCurrentLesson: (response) => ({ type: actionTypes.SetCurrentLesson, payload: { response } }),
  getLessonItems: (guid) => ({ type: actionTypes.GetLessonItems, payload: guid }),
  setLessonItems: (params) => ({ type: actionTypes.SetLessonItems, payload: { params } }),
};

export function* saga() {
  // GET
  yield takeLatest(actionTypes.GetLessons, function* getLessonsSaga(action) {
    const { data } = yield getLessons(action.payload);

    yield put(actions.fulfillLessons(data));
  });

  // DELETE
  yield takeLatest(actionTypes.DeleteLesson, function* deleteLessonSaga(action) {
    const { data } = yield deleteLesson(action.payload);

    yield put(actions.removeLesson({ ...data, lesson_guid: action.payload.lessonGuid.lessonGuid }));
  });

  // UPDATE
  yield takeLatest(actionTypes.EditLesson, function* getLessonsSaga(action) {
    const editResponse = yield editLesson(action.payload);

    if (editResponse.data.data && editResponse.data.status === 'success' && editResponse.data.data.guid) {
      yield put(actions.setCurrentLesson(editResponse.data));
    }

    if (action.payload.image) {
      const { data } = yield getTokenForThumbUpload({ guid: action.payload.guid });

      yield put(actions.setUploadTicket(data));

      editResponse.data.data.thumbnail = action.payload.imageFakePath;
    }

    yield put(actions.updateLesson(editResponse.data));
  });

  // Create
  yield takeLatest(actionTypes.CreateLesson, function* createLessonSaga(action) {
    const newLessonData = yield createLesson(action.payload);
    newLessonData.data.data.thumbnail = action.payload.image;
    if (action.payload.image) {
      const { data } = yield getTokenForThumbUpload({ guid: newLessonData.data.data.lesson_guid });

      yield put(actions.setUploadTicket(data));
    }

    yield put(actions.addOneLesson(newLessonData.data));
  });

  // GET CURRENT PROGRAM
  yield takeLatest(actionTypes.GetProgramDetails, function* getProgramDetailsSaga(action) {
    const { data } = yield getProgramDetails(action.payload);

    yield put(actions.setProgramDetails(data));
  });

  // Get Image Upload Token
  yield takeLatest(actionTypes.GetUploadTicket, function* getUploadTicketSaga(action) {
    const { data } = yield getTokenForThumbUpload(action.payload);

    yield put(actions.setUploadTicket(data));
  });

  // REORDER
  yield takeLatest(actionTypes.ReorderLessons, function* reorderLessonsSaga(action) {
    const reorderData = yield reorderLessons(action.payload);
    const lessons = yield getLessons({ guid: action.payload.programGuid });
    yield put(actions.fulfillLessons(lessons.data));
  });

  yield takeLatest(actionTypes.GetCurrentLesson, function* getCurrentLessonSaga(action) {
    const { data } = yield getLesson(action.payload);

    yield put(actions.setCurrentLesson(data));
  });

  // LESSON ITEMS
  yield takeLatest(actionTypes.GetLessonItems, function* getLessonItemsSaga(action) {
    const { data } = yield getItemsOfLesson({ guid: action.payload }, action.courseGuid);
    yield put(actions.setLessonItems(data));
  });
}
