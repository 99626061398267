import { actionTypes as actionAuth } from './auth.duck';
import { REDUCER_PROJECTION } from './conf';

export const actionTypes = {
  SetPhoneConnected: 'SET_PHONE_CONNECTED',
};

const initialState = {
  isPhoneConnected: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetPhoneConnected: {
      return { ...state, isPhoneConnected: action.payload.params.isConnected };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    default:
      return state;
  }
};

export const selectors = {
  getIsPhoneConnected: (state) => {
    return state.entities[REDUCER_PROJECTION] && state.entities[REDUCER_PROJECTION].isPhoneConnected
      ? state.entities[REDUCER_PROJECTION].isPhoneConnected
      : false;
  },
};

export const actions = {
  setPhoneConnected: (params) => ({ type: actionTypes.SetPhoneConnected, payload: { params } }),
};
