import React from 'react';
import { ROLES_SELECT } from '_core/utils/user';

import Button from '_core/modules/atoms/Button';
import InputCheckbox from '_core/modules/atoms/InputCheckbox';
import InputText from '_core/modules/atoms/InputText';
import SelectCustomizable from '_core/modules/atoms/SelectCustomizable';

const SignupForm = () => {
  // deprecated

  return (
    <div className="login-form">
      <div className="login-form__item">
        <h3 className="login-form__title">Sign Up</h3>
      </div>
      <div className="login-form__item">
        <InputText label="User" />
      </div>
      <div className="login-form__item">
        <InputText label="Email" />
      </div>
      <div className="login-form__item">
        <InputText label="Password" type="password" />
      </div>
      <div className="login-form__item">
        <InputText label="Repeat password" type="password" />
      </div>
      <div className="login-form__item">
        <SelectCustomizable label="Rol" type="password" options={ROLES_SELECT} />
      </div>
      <div className="login-form__item">
        <div className="login-form__checkbox-container">
          <InputCheckbox label="Accept terms and conditions of use" />
        </div>
        <Button text="Sign Up" />
      </div>
      <div className="login-form__item">
        <span className="login-form__text">
          If you already have an account, <span className="login-form__link">login here</span>
        </span>
      </div>
    </div>
  );
};

export default SignupForm;
