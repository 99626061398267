import { usersMock } from './users';

export const commentsMock = [
  {
    user: usersMock[0],
    text: 'I own one and I often use it to watch the stars at night in summer. ',
    guid: '1',
  },
  {
    user: usersMock[0],
    text: 'Great job Laura!',
    guid: '2',
  },
  {
    user: usersMock[0],
    text: 'Thank you for sharing :)',
    guid: '3',
  },
];
