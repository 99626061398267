import { defaultConfig } from 'app/config/environment';
import axios from 'axios';

export function getOrganization() {
  return axios.get('organization', {
    headers: {
      'app-referer': defaultConfig.BASE_URL_ + 'home',
    },
  });
}
