import { useTranslate } from '_core/hooks/useTranslate';
import React from 'react';

const JoinClassStep = () => {
  const { t } = useTranslate();
  return (
    <div className="signup-step__join-class-step">
      <p>{t('teams:Te has unido a la clase')}.</p>
    </div>
  );
};

export default JoinClassStep;
