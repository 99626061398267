import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_DEMO, REDUCER_DEMO } from './conf';

export const actionTypes = {
  SetDemoStudent: 'SET_DEMO_STUDENT',
  SetPayload: 'SET_PAYLOAD',
};

const initialState = {
  demoStudentToken: null,
  payload: {},
};

export const reducer = persistReducer({ storage, key: PERSIST_DEMO, whitelist: ['demoStudentToken', 'payload'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetDemoStudent: {
      return { ...state, demoStudentToken: action.payload };
    }
    case actionTypes.SetPayload: {
      return { ...state, payload: action.payload };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    default:
      return state;
  }
});

export const selectors = {
  getDemoStudentToken: (state) => {
    return state[REDUCER_DEMO].demoStudentToken;
  },
  getPayload: (state) => {
    return state[REDUCER_DEMO].payload;
  },
};

export const actions = {
  setDemoStudent: (params) => ({ type: actionTypes.SetDemoStudent, payload: params }),
  setPayload: (params) => ({ type: actionTypes.SetPayload, payload: params }),
};
