import PdfViewerFullScreen from '_core/modules/components/pdfViewers/PdfViewerFullScreen/PdfViewerFullScreen';
import * as coreEntities from '_core/store/index';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Credits = () => {
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const [credits, setCredits] = useState('');

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      let privacyScope = scopes.filter((scope) => scope.scope === 'credits');
      if (privacyScope && privacyScope.length > 0) {
        setCredits(privacyScope[0].json);
      }
    }
  }, [scopes]);

  return (
    credits && (
      <div>
        <PdfViewerFullScreen url={credits} />
      </div>
    )
  );
};

export default Credits;
