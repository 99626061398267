import { useTranslate } from '_core/hooks/useTranslate';
import Button from '_core/modules/atoms/Button';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import { getLevel } from '_core/modules/components/gamified/gamified.service';
import PropTypes from 'prop-types';
import React from 'react';
import LevelUpBackground from '../icons/level/LevelUpBackground';

const GamificationDialogLevel = ({ open, onAccept, level }) => {
  const { t } = useTranslate();
  return (
    <DialogDefault open={open} className="gamification-dialog-level" buttonTextSucess={t('actions:Save')} buttonTextCancel={t('actions:Cancel')}>
      <div className="gamification-dialog-level__wraper">
        <span className="gamification-dialog-level__title">{t('gamification:Congratulations!')}</span>
        {level && <span className="gamification-dialog-level__description">{t('gamification:You went up to level x!', { level: level })}</span>}
        <div className="gamification-dialog-level__images">
          <div className="gamification-dialog-level__background">
            <LevelUpBackground />
          </div>
          {level && <div className="gamification-dialog-level__number">{getLevel(level)}</div>}
        </div>
        <div className="gamification-dialog-level__button">
          <Button text={t('actions:Accept')} variant="inline" onClick={onAccept} />
        </div>
      </div>
    </DialogDefault>
  );
};

GamificationDialogLevel.propTypes = {
  open: PropTypes.bool,
};

GamificationDialogLevel.defaultProps = {
  open: false,
};

export default GamificationDialogLevel;
