import { hasData } from "../../utils/validation";

export const defaultFilter = (filter) => {
  return {
    pageSize: 10,
    offset: 0,
    ...filter,
  };
};

export const defaultFilterDlo = (filter, page = 1, pageSize = 50) => {
  const filterFinal = {...filter}
  if (filterFinal.grade) delete filterFinal.grade;
  if (filterFinal.unit) delete filterFinal.unit;
  if (filterFinal.lesson) delete filterFinal.lesson;
  if (typeof filterFinal.teacher_only !== "undefined") {
    if (filterFinal.teacher_only !== "1") {
      delete filterFinal.teacher_only;
    }
  }
  var typeDlo = null;
  if (filterFinal.hasOwnProperty("typeDlo")){
    typeDlo = filterFinal.typeDlo;
    delete filterFinal.typeDlo; // == Que no se mande este atributo, ya que lo voy a mandar luego abajo con otro nombre
  }
  return {
    ...filterFinal,
    pageSize: pageSize,
    offset: ((page-1) * pageSize),
    page: page,
    type: ["CTTY_16"],
    orderBy: (filter && filter.orderBy) || "created_at desc",
    ...((typeDlo && hasData(typeDlo)) ? {dloType: [typeDlo]} : {}),
    
  };
};

export const defaultFilterAssetsAudiosImages = (filter, page = 1, pageSize = 50) => {
  const filterFinal = {...filter}
  if (filterFinal.grade) delete filterFinal.grade;
  if (filterFinal.unit) delete filterFinal.unit;
  if (filterFinal.lesson) delete filterFinal.lesson;
  if (typeof filterFinal.teacher_only !== "undefined") {
    if (filterFinal.teacher_only !== "1") {
      delete filterFinal.teacher_only;
    }
  }
  var typeDlo = null;
  if (filterFinal.hasOwnProperty("typeDlo")){
    typeDlo = filterFinal.typeDlo;
    delete filterFinal.typeDlo; // == Que no se mande este atributo, ya que lo voy a mandar luego abajo con otro nombre
  }
  return {
    ...filterFinal,
    pageSize: pageSize,
    offset: ((page-1) * pageSize),
    page: page,
    orderBy: (filterFinal && filterFinal.orderBy) || "created_at desc",
    ...((typeDlo && hasData(typeDlo)) ? {dloType: [typeDlo]} : {}),
  };
};

export const defaultFilterDloCard = (card) => {
  return {
    data: JSON.stringify(card),
  };
};
