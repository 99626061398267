import PropTypes from 'prop-types';
import React from 'react';

const LoadingDots = ({ loadingText, loadingPic }) => {
  return (
    <div className="loading-dots">
      {loadingPic && <img className="loading-dots__img" src={loadingPic} />}
      {loadingText && <p className="loading-dots__text">{loadingText}</p>}
      <div className="loading-dots__loader">
        <div className="loading-dots__dot-one"></div>
        <div className="loading-dots__dot-two"></div>
        <div className="loading-dots__dot-three"></div>
      </div>
    </div>
  );
};

LoadingDots.propTypes = {
  loadingText: PropTypes.string,
};

LoadingDots.defaultProps = {
  loadingText: '',
};

export default LoadingDots;
