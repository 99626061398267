import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getLearningObjectives } from '../crud/learningObjectives.crud';
import { PERSIST_LEARNING_OBJECTIVES, REDUCER_LEARNING_OBJECTIVES } from './conf';

export const actionTypes = {
  GetLearningObjectives: 'cms/GET_LEARNING_OBJECTIVES',
  SetLearningObjectives: 'cms/SET_LEARNING_OBJECTIVES',
};

const initialState = {
  data: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_LEARNING_OBJECTIVES, whitelist: ['data'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetLearningObjectives:
      return { ...state, loading: true };

    case actionTypes.SetLearningObjectives: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { data: response.data, loading: false };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getLearningObjectives: (state) => {
    return state.entities[REDUCER_LEARNING_OBJECTIVES].data;
  },
};

export const actions = {
  getLearningObjectives: (params) => ({ type: actionTypes.GetLearningObjectives, payload: { params } }),
  fulfillLearningObjectives: (response) => ({ type: actionTypes.SetLearningObjectives, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetLearningObjectives, function* getLearningObjectivesSaga(action) {
    const { data } = yield getLearningObjectives(action.payload.params);

    yield put(actions.fulfillLearningObjectives(data));
  });
}
