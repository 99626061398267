import axios from 'axios';

export const GET_TEMPLATES = 'lms/course-templates';
export const GET_FONTS = 'lms/course-typefaces';

export function getTemplates(params) {
  return axios.get(GET_TEMPLATES);
}

export function getFonts() {
  return axios.get(GET_FONTS);
}
