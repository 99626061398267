import PropTypes from 'prop-types';
import React from 'react';
import CoreImage from '../../../variables/images';

const Image = ({ id }) => {
  return <img className="image" src={CoreImage.getImage(id)} alt="" />;
};

Image.propTypes = {
  id: PropTypes.oneOf([
    'logoBig',
    'logoIconS',
    'defaultUser',
    'noCourses',
    'noBooks',
    'noItems',
    'noPosts',
    'noUsers',
    'noTasks',
    'noQuestions',
    'arrow-orange',
    'barMenuLogo',
    'logoWhite',
    'noPictureProgram',
  ]),
};

Image.defaultProps = {
  id: 'logoBig',
};

export default Image;
