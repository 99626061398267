import axios from 'axios';

export const COURSES = 'front/courses';

// caja de herramientas
export const RESOURCES = 'lms/courses/';
export const RESOURCES_MATERIALS = `lms/resource-materials`;
export const RESOURCES_MATERIALS_COURSE = `lms/resource-material-course`;

export function getCourses(params = {}) {
  if (params?.courseGuid) {
    return axios.get('front/courses/' + params.courseGuid);
  } else {
    if (!params?.pageSize) {
      params.pageSize = 24;
    }
    return axios.get(COURSES, { ...params });
  }
}

export function getTeacherCourses() {
  return axios.get(COURSES, { params: { type: ['template-2'] } });
}

export function getCourseResources({ courseGuid, referencedCourseGuid, params }) {
  let path = `${RESOURCES}${courseGuid}/resources`;
  if (referencedCourseGuid) path = path + `?referencedCourse=${referencedCourseGuid}`;
  return axios.get(path, { params });
}

export function getCourseTimetable(guid) {
  return axios.get(`${COURSES}/${guid}/timetable`);
}

export function putCourseTimetable(guid, timetable) {
  return axios.put(`${COURSES}/${guid}/timetable`, { timetable });
}

export function setCourseTimetable(payload) {
  return axios.put('front/courses/' + payload.guid + '/timetable/', payload.data);
}

export function createCourse(params) {
  if (params.token) {
    return axios.post(`front/courses`, params, {
      headers: {
        Authorization: params.token,
      },
    });
  }
  return axios.post(`front/courses`, params);
}

export function addPersonalizedCourse(params) {
  return axios.put(`lms/courses`, params);
}

export function deleteCourse(params) {
  return axios.delete('front/courses', { data: params });
}

export function updateCourse(params) {
  return axios.put('front/courses/' + params.guid, params);
}

export function updateLmsCourse(courseGuid, params) {
  return axios.put('lms/courses', params);
}

export function addCourseByCode(params) {
  return axios.post('front/join-course', { code: params });
}

export function getCourseMessages(courseGuid, params) {
  return axios.get('front/courses/' + courseGuid + '/messages', { params: params });
}

export function getOneCourseMessage(params) {
  return axios.get('front/courses/' + params.courseGuid + '/messages/' + params.messageGuid);
}

export function getCourseMessagesAnswers(params) {
  return axios.get('front/courses/' + params.courseGuid + '/messages/' + params.messageGuid, { params: params });
}

export function createMessage(params) {
  return axios.post('front/courses/' + params.courseGuid + '/messages', params);
}

export function getAllCoursesMessages(params) {
  return axios.get('front/courses/all-courses/messages', params);
}

export function addGoogleDriveFileToMessage(params) {
  return axios.post('front/courses/' + params.courseGuid + '/messages/' + params.messageGuid + '/upload-from-google-drive', {
    fileId: params.fileId,
    oauthToken: params.oauthToken,
  });
}

export function deleteMessage(params) {
  return axios.delete('front/courses/' + params.courseGuid + '/messages', { data: { guid: params.guid } });
}

export function editMessage(params) {
  let payload = {
    message: params.message,
    message_for: params.message_for,
    is_pinned: params.is_pinned,
  };
  return axios.put('front/courses/' + params.courseGuid + '/messages/' + params.guid, payload);
}

export function deleteAttachedFile(params) {
  return axios.delete('front/courses/' + params.courseGuid + '/messages/' + params.messageGuid + '/files/' + params.fileGuid);
}

export function getGradebook(params, courseGuid) {
  let path = 'front/courses/' + params.courseGuid + '/gradebook';
  let customized = params.customized ? params.customized : null;
  if (courseGuid) {
    path = path + `?courseGuid=${courseGuid}`;
  }

  return axios.get(path, { params: { ...(customized && { customized_by: customized }) } });
}

export function getScormStudentGrades(params) {
  let atempt = params?.first ? 'first' : 'last';
  return axios.get('front/courses/' + params.courseGuid + '/gradebook?job=' + params.jobGuid + '&attempt=' + atempt);
}

export function getXapiStudentGrades(params) {
  return axios.get('front/courses/' + params.courseGuid + '/gradebook?job=' + params.jobGuid);
}

export function getAdditioSync(courseGuid) {
  return axios.post(`lms/courses/${courseGuid}/additio-sync`, {});
}

export function getGlobalEducaSync(courseGuid) {
  return axios.post(`lms/courses/${courseGuid}/globaleduca-sync`, {});
}

export function getGradebookBooks(params) {
  return axios.get(`front/courses/${params.courseGuid}/gradebook-books`);
}

// crea materiales para incluirlos en la caja de herramientas
export function createMaterialForResources({ resource_guid, course_guid, contents }) {
  return axios.post(RESOURCES_MATERIALS, { resource_guid, course_guid, contents });
}

// borra materiales en la caja de herramientas
export function deleteMaterialForResources(params = { guid: [] }) {
  return axios.delete(RESOURCES_MATERIALS, { data: params });
}

// obtenemos un material de la caja de herramientas
export function getMaterialForResources({ materialGuid, course_guid, includeRecords = false }) {
  return axios.get(`${RESOURCES_MATERIALS}/${materialGuid}`, { params: { referencedCourseGuid: course_guid, includeRecords } });
}

export function updateDeliverAtResource({ resourceGuid, courseGuid, canDeliverLate, deliverAt }) {
  return axios.patch(`${RESOURCES_MATERIALS_COURSE}/${resourceGuid}`, {
    course_guid: courseGuid,
    deliver_at: new Date(deliverAt).toISOString().slice(0, 19).replace('T', ' '),
    can_deliver_late: canDeliverLate ? 'YES' : 'NO',
  });
}
