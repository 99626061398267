import AuthView from '_core/lite/views/AuthView/AuthView';
import { isTeams } from '_core/utils/teamsTab';
import LayoutConfig from 'app/config/layout/LayoutConfig';
import React from 'react';
import MSTeamsTab from '../MSTeamsTab';
import useLoginPage from './useLoginPage';

const LoginPage = () => {
  const { legal, privacy } = useLoginPage();

  return (
    <>
      {isTeams() && <MSTeamsTab />}
      {!isTeams() && <AuthView form="login" showSignup={LayoutConfig.login.showSignup} legal={legal} privacy={privacy} />}
    </>
  );
};

export default LoginPage;
