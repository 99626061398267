import { useTranslate } from '_core/hooks/useTranslate';
import * as coreEntities from '_core/store/index';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useDialogGlobalPost() {
  const { t } = useTranslate();
  const notificationData = useSelector((state) => coreEntities.ui.selectors.getShowGlobalPostDialog(state));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (notificationData.open) {
      setMessage(notificationData.message);
      setOpen(true);
    } else {
      setOpen(false);
      setMessage('');
    }
  }, [notificationData]);

  function onClose() {
    setOpen(false);
  }

  return { t, open, message, onClose };
}

export default useDialogGlobalPost;
