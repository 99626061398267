import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as coreEntities from '_core/store/index';

export default function useLoginPage() {
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));

  const [legal, setLegal] = useState(null);
  const [privacy, setPrivacy] = useState(null);
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      let legalScope = scopes.filter((scope) => scope.scope === 'legal');
      if (legalScope && legalScope.length > 0) {
        setLegal(legalScope[0].json);
        setTerms(legalScope[0].json);
      }
      let privacyScope = scopes.filter((scope) => scope.scope === 'privacy');
      if (privacyScope && privacyScope.length > 0) {
        setPrivacy(privacyScope[0].json);
      }
    }
  }, [scopes]);

  return {
    legal,
    privacy,
    terms,
  };
}
