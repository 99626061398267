import axios from "axios";

export const GET_CUSTOM_TAGS = "cms/custom-tags";

export function getCustomTags() {
  return axios.get(GET_CUSTOM_TAGS);
}

export function getCustomTagsTypes(types) {
  return axios.get(GET_CUSTOM_TAGS, {params: {type: types}});
}