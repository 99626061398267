import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { PERSIST_AI_ASSISTANT, REDUCER_AI_ASSISTANT } from './conf';

export const actionTypes = {
  SetScope: 'AI_ASSISTANT/SET_SCOPE',
  SetCourse: 'AI_ASSISTANT/SET_COURSE',
  SetLesson: 'AI_ASSISTANT/SET_LESSON',
};

const initialState = {
  scope: null,
  courseGuid: null,
  lessonGuid: null,
};

export const reducer = persistReducer({ storage, key: PERSIST_AI_ASSISTANT }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetScope: {
      return { ...state, scope: action.payload };
    }

    default:
      return state;
  }
});

export const selectors = {
  getScope: (state) => {
    return state[REDUCER_AI_ASSISTANT].scope;
  },
};

export const actions = {
  setScope: (params) => ({ type: actionTypes.SetScope, payload: params }),
};
