import React from 'react';

const ExpText = () => {
  return (
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8023 13C15.3355 13 16.1021 13.8161 16.1021 15.4482V17.8408C16.1021 19.473 15.3355 20.289 13.8023 20.289H11.7065V25H9.10986V13H13.8023ZM13.5055 18.1376V15.1515C13.5055 15.0526 13.4684 14.966 13.3943 14.8918C13.3324 14.8176 13.2521 14.7805 13.1531 14.7805H11.7065V18.5085H13.1531C13.2521 18.5085 13.3324 18.4714 13.3943 18.3972C13.4684 18.323 13.5055 18.2365 13.5055 18.1376Z"
        fill="#1462A0"
      />
      <path
        d="M7.71561 13L5.80526 18.9907L7.95673 25H5.24884L3.98764 20.8083L2.72643 25H0L2.15147 18.9907L0.278208 13H2.80062L3.98764 16.8764L5.17465 13H7.71561Z"
        fill="#1462A0"
      />
    </svg>
  );
};

export default ExpText;
