import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PERSIST_UI, REDUCER_UI } from '../conf';

export const actionTypes = {
  SaveTestMetaData: 'ui/TEST_METADATA',
};

const initialState = {
  testMetaData: {},
};

export const reducer = persistReducer({ storage, key: PERSIST_UI, blacklist: ['testMetaData'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SaveTestMetaData:
      return { ...state, testMetaData: action.payload.value };

    default:
      return state;
  }
});

export const selectors = {
  getTestMetaData: (state) => {
    return state[REDUCER_UI].testMetaData;
  },
};

export const actions = {
  saveTestMetaData: (value) => ({ type: actionTypes.SaveTestMetaData, payload: { value } }),
};
