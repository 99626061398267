import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
// import { normalize } from "normalizr";
import { createSelector } from 'reselect';
import { createProgram, deleteProgram, getProgram, getPrograms, updateProgram } from '../crud/programs.crud';
// import { actionTypes as actionAuth } from "../../../_core/store/auth.duck"; //TO DELETE ALL ON LOGOUT
import { PERSIST_PROGRAMS, REDUCER_PROGRAMS } from './conf';

export const actionTypes = {
  GetPrograms: 'Program/GET_PROGRAMS',
  SetPrograms: 'Program/SET_PROGRAMS',
  FilterPrograms: 'DLO/FILTER_PROGRAMS',
  SetFilteredPrograms: 'DLO/SET_FILTERED_PROGRAMS',
  CleanFilterPrograms: 'Program/CLEAN_FILTER_PROGRAMS',
  CreateProgram: 'Program/CREATE_PROGRAM',
  AddOneProgram: 'Program/ADD_ONE_PROGRAM',
  UpdateProgram: 'Program/UPDATE_PROGRAM',
  DeleteProgram: 'Program/DELETE_PROGRAM',
  RemoveProgram: 'Program/REMOVE_PROGRAM',
  ReplaceProgram: 'Program/REPLACE_PROGRAM',
  StatusUpsert: 'Program/ERROR_UPSERT_PROGRAM',
  GetDetailProgram: 'Program/GET_DETAIL_PROGRAM',
  SetDetailProgram: 'Program/SET_DETAIL_PROGRAM',
  SelectOneProgram: 'Program/SELECT_ONE_PROGRAM',
};

//TO HAVE A DEFAULT NAMING CONVENTION
const namesState = {
  name: 'programs',
  currentSearch: 'currentSearch',
  loading: 'loading',
  pagination: 'pagination',
};

const namesStateCurrentSearch = {
  search: 'search',
  result: 'result',
};

const namesStatePagination = {
  pageActive: 'pageActive',
  total: 'total',
};

const initialState = {
  [namesState.name]: {},
  [namesState.currentSearch]: { [namesStateCurrentSearch.search]: {}, [namesStateCurrentSearch.result]: [] },
  [namesState.pagination]: { [namesStatePagination.pageActive]: 1, [namesStatePagination.total]: null },
  [namesState.searching]: false,
  [namesState.loading]: false,
  loading: false,
  statusUpsert: null,
  current: {},
  currentEdit: {},
  loadingProgram: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_PROGRAMS, whitelist: ['programs', 'currentSearch'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetPrograms: {
      return { ...state, [namesState.loading]: true };
    }
    case actionTypes.CreateProgram: {
      return { ...state, [namesState.loading]: true, statusUpsert: null };
    }

    case actionTypes.SetPrograms: {
      const { result, total, page, offset, pageSize } = action.payload;
      if (result) {
        let contents = result;
        let pageResult = parseInt(page) + 1;
        if (offset > 0) {
          contents = Object.values(state[namesState.name].result).map((val) => {
            return val;
          });
          contents = [...contents, ...result];
        }
        return {
          ...state,
          [namesState.name]: {
            result: contents,
            loading: false,
            total: total,
            offset: offset,
          },
          [namesState.loading]: false,
          [namesState.pagination]: { [namesStatePagination.pageActive]: pageResult || 1, [namesStatePagination.total]: total },
        };
      } else {
        return { ...state, [namesState.loading]: false };
      }
    }
    case actionTypes.AddOneProgram: {
      const { params } = action.payload;
      if (params && params.guid) {
        let data = Object.values(state[namesState.name].result).map((val) => {
          return val;
        });
        data.unshift(params);
        return {
          ...state,
          [namesState.name]: {
            ...state[namesState.name],
            result: data,
          },
          statusUpsert: 200,
          [namesState.loading]: false,
        };
      } else {
        return { ...state, statusUpsert: 200, [namesState.loading]: false };
      }
    }

    case actionTypes.StatusUpsert: {
      return { ...state, statusUpsert: action.payload };
    }
    case actionTypes.UpdateProgram: {
      return { ...state, statusUpsert: null };
    }

    case actionTypes.DeleteProgram: {
      return { ...state, statusUpsert: null };
    }

    case actionTypes.ReplaceProgram: {
      const { params } = action.payload;
      if (params) {
        let programsResultAll = state[namesState.name].result.map((d) => {
          if (d.guid === params.guid) {
            return params;
          }
          return d;
        });
        let programsSearch = { ...state[namesState.currentSearch] };
        programsSearch.result = programsSearch.result.map((d) => {
          if (d.guid === params.guid) {
            return params;
          }
          return d;
        });
        return {
          ...state,
          [namesState.name]: {
            ...state[namesState.name],
            result: programsResultAll,
          },
          statusUpsert: 200,
          [namesState.currentSearch]: {
            [namesStateCurrentSearch.search]: programsSearch.search,
            [namesStateCurrentSearch.result]: programsSearch.result,
          },
        };
      }
      return { ...state, statusUpsert: 200 };
    }

    case actionTypes.RemoveProgram: {
      const { guid } = action.payload;
      if (guid) {
        let programsResultAll = state[namesState.name].result.filter((d) => {
          return d.guid !== guid[0];
        });
        let programsSearch = { ...state[namesState.currentSearch] };
        programsSearch.result = programsSearch.result.filter((d) => {
          return d.guid !== guid[0];
        });
        return {
          ...state,
          [namesState.name]: {
            ...state[namesState.name],
            result: programsResultAll,
          },
          [namesState.currentSearch]: {
            [namesStateCurrentSearch.search]: programsSearch.search,
            [namesStateCurrentSearch.result]: programsSearch.result,
          },
        };
      }
      return { ...state };
    }

    case actionTypes.FilterPrograms: {
      return { ...state, [namesState.searching]: true };
    }

    case actionTypes.SetFilteredPrograms: {
      const { programs, result, search, total, page, pageSize, offset } = action.payload;
      let contents = result;
      let pageResult = parseInt(page) + 1;
      if (offset > 0) {
        let programsSearch = { ...state[namesState.currentSearch] };
        contents = [...programsSearch.result, ...result];
      }
      return {
        ...state,
        [namesState.currentSearch]: {
          [namesStateCurrentSearch.search]: search,
          [namesStateCurrentSearch.result]: contents,
        },
        [namesState.searching]: false,
        [namesState.pagination]: { [namesStatePagination.pageActive]: pageResult || 1, [namesStatePagination.total]: total },
      };
    }

    case actionTypes.CleanFilterPrograms: {
      return {
        ...state,
        [namesState.currentSearch]: {
          [namesStateCurrentSearch.search]: {},
          [namesStateCurrentSearch.result]: [],
        },
      };
    }

    case actionTypes.GetDetailProgram: {
      return { ...state, loadingProgram: true };
    }

    case actionTypes.SetDetailProgram: {
      let current = action.payload && action.payload.guid ? action.payload : null;
      return {
        ...state,
        current: current,
        loadingProgram: false,
      };
    }

    default:
      return state;
  }
});

const getData = (state) => (state.entities ? state.entities[REDUCER_PROGRAMS][namesState.name].result : null);
const getResults = (state) => {
  const { result, loading, total } = state.entities[REDUCER_PROGRAMS][namesState.name];
  return { result, loading, total };
};
const getCurrentSearch = (state) => (state.entities ? state.entities[REDUCER_PROGRAMS][namesState.currentSearch] : null);
const getCurrentSearchResult = (state) => (state.entities ? state.entities[REDUCER_PROGRAMS][namesState.currentSearch][namesStateCurrentSearch.result] : null);
const getSearching = (state) => (state.entities ? state.entities[REDUCER_PROGRAMS][namesState.searching] : null);
const getPagination = (state) => (state.entities ? state.entities[REDUCER_PROGRAMS][namesState.pagination] : 1);
const getUpsertStatus = (state) => (state.entities ? state.entities[REDUCER_PROGRAMS].statusUpsert : false);

export const selectors = {
  getPrograms: createSelector([getData, getResults], (data, info) => {
    const programs = info.result ? info.result : [];
    return { programs: programs, loading: info.loading, total: info.total };
  }),
  getCurrentSearch: createSelector([getData, getCurrentSearchResult, getCurrentSearch, getSearching], (data, result, currentSearch, searching) => {
    const programs = result ? result : [];
    return {
      currentSearch: {
        search: currentSearch ? currentSearch[namesStateCurrentSearch.search] : {},
        results: programs,
      },
      searching: searching,
    };
  }),
  getDetailProgram: (state) => {
    return state.entities && state.entities[REDUCER_PROGRAMS] ? state.entities[REDUCER_PROGRAMS].current : null;
  },
  getPagination: (state) => {
    return getPagination(state);
  },
  // se repiten 2 y no se cual es el bueno
  getUpsertStatus: (state) => {
    return (state.entities && state.entities[REDUCER_PROGRAMS].statusUpsert) || false;
  },
  // getUpsertStatus: createSelector([getUpsertStatus], (upsertStatus) => {
  //   return {
  //     upsertStatus,
  //   };
  // }),
};

export const actions = {
  getPrograms: (filters, pageSize, page) => ({ type: actionTypes.GetPrograms, payload: { filters: filters, page: page, pageSize: pageSize } }),
  fulfillPrograms: (params) => ({ type: actionTypes.SetPrograms, payload: params }),
  createProgram: (params) => ({ type: actionTypes.CreateProgram, payload: { params } }),
  addOneProgram: (params) => ({ type: actionTypes.AddOneProgram, payload: { params } }),
  updateProgram: (params) => ({ type: actionTypes.UpdateProgram, payload: { params } }),
  replaceOneProgram: (params) => ({ type: actionTypes.ReplaceProgram, payload: { params } }),
  deleteProgram: (params) => ({ type: actionTypes.DeleteProgram, payload: { params } }),
  removeProgram: (params) => ({ type: actionTypes.RemoveProgram, payload: params }),
  setStatusUpsert: (params) => ({ type: actionTypes.StatusUpsert, payload: params }),
  filterPrograms: (filters, pageSize, page) => ({ type: actionTypes.FilterPrograms, payload: { filters: filters, page: page, pageSize: pageSize } }),
  fulfillFilteredPrograms: (params) => ({ type: actionTypes.SetFilteredPrograms, payload: params }),
  cleanFilterPrograms: (params) => ({ type: actionTypes.CleanFilterPrograms, payload: params }),
  getDetailProgram: (guid, versionGuid = null, isCards = true) => ({ type: actionTypes.GetDetailProgram, payload: { guid, versionGuid, isCards } }),
  fulfillDetailProgram: (params) => ({ type: actionTypes.SetDetailProgram, payload: params }),
  selectProgram: (params) => ({ type: actionTypes.SetDetailProgram, payload: params }), // cuando desde una lista seleccionamos uno
  selectOneProgram: (params) => ({ type: actionTypes.SelectOneProgram, payload: params }), // cuando desde una lista seleccionamos uno
};

export function* saga() {
  yield takeLatest(actionTypes.CreateProgram, function* createProgramSaga(action) {
    const { data, error } = yield createProgram(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.addOneProgram(data.data));
    } else {
      const status = (error && error.data && error.data.error.code) || 400;
      yield put(actions.setStatusUpsert(status));
    }
  });

  yield takeLatest(actionTypes.GetPrograms, function* getProgramsSaga(action) {
    const { filters, page, pageSize } = action.payload;
    const params = {
      pageSize: pageSize || 20,
      offset: (page && page * pageSize) || 0,
      orderBy: action.payload.orderBy || 'created_at desc',
    };
    const { data } = yield getPrograms(params);

    if (data && data.status === 'success' && data.data && data.data.programs) {
      yield put(
        actions.fulfillPrograms({
          result: data.data.programs,
          total: data.data.total,
          offset: data.data.offset,
          page: page,
          left: data.data.left,
          pageSize: pageSize,
        })
      );
    } else yield put(actions.fulfillPrograms([]));
  });

  yield takeLatest(actionTypes.FilterPrograms, function* filterProgramsSaga(action) {
    const { filters, pageSize, page } = action.payload;
    const params = {
      ...filters,
      offset: (page && page * pageSize) || 0,
      pageSize: pageSize,
      countries: (filters && filters.country && [filters.country]) || '',
      educationLevels: (filters && filters.educationLevel && [filters.educationLevel]) || '',
      educationYears: (filters && filters.educationYear && [filters.educationYear]) || '',
      disciplines: (filters && filters.discipline && [filters.discipline]) || '',
      is_active: (filters && filters.is_active) || null,
    };

    delete params.country;
    delete params.educationLevel;
    delete params.educationYear;
    delete params.discipline;

    const { data } = yield getPrograms(params);

    if (data && data.status === 'success' && data.data && data.data.programs) {
      // let programsNormalized = normalize(data.data.programs, [programsSchema]);
      yield put(
        actions.fulfillFilteredPrograms({
          result: data.data.programs,
          search: filters,
          total: data.data.total,
          page: page,
          offset: data.data.offset,
          left: data.data.left,
          pageSize: pageSize,
        })
      );
    } else yield put(actions.fulfillFilteredPrograms([]));
  });

  yield takeLatest(actionTypes.UpdateProgram, function* updateProgramSaga(action) {
    const { data, error } = yield updateProgram(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      if (action.payload.params && action.payload.params.callSuccess) action.payload.params.callSuccess();
      yield put(actions.replaceOneProgram(data.data));
    } else {
      const status = (error && error.data && error.data.error.code) || 400;
      yield put(actions.setStatusUpsert(status));
    }
  });

  yield takeLatest(actionTypes.DeleteProgram, function* deleteProgramSaga(action) {
    const { data } = yield deleteProgram(action.payload.params);
    if (data && data.status === 'success') yield put(actions.removeProgram(action.payload.params));
  });

  yield takeLatest(actionTypes.SelectOneProgram, function* selectOneProgramSaga(action) {
    const { data } = yield getProgram(action.payload.programGuid);
    if (data && data.status === 'success') {
      // yield put(
      //   actions.fulfillFilteredPrograms({
      //     result: [data.data],
      //     search: {},
      //   })
      // );
      yield put(actions.selectProgram(data.data));
    } else {
      window.location.href = '/programs';
    }
  });
}
