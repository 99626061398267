import { defaultConfig } from 'app/config/environment';
import axios from 'axios';

export function getTimetable(params) {
  //return axios.get('front/courses/' + params.guid + '/items');
  return axios.get('front/courses/' + params.guid + '/timetable');
}

// UNIDAD ADD
export function postSectionOfLesson(params) {
  return axios.post('front/lesson-items', params);
}

export function postUnit(params) {
  // UNIDAD ADD
  return axios.post('front/courses/' + params.guid + '/items', params.data);
}
export function deleteUnit(params) {
  // UNIDAD DELETE
  return axios.delete('front/courses/' + params.guid + '/items', { data: { guid: [params.guid] } });
}

export function editUnit(params) {
  // UNIDAD EDIT TITLE
  return axios.put('front/courses/' + params.course_guid + '/items/' + params.guid, params.data);
  //front/courses/{courseGuid}/items/{itemGuid}
}

export function editUnitOrder(params) {
  // UNIDAD EDIT ORDER
  return axios.put('front/courses/' + params.course_guid + '/items', params.data);
}

export function createLesson(payload) {
  //return axios.post('lms/programs/' + payload.program_id + '/lessons', payload.data);
}

export function getLessons(params) {
  // GET LESSONS
  return axios.get('front/courses/' + params.guid + '/items', { params });
}

export function getEvaluations(params) {
  return axios.get('/lms/courses/' + params.guid + '/evaluations');
}

export function postLesson(params) {
  // LESSON ADD
  return axios.post('front/courses/' + params.guid + '/lessons', params.data);
}

export function editLesson(payload) {
  // LESSON EDIT TITLE
  return axios.put('front/courses/' + payload.course_guid + '/lessons/' + payload.guid, payload.data);
}

// DELETE front/courses/{programGuid}/items
export function deleteLesson(params) {
  return axios.delete('front/courses/' + params.course_guid + '/items', { data: { guid: [params.guid] } });
}

// GET lms/programs/lessons/{lessonGuid}/thumbnail
// GET lms/programs/lessons/{lessonGuid}/thumbnail-blob
export function getTokenForThumbUpload(params) {
  return axios.get('lms/programs/lessons/' + params.guid + '/thumbnail-blob');
}

export function getTokenForImageUpload(params) {
  return axios.get('front/courses/' + params.courseGuid + '/items/' + params.itemGuid + '/image-blob', {
    headers: {
      'app-referer': defaultConfig.API_URL,
    },
  });
}

// GET lms/programs/{programGuid}
export function getProgramDetails(params) {
  return axios.get('lms/programs/' + params.guid);
}

export function reorderLessons(params) {
  return axios.put('front/courses/' + params.programGuid + '/items', params);
}

export function getLesson(guid, courseGuid) {
  let path = 'front/lessons/' + guid;
  if (courseGuid) path = path + `?referencedCourseGuid=${courseGuid}`;
  return axios.get(path);
}

export function getLessonItems(guid, courseGuid) {
  return axios.get('front/lessons/' + guid + '/items');
}

export function restoreLessonItem(payload) {
  return axios.put('front/courses/' + payload.course_guid + '/items/restore', { itemGuid: payload.item_guid });
}

export function restoreLesson(payload) {
  return axios.put('front/courses/' + payload.course_guid + '/lessons/restore', { lessonGuid: payload.lesson_guid });
}

//POSTS

export function getPosts(lessonGuid, courseGuid, type) {
  let path = `front/lessons/${lessonGuid}/posts`;
  if (courseGuid) path = path + `?courseGuid=${courseGuid}`;
  if (type) path = path + `&type=${type}`;
  return axios.get(path);
}

export function savePosts(lessonGuid, payload) {
  return axios.put(`front/lessons/${lessonGuid}`, payload);
}

export function copyMintBlocksFromPost(lessonGuid) {
  return axios.post(`front/lessons/${lessonGuid}/mint-lesson-blocks-copy`, {});
}

export function getUnits(pageSize = 10, offset = 0, educationYear = [], discipline = [], program = [], search) {
  const optParams = {};
  if (educationYear) optParams.educationYear = [educationYear];
  if (discipline) optParams.discipline = [discipline];
  if (program) optParams.program = [program];
  if (search) optParams.search = search;
  return axios.get('front/units', {
    params: {
      ...optParams,
      pageSize: pageSize,
      offset: offset,
      orderBy: 'unit_name asc',
    },
  });
}

export function getFilterUnits() {
  return axios.get('front/unit-filters');
}

export function copyUnits(units, courseGuid, config, names, unitVisibility = 1) {
  return axios.post('front/unit-copy', {
    units: units,
    course_guid: courseGuid,
    unit_config: config,
    unit_visibility: unitVisibility,
    ...(names && { names: names }),
  });
}

export function replaceContentInLessonItem(itemGuid) {
  return axios.put(`front/lesson-items/${itemGuid}?action=cloneQuestion`, {});
}

export function getActivitiesOfUnits(unitsGuid, pageSize, offset, optParams) {
  return axios.get('front/unit-activities', {
    params: {
      ...optParams,
      pageSize: pageSize,
      offset: offset,
      unit: unitsGuid,
    },
  });
}
