import { ReactComponent as Close } from '../assets/icons/close.svg';

export default {
  getIcon: (icon) => {
    let componentIcon;

    switch (icon) {
      default:
        componentIcon = Close;
        break;
    }

    return { icon: componentIcon, lib: 'core' };
  },
};
