import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getSchoolYears } from '../crud/schoolYears.crud';
import { PERSIST_EDUCATION_LEVELS, REDUCER_SCHOOL_YEARS } from './conf';

export const actionTypes = {
  GetSchoolYears: 'cms/GET_SCHOOL_YEARS',
  SetSchoolYears: 'cms/SET_SCHOOL_YEARS',
};

const initialState = {
  years: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_EDUCATION_LEVELS, whitelist: ['years'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetSchoolYears:
      return { ...state, loading: true };

    case actionTypes.SetSchoolYears: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { years: response.data, loading: false };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getSchoolYears: (state) => {
    return state.entities && state.entities[REDUCER_SCHOOL_YEARS] ? state.entities[REDUCER_SCHOOL_YEARS].years : null;
  },
};

export const actions = {
  getSchoolYears: (params) => ({ type: actionTypes.GetSchoolYears, payload: params }),
  fulfillSchoolYears: (response) => ({ type: actionTypes.SetSchoolYears, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetSchoolYears, function* getSchoolYearsSaga(action) {
    const { data } = yield getSchoolYears(action.payload.params);
    if (data && data.status === 'success') {
      yield put(actions.fulfillSchoolYears(data));
    }
  });
}
