import axios from 'axios';

export const GET_LEARNING_OBJECTIVES = 'cms/learning-objectives';
export const CONTENTS = 'cms/contents';

export function getLearningObjectives(params) {
  return axios.get(GET_LEARNING_OBJECTIVES, { params: params });
}

export function addLearningObjective(params) {
  return axios.post(CONTENTS + '/' + params.contentGuid + '/learning-objectives', params);
}

export function getLearningObjectivesContent(params) {
  return axios.get(CONTENTS + '/' + params.contentGuid + '/learning-objectives');
}

export function removeLearningObjective(params) {
  return axios.delete(CONTENTS + '/' + params.contentGuid + '/learning-objectives/' + params.learningObjectiveGuid, params);
}
