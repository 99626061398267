import { useTranslate } from '_core/hooks/useTranslate';
import { getTextInLanguage } from '_core/modules/components/gamified/gamified.service';
import * as coreEntities from '_core/store/index';
import { defaultConfig } from 'app/config/environment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import io from 'socket.io-client';
const PATH_WEBSOCKET = '/game-eng/socket.io/';

const useGamificationSocket = ({ userGuid, isStudentGamified }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t } = useTranslate();

  const [notifications, setNotifications] = useState([]);
  const [notificationsExp, setNotificationsExp] = useState([]);

  const addNotification = (content) => {
    setNotifications((prevNotifications) => [...prevNotifications, content]);
  };

  const addNotificationExp = (content) => {
    setNotificationsExp((prevNotifications) => [...prevNotifications, content]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications.length > 0) {
        const [nextNotification, ...remainingNotifications] = notifications;
        openToast(nextNotification);
        setNotifications(remainingNotifications);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [notifications]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notificationsExp.length > 0) {
        const [nextNotification, ...remainingNotifications] = notificationsExp;
        dispatch(coreEntities.gamification.actions.addNotification(nextNotification.points));
        setNotificationsExp(remainingNotifications);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [notificationsExp, dispatch]);

  function openToast(gamified) {
    addToast(undefined, {
      appearance: 'success',
      autoDismiss: true,
      isGamified: true,
      gamified,
    });
  }

  useEffect(() => {
    let socket = null;
    if (userGuid && isStudentGamified) {
      socket = io(defaultConfig.BASE_URL_GAMIFICATION, {
        path: PATH_WEBSOCKET,
        reconnection: true,
        transports: ['websocket'],
        reconnectionAttempts: 5, // Número máximo de intentos de reconexión
        reconnectionDelay: 10000, // Tiempo de espera entre intentos de reconexión (en milisegundos)
        reconnectionDelayMax: 20000, // Tiempo máximo de espera entre intentos de reconexión (en milisegundos)
        timeout: 20000,
      });
      socket.on('connect', () => {
        console.log('Connected to WebSocket server', userGuid);
        // Unirse a la sala del usuario, por ejemplo, utilizando el userId
        socket.emit('joinRoom', userGuid);
      });

      socket.on('pointsReward', (reward) => {
        console.log('Points reward received:', reward);
        addNotificationExp(reward);
        dispatch(coreEntities.gamification.actions.fillUserGamification(userGuid));
      });

      socket.on('badgeReward', (reward) => {
        console.log('Badge reward received:', reward);
        dispatch(coreEntities.gamification.actions.fillUserGamification(userGuid));
        const gamified = {
          guid: reward.badge?.id,
          image: reward.badge?.imageUrl,
          title: t('gamification:New medal'),
          subtitle: getTextInLanguage(reward.badge?.description),
        };
        addNotification(gamified);
      });

      socket.on('currencyReward', (reward) => {
        console.log('Currency reward received:', reward);
      });

      socket.on('levelUp', (newLevel) => {
        console.log('Level up:', newLevel);
        dispatch(coreEntities.gamification.actions.setDialogLevel(true, newLevel.currentLevel ? newLevel.currentLevel.order : newLevel.order));
        dispatch(coreEntities.gamification.actions.fillUserGamification(userGuid));
      });
    }

    return () => {
      if (userGuid && socket) {
        socket.off('connect');
        socket.off('pointsReward');
        socket.off('badgeReward');
        socket.off('currencyReward');
        socket.off('levelUp');
        socket.close();
      }
    };
  }, [userGuid, isStudentGamified]);

  return {};
};

export default useGamificationSocket;
