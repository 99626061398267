import User1 from '_core/modules/components/gamified/icons/icons/User1';
import User2 from '_core/modules/components/gamified/icons/icons/User2';
import User3 from '_core/modules/components/gamified/icons/icons/User3';
import User4 from '_core/modules/components/gamified/icons/icons/User4';
import User5 from '_core/modules/components/gamified/icons/icons/User5';
import User6 from '_core/modules/components/gamified/icons/icons/User6';
import User7 from '_core/modules/components/gamified/icons/icons/User7';
import Level1 from '_core/modules/components/gamified/icons/level/Level1';
import Level2 from '_core/modules/components/gamified/icons/level/Level2';
import Level3 from '_core/modules/components/gamified/icons/level/Level3';
import Level4 from '_core/modules/components/gamified/icons/level/Level4';
import Level5 from '_core/modules/components/gamified/icons/level/Level5';
import Medal1 from '_core/modules/components/gamified/icons/medals/Medal1';
import Medal2 from '_core/modules/components/gamified/icons/medals/Medal2';
import Medal3 from '_core/modules/components/gamified/icons/medals/Medal3';
import Medal5 from '_core/modules/components/gamified/icons/medals/Medal5';
import MedalCheck from '_core/modules/components/gamified/icons/medals/MedalCheck';
import MedalEmpty from '_core/modules/components/gamified/icons/medals/MedalEmpty';
import MedalStart from '_core/modules/components/gamified/icons/medals/MedalStart';
import MedalTop from '_core/modules/components/gamified/icons/medals/MedalTop';
import Position from '_core/modules/components/gamified/icons/position/Position';
import Position1 from '_core/modules/components/gamified/icons/position/Position1';
import Position2 from '_core/modules/components/gamified/icons/position/Position2';
import Position3 from '_core/modules/components/gamified/icons/position/Position3';
import i18n from 'i18next';

const LEVEL = [
  { level: 1, component: Level1 },
  { level: 2, component: Level2 },
  { level: 3, component: Level3 },
  { level: 4, component: Level4 },
  { level: 5, component: Level5 },
];

const MEDAL = [
  { medal: 0, component: MedalEmpty },
  { medal: 1, component: Medal1 },
  { medal: 2, component: Medal2 },
  { medal: 3, component: Medal3 },
  { medal: 5, component: Medal5 },
  { medal: 'check', component: MedalCheck },
  { medal: 'top', component: MedalTop },
  { medal: 'start', component: MedalStart },
];

const POSITION = [
  { position: 'x', component: Position },
  { position: 1, component: Position1 },
  { position: 2, component: Position2 },
  { position: 3, component: Position3 },
];

const USER_AVATAR = [
  { key: 0, component: User1 },
  { key: 1, component: User2 },
  { key: 2, component: User3 },
  { key: 3, component: User4 },
  { key: 4, component: User5 },
  { key: 5, component: User6 },
  { key: 6, component: User7 },
];

export const MY_ARCHIEVEMENTS = [
  { medal: 1, type: 'unit', value: 1, valueSub: 0, isLoading: true, guid: 'complete_first_unit', title: 'gamified:1_finished_unit' },
  { medal: 3, type: 'unit', value: 3, valueSub: 0, isLoading: true, guid: 2, title: 'gamified:x_units_finished' },
  { medal: 5, type: 'unit', value: 5, valueSub: 0, isLoading: true, guid: 3, title: 'gamified:x_units_finished' },
  {
    medal: 'check',
    type: 'activity',
    value: 5,
    valueSub: 0,
    isLoading: true,
    guid: 'five_consecutive_correct_activities',
    title: 'gamified:x_correct_activities_followed',
  },
  { medal: 'check', type: 'activity', value: 10, valueSub: 0, isLoading: true, guid: 5, title: 'gamified:x_correct_activities_followed' },
  { medal: 'top', type: 'ranking', value: undefined, valueSub: 0, isLoading: true, guid: 'assessment_perfect', title: 'gamified:assessment_perfect' },
];

export const EVENT_SOCKET = [
  {
    guid: 'event_complete_first_unit',
    medal: 1,
    title: 'gamified:You ve gotten a medal',
    subtitle: 'gamified:You have successfully completed a unit',
  },
  {
    guid: 'event_five_consecutive_correct_activities',
    medal: 'check',
    title: 'gamified:You ve gotten a medal',
    subtitle: 'gamified:You have answered correctly to X activities in a row',
    number: 5,
  },
  {
    guid: 'event_assessment_perfect',
    medal: 'top',
    title: 'gamified:You ve gotten a medal',
    subtitle: 'gamified:assessment_perfect',
    number: undefined,
  },
];

export function getLevel(level) {
  const find = LEVEL.find((item) => item.level === level);
  return find ? find.component() : LEVEL[0].component();
}

export function getPosition(position) {
  const find = POSITION.find((item) => item.position === position);
  return find ? find.component() : POSITION[0].component({ position });
}

export function getUserAvatar(key) {
  const find = USER_AVATAR.find((item) => item.key === key);
  return find ? find.component() : USER_AVATAR[0].component();
}

export function getMedal(medal) {
  const find = MEDAL.find((item) => item.medal === medal);
  return find ? find.component() : MEDAL[0].component();
}

export function getTextInLanguage(texts) {
  return texts[i18n.language.split('-')[0]];
}
