import axios from 'axios';
import { parseActivities } from '_core/utils/projection';

export const CALENDARS = 'calendars';
export const CALENDAR = 'calendar';
export const CALENDAR_THEMES = 'calendar-themes';
export const CALENDAR_EVENTS = 'calendar/calendarGuid/events';
export const CALENDAR_HOLIDAYS = 'calendar/holiday';

export function getCalendars(params) {
  return axios.get(CALENDARS, { params: params });
}

export function getCalendarEvents(params) {
  return axios.get(CALENDAR_EVENTS, { params: params });
}

export function getCalendarThemes(params) {
  return axios.get(CALENDAR_THEMES, { params: params });
}

export function getCalendarHolidays(params) {
  return axios.get(CALENDAR_HOLIDAYS, { params: params });
}

export function setCalendarHoliday(params) {
  return axios.post(CALENDAR_HOLIDAYS, params);
}

export function deleteCalendarHoliday(params) {
  return axios.delete(CALENDAR_HOLIDAYS, { data: { guid: [params] } });
}

export function putCalendarHoliday(params) {
  return axios.put(CALENDAR_HOLIDAYS + '/' + params.guid, params);
}

export function postExtraClasses(calendarGuid, params) {
  return axios.post(CALENDAR + '/' + calendarGuid + '/extra-classes', params);
}

export function getAllCalendarEvents(params) {
  return axios.get('calendar/' + params.calendarGuid + '/events?fromDate=' + params.fromDate + '&toDate=' + params.toDate + '&calendar[]=' + params.calendar);
}
