import { useTranslate } from '_core/hooks/useTranslate';
import React from 'react';

import Button from '_core/modules/atoms/Button';
import LineWithText from '_core/modules/atoms/LineWithText';
import Tag from '_core/modules/atoms/Tag';

const WelcomeStep = ({ userContext, onLoginMicrosoft, setStep, setMode, onConsentRequest, shouldConsent, isLoading }) => {
  const { t } = useTranslate();

  return (
    <div className="welcome-step__container">
      <div className="welcome-step__welcome-text">
        {t('teams:Hola')} {userContext?.user?.displayName || userContext?.user?.userPrincipalName}!
      </div>
      <div style={{ marginTop: 16 }}>
        <div style={{ marginTop: 8 }}>
          {t('teams:Estás añadiendo Macmillan a')} <Tag text={`${userContext?.team?.displayName} - ${userContext?.channel?.displayName}`} />
        </div>
        <div style={{ marginTop: 48 }}>
          {!shouldConsent && (
            <>
              <Button
                testid="btn-sso-microsoft"
                color="white"
                text={`${t('teams:Accede con Microsoft')}`}
                icon="microsoft"
                iconPosition="left"
                loading={isLoading}
                onClick={onLoginMicrosoft}
              />
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <LineWithText text="O" />
              </div>
              <Button
                text={`${t('teams:Si no tienes una cuenta aún,')} ${t('teams:regístrate aquí')}`}
                color="white"
                onClick={() => {
                  setStep(2);
                  setMode('register');
                }}
              />
            </>
          )}

          {shouldConsent && (
            <Button text={t('teams:No cierres esta ventana, en unos segundos serás redigirido a Teams')} color="white" onClick={onConsentRequest} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomeStep;
