import React from 'react';

function Level5() {
  return (
    <svg width="66" height="70" viewBox="0 0 66 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.1073 6.35127C30.5857 3.21624 35.345 3.21625 37.8234 6.35127L40.8287 10.1527C42.1612 11.8382 44.2737 12.7123 46.409 12.4617L58.0952 13C58.0952 13 58.0952 12.8831 58.0952 18.7596L58.1484 23.5704C57.8976 25.7035 58.1536 27.8138 59.8409 29.1449L65.9923 33.2897C65.9923 33.2897 65.9079 34.9881 65.9923 36.6908C66.087 38.5995 65.3049 40.5444 63.6463 41.853L59.8409 44.8551C58.1536 46.1862 57.8976 48.2965 58.1484 50.4296L58.0952 55.2404C58.5618 59.2079 55.1965 62.5696 51.2249 62.1035L46.409 61.5383C44.2737 61.2877 42.1612 62.1618 40.8287 63.8473L37.8234 67.6487C35.345 70.7838 30.5857 70.7838 28.1073 67.6487L25.102 63.8473C23.7695 62.1618 21.657 61.2877 19.5217 61.5383L14.7058 62.1035C10.7342 62.5696 7.3689 59.2079 7.8355 55.2404L7.78225 50.4296C8.03311 48.2965 7.7771 46.1862 6.08983 44.8551L2.28444 41.853C0.446024 40.4026 0 38.2368 0 36.0724C0.000102998 34.5265 0 32.9805 0 32.9805L6.08983 29.1449C7.77711 27.8138 8.03311 25.7035 7.78225 23.5704L7.8355 18.7596L8 13L19.5217 12.4617C21.657 12.7123 23.7695 11.8382 25.102 10.1527L28.1073 6.35127Z"
        fill="#6E30BC"
      />
      <path
        d="M28.147 2.35127C30.6229 -0.78376 35.3771 -0.783753 37.853 2.35127L40.8551 6.15267C42.1862 7.83818 44.2965 8.71231 46.4296 8.46171L51.2404 7.89652C55.2078 7.4304 58.5696 10.7922 58.1035 14.7596L57.5383 19.5704C57.2877 21.7035 58.1618 23.8138 59.8473 25.1449L63.6487 28.147C66.7838 30.6229 66.7838 35.3771 63.6487 37.853L59.8473 40.8551C58.1618 42.1862 57.2877 44.2965 57.5383 46.4296L58.1035 51.2404C58.5696 55.2079 55.2078 58.5696 51.2404 58.1035L46.4295 57.5383C44.2965 57.2877 42.1862 58.1618 40.8551 59.8473L37.853 63.6487C35.3771 66.7838 30.6229 66.7838 28.147 63.6487L25.1449 59.8473C23.8138 58.1618 21.7035 57.2877 19.5704 57.5383L14.7596 58.1035C10.7922 58.5696 7.4304 55.2079 7.89651 51.2404L8.4617 46.4296C8.7123 44.2965 7.83817 42.1862 6.15267 40.8551L2.35127 37.853C-0.783753 35.3771 -0.783759 30.6229 2.35127 28.147L6.15267 25.1449C7.83818 23.8138 8.7123 21.7035 8.4617 19.5704L7.89651 14.7596C7.4304 10.7922 10.7921 7.4304 14.7596 7.89652L19.5704 8.46171C21.7035 8.7123 23.8138 7.83818 25.1449 6.15268L28.147 2.35127Z"
        fill="#8F45ED"
      />
      <path
        d="M30.0583 6.42525C31.5591 4.52492 34.4409 4.52492 35.9417 6.42525L39.9342 11.4807C40.741 12.5024 42.0202 13.0323 43.3132 12.8804L49.7111 12.1287C52.116 11.8462 54.1538 13.884 53.8713 16.2889L53.1196 22.6868C52.9677 23.9798 53.4976 25.259 54.5193 26.0658L59.5747 30.0583C61.4751 31.5591 61.4751 34.4409 59.5747 35.9417L54.5193 39.9342C53.4976 40.741 52.9677 42.0202 53.1196 43.3132L53.8713 49.7111C54.1538 52.116 52.116 54.1538 49.7111 53.8713L43.3132 53.1196C42.0202 52.9677 40.741 53.4976 39.9342 54.5193L35.9417 59.5747C34.4409 61.4751 31.5591 61.4751 30.0583 59.5747L26.0658 54.5193C25.259 53.4976 23.9798 52.9677 22.6868 53.1196L16.2889 53.8713C13.884 54.1538 11.8462 52.116 12.1287 49.7111L12.8804 43.3132C13.0323 42.0202 12.5024 40.741 11.4807 39.9342L6.42525 35.9417C4.52492 34.4409 4.52492 31.5591 6.42525 30.0583L11.4807 26.0658C12.5024 25.259 13.0323 23.9798 12.8804 22.6868L12.1287 16.2889C11.8462 13.884 13.884 11.8462 16.2889 12.1287L22.6868 12.8804C23.9798 13.0323 25.259 12.5024 26.0658 11.4807L30.0583 6.42525Z"
        fill="#6E30BC"
      />
      <path
        d="M54.5192 26.0659L59.5747 30.0584C61.475 31.5592 61.475 34.441 59.5747 35.9418L54.5192 39.9343C53.4975 40.7411 52.9677 42.0203 53.1196 43.3133L53.8712 49.7112C54.1538 52.1161 52.116 54.1539 49.711 53.8714L43.3132 53.1197C42.0202 52.9678 40.741 53.4977 39.9341 54.5194L35.9416 59.5748C34.4409 61.4752 31.559 61.4752 30.0583 59.5748L26.0658 54.5194C25.2589 53.4977 23.9797 52.9678 22.6867 53.1197L16.2889 53.8714C13.8839 54.1539 11.8461 52.1161 12.1287 49.7112C26.0658 47 49.711 33.5 54.5192 26.0659Z"
        fill="black"
        fillOpacity="0.1"
      />
      <circle cx="33" cy="33" r="20" fill="#8F45ED" />
      <path
        d="M39.3605 20C39.8023 20 40.0233 20.9742 40.0233 22.9225C40.0233 23.5329 39.9651 24.2606 39.8488 25.1056C39.7558 25.9272 39.5698 26.338 39.2907 26.338H32.4884L32.4535 28.9085H33.6047C36.093 28.9085 37.9419 29.6362 39.1512 31.0915C40.3837 32.5469 41 34.4718 41 36.8662C41 39.2606 40.1628 41.2207 38.4884 42.7465C36.814 44.2488 34.6628 45 32.0349 45C29.407 45 27.4884 44.6244 26.2791 43.8732C26.093 43.5681 26 43.0986 26 42.4648C26 41.8075 26.093 40.892 26.2791 39.7183C26.4651 38.5211 26.7209 37.9225 27.0465 37.9225C27.0698 37.9225 27.1395 37.9695 27.2558 38.0634C28.907 38.7441 30.1628 39.0845 31.0233 39.0845C31.907 39.0845 32.6279 38.8498 33.186 38.3803C33.7674 37.8873 34.0581 37.2653 34.0581 36.5141C34.0581 34.6361 33.0581 33.6972 31.0581 33.6972L28.0581 33.7324C27.8256 33.7793 27.593 33.6854 27.3605 33.4507C27.1279 33.1925 27.0116 32.8638 27.0116 32.4648V21.4437C27.0116 20.4812 27.3605 20 28.0581 20H39.3605Z"
        fill="white"
      />
    </svg>
  );
}

export default Level5;
