import axios from 'axios';

export const GET_NOTIFICATIONS = 'notifications';
export const GET_NOTIFICATIONS_UNREAD = 'unread-notifications';
export const PUT_NOTIFICATIONS_CONSUMED = 'notifications/consumed';

export function getAllNotifications(params) {
  return axios.get(GET_NOTIFICATIONS, { params });
}

export function getAllNotificationsUnread(params) {
  return axios.get(GET_NOTIFICATIONS_UNREAD, { params });
}

export function readNotificationsByGuids(params) {
  return axios.put(GET_NOTIFICATIONS, params);
}

export function consumedNotificationsByGuids(params) {
  return axios.put(PUT_NOTIFICATIONS_CONSUMED, params);
}
