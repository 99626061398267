//TODO: Revisar, muy preparado para STEAM collections-content

import { normalize } from 'normalizr';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { getCollectionsContents } from '../crud/collections.crud';
import { PERSIST_COLLECTIONS, REDUCER_COLLECTIONS } from './conf';

import { collectionsSchema } from '../crud/schema';
import { actions as actionsContents } from './contents.duck';

export const actionTypes = {
  GetCollections: 'cms/GET_COLLECTIONS',
  SetCollections: 'cms/SET_COLLECTIONS',
  GetCollection: 'cms/GET_COLLECTION',
  SetCollection: 'cms/SET_COLLECTION',
};

const initialState = {
  data: {},
  result: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_COLLECTIONS, whitelist: ['data', 'result'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetCollections:
    case actionTypes.GetCollection:
      return { ...state, loading: true };

    case actionTypes.SetCollections: {
      const { collections, result } = action.payload;
      return { ...state, data: collections, result: result, loading: false };
    }

    case actionTypes.SetCollection: {
      const { collections } = action.payload;
      return { ...state, data: { ...state.data, ...collections }, loading: false };
      //El merge parece que le falta algo para hacer la copia
      //return { ...state, data: _merge(...state.data, ...collections), loading: false };
    }

    default:
      return state;
  }
});

const getResultS = (state) => (state.entities ? state.entities[REDUCER_COLLECTIONS].result : null);
const getCollectionsS = (state) => (state.entities ? state.entities[REDUCER_COLLECTIONS].data : null);

export const selectors = {
  getCollections: (state) => {
    return getCollectionsS(state);
  },
  getCollectionsGUIDs: (state) => {
    return getResultS(state);
  },
  getCollectionByGUID: (state, guid) => {
    return guid ? state.entities[REDUCER_COLLECTIONS].data[guid] : null;
  },
  /*getCollectionByGUID: createSelector([getCollectionsS], (data, guid) =>
    data[guid]
  ),*/
  //Al hacer un map en un selector normal, se actualiza el estado de redux tantas veces como result haya, no se porque carajo
  //De esta manera memoriza el resultado y si cambia ahí es solo cuando se actualiza
  getCollectionsArray: createSelector([getCollectionsS, getResultS], (data, result) => (result ? result.map((guid) => data[guid]) : [])),
  getCollectionsItems: createSelector([getCollectionsS, getResultS], (data, result) =>
    result
      ? result.map((c) => {
          const { guid, icon, collection } = data[c];
          return { guid, icon, collection };
        })
      : []
  ),
};

export const actions = {
  getCollections: (params) => ({ type: actionTypes.GetCollections, payload: { params } }),
  fulfillCollections: (collections, result) => ({ type: actionTypes.SetCollections, payload: { collections, result } }),

  getCollection: (guid) => ({ type: actionTypes.GetCollection, payload: guid }),
  fulfillCollection: (collections) => ({ type: actionTypes.SetCollection, payload: { collections } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetCollections, function* getCollectionsSaga(action) {
    const { data } = yield getCollectionsContents(action.payload);

    if (data && data.status === 'success' && data.data) {
      let dataNormalized = normalize(data.data.collections, [collectionsSchema]);
      //Rellenamos las colecciones
      yield put(actions.fulfillCollections(dataNormalized.entities.collections, dataNormalized.result));
      //Rellenamos los proyectos
      yield put(actionsContents.fulfilProjects(dataNormalized.entities.contents));
    } else {
      yield put(actions.fulfillCollections({}));
    }
  });

  yield takeLatest(actionTypes.GetCollection, function* getCollectionSaga(action) {
    const { data } = yield getCollectionsContents({}, action.payload);

    if (data && data.status === 'success' && data.data) {
      let dataNormalized = normalize(data.data, [collectionsSchema]);
      //Rellenamos las colecciones
      yield put(actions.fulfillCollection(dataNormalized.entities.collections));
      //Rellenamos los proyectos
      yield put(actionsContents.fulfilProjects(dataNormalized.entities.contents));
    } else {
      yield put(actions.fulfillCollection({}));
    }
  });
}
