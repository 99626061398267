import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const ImageBubble = ({ size, backgroundColor, imageUrl, variant }) => {
  return (
    <div className={clsx('image-bubble', `image-bubble--size-${size}`, `image-bubble--variant-${variant}`)} style={{ backgroundColor: backgroundColor }}>
      <div className="image-bubble__picture" style={{ backgroundImage: `url("${imageUrl}")` }}></div>
    </div>
  );
};

ImageBubble.propTypes = {
  backgroundColor: PropTypes.string,
  imageUrl: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'square']),
};

ImageBubble.defaultProps = {
  backgroundColor: '',
  imageUrl: '',
  variant: 'default',
};

export default ImageBubble;
