import { put, takeLatest } from 'redux-saga/effects';
import { getOrganization } from '../crud/organization.crud';
import { REDUCER_ORGANIZATION } from './conf';

export const actionTypes = {
  SetOrganization: 'organization/SET_ORGANIZATION',
  GetOrganization: 'organization/GET_ORGANIZATION',
};

const initialState = {
  lms: null,
  tenant: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetOrganization: {
      const { lms, tenant } = action.payload;
      return { ...state, lms: { ...lms }, tenant: tenant.guid, tenantData: tenant };
    }

    default:
      return state;
  }
};

export const selectors = {
  getLms: (state) => {
    return state[REDUCER_ORGANIZATION].lms;
  },
  getEntryPoint: (state) => {
    return state[REDUCER_ORGANIZATION].lms?.entryPoint;
  },
  getClient: (state) => {
    return state[REDUCER_ORGANIZATION].tenant;
  },
  getTenant: (state) => {
    return state[REDUCER_ORGANIZATION].tenantData;
  },
  getCanCreateCourse: (state) => {
    return state[REDUCER_ORGANIZATION].lms?.canCreateCourse;
  },
  getCanCreateGroup: (state) => {
    return state[REDUCER_ORGANIZATION].lms?.canCreateGroup;
  },
};

export const actions = {
  getOrganization: () => ({ type: actionTypes.GetOrganization, payload: {} }),
  setOrganization: (params) => ({ type: actionTypes.SetOrganization, payload: params }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetOrganization, function* getOrganizationSaga() {
    const { data } = yield getOrganization();

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setOrganization(data.data));
    }
  });
}
