import { defaultConfig } from 'app/config/environment';
import axios from 'axios';

export const GET_TASKS = 'front/tasks';
export const GET_AMOUNT_TASKS = 'front/amount-tasks';
export const LRS_ATTEMP = 'lrs/records';
export const LRS_EVALUATE = 'lrs/evaluate';
export const LRS_FILES = 'lrs/files?uploader=froala';

export function getTasks(params) {
  return axios.get(GET_TASKS, { params: params });
}

export function getAmountTasks(params) {
  return axios.get(GET_AMOUNT_TASKS, { params: params });
}

export function postAttemp(params) {
  return axios.post(LRS_ATTEMP, params);
}

export function postTicketUpload() {
  return axios.post(
    LRS_FILES,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'app-referer': defaultConfig.BASE_URL_,
      },
    }
  );
}

export function putAttemp(params) {
  return axios.put(`${LRS_ATTEMP}/${params.id}`, params);
}

export function getLastAttemp(params) {
  return axios.get(LRS_ATTEMP, { params: params });
}

export function getToolboxStorybook(params) {
  return axios.get('front/courses/' + params.courseGuid + '/gradebook', { params: { resourcesOfDigitalProgram: params.programGuid, owner: params.owner } });
}

export function getTreasureHuntStorybook(params) {
  return axios.get('front/courses/' + params.courseGuid + '/gradebook', { params: { thOfDigitalProgram: params.programGuid } });
}

export function evaluateRecords({ records = [], courseReference, materialGuid }) {
  return axios.post(LRS_EVALUATE, { records, referenced_course_guid: courseReference, material_guid: materialGuid });
}
