import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getTopics } from '../crud/topics.crud';
import { isArray } from '../utils/validation';
import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_TOPICS, REDUCER_TOPICS } from './conf';

export const actionTypes = {
  GetTopics: 'cms/GET_TOPICS',
  SetTopics: 'cms/SET_TOPICS',
};

const initialState = {
  data: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_TOPICS, whitelist: ['data'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTopics:
      return { ...state, loading: true };

    case actionTypes.SetTopics: {
      const { topics } = action.payload;
      return { data: topics, loading: false };
    }

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    default:
      return state;
  }
});

export const selectors = {
  getTopics: (state) => {
    return state.entities[REDUCER_TOPICS] && state.entities[REDUCER_TOPICS].data && isArray(state.entities[REDUCER_TOPICS].data)
      ? state.entities[REDUCER_TOPICS].data
      : [];
  },
};

export const actions = {
  getTopics: (params) => ({ type: actionTypes.GetTopics, payload: { params } }), //{pageSize:10, offset:0}
  fulfillTopics: (topics) => ({ type: actionTypes.SetTopics, payload: { topics } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetTopics, function* getTopicsSaga(action) {
    const { data } = yield getTopics(action.payload.params);

    if (data && data.status === 'success' && data.data) yield put(actions.fulfillTopics(data.data));
    else yield put(actions.fulfillTopics([]));
  });
}
