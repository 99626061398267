import axios from 'axios';

export const QUESTIONS = 'front/v2/questions?type[]=CTTY_14&status=active'; //The param type is hardcoded because we always wants question type lemonade
export const QUESTIONS_SOURCES = 'cms/questions-sources';
export const QUESTIONS_YEARS = 'cms/questions-years';
export const QUESTIONS_EDUCATION_FILTERS = 'front/v2/questions-education-filters';
export const QUESTIONS_TYPES = 'cms/questions-types';
export const TEST_QUESTIONS = 'cms/contents';
export const JOBS = 'front/jobs';

export function getTestQuestions(payload) {
  return axios.get(TEST_QUESTIONS + '/' + payload.guid);
}

export function updateTestQuestions(payload) {
  return axios.put(TEST_QUESTIONS + '/' + payload.guid, { data: payload.data });
}

export function reorderQuestions(payload) {
  return axios.put(TEST_QUESTIONS + '/' + payload.guid, { data: payload.params });
}

export function getQuestions(payload) {
  const offset = '&offset=' + payload.offset;
  const pageSize = '&pageSize=' + payload.pageSize;
  //const author = '&author=editorial';
  const type = payload.filters && payload.filters.type ? '&questionTypes[]=' + payload.filters.type : '';
  const educationLevels = payload.filters && payload.filters.educationLevels ? '&educationLevel[]=' + payload.filters.educationLevels : '';
  const search = payload.filters && payload.filters.query ? '&search=' + payload.filters.query : '';
  const country = payload.filters && payload.filters.country ? '&search=' + payload.filters.query : '';

  return axios.get(QUESTIONS + offset + pageSize /*+ author*/, { params: payload.filters });
}

export function getQuestionsEducationFilters() {
  return axios.get(QUESTIONS_EDUCATION_FILTERS);
}

export function getQuestionsTypes() {
  return axios.get(QUESTIONS_TYPES);
}

export function submitTestAnswers(params) {
  return axios.put(JOBS + '/' + params.guid, params.data);
}

export function cloneQuestion({ guidQuestion, data = {} }) {
  return axios.post('cms/contents-clone-question/' + guidQuestion, data);
}
