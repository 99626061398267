import axios from "axios";

export const GET_DLOS = "cms/contents/";
export const GET_BOTS = "cms/dlo/bots";
export const GET_CARDS = "cms/dlo/cards/";
export const REORDER_CARDS = GET_CARDS + "reorder/";
export const GET_COMMENTS_DLO = "cms/dlo/comments";
export const GET_VERSIONS_DLO = "cms/dlo/versions/";
export const GET_PACKAGE_DLO = "cms/dlo/build-package";
export const GET_ASSETS_AUDIOS = "cms/dlo/assets-manager/audios";
export const GET_ASSETS_IMAGES = "cms/dlo/assets-manager/images";
export const GET_ASSETS_CSV_AUDIOS = "cms/dlo/assets-manager/audios/csv";
export const GET_ASSETS_CSV_IMAGES = "cms/dlo/assets-manager/images/csv";
export const GET_STAGES = "cms/dlo/stages";
export const PUT_TEXT_TO_SPEECH = "cms/dlo/text-to-speech";
export const GET_FEEDBACK_CUSTOM = "cms/dlo/feedbacks/custom";
export const GET_DLO_PREVIEW = "cms/dlo/preview";

export function getDLOs(params) {
  return axios.get(GET_DLOS, { params: params });
}

export function getDLO(guid) {
  return axios.get(GET_DLOS + guid, {});
}

export function getBotList() {
  return axios.get(GET_BOTS, { params: { orderBy: "created_at" } });
}

export function getStageList() {
  return axios.get(GET_STAGES, {});
}

export function getFeedbackCustom() {
  return axios.get(GET_FEEDBACK_CUSTOM, { params: { orderBy: "guid desc" } });
}

export function createDLO(params) {
  return axios.post(GET_DLOS, params);
}

export function updateDLO(params) {
  return axios.put(GET_DLOS + params.guid, params);
}

export function deleteDLO(params) {
  return axios.delete(GET_DLOS, { data: params });
}

export function updateVersionDLO(verion, params) {
  return axios.put(GET_VERSIONS_DLO + verion, params);
}

// == CARDS

export function getCards(guid, versionGuid) {
  return axios.get(GET_CARDS, { params: { contentGuid: guid, versionGuid: versionGuid, orderBy: "`order`" } });
}

export function addCardDLO(dloGuid, cardGuid, params) {
  return axios.post(GET_CARDS, params);
}

export function updateCardDLO(dloGuid, cardGuid, params) {
  return axios.put(GET_CARDS + cardGuid, params);
}


export function updateCardsDLO(dloGuid, cardsGuidArray, paramsArray) {
   // TODO: Cambiarlo cuando haya un servicio que podamos actualizar cards en lote en la misma llamada
  var arrayRequest = [];
  cardsGuidArray && cardsGuidArray.forEach((cardGuid, index) => {
    arrayRequest.push(updateCardDLO(dloGuid, cardGuid, paramsArray[index]))
  });
  return axios.all(arrayRequest);
}

export function reorderCardsDLO(dloGuid, versionGuid, reorder = []) {
  return axios.put(REORDER_CARDS, { dlo_guid: dloGuid, version_guid: versionGuid, reorder: reorder });
}

export function deleteCardDLO(dloGuid, cardGuid, params) {
  return axios.delete(GET_CARDS, { data: params });
}

// == COMMENTS

export function getCommentsDLO(guid) {
  return axios.get(GET_COMMENTS_DLO, { params: { contentGuid: guid } });
}

export function addCommentDLO(params) {
  return axios.post(GET_COMMENTS_DLO, params);
}

// == FROALA UPLOAD
export function getTicketUploadFroala(dloGuid, version, cardGuid) {
  return axios.get(GET_DLOS + dloGuid + "/content/upload?uploader=froala&innerFolder=assets_v" + version + "/assets" + cardGuid);
}

// == DLO VERSIONS
export function getVersionsDLO(params) {
  return axios.get(GET_VERSIONS_DLO, { params });
}

export function addVersionDLO(params) {
  return axios.post(GET_VERSIONS_DLO, params);
}

export function deleteVersionDLO(params) {
  return axios.delete(GET_VERSIONS_DLO, { data: params });
}

// == DLO PACKAGE
export function getPackageDLO(contentGuid, versionGuid, scorm = 0) {
  const params = { contentGuid, versionGuid, scorm };
  return axios.get(GET_PACKAGE_DLO, { params });
}

// == ASSETS

export function getAssetsAudios(params) {
  return axios.get(GET_ASSETS_AUDIOS, { params: params });
}

export function getAssetsImages(params) {
  return axios.get(GET_ASSETS_IMAGES, { params: params });
}

export function getAssetsAudio(audioGuid) {
  return axios.get(GET_ASSETS_AUDIOS + "/" + audioGuid, {});
}

export function getAssetsImage(imageGuid) {
  return axios.get(GET_ASSETS_IMAGES + "/" + imageGuid, {});
}

export function putAssetsAudio(audioGuid, params) {
  return axios.put(`${GET_ASSETS_AUDIOS}/${audioGuid}`, params);
}

export function putAssetsImage(imageGuid, params) {
  return axios.put(`${GET_ASSETS_IMAGES}/${imageGuid}`, params);
}

export function getAssetsCSVAudio(filter) {
  return axios.get(GET_ASSETS_CSV_AUDIOS, { params: filter });
}

export function getAssetsCSVImage(filter) {
  return axios.get(GET_ASSETS_CSV_IMAGES, { params: filter });
}

// == TEXT TO SPEECH
export function putTextToSpeech(contentGuid, versionGuid) {
  const params = { contentGuid, versionGuid };
  return axios.put(PUT_TEXT_TO_SPEECH, params);
}

export function getDLOPreview(params) {
  return axios.get(GET_DLO_PREVIEW, { params: params });
}
