import React from 'react';

const Plus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M9.81748 1.43204V6.65146H15.068C15.4408 6.65146 15.71 6.95178 15.8757 7.55243C15.9586 7.86311 16 8.16343 16 8.4534C16 8.74337 15.9586 9.04369 15.8757 9.35437C15.71 9.95502 15.4408 10.2553 15.068 10.2553H9.81748V15.568C9.81748 15.9408 9.51715 16.21 8.9165 16.3757C8.62654 16.4586 8.32621 16.5 8.01553 16.5L7.11456 16.3757C6.51392 16.21 6.21359 15.9408 6.21359 15.568V10.2553H0.932039C0.559223 10.2553 0.289968 9.95502 0.124272 9.35437C0.041424 9.04369 0 8.74337 0 8.4534C0 8.16343 0.041424 7.86311 0.124272 7.55243C0.289968 6.95178 0.559223 6.65146 0.932039 6.65146H6.21359V1.43204C6.21359 1.05922 6.51392 0.789969 7.11456 0.624273C7.40453 0.541425 7.70485 0.5 8.01553 0.5L8.9165 0.624273C9.51715 0.789969 9.81748 1.05922 9.81748 1.43204Z"
        fill="#2D95E6"
      />
    </svg>
  );
};

export default Plus;
