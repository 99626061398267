import { COURSE_TYPES } from '_core/utils/constants/course';
import { getDateWithoutTimeZone } from '_core/utils/date';
import { getAverage } from '_core/utils/grades';
import { isEditorial, isStudent } from '_core/utils/user';
import { calculateUnitConsumption, getIconByDisciplineCourse, isScorm, isxApi, stripHtml } from '_core/utils/utils';
import i18next from 'i18next';
import { chain, flatten, flattenDeep } from 'lodash';
import moment from 'moment';

export function parsePrograms(programs = []) {
  return programs.map((item) => {
    return {
      ...item,
      guid: item.program.guid,
      image: item.program.picture,
      title: item.program.name,
      language: item.course_lang_description,
      isAccessible: item.program.is_accessible,
    };
  });
}

export function parseLessonOfGradebook(lesson) {
  return {
    name: lesson.lesson_name,
    guid: lesson.lesson_guid,
    assessments: lesson.lessonItems.map((item) => parseAssesmentOfGradebook(item)),
  };
}

export function parseTaskStatus(task = {}) {
  const hoursLeft = moment(new Date(task.lesson_item_deliver_at)).diff(moment(new Date()), 'hours');
  const isLate = moment(new Date(task.lesson_item_deliver_at)).isBefore(new Date());

  if (task.job_status === 'pending' && isLate) return 'late';
  if (task.job_status === 'pending' && hoursLeft < 24) return 'soon';
  if (task.job_status === 'pending' && task.job_evaluated_by) return 'assessment-reassigned';
  if (task.job_status === 'pending' && hoursLeft > 24) return 'assessment-assigned';
  if (task.job_status === 'delivered') return 'turned-in';
  if (task.job_status === 'evaluated') return 'assessment-evaluated';
}

export function parseAssesmentOfGradebook(assesment) {
  return { name: assesment.name, guid: assesment.guid };
}

export function getJobScore(assesment, job) {
  let score = '-';
  if (isPassDeliveryAt(assesment, job)) {
    score = 0;
  } else if (job.status === 'evaluated' || isScorm(assesment.content_type_guid) || isxApi(assesment.content_type_guid)) {
    score = job.score || score;
  }
  return score;
}

export function getAgroupByCalendar(timetable = [], t) {
  const times = [];
  for (const time of timetable) {
    times.push({
      name: timetable.length === 1 ? _title_quarter(timetable.length, t) : times.length + 1 + _title_quarter(timetable.length, t),
      guid: time.guid,
    });
  }
  // times.push({
  //   name: 'No planificada',
  //   guid: 'notguid',
  // });

  return times;
}

export function getStudents(users = []) {
  let students = [];
  for (const user of users.filter((itemUser) => isStudent(itemUser))) {
    students.push({ user: { ...parseUser(user), guid: user.person_guid }, units: [] });
  }

  return students;
}

export function isPassDeliveryAt(assesment, job) {
  return (
    !assesment.can_deliver_late &&
    (assesment.deliver_at < job.delivered_at || (job.delivered_at === null && moment(new Date()).isAfter(new Date(assesment.deliver_at))))
  );
}

function getNewAssessment(guid, name, status, score) {
  return {
    guid: guid,
    name: name,
    status: status,
    score: score,
  };
}

function getNewLesson(lesson, lessonItem, job) {
  return {
    guid: lesson.lesson_guid,
    name: lesson.lesson_name,
    assessments: [getNewAssessment(lessonItem.guid, lessonItem.name, isPassDeliveryAt(lessonItem, job) ? 'late' : job.status, getJobScore(lessonItem, job))],
  };
}

function getNewUnit(lesson, lessonItem, job) {
  let timetable = null;
  try {
    timetable = lesson.parent_config ? JSON.parse(lesson.parent_config) : null;
  } catch (e) {
    console.log(e, 'error getNewUnit');
  }

  return {
    guid: lesson.parent_guid,
    name: lesson.parent_section,
    timetable: timetable,
    lessons: [getNewLesson(lesson, lessonItem, job)],
  };
}

function isAssessmentForStudent(lessonItem, studentGuid) {
  // return lessonItem.item_for === 'all' || (lessonItem.item_for === 'some-users' && lessonItem.item_for_users.includes(studentGuid));
  // lo comento por si se acaba cambiando, ahora solo se veran los assesments para todos los users
  return lessonItem.item_for === 'all';
}

function putJobsToUser(dataToTable, lessonItem, lesson) {
  let currentUnit = null;
  let currentLesson = null;
  if (lessonItem.job) {
    for (const job of lessonItem.job) {
      const currentUser = dataToTable.find((item) => item.user.guid === job.student_guid);

      if (isAssessmentForStudent(lessonItem, job.student_guid)) {
        currentUnit = currentUser.units.find((unit) => unit.guid === lesson.parent_guid);
        if (currentUnit) {
          currentLesson = currentUnit.lessons.find((itemLesson) => itemLesson.guid === lesson.lesson_guid);
          if (currentLesson) {
            currentLesson.assessments.push(
              getNewAssessment(lessonItem.guid, lessonItem.name, isPassDeliveryAt(lessonItem, job) ? 'late' : job.status, getJobScore(lessonItem, job))
            );
          } else {
            currentUnit.lessons.push(getNewLesson(lesson, lessonItem, job));
          }
        } else {
          currentUser.units.push(getNewUnit(lesson, lessonItem, job));
        }
      }
    }
  }

  return dataToTable;
}

function getAverageAssignment(assessment, parseData) {
  let sum = 0;

  for (const user of parseData) {
    for (const unit of user.units) {
      for (const lesson of unit.lessons) {
        for (const assessmentItem of lesson.assessments) {
          if (assessmentItem.guid === assessment.guid) {
            if (assessmentItem.score !== '-') {
              sum += parseFloat(assessmentItem.score);
            }
          }
        }
      }
    }
  }

  return sum === 0 ? 0 : sum / parseData.length;
}

export function parseStudents(students = []) {
  students = students.filter((student) => isStudent(student)).map((student) => parseUser(student));

  return students;
}

export function parseUser(user = {}) {
  user.lastName = user.lastname;
  user.thumbnail = user.avatar;
  user.guid = user.person_guid || user.guid;
  return user;
}

export function getAgroupDataToGraphicAssigments(lessons, users, selectedStudents) {
  let parseData = [];
  const data = parseDataServerToFormatSimpleTable(lessons, users);
  for (const user of data) {
    for (const unit of user.units) {
      for (const lesson of unit.lessons) {
        parseData = getElementToDataGraphic(lesson.assessments, parseData, user, data, selectedStudents);
      }
    }
  }

  return parseData;
}

export function parseLessonItemToExport(lessons = []) {
  return flatten(
    lessons.map((lesson) => {
      return lesson.lessonItems.map((item) => {
        return {
          ...item,
          lesson_name: lesson.lesson_name,
          unit_name: lesson.parent_section,
          unit_order: lesson.parent_order,
          item_name: item.name,
        };
      });
    })
  );
}

export function parseLessonItemBookToExport(units = []) {
  return flattenDeep(
    units.map((unit) => {
      return unit.items.map((lesson) => {
        return lesson.items.map((item) => {
          return { ...item, lesson_name: lesson.name, unit_name: unit.name, unit_order: unit.order };
        });
      });
    })
  );
}

export function parseEventAddCourseName(events) {
  return (events || []).map((item) => {
    return { ...item, name: item.course_name + ' - ' + item.title };
  });
}

export function parseDataServerToFormatSimpleTable(lessons = [], users = []) {
  let dataToTable = getStudents(users);
  const sortedLessons = lessons.sort((a, b) => a.parent_order - b.parent_order || a.order - b.order);

  for (const lesson of sortedLessons) {
    const lessonItems = lesson.lessonItems.sort((a, b) => a.parent_order - b.parent_order || a.order - b.order);
    for (const lessonItem of lessonItems) {
      dataToTable = putJobsToUser(dataToTable, lessonItem, lesson);
    }
  }

  for (const data of dataToTable) {
    for (const unit of data.units) {
      for (const lesson of unit.lessons) {
        lesson.score = getAverage(lesson.assessments);
      }
      unit.score = getAverage(unit.lessons);
    }
    data.score = getAverage(data.units);
  }

  return dataToTable;
}

export function getAgroupUnitsByLessons(lessons = []) {
  const units = [];
  const sortedLessons = lessons.sort((a, b) => a.parent_order - b.parent_order || a.order - b.order);

  let findUnit = null;
  for (const lesson of sortedLessons) {
    if (lesson.parent_guid) {
      findUnit = units.find((unit) => unit.guid === lesson.parent_guid);
      if (findUnit) {
        findUnit.lessons.sort((a, b) => a.parent_order - b.parent_order || a.order - b.order).push(parseLessonOfGradebook(lesson));
      } else {
        units.push({
          guid: lesson.parent_guid,
          name: lesson.parent_section,
          lessons: [parseLessonOfGradebook(lesson)],
        });
      }
    }
  }
  return units;
}

export function parseTaskStudent(tasks = []) {
  return tasks.map((item) => {
    return {
      ...item,
      lessonName: item.lesson.name,
      section: item.section.name,
      name: item.lesson_item_name,
      title: item.lesson_item_name,
      date: moment(item.lesson_item_deliver_at).format('DD-MM-YYYY HH:mm'),
      dueDate: item.lesson_item_deliver_at,
      pending: item.jobs_delivered,
      evaluated: item.jobs_evaluated,
      noStarted: item.jobs_amount,
      questionsLength: item.test_amount_questions,
      status: parseTaskStatus(item),
      grade: item.job_score,
      gradeTotal: item.lesson_item_ranking_scale,
    };
  });
}

export function getAgroupDataToRanking(lessons, users) {
  const data = parseDataServerToFormatSimpleTable(lessons, users);
  return data.map((item) => ({ ...item, ...item.user }));
}

function _title_quarter(_quarter_length, t) {
  switch (_quarter_length) {
    case 1:
      return t('section:Calendar steep anual');
    case 2:
      return t('section:Calendar steep semestre');
    case 3:
      return t('section:Calendar steep trimestre');
    case 4:
      return t('section:Calendar steep bimestre');
    default:
      return '';
  }
}

function getElementToDataGraphic(items, parseData, user, initData, selectedStudents) {
  let currentAssessment = null;

  for (const assessment of items) {
    currentAssessment = parseData.find((item) => item.guid === assessment.guid);
    if (currentAssessment) {
      currentAssessment[user.user.guid] = assessment.status === 'evaluated' || assessment.isScorm ? assessment.score : 0;
    } else if (!currentAssessment) {
      let data = {
        name: assessment.name,
        guid: assessment.guid,
      };
      data[user.user.guid] = assessment.status === 'evaluated' || assessment.isScorm ? assessment.score : 0;
      data[i18next.t('grades:Average')] = getAverageAssignment(assessment, initData);
      parseData.push(data);
    }
  }

  return parseData;
}

export function getAgroupDataToTableCalendar(lessons, users, timetable, t) {
  let times = [];
  const data = parseDataServerToFormatSimpleTable(lessons, users);
  for (const student of data) {
    times = [];

    for (let timetableItem of timetable) {
      times.push({
        name: timetable.length === 1 ? _title_quarter(timetable.length, t) : times.length + 1 + _title_quarter(timetable.length, t),
        guid: timetableItem ? timetableItem.guid : '',
        units: [],
      });
    }

    for (const unit of student.units) {
      const currentTime = times.find((item) => unit.timetable && item.guid === unit.timetable.trimestre_guid);
      if (currentTime) {
        currentTime.units.push(unit);
      } else {
        const findTime = timetable.find((item) => unit.timetable && item.guid === unit.timetable.trimestre_guid);
        if (findTime) {
          times.push({
            name: timetable.length === 1 ? _title_quarter(timetable.length, t) : times.length + 1 + _title_quarter(timetable.length, t),
            guid: findTime ? findTime.guid : '',
            units: [unit],
          });
        }
        // else {
        //   let timesFound = times.find((item) => item.guid === 'notguid');
        //   if (timesFound) {
        //     timesFound.units.push(unit);
        //   } else {
        //     times.push({
        //       name: 'No planificada',
        //       guid: 'notguid',
        //       units: [unit],
        //     });
        //   }
        // }
      }
    }
    student.times = times;
  }

  for (const student of data) {
    for (const time of student.times) {
      time.score = getAverage(time.units);
    }
  }

  return data;
}

export function parseEventsCalendar(events = [], calendar) {
  return events.map((item) => {
    return {
      ...item,
      name: item.title === 'CLASS' ? item.course_name : cleanEventName(item.title),
      start: getDateWithoutTimeZone(new Date(item.start_at)),
      end: item.end_at ? getDateWithoutTimeZone(new Date(item.end_at)) : getDateWithoutTimeZone(new Date(item.start_at)),
      calendar: calendar.filter((ca) => ca.guid == item.calendar_guid)[0],
      lesson: item.lesson_guid || '',
      detail: item.description,
      type_icon: 'onsite',
      isTest: item.type_name === 'content',
      allDay: item.type_name === 'content',
      gradient: item.education_discipline_guid ? getGradientByDisciplineCourse(item.education_discipline_guid) : null,
      discipline_icon: item.education_discipline_guid ? getIconByDisciplineCourse(item.education_discipline_guid) : null,
    };
  });
}

function getAverageAssignmentUnitsAndTimes(item, parseData, attr = 'units') {
  let sum = 0;

  for (const user of parseData) {
    for (const unit of user[attr]) {
      if (unit.guid === item.guid) {
        if (unit.score !== '-') {
          sum += parseFloat(unit.score);
        }
      }
    }
  }

  return sum === 0 ? 0 : sum / parseData.length;
}

function reorganizeProgramUnits(quarterToOrder, lessonsToOrder) {
  quarterToOrder = quarterToOrder.map((quarter) => {
    quarter.units = [];
    lessonsToOrder.map((lesson) => {
      if (lesson.config) {
        try {
          let _unit = JSON.parse(lesson.config).trimestre_guid;
          if (_unit == quarter.guid) {
            quarter.units.push(lesson);
          }
        } catch (e) {
          console.log(e, 'error reorganizeProgramUnits');
        }
      }
    });
    return quarter;
  });

  return quarterToOrder;
}

function _title_status(_trimestre) {
  if (_trimestre.guid == 'notguid') {
    return 'pending';
  } else if (new Date(_trimestre.end_at) < new Date()) {
    return 'done';
  } else if (new Date(_trimestre.start_at) > new Date()) {
    return 'pending';
  } else {
    return 'current';
  }
}

function filterUnit(item, unit) {
  return {
    ...item,
    units: item.units.filter((_unit) => {
      return _unit.guid != unit.guid;
    }),
  };
}

export function parseAllProgramV2(_quarter, _lessonss, t, user, _callback) {
  _quarter = reorganizeProgramUnits(_quarter, _lessonss);

  let _tempate_quarter = {
    guid: 'notguid',
    name: t('section:Calendar steep planeador'),
    units: [],
  };

  let listOfFoundUnits = flatten(_quarter.map((singleQuarter) => singleQuarter.units)).map((item) => item.guid);

  _lessonss.map((lesson) => {
    if (lesson.config && listOfFoundUnits.indexOf(lesson.guid) > -1) {
      try {
        let _unit_re = JSON.parse(lesson.config).trimestre_guid;
        if (!_unit_re || _unit_re == 'notguid') {
          _tempate_quarter.units.push(lesson);
        }
      } catch (e) {
        console.log(e, 'error parseAllProgramV2');
      }
    } else {
      _tempate_quarter.units.push(lesson);
    }
  });

  var _quarter_length = _quarter.length;
  var _quarter_index_rest = 0;

  if (_tempate_quarter.units.length > 0 || _quarter.length == 0) {
    _quarter.unshift(_tempate_quarter);
    _quarter_length = _quarter.length - 1;
    _quarter_index_rest = 1;
  }

  var fullProgram = _quarter.map((_trimestre, _ix) => {
    return {
      ..._trimestre,
      status: _title_status(_trimestre),
      start: _trimestre.end_at,
      guid: _trimestre.guid,
      name:
        _trimestre.name || (_quarter_length === 1 ? _title_quarter(_quarter_length, t) : _ix - _quarter_index_rest + 1 + _title_quarter(_quarter_length, t)),
      end: _trimestre.start_at,
      start_at: _trimestre.start_at,
      end_at: _trimestre.end_at,
      units: _trimestre.units.map((_unidade) => {
        return parseUnit(_unidade);
      }),
    };
  });

  _callback(fullProgram);
}

function parseUnit(_unidade) {
  var _stus = '';
  _unidade.items.map((_lesson) => {
    _stus = new Date(_lesson.lesson_to_date) < new Date() ? 'done' : 'pending';
  });

  return {
    ..._unidade,
    date: _unidade.lesson_to_date,
    name: _unidade.section || '---',
    showDate: true,
    status: _stus,
    lessons: _unidade.items.map((_lesson) => {
      return {
        ..._lesson,

        date: _lesson.lesson_from_date,
        duration: '-', //lesson_from_date - lesson_to_date
        learningObjectives: [],
        name: _lesson.lesson_name || '.....',
        isToday: _lesson.lesson_from_date ? moment(_lesson.lesson_from_date).isSame(moment(), 'day') : false,
        showDate: true,
        status: parseStatusUnit(_lesson),
        type: 'online',
      };
    }),
  };
}

export function unitMoveUpDown(moviment, unit, _program, _callback) {
  var mustUpdate = true;
  let trimGuid = null;
  try {
    trimGuid = unit.config ? JSON.parse(unit.config).trimestre_guid : null;
  } catch (e) {
    console.log(e, 'error unitMoveUpDown');
  }

  let trim = {};

  let positionTrim = 0;
  let positionUnit = 0;

  _program.times.map((item, i) => {
    positionTrim = item.guid == trimGuid ? i : positionTrim;
    return {
      ...item,
      units: item.units.map((_unit, j) => {
        positionUnit = unit.guid == _unit.guid ? j : positionUnit;
      }),
    };
  });
  var newPosition;
  // Move unit UP
  if (moviment == 'up') {
    if (positionUnit > 0) {
      trim = _program.times[positionTrim];
      newPosition = positionUnit - 1;
      const result = _program.times[positionTrim].units;
      const [removed] = result.splice(positionUnit, 1);
      result.splice(newPosition, 0, removed);
      _program.times[positionTrim].units = result;
    } else {
      if (positionTrim == 0) {
        mustUpdate = false;
      } else {
        // eliminando unidad del trimestre
        _program.times = _program.times.map((item) => filterUnit(item, unit));

        // agregar unidad a trimestre anterior
        _program.times[positionTrim - 1].units.push(unit);
        trim = _program.times[positionTrim - 1];
      }
    }
  } else {
    // Move unit DOWN

    // llega al tope
    if (positionTrim >= _program.times.length - 1 && positionUnit >= _program.times[positionTrim].units.length - 1) {
      mustUpdate = false;
    } else {
      mustUpdate = true;
      trim = _program.times[positionTrim];

      // Unidada no llega a tope de trimestre
      if (positionUnit < _program.times[positionTrim].units.length - 1) {
        newPosition = positionUnit + 1;
        const result = _program.times[positionTrim].units;
        const [removed] = result.splice(positionUnit, 1);
        result.splice(newPosition, 0, removed);

        _program.times[positionTrim].units = result;
      } else {
        // Unidada llega a tope de trimestre
        // eliminando unidad del trimestre
        _program.times = _program.times.map((item) => filterUnit(item, unit));

        // agregar unidad a trimestre anterior
        _program.times[positionTrim + 1].units.unshift(unit);
        trim = _program.times[positionTrim + 1];
      }
    }
  }

  _callback(_program, mustUpdate, trim, unit);
}

export function reorderUnitsAndLesson(_lesson, _program, _callback) {
  let positionTrim = 0;
  let positionUnit = 0;
  _program.times.map((trim, i) => {
    return {
      ...trim,
      units: trim.units.map((unit, j) => {
        return {
          ...unit,
          lessons: unit.lessons.map((lesson, z) => {
            if (lesson.guid == _lesson.guid) {
              positionUnit = j;
              positionTrim = i;
            }
          }),
        };
      }),
    };
  });

  const result = _program.times[positionTrim].units[positionUnit].lessons;
  const [removed] = result.splice(_lesson.start, 1);
  result.splice(_lesson.end, 0, removed);
  _program.times[positionTrim].units[positionUnit].lessons = result;

  _callback(_program);
}

export function reorderAfterCreateLesson(_program, response, data, _callback) {
  // Localizando ID de lesson
  var _custom_guid = response.data.data.guid;
  var _ar = _custom_guid.split('-');
  _ar[0] = _ar[0].substring(0, _ar[0].length - 1) + '1';
  _custom_guid = _ar.join('-');

  let newUnit = response.data.data;
  newUnit.guid = _custom_guid;
  newUnit.name = data.title;

  let unitGUID = data.parent_guid;
  let positionTrim = 0;
  let positionUnit = 0;

  _program.times.map((item, i) => {
    return {
      ...item,
      units: item.units.map((_unit, j) => {
        if (_unit.guid == unitGUID) {
          positionUnit = j;
          positionTrim = i;
        }
      }),
    };
  });

  //lessons
  let _arr = _program.times[positionTrim].units[positionUnit].lessons;
  let position = data.order;
  const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];
  let _retu = insert(_arr, position, newUnit);
  _program.times[positionTrim].units[positionUnit].lessons = _retu;
  _callback(_program);
}

export function parseStudentUnits(units) {
  return units.map((unit) => {
    return { ...unit, consumption: calculateUnitConsumption(unit) };
  });
}

function parseStatusUnit(_lesson) {
  const isNotOnPast = new Date(_lesson.lesson_from_date) < new Date();
  const isNotOnFuture = new Date(_lesson.lesson_to_date) >= new Date();

  if (isNotOnPast && isNotOnFuture) {
    return 'current';
  } else if (isNotOnPast) {
    return 'done';
  } else {
    return 'pending';
  }
}

export function parseReorderUnitAndLesson(_program, _callback, haveToGetProgram = false) {
  let _reorder = [];
  let _order = 1;
  let trimestres = _program.times;

  for (var i = 0; i < trimestres.length; i++) {
    for (var j = 0; j < trimestres[i].units.length; j++) {
      // Unidades
      _reorder.push({
        guid: trimestres[i].units[j].guid,
        name: trimestres[i].units[j].section,
        order: _order,
        parent_guid: trimestres[i].units[j].parent_guid,
      });
      _order++;

      // Lección
      for (var z = 0; z < trimestres[i].units[j].lessons.length; z++) {
        _reorder.push({
          guid: trimestres[i].units[j].lessons[z].guid,
          name: trimestres[i].units[j].lessons[z].lesson_name,
          order: _order,
          parent_guid: trimestres[i].units[j].lessons[z].parent_guid,
        });
        _order++;
      }
    }
  }

  _callback(_reorder, haveToGetProgram);
}

// UNIT ADD
export function parseAddUnit(_unit, _program, position, time, _callback) {
  var _arr = [];
  var _pos = 0;

  var newUnit = _unit;
  newUnit.date = newUnit.lesson_to_date;
  newUnit.name = newUnit.section || '---';
  newUnit.showDate = true;
  newUnit.status = 'done';
  newUnit.lessons = [];

  for (var i = 0; i < _program.times.length; i++) {
    if (time.guid == _program.times[i].guid) {
      _arr = _program.times[i].units;
      _pos = i;
    }
  }

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  let _ret = insert(_arr, position, newUnit);

  if (_program.times.length == 0) {
    _program.times = [];
    _program.times.push({ units: _ret });
  } else {
    _program.times[_pos].units = _ret;
  }
  _callback(_program);
}

export function getAgroupDataToGraphicCalendar(lessons, users, timetable, t, selectedStudents) {
  return agroupDataToGraphicCalendarUnits(getAgroupDataToTableCalendar(lessons, users, timetable, t), 'times', selectedStudents);
}

function agroupDataToGraphicCalendarUnits(data, attributeOfUser, selectedStudents) {
  const parseData = [];
  let newData = {};
  let currentUnit = null;
  for (const user of data) {
    for (const unit of user[attributeOfUser]) {
      currentUnit = parseData.find((item) => item.guid === unit.guid);
      if (currentUnit) {
        currentUnit[user.user.guid] = unit.score !== '-' ? unit.score : 0;
      } else if (!currentUnit) {
        newData = {
          name: stripHtml(unit.name),
          guid: unit.guid,
        };
        // if (selectedStudents.find((item) => item.guid === user.user.guid)) {
        // if (unit.score !== '-') {
        newData[user.user.guid] = unit.score !== '-' ? unit.score : 0;
        // }
        // }

        newData[i18next.t('grades:Average')] = getAverageAssignmentUnitsAndTimes(unit, data, attributeOfUser);
        parseData.push(newData);
      }
    }
  }

  return parseData;
}

export const cleanEventName = (name) => {
  return name.replace('CLASS_WITH_LESSON: ', '').replace('CONTENT_TASK_EVENT: ', '').replace('`', '').replace('´', '');
};

export function getAgroupDataToGraphicUnits(lessons, users, selectedStudents) {
  return agroupDataToGraphicCalendarUnits(parseDataServerToFormatSimpleTable(lessons, users), 'units', selectedStudents);
}

export function parseTasks2(tasks) {
  return tasks.map((item) => {
    return {
      ...item,
      name: item.lesson_item_name,
      title: item.lesson_item_name,
      date: moment(item.lesson_item_deliver_at).format('DD-MM-YYYY HH:mm'),
      dueDate: item.lesson_item_deliver_at,
      pending: item.jobs_delivered,
    };
  });
}

export function parseGroupsFromCourses(courses = []) {
  let groups = [];
  courses = chain(courses).groupBy('school_group_guid').value();
  Object.keys(courses).map(function (key, idx) {
    const group = courses[key][0];
    return groups.push({
      amount_students: group.studentsLength,
      education_level_guid: group.school_group_education_level_guid,
      education_level_name: group.school_group_education_level_name,
      education_year_guid: group.school_group_education_year_guid,
      education_year_name: group.school_group_education_year_name,
      guid: group.school_group_guid,
      is_active: 1,
      name: group.school_group_name,
      code: group.school_group_code,
    });
  });

  return groups;
}

export function parseEvents(events = []) {
  return events.map((item) => {
    return {
      ...item,
      name: item.title === 'CLASS' ? item.course_name : cleanEventName(item.title),
      title: item.title === 'CLASS' ? item.course_name : cleanEventName(item.title),
      start: item.start_at,
      type: item.type_name,
      course: {
        gradient: item.education_discipline_guid ? getGradientByDisciplineCourse(item.education_discipline_guid) : null,
        name: item.school_group_name,
      },
      lesson: {
        name: item.title === 'CLASS' ? item.course_name : cleanEventName(item.title),
      },
    };
  });
}

export function parseCourse(course = {}) {
  return {
    ...course,
    gradient: course.education_discipline_guid ? getGradientByDisciplineCourse(course.education_discipline_guid) : null,
    disregarded: course.author_role_guid ? isEditorial(course.author_role_guid) : false,
    isEditable:
      course.course_template_type === COURSE_TYPES.TEMPLATE_2 || course.type === COURSE_TYPES.TEMPLATE_2 || course.type === COURSE_TYPES.NO_REFERENCED,
  };
}
export function getParseDisciplines() {
  return [
    { guid: 'ed1', discipline: 'All', iconType: 'science', gradient: 'linear-gradient(to top, #86e328, #00b775)' },
    { guid: 'ed10', discipline: 'Religion', iconType: 'language', gradient: 'linear-gradient(135deg, #FF5AC7 15.25%, #B53FEC 100%)' },
    { guid: 'ed11', discipline: 'Ethics', iconType: 'history', gradient: 'linear-gradient(135deg, #BF48F8 14.75%, #5714E7 100%)' },
    { guid: 'ed12', discipline: 'Art', iconType: 'art', gradient: 'linear-gradient(135deg, #FFAD31 14.75%, #F05432 100%)' },
    { guid: 'ed13', discipline: 'English', iconType: 'technology-language', gradient: 'linear-gradient(135deg, #2AECC9 14.5%, #01A8B3 100%)' },
    { guid: 'ed2', discipline: 'Math', iconType: 'maths', gradient: 'linear-gradient(135deg, #FF7171 14.75%, #E3284A 100%)' },
    { guid: 'ed3', discipline: 'Language', iconType: 'technology-language', gradient: 'linear-gradient(135deg, #2AECC9 14.5%, #01A8B3 100%)' },
    { guid: 'ed4', discipline: 'Plastics', iconType: 'class-book', gradient: 'linear-gradient(111.12deg, #FF5029 12.58%, #F91B6B 100%)' },
    { guid: 'ed5', discipline: 'Technology', iconType: 'technology-language', gradient: 'linear-gradient(135deg, #2AECC9 14.5%, #01A8B3 100%)' },
    { guid: 'ed6', discipline: 'Biology', iconType: 'science', gradient: 'linear-gradient(135deg, #86E328 15.25%, #00B775 100%)' },
    { guid: 'ed7', discipline: 'Social Sciences', iconType: 'geography', gradient: 'linear-gradient(135deg, #72C4FF 14.75%, #4325FA 100%)' },
    { guid: 'ed8', discipline: 'Geography', iconType: 'geography', gradient: 'linear-gradient(to top, #40afff, #361bdc)' },
    { guid: 'ed9', discipline: 'History', iconType: 'history', gradient: 'linear-gradient(135deg, #BF48F8 14.75%, #5714E7 100%)' },
  ];
}

export function getGradientByDisciplineCourse(discipline) {
  const gradients = [
    { guid: 'ed1', gradient: 'linear-gradient(to top, #86e328, #00b775)' },
    { guid: 'ed10', gradient: 'linear-gradient(135deg, #FF5AC7 15.25%, #B53FEC 100%)' },
    { guid: 'ed11', gradient: 'linear-gradient(135deg, #BF48F8 14.75%, #5714E7 100%)' },
    { guid: 'ed12', gradient: 'linear-gradient(135deg, #FFAD31 14.75%, #F05432 100%)' },
    { guid: 'ed2', gradient: 'linear-gradient(135deg, #FF7171 14.75%, #E3284A 100%)' },
    { guid: 'ed3', gradient: 'linear-gradient(135deg, #2AECC9 14.5%, #01A8B3 100%)' },
    { guid: 'ed4', gradient: 'linear-gradient(111.12deg, #FF5029 12.58%, #F91B6B 100%)' },
    { guid: 'ed5', gradient: 'linear-gradient(135deg, #2AECC9 14.5%, #01A8B3 100%)' },
    { guid: 'ed6', gradient: 'linear-gradient(135deg, #86E328 15.25%, #00B775 100%)' },
    { guid: 'ed7', gradient: 'linear-gradient(135deg, #72C4FF 14.75%, #4325FA 100%)' },
    { guid: 'ed8', gradient: 'linear-gradient(to top, #40afff, #361bdc)' },
    { guid: 'ed9', gradient: 'linear-gradient(135deg, #BF48F8 14.75%, #5714E7 100%)' },
  ];

  let theGradient = gradients.find((gradient) => gradient.guid === discipline);

  return theGradient ? theGradient.gradient : 'linear-gradient(to top, #86e328, #00b775)';
}

function getCalendarTheme(calendar, themesCalendar) {
  let theme = {
    color: 'orange',
  };
  if (calendar.theme_guid && themesCalendar) {
    theme = themesCalendar.find((item) => item.guid === calendar.theme_guid);
  }

  return theme;
}

export function parseCalendars(calendars = [], themesCalendar) {
  if (calendars.length == 0) return calendars;

  let ar_icons = getParseDisciplines();

  return calendars.filter((c) => {
    try {
      const calendar = ar_icons.filter((_i) => _i.guid === c.education_discipline_guid)[0];
      c.iconType = calendar ? calendar.iconType : 'program';
      c.gradient = calendar ? calendar.gradient : 'linear-gradient(to top, #ff5029, #f91b6b)';
      c.calendarTheme = getCalendarTheme(c, themesCalendar);
    } catch (_r) {}
    c.level = 'level';
    c.studentsLength = 0;
    return c;
  });
}

export function parseKanbanColumns(columns = []) {
  let newColumns = {};
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    newColumns = {
      ...newColumns,
      [column.guid]: {
        guid: column.guid,
        order: column.order,
        name: column.name,
        items: parseKanbanCards(column.cards),
        icon: getKanbanColumnIcon(column.name),
        placeholder: getKanbanColumnPlaceholder(column.name),
        showCreate: column.name === 'what_to_do',
      },
    };
  }

  return newColumns;
}

export function parseKanbanCards(cards = []) {
  return cards.map((card) => {
    return parseKanbanCard(card);
  });
}

export function parseKanbanCard(card = {}) {
  return {
    ...card,
    text: card.description,
    icon: card.emoji,
    authorName: card.author_name,
    isEditorial: !card.person_guid,
  };
}

export function getKanbanColumnIcon(name) {
  if (name === 'what_to_do') return 'stream';
  if (name === 'to_do') return 'timer';
  if (name === 'doing') return 'lightning';
  if (name === 'done') return 'success';
}

export function getKanbanColumnPlaceholder(name) {
  if (name === 'what_to_do') return i18next.t('kanban:Drag here the task cards of what you are going to do first');
  if (name === 'to_do') return i18next.t('kanban:Drag here the task cards of what you are going to do first');
  if (name === 'doing') return i18next.t('kanban:Drag here the cards of the tasks you are doing now');
  if (name === 'done') return i18next.t('kanban:Drag your completed task cards here');
}

export function parseLanguages(languages = [], isFilter) {
  if (isFilter) {
    languages = languages.filter((language) => language.is_for_ui === 1);
  }

  return languages.map((language) => {
    return {
      ...language,
      description: i18next.t(`languages:${language.description}`),
    };
  });
}

export function parsePersonalizedPrograms(programs = []) {
  try {
    return programs.map((program) => {
      return {
        ...program,
        program_guid: program.guid,
        title: program.name,
        image: program.picture,
        isAccessible: 1,
        versionAuthorAvatar: program.users[0]?.avatar,
        versionName: true,
        isEdited: true,
        personalized: true,
        discipline: {
          guid: program.education_discipline_guid,
          name: program.education_discipline_name,
        },
        education_level: {
          guid: program.education_level_guid,
          name: program.education_level_name,
        },
        education_year: {
          guid: program.education_year_guid,
          name: program.education_year_name,
        },
        program: {
          code: program.code,
          guid: program.guid,
          name: program.name,
          picture: program.picture,
        },
        publication: {
          guid: program.publication_guid,
        },
      };
    });
  } catch (ex) {
    return [];
  }
}

export function parseEducationYearName(educationYearName) {
  try {
    let parsed = educationYearName.substring(0, 3);
    return parsed;
  } catch (ex) {
    return educationYearName;
  }
}
