import axios from 'axios';

export const GET_LICENSES_PROFILE = 'front/profile/licenses';
export const LICENSES = 'licenses/personals';

export function getLicensesProfile() {
  return axios.get(GET_LICENSES_PROFILE, {});
}

export function getLicensesProfileExpired() {
  return axios.get(GET_LICENSES_PROFILE + '?isExpired=true', {});
}

export function getLicenses(params) {
  return axios.get(LICENSES, { params });
}

export function createLicense(params) {
  return axios.post(LICENSES, params);
}

export function updateLicense(params) {
  return axios.put(LICENSES + '/' + params.guid, params);
}

export function deleteLicense(params) {
  return axios.delete(LICENSES, { data: params });
}

export function getLicensePublications(licenseGuid) {
  return axios.get(LICENSES + '/' + licenseGuid + '/publications', {});
}
