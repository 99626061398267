import Icons from '../../../_core/variables/icons-gradient';
import { ReactComponent as Stream } from '../../assets/icons-gradient/general/announce.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons-gradient/general/arrow-right.svg';
import { ReactComponent as Audio } from '../../assets/icons-gradient/general/audio.svg';
import { ReactComponent as BookOpen } from '../../assets/icons-gradient/general/book-open.svg';
import { ReactComponent as Program } from '../../assets/icons-gradient/general/book.svg';
import { ReactComponent as CalendarDone } from '../../assets/icons-gradient/general/calendar-done.svg';
import { ReactComponent as CheckChecked } from '../../assets/icons-gradient/general/checkbox/checkbutton--active.svg';
import { ReactComponent as CheckUnchecked } from '../../assets/icons-gradient/general/checkbox/checkbutton--inactive.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons-gradient/general/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons-gradient/general/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons-gradient/general/chevron-right.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons-gradient/general/chevron-up.svg';
import { ReactComponent as CirclePlayCicle } from '../../assets/icons-gradient/general/circle_play-circle.svg';
import { ReactComponent as Clipboard } from '../../assets/icons-gradient/general/clipboard.svg';
import { ReactComponent as Clock } from '../../assets/icons-gradient/general/clock.svg';
import { ReactComponent as Clock2 } from '../../assets/icons-gradient/general/clock2.svg';
import { ReactComponent as Close } from '../../assets/icons-gradient/general/close.svg';
import { ReactComponent as CommentFill } from '../../assets/icons-gradient/general/comment-fill.svg';
import { ReactComponent as Comment } from '../../assets/icons-gradient/general/comment.svg';
import { ReactComponent as Copy } from '../../assets/icons-gradient/general/copy.svg';
import { ReactComponent as Drive } from '../../assets/icons-gradient/general/drive.svg';
import { ReactComponent as Pencil } from '../../assets/icons-gradient/general/edit.svg';
import { ReactComponent as Exit } from '../../assets/icons-gradient/general/exit.svg';
import { ReactComponent as Eye } from '../../assets/icons-gradient/general/eye.svg';
import { ReactComponent as Filter } from '../../assets/icons-gradient/general/filter.svg';
import { ReactComponent as Flag } from '../../assets/icons-gradient/general/flag2.svg';
import { ReactComponent as Flame } from '../../assets/icons-gradient/general/flame.svg';
import { ReactComponent as Help } from '../../assets/icons-gradient/general/help.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons-gradient/general/image.svg';
import { ReactComponent as InfoCircle } from '../../assets/icons-gradient/general/info.svg';
import { ReactComponent as VisibleOff } from '../../assets/icons-gradient/general/invisible.svg';
import { ReactComponent as Key } from '../../assets/icons-gradient/general/key.svg';
import { ReactComponent as Lesson } from '../../assets/icons-gradient/general/lesson.svg';
import { ReactComponent as Lightning } from '../../assets/icons-gradient/general/lightning.svg';
import { ReactComponent as Like } from '../../assets/icons-gradient/general/like.svg';
import { ReactComponent as LinkContent } from '../../assets/icons-gradient/general/link.svg';
import { ReactComponent as Lock } from '../../assets/icons-gradient/general/lock.svg';
import { ReactComponent as Email } from '../../assets/icons-gradient/general/mail.svg';
import { ReactComponent as Menu } from '../../assets/icons-gradient/general/menu.svg';
import { ReactComponent as Mic } from '../../assets/icons-gradient/general/mic.svg';
import { ReactComponent as MoveDown } from '../../assets/icons-gradient/general/move-down.svg';
import { ReactComponent as MoveTo } from '../../assets/icons-gradient/general/move-up.svg';
import { ReactComponent as Move } from '../../assets/icons-gradient/general/move.svg';
import { ReactComponent as Online } from '../../assets/icons-gradient/general/online.svg';
import { ReactComponent as Onsite } from '../../assets/icons-gradient/general/onsite.svg';
import { ReactComponent as MoreBullets } from '../../assets/icons-gradient/general/options.svg';
import { ReactComponent as Link } from '../../assets/icons-gradient/general/paperclip.svg';
import { ReactComponent as Party } from '../../assets/icons-gradient/general/party.svg';
import { ReactComponent as PauseCicle } from '../../assets/icons-gradient/general/pause-circle.svg';
import { ReactComponent as Pin } from '../../assets/icons-gradient/general/pin.svg';
import { ReactComponent as PlayCicle } from '../../assets/icons-gradient/general/play-circle.svg';
import { ReactComponent as AddCircle } from '../../assets/icons-gradient/general/plus-circle.svg';
import { ReactComponent as Questionary } from '../../assets/icons-gradient/general/questionary.svg';
import { ReactComponent as Questionmark } from '../../assets/icons-gradient/general/questionmark.svg';
import { ReactComponent as Random } from '../../assets/icons-gradient/general/random.svg';
import { ReactComponent as Refresh } from '../../assets/icons-gradient/general/refresh.svg';
import { ReactComponent as Replay } from '../../assets/icons-gradient/general/replay.svg';
import { ReactComponent as Teacher } from '../../assets/icons-gradient/general/school.svg';
import { ReactComponent as Search } from '../../assets/icons-gradient/general/search.svg';
import { ReactComponent as Send } from '../../assets/icons-gradient/general/send.svg';
import { ReactComponent as Star } from '../../assets/icons-gradient/general/star-line.svg';
import { ReactComponent as CheckCheckedRound, ReactComponent as Success } from '../../assets/icons-gradient/general/success.svg';
import { ReactComponent as Task } from '../../assets/icons-gradient/general/test.svg';
import { ReactComponent as TestGroup } from '../../assets/icons-gradient/general/testgroup.svg';
import { ReactComponent as Thumbsup } from '../../assets/icons-gradient/general/thumbsup.svg';
import { ReactComponent as Timer } from '../../assets/icons-gradient/general/timer.svg';
import { ReactComponent as SwitchOff } from '../../assets/icons-gradient/general/toggle-left.svg';
import { ReactComponent as SwitchOn } from '../../assets/icons-gradient/general/toggle-right.svg';
import { ReactComponent as Tools } from '../../assets/icons-gradient/general/tools.svg';
import { ReactComponent as Trash } from '../../assets/icons-gradient/general/trash.svg';
import { ReactComponent as VideoCamera } from '../../assets/icons-gradient/general/video.svg';
import { ReactComponent as Video } from '../../assets/icons-gradient/general/video2.svg';
import { ReactComponent as Visible } from '../../assets/icons-gradient/general/view.svg';
import { ReactComponent as World } from '../../assets/icons-gradient/general/world.svg';
import { ReactComponent as Profile } from '../../assets/icons-gradient/header/account.svg';
import { ReactComponent as Calendar } from '../../assets/icons-gradient/header/calendar.svg';
import { ReactComponent as Warning } from '../../assets/icons-gradient/header/caution.svg';
import { ReactComponent as Content } from '../../assets/icons-gradient/header/content.svg';
import { ReactComponent as Notifications } from '../../assets/icons-gradient/header/notifications.svg';

const getCoreIcon = Icons.getIcon;

Icons.getIcon = (icon, gradient) => {
  let componentIcon;
  let lib = 'skin';

  switch (icon) {
    case 'add-circle':
      componentIcon = AddCircle;
      break;

    case 'audio':
      componentIcon = Audio;
      break;

    case 'book-open':
      componentIcon = BookOpen;
      break;

    case 'calendar':
      componentIcon = Calendar;
      break;

    case 'calendar-done':
      componentIcon = CalendarDone;
      break;

    case 'check-checked':
      componentIcon = CheckChecked;
      break;

    case 'check-unchecked':
      componentIcon = CheckUnchecked;
      break;

    case 'check-checked-round':
      componentIcon = CheckCheckedRound;
      break;

    case 'close':
      componentIcon = Close;
      break;

    case 'clock':
      componentIcon = Clock;
      break;

    case 'clock2':
      componentIcon = Clock2;
      break;

    case 'copy':
      componentIcon = Copy;
      break;

    case 'comment':
      componentIcon = Comment;
      break;

    case 'comment-fill':
      componentIcon = CommentFill;
      break;

    case 'content':
      componentIcon = Content;
      break;

    case 'chevron-down':
      componentIcon = ChevronDown;
      break;

    case 'chevron-up':
      componentIcon = ChevronUp;
      break;

    case 'chevron-right':
      componentIcon = ChevronRight;
      break;

    case 'chevron-left':
      componentIcon = ChevronLeft;
      break;

    case 'drive':
      componentIcon = Drive;
      break;

    case 'email':
      componentIcon = Email;
      break;

    case 'eye':
      componentIcon = Eye;
      break;

    case 'exit':
      componentIcon = Exit;
      break;

    case 'filter':
      componentIcon = Filter;
      break;

    case 'flag':
      componentIcon = Flag;
      break;

    case 'flame':
      componentIcon = Flame;
      break;

    case 'image':
      componentIcon = ImageIcon;
      break;

    case 'info-circle':
      componentIcon = InfoCircle;
      break;

    case 'link-content':
      componentIcon = LinkContent;
      break;

    case 'lock':
      componentIcon = Lock;
      break;

    case 'link':
      componentIcon = Link;
      break;

    case 'help':
      componentIcon = Help;
      break;

    case 'lesson':
      componentIcon = Lesson;
      break;

    case 'more-bullets':
      componentIcon = MoreBullets;
      break;

    case 'like':
      componentIcon = Like;
      break;

    case 'move':
      componentIcon = Move;
      break;

    case 'move-down':
      componentIcon = MoveDown;
      break;

    case 'move-up':
      componentIcon = MoveTo;
      break;

    case 'mic':
      componentIcon = Mic;
      break;

    case 'notifications':
      componentIcon = Notifications;
      break;

    case 'send':
      componentIcon = Send;
      break;

    case 'pdf':
      componentIcon = Content;
      break;

    case 'pencil':
      componentIcon = Pencil;
      break;

    case 'pin':
      componentIcon = Pin;
      break;

    case 'party':
      componentIcon = Party;
      break;

    case 'clipboard':
      componentIcon = Clipboard;
      break;

    case 'play-circle':
      componentIcon = PlayCicle;
      break;

    case 'circle-play-circle':
      componentIcon = CirclePlayCicle;
      break;

    case 'pause-circle':
      componentIcon = PauseCicle;
      break;

    case 'profile':
      componentIcon = Profile;
      break;

    case 'program':
      componentIcon = Program;
      break;

    case 'questionary':
      componentIcon = Questionary;
      break;

    case 'online':
      componentIcon = Online;
      break;

    case 'onsite':
      componentIcon = Onsite;
      break;

    case 'refresh':
      componentIcon = Refresh;
      break;

    case 'search':
      componentIcon = Search;
      break;

    case 'stream':
      componentIcon = Stream;
      break;

    case 'success':
      componentIcon = Success;
      break;

    case 'star':
      componentIcon = Star;
      break;

    case 'switch-off':
      componentIcon = SwitchOff;
      break;

    case 'switch-on':
      componentIcon = SwitchOn;
      break;

    case 'random':
      componentIcon = Random;
      break;

    case 'teacher':
      componentIcon = Teacher;
      break;

    case 'test-group':
      componentIcon = TestGroup;
      break;

    case 'timer':
      componentIcon = Timer;
      break;

    case 'thumbsup':
      componentIcon = Thumbsup;
      break;

    case 'trash':
      componentIcon = Trash;
      break;

    case 'video':
      componentIcon = Video;
      break;

    case 'video-camera':
      componentIcon = VideoCamera;
      break;

    case 'visible':
      componentIcon = Visible;
      break;

    case 'visible-off':
      componentIcon = VisibleOff;
      break;

    case 'warning':
      componentIcon = Warning;
      break;

    case 'world':
      componentIcon = World;
      break;

    case 'questionmark':
      componentIcon = Questionmark;
      break;

    case 'lightning':
      componentIcon = Lightning;
      break;

    case 'key':
      componentIcon = Key;
      break;

    case 'replay':
      componentIcon = Replay;
      break;

    case 'arrow-right':
      componentIcon = ArrowRight;
      break;
    case 'menu':
      componentIcon = Menu;
      break;
    case 'tools':
      componentIcon = Tools;
      break;
    case 'task':
      componentIcon = Task;
      break;
    default:
      componentIcon = getCoreIcon(icon).icon;
      lib = 'core';
      break;
  }

  return { icon: componentIcon, lib: lib };
};
