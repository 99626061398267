import React from 'react';

function MedalStart() {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.8575 21.3515H19.94C18.5582 21.3515 17.5524 20.5491 17.9101 19.7322L27.8189 0.693601C27.9979 0.284479 28.6036 0 29.2955 0H40.3991C41.4043 0 42.1359 0.583671 41.8757 1.17796L31.8875 20.398C31.6414 20.9604 30.8087 21.3515 29.8575 21.3515Z"
        fill="#C12A31"
      />
      <path
        d="M32.1488 31.1893H17.7342C16.9561 31.1893 16.2774 30.6578 16.0859 29.8987L14.1441 22.199C13.7094 20.4754 14.0822 18.6816 15.1669 17.278C16.2516 15.8743 17.8868 15.0693 19.6535 15.0693H30.2296C31.9962 15.0693 33.6316 15.8743 34.7163 17.278C35.801 18.6818 36.1736 20.4754 35.7389 22.199L33.7971 29.8987C33.6056 30.6576 32.9267 31.1893 32.1488 31.1893ZM19.0571 27.7656H30.826L32.4424 21.3565C32.6169 20.6642 32.4673 19.9439 32.0315 19.3801C31.5958 18.8162 30.9391 18.4929 30.2295 18.4929H19.6534C18.9439 18.4929 18.2871 18.8162 17.8513 19.3801C17.4156 19.9439 17.2661 20.6642 17.4406 21.3566L19.0571 27.7656Z"
        fill="#E6A22A"
      />
      <path
        d="M30.156 15.0693H29.1405V18.493H30.156C30.8748 18.493 31.5401 18.8164 31.9814 19.3802C32.4226 19.944 32.5743 20.6643 32.3975 21.3566L30.7603 27.7656H29.1405V31.1893H32.1001C32.8883 31.1893 33.5758 30.6578 33.7697 29.8987L35.7365 22.1991C36.1768 20.4755 35.7994 18.6819 34.7007 17.2782C33.6019 15.8743 31.9455 15.0693 30.156 15.0693Z"
        fill="#E6A22A"
      />
      <path
        d="M19.9472 21.3515H29.8647C31.2465 21.3515 31.2878 20.5491 30.9301 19.7322L21.1097 0.693601C20.9307 0.284479 20.325 0 19.6331 0H8.52952C7.52437 0 6.79277 0.583671 7.05293 1.17796L17.9172 20.398C18.1633 20.9604 18.996 21.3515 19.9472 21.3515Z"
        fill="#DE3E46"
      />
      <path
        d="M24.1278 64.0002C13.0575 64.0002 4 54.8916 4 43.7589C4 32.6262 13.0575 23.5176 24.1278 23.5176C35.198 23.5176 44.2555 32.6262 44.2555 43.7589C44.2555 54.8916 35.198 64.0002 24.1278 64.0002Z"
        fill="#E6A22A"
      />
      <path
        d="M24.1278 60.6941C34.54 60.6941 42.9808 52.2057 42.9808 41.7347C42.9808 31.2638 34.54 22.7754 24.1278 22.7754C13.7156 22.7754 5.27478 31.2638 5.27478 41.7347C5.27478 52.2057 13.7156 60.6941 24.1278 60.6941Z"
        fill="#C78300"
      />
      <path
        d="M9.27313 43.9511C9.27313 34.5058 16.9009 26.8277 26.2842 26.8277C30.4613 26.8277 34.2751 28.3512 37.2415 30.8496C34.1541 27.1324 29.4927 24.7559 24.2864 24.7559C14.9031 24.7559 7.27539 32.434 7.27539 41.8792C7.27539 47.1198 9.63635 51.812 13.3291 54.9807C10.7866 51.9948 9.27313 48.1558 9.27313 43.9511Z"
        fill="#E5A300"
      />
      <path d="M37.4792 28.3755C44.8594 35.7973 44.8594 47.8071 37.4792 55.229C30.099 62.6508 18.1565 62.6508 10.7764 55.229" fill="black" fillOpacity="0.05" />
      <path
        d="M44.3989 41.6913C44.3989 52.8993 35.3622 61.9852 24.2148 61.9852C13.0674 61.9852 4.03064 52.8993 4.03064 41.6913C4.03064 30.4833 13.0674 21.3975 24.2148 21.3975C35.3622 21.3975 44.3989 30.4833 44.3989 41.6913ZM8.1344 41.6913C8.1344 50.6205 15.3338 57.8591 24.2148 57.8591C33.0958 57.8591 40.2952 50.6205 40.2952 41.6913C40.2952 32.7621 33.0958 25.5235 24.2148 25.5235C15.3338 25.5235 8.1344 32.7621 8.1344 41.6913Z"
        fill="#FFC84B"
      />
      <path
        d="M32.7651 57.0847C32.9404 57.3992 32.8295 57.7982 32.5106 57.963C30.0008 59.2606 27.2201 59.9494 24.3919 59.9697C21.5637 59.9901 18.7734 59.3415 16.2453 58.0803C15.9241 57.92 15.8075 57.5227 15.9784 57.2057C16.1492 56.8888 16.5427 56.7723 16.8644 56.9316C19.1994 58.0884 21.7736 58.6831 24.3826 58.6643C26.9916 58.6455 29.557 58.0138 31.8753 56.8234C32.1946 56.6595 32.5898 56.7703 32.7651 57.0847Z"
        fill="#FFE09B"
      />
      <path
        d="M42.5243 36.6713C42.8256 36.5845 43.0006 36.2683 42.9056 35.968C41.6784 32.0894 39.2827 28.6841 36.0434 26.2275C32.653 23.6563 28.5224 22.2641 24.2756 22.2612C20.0287 22.2584 15.8963 23.645 12.5024 26.2117C9.25987 28.664 6.85968 32.0661 5.62733 35.943C5.53193 36.2432 5.70655 36.5597 6.00769 36.6468C6.30884 36.734 6.62242 36.5588 6.71835 36.2589C7.88219 32.6199 10.1389 29.4269 13.1846 27.1236C16.3816 24.7058 20.2743 23.3996 24.2748 23.4022C28.2753 23.4049 32.1662 24.7163 35.3601 27.1384C38.4027 29.4459 40.6552 32.6419 41.8142 36.2824C41.9097 36.5825 42.2231 36.7581 42.5243 36.6713Z"
        fill="#FFE09B"
      />
      <path
        d="M23.1882 31.3259C23.3219 31.0945 23.5135 30.9025 23.7437 30.7691C23.974 30.6356 24.2349 30.5654 24.5005 30.5654C24.766 30.5654 25.0269 30.6356 25.2572 30.7691C25.4874 30.9025 25.679 31.0945 25.8127 31.3259L29.2086 35.4091L35.8037 36.85C36.0632 36.9068 36.3033 37.0312 36.5001 37.2108C36.697 37.3904 36.8438 37.6189 36.9257 37.8736C37.0077 38.1282 37.0221 40.1954 36.9674 40.4574C36.9128 40.7194 36.791 40.9623 36.6142 41.162L32.1186 46.2357L32.799 51.3219C32.8258 51.5885 32.7829 53.5407 32.6744 53.7852C32.5659 54.0298 32.3957 54.2414 32.1807 54.3989C31.9658 54.5564 31.7136 54.6542 31.4494 54.6827C31.1852 54.7112 30.9183 54.6693 30.6752 54.5612L24.5005 51.8166L18.3258 54.5612C18.0826 54.6693 17.8157 54.7112 17.5515 54.6827C17.2873 54.6542 17.0351 54.5564 16.8202 54.3989C16.6052 54.2414 16.435 54.0298 16.3265 53.7852C16.218 53.5407 16.1751 51.7007 16.2019 51.4341L16.8823 46.2357L12.3868 41.1632C12.2096 40.9636 12.0876 40.7205 12.0327 40.4584C11.9779 40.1963 11.9922 38.241 12.0742 37.9862C12.1562 37.7314 12.3031 37.5027 12.5001 37.323C12.6972 37.1433 12.9375 37.0189 13.1972 36.9622L19.7923 35.5213L23.1882 31.3259Z"
        fill="#E6A22A"
      />
      <path
        d="M23.1882 29.5304C23.3219 29.2991 23.5135 29.1071 23.7437 28.9737C23.974 28.8402 24.2349 28.77 24.5005 28.77C24.766 28.77 25.0269 28.8402 25.2572 28.9737C25.4874 29.1071 25.679 29.2991 25.8127 29.5304L29.2086 35.4091L35.8037 36.85C36.0632 36.9068 36.3033 37.0312 36.5001 37.2108C36.697 37.3904 36.8438 37.6189 36.9257 37.8736C37.0077 38.1282 37.0221 38.4 36.9674 38.662C36.9128 38.924 36.791 39.1669 36.6141 39.3666L32.1186 44.4403L32.799 51.2097C32.8258 51.4763 32.7829 51.7452 32.6744 51.9898C32.5659 52.2344 32.3957 52.446 32.1807 52.6035C31.9658 52.761 31.7136 52.8588 31.4494 52.8873C31.1852 52.9158 30.9183 52.8739 30.6752 52.7657L24.5005 50.0212L18.3257 52.7657C18.0826 52.8739 17.8157 52.9158 17.5515 52.8873C17.2873 52.8588 17.0351 52.761 16.8202 52.6035C16.6052 52.446 16.435 52.2344 16.3265 51.9898C16.218 51.7452 16.1751 51.4763 16.2019 51.2097L16.8823 44.4403L12.3868 39.3678C12.2096 39.1681 12.0876 38.9251 12.0327 38.663C11.9779 38.4008 11.9922 38.1288 12.0742 37.874C12.1562 37.6192 12.3031 37.3905 12.5001 37.2108C12.6972 37.0311 12.9375 36.9067 13.1972 36.85L19.7923 35.4091L23.1882 29.5304Z"
        fill="#FFD159"
      />
      <path
        d="M23.2874 30.7592C23.3997 30.5637 23.5604 30.4015 23.7536 30.2888C23.9469 30.176 24.1659 30.1167 24.3888 30.1167C24.6116 30.1167 24.8306 30.176 25.0239 30.2888C25.2171 30.4015 25.3779 30.5637 25.4901 30.7592L28.5635 36.1755L34.8799 37.393C35.0976 37.4411 35.2992 37.5462 35.4644 37.698C35.6296 37.8497 35.7528 38.0428 35.8216 38.258C28.5635 37.698 18.1942 38.925 16.3527 42.5163C16.204 42.3476 13.3012 39.1465 13.2551 38.925C13.2091 38.7035 13.2211 38.4736 13.2899 38.2583C13.3588 38.043 13.482 37.8498 13.6474 37.6979C13.8128 37.5461 14.0145 37.441 14.2324 37.393L20.4372 36.1755L23.2874 30.7592Z"
        fill="#FFE19C"
      />
    </svg>
  );
}

export default MedalStart;
