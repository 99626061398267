import axios from 'axios';

export function getSchoolYears(params) {
  return axios.get(`front/school-admin/${params.schoolGuid}/school-years`, {
    params: params,
    ...(params && params.token
      ? {
          headers: {
            Authorization: params.token,
          },
        }
      : {}),
  });
}
