import { commentsMock } from './comments';
import { usersMock } from './users';

export const postsMock = [
  {
    title: 'About telescopes',
    text: 'Our telescopes have been getting better really fast in the last few decades. Have you ever used one? What did you see?   Share with your classmates.',
    likes: 10,
    views: 10,
    nameItem: '',
    type: 'task',
    createdAt: '',
    comments: commentsMock,
    commentsLength: commentsMock.length,
    pin: true,
    user: usersMock[0],
    guid: '1',
  },
  {
    title: 'About telescopes',
    text: 'Our telescopes have been getting better really fast in the last few decades. Have you ever used one? What did you see?   Share with your classmates.',
    likes: 10,
    comments: [],
    commentsLength: 0,
    views: 10,
    nameItem: '',
    type: 'task',
    createdAt: '',
    user: usersMock[1],
    guid: '2',
  },
  {
    title: 'About telescopes',
    text: 'Our telescopes have been getting better really fast in the last few decades. Have you ever used one? What did you see?   Share with your classmates.',
    likes: 10,
    comments: commentsMock,
    commentsLength: commentsMock.length,
    views: 10,
    nameItem: '',
    type: 'task',
    createdAt: '',
    user: usersMock[0],
    guid: '3',
  },
];

export const apiMessagesMock = {
  status: 'success',
  data: {
    total: 2,
    left: 0,
    pageSize: 20,
    offset: 0,
    messages: [
      {
        guid: '9e994f90-243b-11eb-81fa-e7989af3adad',
        message_for: 'all',
        message_for_users: null,
        course_guid: '3cbd0230-11fd-11eb-81fa-e7989af3adad',
        message: 'Otro comentario de prueba',
        created_at: '2020-11-11T16:33:24.000Z',
        creator_guid: 'c3bc2251-0f02-11eb-81fa-e7989af3adad',
        creator_name: 'Pofesor',
        creator_lasname: 'Rendimeinto',
        creator_avatar: 'https://octangerine.s3.eu-central-1.amazonaws.com/c3bc2251-0f02-11eb-81fa-e7989af3adad/avatar-BP7hTTJQfY',
        creator_role_guid: 'R02',
        creator_role_name: 'Teacher',
        amount_comments: 0,
        lesson_item_guid: null,
        lesson_item_name: null,
        lesson_item_deliver_at: null,
        lesson_item_content_guid: null,
        lesson_item_content_type_guid: null,
        lesson_item_content_name: null,
        lesson_item_content_description: null,
        lesson_item_content_thumbnail: null,
        lesson_item_content_url: null,
        lesson_item_content_size: null,
        lesson_guid: null,
        lesson_name: null,
        files: [],
      },
      {
        guid: 'b8f5b310-2422-11eb-81fa-e7989af3adad',
        message_for: 'all',
        message_for_users: null,
        course_guid: '3cbd0230-11fd-11eb-81fa-e7989af3adad',
        message: 'Comentario de Prueba',
        created_at: '2020-11-11T13:35:11.000Z',
        creator_guid: 'c3bc2251-0f02-11eb-81fa-e7989af3adad',
        creator_name: 'Pofesor',
        creator_lasname: 'Rendimeinto',
        creator_avatar: 'https://octangerine.s3.eu-central-1.amazonaws.com/c3bc2251-0f02-11eb-81fa-e7989af3adad/avatar-BP7hTTJQfY',
        creator_role_guid: 'R02',
        creator_role_name: 'Teacher',
        amount_comments: 1,
        lesson_item_guid: null,
        lesson_item_name: null,
        lesson_item_deliver_at: null,
        lesson_item_content_guid: null,
        lesson_item_content_type_guid: null,
        lesson_item_content_name: null,
        lesson_item_content_description: null,
        lesson_item_content_thumbnail: null,
        lesson_item_content_url: null,
        lesson_item_content_size: null,
        lesson_guid: null,
        lesson_name: null,
        files: [],
      },
    ],
  },
};

export const apiMessagesAnswersMock = {
  status: 'success',
  data: {
    guid: 'b8f5b310-2422-11eb-81fa-e7989af3adad',
    message_for: 'all',
    message_for_users: null,
    course_guid: '3cbd0230-11fd-11eb-81fa-e7989af3adad',
    message: 'Comentario de Prueba',
    created_at: '2020-11-11T13:35:11.000Z',
    creator_guid: 'c3bc2251-0f02-11eb-81fa-e7989af3adad',
    creator_name: 'Pofesor',
    creator_lasname: 'Rendimeinto',
    creator_avatar: 'https://octangerine.s3.eu-central-1.amazonaws.com/c3bc2251-0f02-11eb-81fa-e7989af3adad/avatar-BP7hTTJQfY',
    creator_role_guid: 'R02',
    creator_role_name: 'Teacher',
    amount_comments: 1,
    lesson_item_guid: null,
    lesson_item_name: null,
    lesson_item_deliver_at: null,
    lesson_item_content_guid: null,
    lesson_item_content_type_guid: null,
    lesson_item_content_name: null,
    lesson_item_content_description: null,
    lesson_item_content_thumbnail: null,
    lesson_item_content_url: null,
    lesson_item_content_size: null,
    lesson_guid: null,
    lesson_name: null,
    files: [],
    comments: [
      {
        guid: 'bed63750-2422-11eb-81fa-e7989af3adad',
        message: 'Respuesta de prueba',
        creator_guid: 'c3bc2251-0f02-11eb-81fa-e7989af3adad',
        created_at: '2020-11-11T13:35:21.000Z',
        creator_name: 'Pofesor',
        creator_lasname: 'Rendimeinto',
        creator_avatar: 'https://octangerine.s3.eu-central-1.amazonaws.com/c3bc2251-0f02-11eb-81fa-e7989af3adad/avatar-BP7hTTJQfY',
        creator_role_guid: 'R02',
        creator_role_name: 'Teacher',
      },
    ],
  },
};

export const apiPostMessage = {
  status: 'success',
  data: {
    message: 'test',
    message_for: 'all',
    guid: 'f169e440-24c8-11eb-81fa-e7989af3adad',
    course_guid: '3cbd0230-11fd-11eb-81fa-e7989af3adad',
    message_for_users: null,
    parent_guid: null,
    lesson_item_guid: null,
    creator_guid: 'c3bc2251-0f02-11eb-81fa-e7989af3adad',
    created_at: '2020-11-12T09:25:02.724Z',
  },
};
