export function getAverage(items = []) {
  if (isAllDash(items)) {
    return '-';
  } else if (isAllPending(items)) {
    return '-';
  } else {
    let sum = 0;
    let unitsDelivered = 0;
    for (const unit of items) {
      sum += unit.score !== '-' ? parseFloat(unit.score) : 0;
      unitsDelivered += unit.score !== '-' ? 1 : 0;
    }
    return sum !== 0 ? (sum / unitsDelivered).toFixed(1) : 0;
  }
}

function isAllDash(items) {
  let isAll = true;
  for (const unit of items) {
    if (unit.score !== '-') {
      isAll = false;
      break;
    }
  }
  return isAll;
}

function isAllPending(items) {
  let isAll = true;
  for (const unit of items) {
    if (unit.status !== 'pending' || unit.score !== '-') {
      isAll = false;
      break;
    }
  }
  return isAll;
}
