import useMathRender from '_core/hooks/useMathRender';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

// blockFormatted: lo añado porque la funcion que formatea, rompe algunas formulas de katex,
// entonces... el cliente se quejo en el generador de evaluaciones, así por el momento se usa ahi
const TextHtml = ({ text, className, onClick, id, blockFormatted }) => {
  const rootNode = useRef(null);
  let formattedText = text;

  if (text && !blockFormatted && typeof text.includes === 'function' && text.includes('\n')) {
    formattedText = text.replace(/\n/g, '<br />');
  }

  useMathRender(rootNode, [formattedText]);

  return <div ref={rootNode} id={id} dangerouslySetInnerHTML={{ __html: formattedText }} className={clsx('text-html', className)} onClick={onClick} />;
};

TextHtml.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

TextHtml.defaultProps = {
  id: 'useMathRender',
  onClick: () => {},
  text: '',
};

export default TextHtml;
