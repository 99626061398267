import { useEffect, useState } from 'react';
import { useTeamsData } from './useTeamsData';

export const useTeamsView = () => {
  const [step, setStep] = useState(1);
  const {
    mode,
    setMode,
    books,
    courses,
    licenses,
    isLoading,
    isTeacher,
    isStudent,
    userContext,
    shouldConsent,
    selectedCourse,
    onCheckLicense,
    onClearLicenses,
    onLoginMicrosoft,
    hasAuthenticated,
    onConsentRequest,
    onSignupMicrosoft,
    setSelectedCourse,
    addCourseToChannel,
    createClassAndCourse,
  } = useTeamsData();

  useEffect(() => {
    if (hasAuthenticated && courses) {
      setStep(3);
    }
  }, [hasAuthenticated, courses, licenses]);

  return {
    step,
    setStep,
    mode,
    setMode,
    books,
    courses,
    licenses,
    isLoading,
    isTeacher,
    isStudent,
    userContext,
    shouldConsent,
    selectedCourse,
    onCheckLicense,
    onClearLicenses,
    onConsentRequest,
    onLoginMicrosoft,
    onSignupMicrosoft,
    setSelectedCourse,
    addCourseToChannel,
    createClassAndCourse,
  };
};
