export const getCardResponse = (response) => {
  var toRet = {}
  if(response){
    toRet = {...response}
    if(toRet.data){
      const dataJson = JSON.parse(toRet.data);
      delete toRet.data;
      toRet = {
        ...toRet,
        ...dataJson
      }
    } 
  }
  return toRet;
};

