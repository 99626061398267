import { getFonts } from '_core/crud/templates.crud';
import { COURSE_MODE } from '_core/utils/constants/course';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { actionTypes as actionAuth } from './auth.duck';
import { PERSIST_UI, REDUCER_UI } from './conf';

export const actionTypes = {
  SearchHeaderbar: 'ui/SEARCH_HEADERBAR',
  ChangeTheme: 'ui/CHANGE_THEME',
  ShowCreateClass: 'ui/SHOW_CREATE_CLASS',
  CourseMode: 'ui/COURSE_MODE',
  ShowInfoDialog: 'ui/SHOW_INFO_DIALOG',
  AddPreselectedPrograms: 'ui/ADD_PRESELECTED_PROGRAMS',
  ChangeShowMigrationPopup: 'ui/CHANGE_SHOW_MIGRATION_POPUP',
  SetHealthStatus: 'ui/SET_HEALTH_STATUS',
  SetEmbedFrom: 'ui/SET_EMBED_FROM',
  SetUiColors: 'cms/SET_UI_COLOR',
  SetUiFonts: 'cms/SET_UI_FONTS',
  SetImmersiveReaderOptions: 'ui/SET_IR_OPTIONS',
  SetCustomAvatar: 'ui/SET_CUSTOM_AVATAR',
  ShowGlobalPostDialog: 'ui/SHOW_GLOBAL_POST_DIALOG',
  GetFonts: 'ui/GET_FONTS',
  SetFonts: 'ui/SET_FONTS',
};

const initialState = {
  headerSearch: undefined,
  theme: 'light',
  showCreateClass: false,
  preselectedProgramsToCreateClass: [],
  courseMode: COURSE_MODE.default,
  infoDialog: { text: null },
  showMigrationPopup: false,
  embedFrom: null,
  fonts: [],
  immersiveReaderOptions: {},
  customAvatar: {
    topType: 'ShortHairShortFlat',
    accessoriesType: 'Blank',
    hairColor: 'BrownDark',
    facialHairType: 'Blank',
    clotheType: 'Hoodie',
    clotheColor: 'PastelBlue',
    eyeType: 'Default',
    eyebrowType: 'Default',
    mouthType: 'Smile',
    skinColor: 'Light',
  },
  healthStatus: {
    code: 200,
    title: '',
    message: '',
  },
  color: '',
  dialogs: {
    showGlobalPost: { open: false, message: '' },
  },
};

export const reducer = persistReducer(
  {
    storage,
    key: PERSIST_UI,
    blacklist: ['headerSearch', 'showCreateClass', 'infoDialog', 'preselectedProgramsToCreateClass'],
    whitelist: ['immersiveReaderOptions', 'customAvatar'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SearchHeaderbar:
        return { ...state, headerSearch: action.payload.value };

      case actionTypes.ChangeTheme:
        return { ...state, theme: action.payload.theme };

      case actionTypes.ShowCreateClass:
        return { ...state, showCreateClass: action.payload };

      case actionTypes.CourseMode:
        return { ...state, courseMode: action.payload };

      case actionTypes.ShowInfoDialog:
        return { ...state, infoDialog: action.payload };

      case actionTypes.AddPreselectedPrograms:
        return { ...state, preselectedProgramsToCreateClass: action.payload };

      case actionTypes.ChangeShowMigrationPopup:
        return { ...state, showMigrationPopup: action.payload };

      case actionTypes.SetHealthStatus: {
        return { ...state, healthStatus: action.payload };
      }

      case actionTypes.SetEmbedFrom: {
        return { ...state, embedFrom: action.payload };
      }

      case actionTypes.SetImmersiveReaderOptions: {
        return { ...state, immersiveReaderOptions: action.payload };
      }

      case actionTypes.SetCustomAvatar: {
        return { ...state, customAvatar: action.payload };
      }

      case actionAuth.UserLoaded: {
        const { user } = action.payload;

        return { ...state, showMigrationPopup: !user.data.oraculo_id && user.data.role_guid === 'R02' && user.data.source !== 'lti' && user.is_demo !== 1 };
      }

      case actionTypes.SetUiColors: {
        return { ...state, colors: action.payload.colors };
      }

      case actionTypes.SetUiFonts: {
        return { ...state, fontsUi: action.payload.fonts };
      }

      case actionTypes.ShowGlobalPostDialog: {
        return { ...state, dialogs: { ...state.dialogs, showGlobalPost: action.payload } };
      }

      case actionTypes.ShowGlobalPostDialog: {
        return { ...state, dialogs: { ...state.dialogs, showGlobalPost: action.payload } };
      }

      case actionTypes.SetFonts: {
        return { ...state, fonts: action.payload };
      }

      case actionAuth.Logout:
        return initialState;

      default:
        return state;
    }
  }
);

export const selectors = {
  getSearchHeaderbar: (state) => {
    return state[REDUCER_UI].headerSearch;
  },
  getTheme: (state) => {
    return state[REDUCER_UI].theme;
  },
  getShowCreateClass: (state) => {
    return state[REDUCER_UI].showCreateClass;
  },
  getCourseMode: (state) => {
    return state[REDUCER_UI].courseMode;
  },
  getInfoDialog: (state) => {
    return state[REDUCER_UI].infoDialog;
  },
  getPreselectedPrograms: (state) => {
    return state[REDUCER_UI].preselectedProgramsToCreateClass;
  },
  getShowMigrationPopup: (state) => {
    return state[REDUCER_UI].showMigrationPopup;
  },
  getHealthStatus: (state) => {
    return state[REDUCER_UI].healthStatus;
  },
  getEmbedFrom: (state) => {
    return state[REDUCER_UI].embedFrom;
  },
  getColorsUi: (state) => {
    return state[REDUCER_UI].colors;
  },
  getFontsUi: (state) => {
    return state[REDUCER_UI].fontsUi;
  },
  getImmersiveReaderOptions: (state) => {
    return state[REDUCER_UI].immersiveReaderOptions;
  },
  getCustomAvatar: (state) => {
    return state[REDUCER_UI].customAvatar;
  },
  getShowGlobalPostDialog: (state) => {
    return state[REDUCER_UI].dialogs.showGlobalPost;
  },
  getFonts: (state, guid) => {
    return state[REDUCER_UI].fonts;
  },
};

export const actions = {
  searchHeaderbar: (value) => ({ type: actionTypes.SearchHeaderbar, payload: { value } }),
  setTheme: (theme) => ({ type: actionTypes.ChangeTheme, payload: { theme } }),
  setShowCreateClass: (value) => ({ type: actionTypes.ShowCreateClass, payload: value }),
  setCourseMode: (value) => ({ type: actionTypes.CourseMode, payload: value }),
  setShowInfoDialog: (value) => ({ type: actionTypes.ShowInfoDialog, payload: value }),
  addPreselectedPrograms: (value) => ({ type: actionTypes.AddPreselectedPrograms, payload: value }),
  changeShowMigrationPopup: (value) => ({ type: actionTypes.ChangeShowMigrationPopup, payload: value }),
  setHealthStatus: (value) => ({ type: actionTypes.SetHealthStatus, payload: value }),
  setEmbedFrom: (value) => ({ type: actionTypes.SetEmbedFrom, payload: value }),
  setUiColors: (colors) => ({ type: actionTypes.SetUiColors, payload: { colors } }),
  setUiFonts: (fonts) => ({ type: actionTypes.SetUiFonts, payload: { fonts } }),
  setImmersiveReaderOptions: (value) => ({ type: actionTypes.SetImmersiveReaderOptions, payload: value }),
  setCustomAvatar: (value) => ({ type: actionTypes.SetCustomAvatar, payload: value }),
  changeShowGlobalPostDialog: (value) => ({ type: actionTypes.ShowGlobalPostDialog, payload: value }),
  getFonts: () => ({ type: actionTypes.GetFonts }),
  setFonts: (fonts) => ({ type: actionTypes.SetFonts, payload: fonts }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetFonts, function* getFontsSaga(action) {
    const { data } = yield getFonts();
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setFonts(data.data));
    }
  });
}
