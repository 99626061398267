import { schema } from 'normalizr';

const educationLevelsSchema = new schema.Entity('educationLevels', {},{ idAttribute: 'guid' });
const educationYearsSchema = new schema.Entity('educationYears', {},{ idAttribute: 'guid' });
const projectSchema = new schema.Entity('contents', {/*educationLevels: [educationLevelsSchema], educationYears: [educationYearsSchema]*/},{ idAttribute: 'guid' });
export const collectionsSchema = new schema.Entity(
    'collections', 
    { educationLevels: [
        {
            //educationYears: [educationYearsSchema],
            contents: [projectSchema]
        }
    ]},
    { idAttribute: 'guid'}
);
