import { useTranslate } from '_core/hooks/useTranslate';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import GamificationLeaderBoard from '_core/modules/components/gamified/GamificationLeaderBoard/GamificationLeaderBoard';
import Trophy from '_core/modules/components/gamified/icons/icons/Trophy';
import PropTypes from 'prop-types';
import React from 'react';
import GamifiedLevelProgress from '../GamifiedLevelProgress/GamifiedLevelProgress';
import useGamificationDialogRanking from './useGamificationDialogRanking';

const GamificationDialogBadges = ({ open, onClose, level }) => {
  const { t } = useTranslate();
  const { basicInfo, loading, users, userLoggedGuid } = useGamificationDialogRanking({ open });

  return (
    <DialogDefault
      open={open}
      className="gamification-dialog-badges"
      onClose={onClose}
      title={
        <div className="gamification-dialog-badges__title">
          <div className="gamification-dialog-badges__trophy">
            <Trophy />
          </div>
          <span className="gamification-dialog-badges__text">{basicInfo.level}</span>
        </div>
      }
    >
      <div className="gamification-dialog-ranking__wrapper">
        <div className="gamification-dialog-ranking__progress">
          <GamifiedLevelProgress
            level={basicInfo.order}
            start={basicInfo.totalPoints}
            end={basicInfo.pointsForNextLevel}
            progress={basicInfo.progressToNextLevel}
          />
        </div>
        {basicInfo.progressToNextLevel !== 100 && (
          <span className="gamification-dialog-ranking__text-next-level">
            {t('gamification:Get x EXP to go to the next level', { exp: basicInfo.pointsForNextLevel - basicInfo.totalPoints })}
          </span>
        )}
        <div className="gamification-dialog-ranking__board">
          <GamificationLeaderBoard t={t} users={users} loading={loading} userLoggedGuid={userLoggedGuid} />
        </div>
      </div>
    </DialogDefault>
  );
};

GamificationDialogBadges.propTypes = {
  open: PropTypes.bool,
};

GamificationDialogBadges.defaultProps = {
  open: false,
};

export default GamificationDialogBadges;
