import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getTemplates } from '_core/crud/templates.crud';
import { PERSIST_TEMPLATES, REDUCER_TEMPLATES } from './conf';

export const actionTypes = {
  GetTemplates: 'cms/GET_TEMPLATES',
  SetTemplates: 'cms/SET_TEMPLATES',
};

const initialState = {
  templates: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_TEMPLATES, whitelist: ['data'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTemplates:
      return { ...state, loading: true };

    case actionTypes.SetTemplates: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { templates: response.data, loading: false };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getTemplates: (state) => {
    return state.entities && state.entities[REDUCER_TEMPLATES] ? state.entities[REDUCER_TEMPLATES].templates : null;
  },
};

export const actions = {
  getTemplates: () => ({ type: actionTypes.GetTemplates }),
  fulfillTemplates: (response) => ({ type: actionTypes.SetTemplates, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetTemplates, function* getTemplatesSaga() {
    const { data } = yield getTemplates();
    yield put(actions.fulfillTemplates(data));
  });
}
