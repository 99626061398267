import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import kanban_ca from './ca/kanban.json';
import kanban_en from './en/kanban.json';
import ui_en from './en/ui.json';
import kanban_es from './es/kanban.json';
import ui_es from './es/ui.json';
import kanban_eu from './eu/kanban.json';
import kanban_gl from './gl/kanban.json';
import kanban_mx from './mx/kanban.json';
import ui_mx from './mx/ui.json';
import kanban_pt from './pt/kanban.json';
import ui_pt from './pt/ui.json';
import kanban_vl from './vl/kanban.json';
import ui_vl from './vl/ui.json';
//import XHR from 'i18next-xhr-backend';

i18n
  .use(LanguageDetector)
  //.use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'es',
    lng: 'es',
    resources: {
      pt: { ui: ui_pt, kanban: kanban_pt },
      en: { ui: ui_en, kanban: kanban_en },
      es: { ui: ui_es, kanban: kanban_es },
      gl: { ui: ui_es, kanban: kanban_gl },
      eu: { ui: ui_es, kanban: kanban_eu },
      vl: { ui: ui_vl, kanban: kanban_vl },
      ca: { ui: ui_es, kanban: kanban_ca },
      'es-MX': { ui: ui_mx, kanban: kanban_mx },
    },
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
