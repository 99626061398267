import axios from 'axios';
import { randomGuidGenerator } from './utils';

export const CHANGE_PASS_URL = 'users/change-password';
export const CREATE_USER_URL = 'users';
export const EDIT_USER_URL = 'users';
export const EDIT_PROFILE = 'front/profile';
export const LOGIN_APP_URL = 'p+/login-app';
export const LOGIN_SSO_URL = 'p+/login-sso';
export const LOGIN_URL = 'login';
export const ME_URL = 'users/whoami';
export const RECOVER_ACCOUNT = 'front/recover-account';
export const RECOVER_ACCOUNT_PASSWORD = 'front/recover-account-password';
export const REGISTER_URL = 'register';
export const REQUEST_PASSWORD_URL = 'forgot-password';
export const SIGN_UP_USER_URL = 'front/sign-up/user';
export const SIGN_UP_LICENSE = 'front/sign-up/license/';
export const CHECK_USER = 'front/school-admin/check/check-users';
export const LOGIN_OAUTH = 'login-oauth';
export const LOGIN_OAUTH_URL = 'login-oauth';
export const MAGIC_LINK = 'users/magic-link';
export const SESSIONS = 'sessions';

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password /*, admitted_roles: ["1","3"]*/ });
}
export function loginPublisher(email, password) {
  return axios.post(LOGIN_URL, { email, password, admitted_roles: ['R01', 'R02', 'R03', 'R04', 'R05'] });
}
export function loginNoPublisher(email, password) {
  return axios.post(LOGIN_URL, { email, password, admitted_roles: ['R01', 'R02'] });
}

export function sendMagicLink(url) {
  return axios.post(MAGIC_LINK, { url });
}

export function loginApp(email, password) {
  return axios.post(LOGIN_APP_URL, {
    email,
    password,
    device_guid: randomGuidGenerator(),
    device_name: 'tangerine-web-app',
    device_description: 'Browser' /*, admitted_roles: ["1","3"]*/,
  });
}

export function loginSSO(token, identity_provider) {
  //return requestPostWithCancel(LOGIN_SSO_URL, { token }, 'post');
  return axios.post(LOGIN_OAUTH, { token, identity_provider });
}

export function loginOauth(token, identity_provider) {
  return axios.post(LOGIN_OAUTH_URL, { token, identity_provider });
}

// used for teams only
export function loginOBO(params) {
  return axios.post(LOGIN_OAUTH, params);
}

export function register(email, fullname, password) {
  return axios.post(REGISTER_URL, { email, fullname, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(params) {
  // Authorization head should be fulfilled in interceptor.
  if (params && params.token) {
    return axios.get(ME_URL, {
      headers: {
        Authorization: params.token,
      },
    });
  }
  return axios.get(ME_URL);
}

export function editUser(guid, name, lastname, email) {
  return axios.put(EDIT_USER_URL + '/' + guid, { name, lastname, email });
}

export function editProfile(user) {
  return axios.put(EDIT_PROFILE, user);
}

export function editPass(passActual, passNew) {
  return axios.put(CHANGE_PASS_URL, { current: passActual, new: passNew, source: 'profile' });
}

export function createUser(name, lastname, email, username, password, roleGuid, changePassword = false) {
  return axios.post(CREATE_USER_URL, { name, lastname, email, username, password, role_guid: roleGuid, change_password: changePassword ? 1 : 0 });
}

export function recoverAccount(email, params) {
  return axios.post(RECOVER_ACCOUNT, { email, ...params });
}

export function recoverAccountPassword(token, password) {
  return axios.post(RECOVER_ACCOUNT_PASSWORD, { token, new_password: password });
}

/*export function singUpUser(name, lastname, email, username, password, roleGuid, licenses = null) {
  return axios.post(SIGN_UP_USER_URL, { name, lastname, email, username, password, role: roleGuid, licenses: licenses });
}*/

export function signupUser(payload) {
  return axios.post(SIGN_UP_USER_URL, payload);
}

export function validateLicense(license) {
  return axios.get(SIGN_UP_LICENSE + license, { params: { type: ['CTTY_01', 'CTTY_02'] } });
}

export function checkUser(user) {
  return axios.post(CHECK_USER, user);
}

export function loginWithUserGuid(userGuid) {
  return axios.post(`users/${userGuid}/login`, {});
}
