import axios from 'axios';

export const GET_LANGS = 'langs';

export function getLangs() {
  return axios.get(GET_LANGS);
}

export function getLangScopes() {
  return axios.get(GET_LANGS + '/scopes');
}

export function getScope(scope) {
  return axios.get(GET_LANGS + `/scopes?scope[]=${scope}`);
}
