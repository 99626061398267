import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PERSIST_SAML, REDUCER_SAML } from '../conf';

export const actionTypes = {
  IsSaml: 'saml/IS_SAML',
};

const initialSamlState = {
  isSaml: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_SAML, whitelist: ['isSaml'] }, (state = initialSamlState, action) => {
  switch (action.type) {
    case actionTypes.IsSaml: {
      const { isSaml } = action.payload;

      return { isSaml: isSaml };
    }

    default:
      return state;
  }
});

export const selectors = {
  getIsSaml: (state) => {
    return state[REDUCER_SAML] ? state[REDUCER_SAML].isSaml : false;
  },
};

export const actions = {
  isSaml: (isSaml) => ({ type: actionTypes.IsSaml, payload: { isSaml } }),
};
