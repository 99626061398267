import { getCountries } from '_core/crud/countries.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { PERSIST_COUNTRIES, REDUCER_COUNTRIES } from './conf';

export const actionTypes = {
  GetCountries: 'cms/GET_COUNTRIES',
  SetCountries: 'cms/SET_COUNTRIES',
};

const initialState = {
  countries: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_COUNTRIES, whitelist: ['countries'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetCountries:
      return { ...state, loading: true };

    case actionTypes.SetCountries: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { countries: response.data, loading: false };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getCountries: (state) => {
    return state.entities && state.entities[REDUCER_COUNTRIES] ? state.entities[REDUCER_COUNTRIES].countries : null;
  },
};

export const actions = {
  getCountries: () => ({ type: actionTypes.GetCountries }),
  fulfillCountries: (response) => ({ type: actionTypes.SetCountries, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetCountries, function* getCountriesSaga() {
    const { data } = yield getCountries();

    yield put(actions.fulfillCountries(data));
  });
}
