import {
  addTestToLesson,
  createContent,
  createLessonItem,
  createLessonItemsBatch,
  createTest,
  deleteContent,
  deleteLessonItem,
  getContentCMS,
  getContentsCMS,
  getJobs,
  getLesson,
  markContent,
  reorderLessonItems,
  updateContent,
  updateLessonItem,
} from '_core/crud/contents.crud';
import { getItemsOfLesson } from '_core/crud/lesson.crud';
import { put, takeLatest } from 'redux-saga/effects';
import { REDUCER_CONTENT } from '../conf';

export const actionTypes = {
  GetLesson: 'lms/GET_LESSON',
  SetLesson: 'lms/SET_LESSON',
  GetLessonItems: 'lms/GET_LESSON_ITEMS',
  SetLessonItems: 'lms/SET_LESSON_ITEMS',
  CreateLessonItem: 'lms/CREATE_LESSON_ITEM',
  AddOneLessonItem: 'lml/ADD_ONE_LESSON_ITEM',
  UpdateLessonItem: 'lms/UPDATE_LESSON_ITEM',
  ReplaceLessonItem: 'lms/REPLACE_LESSON_ITEM',
  DeleteLessonItem: 'lms/DELETE_LESSON_ITEM',
  RemoveLessonItem: 'lms/REMOVE_LESSON_ITEM',
  GetContent: 'lms/GET_CONTENT',
  SetContent: 'lms/SET_CONTENT',
  RemoveContent: 'lms/REMOVE_CONTENT',
  CreateContent: 'lms/CREATE_CONTENT',
  AddOneContent: 'lms/ADD_ONE_CONTENT',
  UploadedContent: 'lms/UPLOADED_CONTENT',
  DeleteContent: 'lms/DELETE_CONTENT',
  ReorderLessonItems: 'lms/REORDER_LESSON_ITEMS',
  UpdateOrderOfLessonItems: 'lms/UPDATE_ORDER_OF_LESSON_ITEMS',
  CreateTest: 'lms/CREATE_TEST',
  AddOneTestItem: 'lms/ADD_TEST',
  CreateLessonItemsBatch: 'lms/CREATE_LESSON_ITEMS_BATCH',
  AddMultipleLessonItems: 'lms/ADD_MULTIPLE_LESSON_ITEMS',
  CreateContentCms: 'lms/CREATE_CONTENT_CMS',
  CurrentContentCms: 'lms/NEW_CONTENT_CMS',
  UpdateContentCms: 'lms/UPDATE_CONTENT_CMS',
  GetContentsForSearch: 'lms/GET_CONTENTS_FOR_SEARCH',
  FulfillContentsForSearch: 'lms/Fulfill_CONTENTS_FOR_SEARCH',
  MarkContent: 'lms/MARK_CONTENT',
  UpdateContentJob: 'lms/UPDATE_CONTENT_JOB',
  GetJobs: 'lms/GET_JOBS',
  SetJobs: 'lms/SET_JOBS',
};

//TO HAVE A DEFAULT NAMING CONVENTION
const namesState = {
  name: 'content',
  loading: 'loading',
};

const initialState = {
  [namesState.name]: {},
  lesson: {
    loading: false,
    data: {},
  },
  items: [],
  loading: false,
  uploadingFile: false,
  selectedContent: null,
  currentContentCms: null,
  contentsForSearch: {
    left: 0,
    length: 0,
    offset: 0,
    pageSize: 0,
    data: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetLesson: {
      return { ...state, lesson: { loading: true } };
    }

    case actionTypes.SetLesson: {
      const { params } = action.payload;
      if (params && params.status === 'success' && params.data) {
        return { ...state, lesson: { data: params.data, loading: false } };
      }
      return { ...state };
    }

    case actionTypes.GetLessonItems: {
      return { ...state, loading: true };
    }

    case actionTypes.SetLessonItems: {
      const { params } = action.payload;
      if (params && params.status === 'success' && params.data) {
        return {
          ...state,
          items: params.data.items,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.GetContent: {
      return { ...state };
    }

    case actionTypes.SetContent: {
      const { params } = action.payload;
      if (params && params.guid) {
        return {
          ...state,
          selectedContent: params,
        };
      }

      return { ...state };
    }

    case actionTypes.RemoveContent: {
      const { params } = action.payload;
      if (params && params.guid && params.guid.length) {
        return {
          ...state,
          contentsForSearch: {
            ...state.contentsForSearch,
            data: state.contentsForSearch.data.filter((cfs) => !params.guid.includes(cfs.guid)),
          },
          loading: false,
        };
      }
      return {
        ...state,
        selectedContent: null,
      };
    }

    case actionTypes.CreateContent: {
      return { ...state, loading: true, uploadingFile: true, currentContentCms: null };
    }
    case actionTypes.UpdateContentCms: {
      return { ...state, loading: true, currentContentCms: null };
    }
    case actionTypes.CurrentContentCms: {
      const { params } = action.payload;
      if (params && params.guid) {
        return {
          ...state,
          loading: false,
          currentContentCms: params,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.AddOneContent: {
      const { params } = action.payload;
      if (params && params.guid) {
        return {
          ...state,
          [namesState.name]: params,
        };
      }
      return { ...state };
    }

    case actionTypes.UploadedContent: {
      return { ...state, uploadingFile: false, [namesState.name]: {} };
    }

    case actionTypes.CreateLessonItem: {
      return { ...state, loading: true };
    }

    case actionTypes.AddOneLessonItem: {
      const { params } = action.payload;
      if (params && params.guid) {
        let data = Object.values(state.items).map((val) => {
          return val;
        });
        let index = data.findIndex((item) => item.guid === params.parent_guid);
        data[index].items.push(params);

        return {
          ...state,
          items: data,
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    }

    case actionTypes.AddMultipleLessonItems: {
      const { params } = action.payload;
      if (params && params.length > 0) {
        let data = Object.values(state.items).map((val) => {
          return val;
        });

        let oldItems = data[1].items;
        let newItems = [...oldItems, ...params];
        data[1].items = newItems;

        return {
          ...state,
          items: data,
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    }

    case actionTypes.UpdateLessonItem: {
      return { ...state, loading: true };
    }

    case actionTypes.ReplaceLessonItem: {
      const { params } = action.payload;
      if (params && params.guid) {
        let data = Object.values(state.items).map((val) => {
          return val;
        });

        let newState = { ...state };
        // Check for update in ¿Qué recuerdas?
        newState.items[0].items = newState.items[0].items.map((item) => (item.guid == params.guid ? params : item));
        // Check for update in Repasa
        newState.items[1].items = newState.items[1].items.map((item) => (item.guid == params.guid ? params : item));
        // Check for update in Práctica
        newState.items[2].items = newState.items[2].items.map((item) => (item.guid == params.guid ? params : item));

        return {
          state: newState,
          items: data,
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    }

    case actionTypes.DeleteLessonItem: {
      return { ...state, loading: true };
    }

    case actionTypes.RemoveLessonItem: {
      const { params } = action.payload;
      if (params && params.guid) {
        let data = Object.values(state.items).map((val) => {
          return val;
        });

        let newState = { ...state };
        // Remove for ¿Qué recuerdas?
        newState.items[0].items = newState.items[0].items.filter((item) => item.guid !== params.guid[0]);
        // Remove for Repasa
        newState.items[1].items = newState.items[1].items.filter((item) => item.guid !== params.guid[0]);
        // Remove for Práctica
        newState.items[2].items = newState.items[2].items.filter((item) => item.guid !== params.guid[0]);

        return {
          state: newState,
          items: data,
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    }

    case actionTypes.ReorderLessonItems: {
      return { ...state, loading: true };
    }

    case actionTypes.UpdateOrderOfLessonItems: {
      const { response } = action.payload;
      if (response.reorder) {
        const newList = response.reorder.map(({ guid, order }) => ({ ...state.items[1].items.filter((item) => item.guid === guid)[0], order }));
        return {
          ...state,
          items: [state.items[0], { ...state.items[1], items: newList }, state.items[2]],
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.CreateTest: {
      return { ...state, loading: false };
    }

    case actionTypes.AddOneTestItem: {
      const { response } = action.payload;
      // Check if the parent section ID matches ¿Qué recuerdas? OR Práctica
      if (response.data.parent_guid == state.items[0].guid) {
        return {
          ...state,
          items: [{ ...state.items[0], items: [...state.items[0].items, { ...response.data, shouldRedirect: true }] }, state.items[1], state.items[2]],
          loading: false,
        };
      } else if (response.data.parent_guid == state.items[2].guid) {
        return {
          ...state,
          items: [state.items[0], state.items[1], { ...state.items[2], items: [...state.items[2].items, { ...response.data, shouldRedirect: true }] }],
          loading: false,
        };
      } else {
        return { ...state, loading: false };
      }
    }

    case actionTypes.GetContentsForSearch: {
      return { ...state, loading: true };
    }

    case actionTypes.FulfillContentsForSearch: {
      const { response } = action.payload;

      if (response && response.status === 'success' && response.data) {
        let newContentsForSearch = [...response.data.contents];

        if (response.data.offset > 0) {
          newContentsForSearch = [...state.contentsForSearch.data, ...response.data.contents];
        }
        return {
          ...state,
          contentsForSearch: {
            left: response.data.left,
            length: response.data.total,
            offset: response.data.offset,
            pageSize: response.data.pageSize,
            data: newContentsForSearch,
          },
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case actionTypes.MarkContent: {
      // Loading false because there is no response on success/fail
      return { ...state, loading: false };
    }

    case actionTypes.UpdateContentJob: {
      const { params } = action.payload;

      if (params && params.status === 'success' && params.data) {
        let items = [...state.items];
        console.log('items: ', items);
        if (items.length > 0) {
          let item = items[1].items.filter((result) => {
            return result.guid === params.data.itemGuid;
          });
          item = item[0];
          console.log('ITEM: ', item);
          if (item && item.jobs) item.jobs[0].status = params.data.status;
          if (params.data.score) item.jobs[0].score = params.data.score;

          items[1].items = items[1].items.map((result) => (result.guid === params.data.itemGuid ? item : result));

          console.log('items after: ', items);

          return {
            ...state,
            items,
          };
        }
      }

      return { ...state, loading: false };
    }

    case actionTypes.GetJobs: {
      // Loading false because there is no response on success/fail
      return { ...state, loading: false };
    }

    case actionTypes.SetJobs: {
      // Loading false because there is no response on success/fail
      const { response } = action.payload;
      if (response && response.status === 'success') {
        return {
          ...state,
          jobs: response.data,
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};

export const selectors = {
  getLesson: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].lesson.data : null;
  },
  getLessonItems: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].items : null;
  },
  getJobs: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].jobs : null;
  },
  getCreatedContent: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT][namesState.name] : null;
  },
  getUpating: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].uploadingFile : null;
  },
  getSelectedContent: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].selectedContent : null;
  },
  getCurrentContentCms: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].currentContentCms : null;
  },
  getLoading: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].loading : null;
  },
  getContentsForSearch: (state) => {
    return state.entities && state.entities[REDUCER_CONTENT] ? state.entities[REDUCER_CONTENT].contentsForSearch : null;
  },
};

export const actions = {
  getLesson: (guid) => ({ type: actionTypes.GetLesson, payload: guid }),
  setLesson: (params) => ({ type: actionTypes.SetLesson, payload: { params } }),
  getLessonItems: (guid) => ({ type: actionTypes.GetLessonItems, payload: guid }),
  fulfillLessonItems: (params) => ({ type: actionTypes.SetLessonItems, payload: { params } }),
  getContent: (guid) => ({ type: actionTypes.GetContent, payload: guid }),
  setContent: (params) => ({ type: actionTypes.SetContent, payload: { params } }),
  removeContent: (params) => ({ type: actionTypes.RemoveContent, payload: { params } }),
  createContent: (params, guidItemLesson, lessonGuid, sectionGuid) => ({
    type: actionTypes.CreateContent,
    payload: { params, guidItemLesson: guidItemLesson, lessonGuid: lessonGuid, sectionGuid: sectionGuid },
  }),
  addOneContent: (params, guidItemLesson, lessonGuid, sectionGuid) => ({
    type: actionTypes.AddOneContent,
    payload: { params, guidItemLesson: guidItemLesson, lessonGuid: lessonGuid, sectionGuid: sectionGuid },
  }),
  uploadedContent: (params) => ({ type: actionTypes.UploadedContent, payload: { params } }),
  deleteContent: (params) => ({ type: actionTypes.DeleteContent, payload: { params } }),
  createLessonItem: (params) => ({ type: actionTypes.CreateLessonItem, payload: { params } }),
  addOneLessonItem: (params) => ({ type: actionTypes.AddOneLessonItem, payload: { params } }),
  updateLessonItem: (params) => ({ type: actionTypes.UpdateLessonItem, payload: { params } }),
  replaceLessonItem: (params) => ({ type: actionTypes.ReplaceLessonItem, payload: { params } }),
  deleteLessonItem: (params) => ({ type: actionTypes.DeleteLessonItem, payload: { params } }),
  removeLessonItem: (params) => ({ type: actionTypes.RemoveLessonItem, payload: { params } }),
  reorderLessonItems: (params) => ({ type: actionTypes.ReorderLessonItems, payload: params }),
  updateOrderOfLessonItems: (response) => ({ type: actionTypes.UpdateOrderOfLessonItems, payload: { response } }),
  createTest: (params) => ({ type: actionTypes.CreateTest, payload: { params } }),
  addOneTestItem: (response) => ({ type: actionTypes.AddOneTestItem, payload: { response } }),
  createLessonItemsBatch: (params) => ({ type: actionTypes.CreateLessonItemsBatch, payload: { params } }),
  addMultipleLessonItems: (params) => ({ type: actionTypes.AddMultipleLessonItems, payload: { params } }),
  createContentCms: (params) => ({
    type: actionTypes.CreateContentCms,
    payload: { params },
  }),
  currentContentCms: (params) => ({
    type: actionTypes.CurrentContentCms,
    payload: { params },
  }),
  updateContentCms: (params) => ({ type: actionTypes.UpdateContentCms, payload: { params } }),
  getContentsForSearch: (params) => ({ type: actionTypes.GetContentsForSearch, payload: { params } }),
  fulfillContentsForSearch: (response) => ({ type: actionTypes.FulfillContentsForSearch, payload: { response } }),
  markContent: (params) => ({ type: actionTypes.MarkContent, payload: { params } }),
  updateContentJob: (params) => ({ type: actionTypes.UpdateContentJob, payload: { params } }),
  getJobs: (params) => ({ type: actionTypes.GetJobs, payload: { params } }),
  setJobs: (response) => ({ type: actionTypes.SetJobs, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetLesson, function* getLessonSaga(action) {
    const { data } = yield getLesson(action.payload);

    yield put(actions.setLesson(data));
  });

  yield takeLatest(actionTypes.GetLessonItems, function* getLessonItemsSaga(action) {
    const { data } = yield getItemsOfLesson({ guid: action.payload }, action.courseGuid);

    yield put(actions.fulfillLessonItems(data));
  });

  yield takeLatest(actionTypes.GetContent, function* getContentSaga(action) {
    const { data } = yield getContentCMS(action.payload);

    yield put(actions.setContent(data.data));
  });

  yield takeLatest(actionTypes.CreateContent, function* createContentSaga(action) {
    const { data } = yield createContent(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.addOneContent(data.data, action.payload.guidItemLesson, action.payload.lessonGuid, action.payload.sectionGuid));
    }
  });

  yield takeLatest(actionTypes.CreateContentCms, function* createContentCmsSaga(action) {
    const { data } = yield createContent(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.currentContentCms(data.data));
    }
  });
  yield takeLatest(actionTypes.UpdateContentCms, function* updateContentCmsSaga(action) {
    const { data } = yield updateContent(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.currentContentCms(data.data));
    }
  });

  yield takeLatest(actionTypes.AddOneContent, function* addOneContentSaga(action) {
    const content = action.payload.params;
    const guidItemLesson = action.payload.guidItemLesson;
    const lessonGuid = action.payload.lessonGuid;
    const sectionGuid = action.payload.sectionGuid;

    const payload = {
      name: content.name,
      description: content.description || '',
      status: 'draft',
      lesson_guid: lessonGuid,
      content_guid: content.guid,
      parent_guid: sectionGuid,
      ranking_scale: 10,
      item_for: 'all',
    };
    if (guidItemLesson) {
      payload.guid = guidItemLesson;
      yield put(actions.updateLessonItem(payload));
    } else yield put(actions.createLessonItem(payload));
  });

  yield takeLatest(actionTypes.CreateLessonItem, function* createLessonItemSaga(action) {
    const { data } = yield createLessonItem(action.payload.params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.addOneLessonItem(data.data));
    }
  });

  yield takeLatest(actionTypes.UpdateLessonItem, function* updateLessonItemSaga(action) {
    const { data } = yield updateLessonItem(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.replaceLessonItem(data.data));
    }
  });

  yield takeLatest(actionTypes.DeleteLessonItem, function* deleteLessonItemSaga(action) {
    const { data } = yield deleteLessonItem(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.removeLessonItem(data.data));
    }
  });

  yield takeLatest(actionTypes.DeleteContent, function* deleteContentSaga(action) {
    const { data } = yield deleteContent(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.removeContent(data.data));
    }
  });

  yield takeLatest(actionTypes.ReorderLessonItems, function* reorderLessonItemsSaga(action) {
    const { data } = yield reorderLessonItems(action.payload);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.updateOrderOfLessonItems(data.data));
    }
  });

  yield takeLatest(actionTypes.CreateTest, function* createTestSaga(action) {
    const newTest = yield createTest(action.payload.params);

    if (newTest.data && newTest.data.status === 'success') {
      const newItem = yield addTestToLesson({
        name: action.payload.params.name,
        description: action.payload.params.description,
        status: 'draft',
        lesson_guid: action.payload.params.lesson_guid,
        content_guid: newTest.data.data.guid,
        parent_guid: action.payload.params.section_guid,
        ranking_scale: 10,
        item_for: 'all',
      });

      if (newItem.data && newItem.data.status === 'success') {
        yield put(actions.addOneTestItem(newItem.data));

        // console.log(action.payload.params.lesson_guid)
        // const { data } = yield getLessonItems(action.payload.params.lesson_guid);
        // yield put(actions.fulfillLessonItems(data));
      }
    }
  });

  yield takeLatest(actionTypes.CreateLessonItemsBatch, function* createLessonItemsSaga(action) {
    const { data } = yield createLessonItemsBatch(action.payload.params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.addMultipleLessonItems(data.data));
    }
  });

  yield takeLatest(actionTypes.GetContentsForSearch, function* getContentsForSearchSaga(action) {
    const { data } = yield getContentsCMS(action.payload.params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.fulfillContentsForSearch(data));
    }
  });

  yield takeLatest(actionTypes.MarkContent, function* markContentSaga(action) {
    const { data } = yield markContent(action.payload.params);
    if (data && data.status === 'success' && data.data) {
      yield put(actions.updateContentJob(data));
    }
  });

  yield takeLatest(actionTypes.GetJobs, function* getJobsSaga(action) {
    const { data } = yield getJobs(action.payload.params);

    if (data && data.status === 'success' && data.data) {
      yield put(actions.setJobs(data));
    }
  });
}
