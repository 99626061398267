import { ACTION_BRODCAST } from '../protocol';
import Socket from '../socket';
import actionsSocketsProject from './actions';

export default class SocketToProject extends Socket {
  constructor(authToken, onAction, onBroadcast) {
    super(authToken, onAction, onBroadcast, 'toproject');

    this.token = authToken;
    this.onBroadcast = onBroadcast;
  }

  // Send a message over the socket
  sendAction = (action) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { ...action, payload: { ...action.payload, socketType: 'toproject', token: this.token } });
    }
  };

  onPhoneConnected = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_PHONE_CONNECTED,
        payload: { socketType: 'toproject', token: this.token },
      });
    }
  };

  changePhoneConnected = (connected) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.CHANGE_PHONE_CONNECTED,
        payload: { connected, socketType: 'toproject', token: this.token },
      });
    }
  };

  onSetTools = (type) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.SET_TOOLS, payload: { type, socketType: 'toproject', token: this.token } });
    }
  };

  setItemSelected = (itemSelected) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SEND_ITEM_SELECTED,
        payload: { itemSelected, socketType: 'toproject', token: this.token },
      });
    }
  };

  changeItemSelected = (itemSelected) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.CHANGE_ITEM_SELECTED,
        payload: { itemSelected, socketType: 'toproject', token: this.token },
      });
    }
  };

  onCloseTools = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.CLOSE_TOOLS, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  onClosePopupTools = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CLOSE_POPUP_TOOLS,
        payload: { socketType: 'toproject', token: this.token },
      });
    }
  };

  // turn to speak
  onChangeUserActive = (user) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SET_USER_ACTIVE,
        payload: { user, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeUsersTalked = (usersTalked) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SET_USERS_TALKED,
        payload: { usersTalked, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeAnimation = (animation) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SET_ANIMATION,
        payload: { animation, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangePlay = (type) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.SET_PLAY, payload: { type, socketType: 'toproject', token: this.token } });
    }
  };

  onChangeReload = (reload) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SET_RELOAD,
        payload: { reload, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeTimeAction = (action) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SET_CHANGE_TIME_ACTION,
        payload: { action, socketType: 'toproject', token: this.token },
      });
    }
  };

  closeWindow = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.CLOSE_WINDOW, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  onCloseProject = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.ON_CLOSE_PROJECT, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  onBack = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.ON_BACK, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  onNext = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.ON_NEXT, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  sendPing = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.PING, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  sendItems = (items, itemSelected, students) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SEND_ITEMS,
        payload: { items, itemSelected, students, socketType: 'toproject', token: this.token },
      });
    }
  };

  sendStudents = (students) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SEND_STUDENTS,
        payload: { students, socketType: 'toproject', token: this.token },
      });
    }
  };

  sendCurrentTabProjecting = (tab) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SEND_CURRENT_TAB_PROJECTING,
        payload: { tab, socketType: 'toproject', token: this.token },
      });
    }
  };

  onMoveScroll = (direction) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_MOVE_SCROLL,
        payload: { direction, socketType: 'toproject', token: this.token },
      });
    }
  };

  changeVisibleScrollControls = (isVisible) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.CHANGE_VISIBLE_SCROLL_CONTROL,
        payload: { isVisible, socketType: 'toproject', token: this.token },
      });
    }
  };

  sendBook = (book) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.SEND_BOOK, payload: { book, socketType: 'toproject', token: this.token } });
    }
  };

  onOpenLink = (item) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_OPEN_LINK,
        payload: { item, socketType: 'toproject', token: this.token },
      });
    }
  };

  onPlay = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.ON_PLAY, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  onPause = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.ON_PAUSE, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  onPlayToRemote = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, { action: actionsSocketsProject.ON_PLAY_TO_REMOTE, payload: { socketType: 'toproject', token: this.token } });
    }
  };

  onPauseToRemote = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_PAUSE_TO_REMOTE,
        payload: { socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeDurationMedia = (seconds) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_DURATION_MEDIA,
        payload: { seconds, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeProgressMedia = (progress) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_PROGRESS_MEDIA,
        payload: { progress, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeTimeInRemote = (time) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_TIME_IN_REMOTE,
        payload: { time, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeFullScreen = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_FULLSCREEN,
        payload: { socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeFullScreenToRemote = (full) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_FULLSCREEN_TO_REMOTE,
        payload: { full, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeMute = (mute) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_MUTE,
        payload: { mute, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeTimeOut = (timeOut) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_TIMEOUT,
        payload: { timeOut, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangePage = (page) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_PAGE,
        payload: { page, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangePageToRemote = (page) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_PAGE_TO_REMOTE,
        payload: { page, socketType: 'toproject', token: this.token },
      });
    }
  };

  onNextPageVisor = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_NEXT_PAGE_VISOR,
        payload: { socketType: 'toproject', token: this.token },
      });
    }
  };

  onPrevPageVisor = () => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_PREV_PAGE_VISOR,
        payload: { socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeShowSolutions = (open) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_SHOW_SOLUTIONS,
        payload: { open, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeShowHint = (open) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_SHOW_HINT,
        payload: { open, socketType: 'toproject', token: this.token },
      });
    }
  };

  onChangeShowHintRemote = (open) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.ON_CHANGE_SHOW_HINT_REMOTE,
        payload: { open, socketType: 'toproject', token: this.token },
      });
    }
  };

  setTypeViewRemote = (type, data) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SET_TYPE_VIEW_REMOTE,
        payload: { type, data, socketType: 'toproject', token: this.token },
      });
    }
  };

  onSendExtraInfo = (data) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.SEND_EXTRA_INFO,
        payload: { data, socketType: 'toproject', token: this.token },
      });
    }
  };

  changeLesson = (data) => {
    if (this.socket) {
      this.socket.emit(ACTION_BRODCAST, {
        action: actionsSocketsProject.CHANGE_LESSON,
        payload: { data, socketType: 'toproject', token: this.token },
      });
    }
  };
}
