import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { put, takeLatest } from "redux-saga/effects";

import { getProjectsLevels } from "../crud/projectsLevels.crud";
import { PERSIST_PROJECTS_LEVELS, REDUCER_PROJECTS_LEVELS } from "./conf";

export const actionTypes = {
  GetProjects: "projectsLevels/GET_PROJECTS",
  GetProjectsSuccess: "projectsLevels/GET_PROJECTS_SUCCESS",
};

const initialState = {
  projects: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_PROJECTS_LEVELS, whitelist: ["projectsLevels"] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetProjects:
      return { ...state, loading: true };

    case actionTypes.GetProjectsSuccess: {
      const levels = action.payload.map((element) => {
        const projects = element.projects.map((item) => {
          const level = item.educationLevels && item.educationLevels.length ? item.educationLevels[0].education_level_name : "";
          const year = item.educationYears && item.educationYears.length ? item.educationYears[0].education_year_name : "";

          return {
            ...item,
            levelName: `${level} ${year}`,
            chanel: item.collections && item.collections.length ? item.collections[0].collection : "",
            designForChange: false,
          };
        });

        return { ...element, projects };
      });

      return {
        ...state,
        projects: levels,
        loading: false,
      };
    }

    default:
      return state;
  }
});

export const selectors = {
  getProjects: (state) => {
    const { projects } = state.entities[REDUCER_PROJECTS_LEVELS];
    return projects;
  },
};

export const actions = {
  getProjects: (params) => ({
    type: actionTypes.GetProjects,
    payload: params,
  }),

  getProjectsSuccess: (data) => ({
    type: actionTypes.GetProjectsSuccess,
    payload: data,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetProjects, function* getProjectsSaga(action) {
    const { data } = yield getProjectsLevels(action.payload);

    if (data && data.status === "success" && data.data) {
      yield put(actions.getProjectsSuccess(data.data));
    } else {
      yield put(actions.getProjectsSuccess(undefined));
    }
  });
}
