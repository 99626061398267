import React from 'react';

import Loading from '_core/modules/atoms/Loading';

import AddClassStep from './Teams/Steps/AddClassStep';
import JoinClassStep from './Teams/Steps/JoinClassStep';
import RegisterStep from './Teams/Steps/RegisterStep';
import WelcomeStep from './Teams/Steps/WelcomeStep';

import { useTeamsView } from './Teams/useTeamsView';

export default function MSTeamsTab() {
  const totalSteps = 4;

  const {
    step,
    setStep,
    mode,
    setMode,
    books,
    courses,
    licenses,
    isLoading,
    isTeacher,
    isStudent,
    userContext,
    shouldConsent,
    selectedCourse,
    onCheckLicense,
    onClearLicenses,
    onLoginMicrosoft,
    onConsentRequest,
    onSignupMicrosoft,
    setSelectedCourse,
    addCourseToChannel,
    createClassAndCourse,
  } = useTeamsView();

  return (
    <div className="msteams-container">
      {step === 1 && (
        <WelcomeStep
          isLoading={isLoading}
          shouldConsent={shouldConsent}
          onConsentRequest={onConsentRequest}
          onLoginMicrosoft={onLoginMicrosoft}
          setStep={setStep}
          setMode={setMode}
          userContext={userContext}
        />
      )}
      {step === 2 && (
        <RegisterStep
          isLoading={isLoading}
          shouldConsent={shouldConsent}
          onConsentRequest={onConsentRequest}
          onGoBack={() => setStep(1)}
          onClearLicenses={onClearLicenses}
          onCheckLicense={onCheckLicense}
          onSignupMicrosoft={onSignupMicrosoft}
          licenses={licenses}
        />
      )}
      {step === 3 && isLoading && <Loading />}
      {step === 3 && !isLoading && isTeacher && (
        <AddClassStep
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
          mode={mode}
          courses={courses}
          books={books}
          createClassAndCourse={createClassAndCourse}
          addCourseToChannel={addCourseToChannel}
        />
      )}
      {step === 3 && !isLoading && isStudent && <JoinClassStep />}
    </div>
  );
}
