import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const MosaicList = ({ items, columns }) => {
  return (
    <ul className={clsx('mosaic-list', `mosaic-list--col-${columns}`)}>
      {items.map((item, i) => {
        return (
          <li key={i} className="mosaic-list__item">
            {item}
          </li>
        );
      })}
    </ul>
  );
};

MosaicList.propTypes = {
  items: PropTypes.array,
  columns: PropTypes.oneOf([4, 2]),
};

MosaicList.defaultProps = {
  items: [],
  columns: 4,
};

export default MosaicList;
