import ProgressBar from '_core/modules/atoms/ProgressBar';
import GamificationDialogBadges from '_core/modules/components/gamified/GamificationDialogBadges/GamificationDialogBadges';
import GamificationDialogRanking from '_core/modules/components/gamified/GamificationDialogRanking/GamificationDialogRanking';
import { getLevel } from '_core/modules/components/gamified/gamified.service';
import ExpText from '_core/modules/components/gamified/icons/exp/ExpText';
import Arrow from '_core/modules/components/gamified/icons/icons/Arrow';
import Plus from '_core/modules/components/gamified/icons/icons/Plus';
import MedalStart from '_core/modules/components/gamified/icons/medals/MedalStart';
import clsx from 'clsx';
import React from 'react';
import useGamificationBasicInfoFloat from './useGamificationBasicInfoFloat';

const GamificationBasicInfoFloat = () => {
  const {
    basicInfo,
    notificationsExp,
    onOpenDialogBadges,
    onCloseDialogBadges,
    openDialogBadge,
    onCloseDialogRanking,
    openDialogRanking,
    onOpenDialogRanking,
    isStudentGamified,
  } = useGamificationBasicInfoFloat();

  return (
    <>
      {isStudentGamified && (
        <div className={clsx('gamification-basic-info-float', { 'gamification-basic-info-float--notification': notificationsExp.length > 0 })}>
          <div className="gamification-basic-info-float__icon gamification-basic-info-float__icon--level" onClick={onOpenDialogRanking}>
            <div className="gamification-basic-info-float__icon-level">{getLevel(basicInfo?.order)}</div>
            <div className="gamification-basic-info-float__bar gamification-basic-info-float__bar--progress">
              <ProgressBar progress={basicInfo?.progressToNextLevel} withLabel={false} />
              <div className="gamification-basic-info-float__arrow">
                <Arrow />
              </div>
            </div>
          </div>
          <div className="gamification-basic-info-float__icon gamification-basic-info-float__icon--medal" onClick={onOpenDialogBadges}>
            <div className="gamification-basic-info-float__icon-medal">
              <MedalStart />
            </div>
            <div className="gamification-basic-info-float__bar">
              {basicInfo?.badgeCount}{' '}
              <div className="gamification-basic-info-float__arrow">
                <Arrow />
              </div>
            </div>
          </div>
          {notificationsExp.map((notification) => (
            <div key={notification.id} className="gamification-basic-info-float__notification">
              <Plus /> {notification.message} <ExpText />
            </div>
          ))}
        </div>
      )}
      <GamificationDialogBadges onClose={onCloseDialogBadges} open={openDialogBadge} />
      <GamificationDialogRanking onClose={onCloseDialogRanking} open={openDialogRanking} />
    </>
  );
};

export default GamificationBasicInfoFloat;
