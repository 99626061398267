import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getCustomTags, getCustomTagsTypes } from "../crud/customTags.crud";
import { PERSIST_CUSTOM_TAGS, REDUCER_CUSTOM_TAGS } from "./conf";

export const customTagsTypes = {
  age: "age",
  equipment: "equipment",
  software: "software",
  design_for_change: "design_for_change",
  duration: "duration"
}

export const actionTypes = {
  GetCustomTags: "cms/GET_CUSTOM_TAGS",
  SetCustomTags: "cms/SET_CUSTOM_TAGS"  
};

const initialState = {
    customTags: [],
    loading:false  
};

export const reducer = persistReducer(
    { storage, key: PERSIST_CUSTOM_TAGS, whitelist: ["data"] },
    (state = initialState, action) => {
      switch (action.type) {
        case actionTypes.GetCustomTags: 
          return {...state, loading: true}          
        
        case actionTypes.SetCustomTags: {
          const { response } = action.payload;
          if (response && response.status === "success" && response.data){
            var customTagsAdd = {};
            response.data.map((customTag) => customTagsAdd[customTag.type] = [...customTagsAdd[customTag.type] || [], customTag]);
            // Para que las busquedas sean mas rapidas voy a almacenar en custom tags un json cuya key sea el type
            return { ...state, customTags: customTagsAdd, loading:false };
          }
          return {...state, loading: false};
        }

        default:
          return state;
      }
    }
);

export const selectors = {
  getCustomTags: (state) => {
      return state.entities && state.entities[REDUCER_CUSTOM_TAGS] ? state.entities[REDUCER_CUSTOM_TAGS].customTags : null
  },
  getCustomTagsType: (state, type) => {
    const customTags = selectors.getCustomTags(state);
    return selectorsUtils.getCustomTagsType(customTags, type);
  }
}

export const selectorsUtils = {
  getCustomTagsType(customTags, type){
    return customTags && customTags.hasOwnProperty(type) ? customTags[type] : null;
  },
  getCustomTagsDesignForChange(customTags){
    return selectorsUtils.getCustomTagsType(customTags, customTagsTypes.design_for_change);
  }

}

export const actions = {
  /** Si only types es null o vacio los trae todos */
  getCustomTags: (onlyTypes) => ({type:actionTypes.GetCustomTags, payload: {onlyTypes: onlyTypes}}),
  fulfillCustomTags: response => ({ type: actionTypes.SetCustomTags, payload:{response} }),  
};

export function* saga() {
    yield takeLatest(actionTypes.GetCustomTags, function* getCustomTagsSaga(action) {
      const { onlyTypes } = action.payload;
        if(onlyTypes && onlyTypes.length>0){
          const { data } = yield getCustomTagsTypes(onlyTypes);
          yield put(actions.fulfillCustomTags(data));
        }else{
          const { data } = yield getCustomTags();
          yield put(actions.fulfillCustomTags(data));
        }
    });
}
