import Avatar from '_core/modules/atoms/Avatar';
import LoadingDots from '_core/modules/atoms/LoadingDots/LoadingDots';
import GamifiedExpProgress from '_core/modules/components/gamified/GamifiedExpProgress/GamifiedExpProgress';
import RosemaryLeft from '_core/modules/components/gamified/icons/icons/RosemaryLeft';
import RosemaryRight from '_core/modules/components/gamified/icons/icons/RosemaryRight';
import clsx from 'clsx';
import React from 'react';
import { getLevel, getPosition } from '../gamified.service';

function renderUser(position, name, avatar, level, points, isMe) {
  return (
    <div className={clsx('gamification-leader-board__user', { 'gamification-leader-board__user--isMe': isMe })}>
      <div className="gamification-leader-board__user-left">
        <div className="gamification-leader-board__user-position">{getPosition(position)}</div>
        <div className="gamification-leader-board__user-avatar">
          <Avatar image={avatar} />
        </div>
        <div className="gamification-leader-board__user-name">{name}</div>
      </div>
      <div className="gamification-leader-board__user-right">
        <div className="gamification-leader-board__user-level">{getLevel(level)}</div>
        <GamifiedExpProgress exp={points} />
      </div>
    </div>
  );
}

const GamificationLeaderBoard = ({ t, users, loading, userLoggedGuid }) => {
  return (
    <div className="gamification-leader-board">
      <div className="gamification-leader-board__title-wrapper">
        <RosemaryLeft />
        <span className="gamification-leader-board__title">{t('gamification:Ranking')}</span>
        <RosemaryRight />
      </div>

      {loading && <LoadingDots />}
      {!loading && (
        <div className="gamification-leader-board__users">
          {users.map((item) => renderUser(item.position, item.name, item.avatar, item.level, item.points, userLoggedGuid === item.userId))}
        </div>
      )}
    </div>
  );
};

export default GamificationLeaderBoard;
