import axios from 'axios';

export const GET_PROJECTS = 'cms/contents/';
export const GET_PROJECTS_PUBLICATION = 'cms/publications/';
export const GET_PROJECTS_COLLECTION = 'cms/collections/';
export const GET_PROJECTS_PUBLIC = 'front/project/landing/contents/';
export const SET_DRAFT_PROJECT = 'cms/project/draft/';
export const POST_PROJECTS = 'front/projects';

export function createProject(params) {
  return axios.post(GET_PROJECTS, params);
}

export function updateProject(guid, params) {
  return axios.put(GET_PROJECTS + guid, params);
}

export function updateDraftProject(guid, projectDraftData) {
  const params = { project_draft_data: projectDraftData };
  return axios.put(SET_DRAFT_PROJECT + '?contentGuid=' + guid, params);
}

export function getProject(guid) {
  return axios.get(GET_PROJECTS + guid);
}

export function getTicketUploadContent(guid, isFroala = false, folder = 'assets') {
  return axios.get(GET_PROJECTS + guid + '/content/upload?innerFolder=' + folder + (isFroala ? '&uploader=froala' : ''));
}

export function getTicketUploadThumbnail(guid, type = GET_PROJECTS) {
  return axios.get(type + guid + '/thumbnail/upload');
}

export function getProjectPublic(guid) {
  return axios.get(GET_PROJECTS_PUBLIC + guid);
}

export function postProjectCourses(projectGuid, params) {
  return axios.post(`${POST_PROJECTS}/${projectGuid}/courses`, params);
}
