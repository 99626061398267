/** Funciones utiles para el manejo del projecto para transformar lo que nos viene del servidor a los datos que necesitamos para trabajar */

/**
 * Devuelve el projecto dado el projecto que nos devuelve el servidor
 * Dentro se hacen las adaptaciones oportunas
 * @param {Object} projectServer 
 */
export function getProjectFromServer(projectServer){
  var project = {...projectServer}
  if(project.sections){
    project.sections = getSectionsFromServer(project.sections);
  }
  // == Para unificar como se llaman los atributos de los elementos de educationLevel y educationYears con respecto a estos atributos cuando pedimos todos los proyectos que se llaman de esa forma
  // == El hecho de hacer este cambio es porque cuando consulto un projecto amplio la información que tenia de projectos, y los educationLevel y educationYear del projecto vienen con unos atributos que se llaman diferentes segun si pides todos los proyectos o bien si pides la información de un projecto. De esta forma está todo unificado sus nombres. Tambien tengo esto en cuenta para cuando se guarde el projecto que se lo tengo que poner como el servidor espera
  if(project.educationLevels){
    project.educationLevels = projectServer.educationLevels.map(educationLevel=>{
      return({
        guid: educationLevel.hasOwnProperty("education_level_guid") ? educationLevel.education_level_guid : educationLevel.guid ,
        level: educationLevel.hasOwnProperty("education_level_name") ? educationLevel.education_level_name : educationLevel.level ,
      })
    });
  }
  if(project.educationYears){
    project.educationYears = projectServer.educationYears.map(educationYear=>{
      return({
        guid: educationYear.hasOwnProperty("education_year_guid") ? educationYear.education_year_guid : educationYear.guid ,
        year: educationYear.hasOwnProperty("education_year_name") ? educationYear.education_year_name : educationYear.year ,
      })
    });
  }
  return project;
}

function getSectionsFromServer(sectionsServer){
  var sectionsToRet = sectionsServer;
  if(sectionsServer){
    sectionsToRet = sectionsServer.map(section => {
      return getSectionFromServer(section);
    })
  }
  return sectionsToRet;
}

function getSectionFromServer(sectionServer){
  var section = sectionServer;
  if(section){
    section = {...sectionServer};
    if(section.items){
      section.items = getItemsFromServer(section.items);
    }
    if(section.sections){
      section.sections = getSectionsFromServer(section.sections);
    }
  }
  return section;
}

function getItemsFromServer(itemsServer){
  var itemsToRet = itemsServer;
  if(itemsServer){
    itemsToRet = itemsServer.map(item => {
      return getItemFromServer(item);
    })
  }
  return itemsToRet;
}

function getItemFromServer(itemServer){
  var itemToRet = itemServer;
  if(itemToRet){
    itemToRet = {...itemServer};
    if(itemToRet.hasOwnProperty("question_data") && itemToRet.question_data){
      try {
        itemToRet.question_data = JSON.parse(itemToRet.question_data);
      } catch (error) {
        
      }
    }
  }
  return itemToRet;
}