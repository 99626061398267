export function renderKatex(
  element,
  callBack = () => {
    console.log('renderKatex:: callback not defined');
  }
) {
  const processMath = () => {
    // console.log(element)
    try {
      window.renderMathInElement(element, {
        throwOnError: false,
      });
      callBack(element);
    } catch (e) {
      console.log(e);
    }
  };
  if (window && window.renderMathInElement) {
    processMath();
  }
}
