import React from 'react';
import PropTypes from 'prop-types';

const LayoutLogin = ({ children }) => {
  return (
    <div className="layout-login">
      <div className="layout-login__column-1">{children && children[0]}</div>
      <div className="layout-login__column-2">{children && children[1]}</div>
    </div>
  );
};

LayoutLogin.propTypes = {
  children: PropTypes.array,
};

LayoutLogin.defaultProps = {
  children: [<div></div>, <div></div>],
};

export default LayoutLogin;
