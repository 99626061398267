import axios from 'axios';

export const GET_EDUCATION_LEVELS = 'education-levels';

export function getEducationLevels(country, client) {
  let params = {};

  if (country) {
    params.country = country;
  } else {
    params.all = true;
  }

  return axios.get(GET_EDUCATION_LEVELS, { params: params });
}
