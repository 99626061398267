import axios from 'axios';

export const PROGRAMS = 'lms/programs';
export const GET_PROGRAMS = 'front/programs';

export function getTeacherPrograms(params) {
  return axios.get(GET_PROGRAMS, { params });
}

export function getPrograms(params) {
  return axios.get(GET_PROGRAMS, { params: params });
}

export function createProgram(params) {
  return axios.post(PROGRAMS, params);
}

export function updateProgram(params) {
  return axios.put(PROGRAMS + '/' + params.guid, params);
}

export function deleteProgram(params) {
  return axios.delete(PROGRAMS, { data: params });
}
export function getProgram(programGuid) {
  return axios.get('lms/programs/' + programGuid);
}
