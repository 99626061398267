import React from 'react';
import PropTypes from 'prop-types';

const ErrorText = ({ text }) => {
  return <span className="error-text">{text}</span>;
};

ErrorText.propTypes = {
  text: PropTypes.string,
};

ErrorText.defaultProps = {};

export default ErrorText;
