import { getLeaderBoard } from '_core/crud/gamified.crud';
import useGamification from '_core/hooks/useGamification';
import { useUser } from '_core/lite/hooks/useUser';
import * as coreEntities from '_core/store/index';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useGamificationDialogRanking = ({ open }) => {
  const { basicInfo } = useGamification();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      getUsers();
      refreshBasicInfo();
    }
  }, [open]);

  async function getUsers() {
    setLoading(true);
    const data = await getLeaderBoard();
    setLoading(false);

    let users = data?.data || [];
    users = users.map((user, index) => ({
      ...user,
      position: index + 1,
    }));

    let topUsers = users.slice(0, 5);
    const userInTopFive = topUsers.some((item) => item.userId === user.guid);

    if (!userInTopFive) {
      const loggedInUser = users.find((item) => item.userId === user.guid);
      if (loggedInUser) {
        topUsers[4] = { ...loggedInUser, isMe: true };
      }
    }

    setUsers(topUsers);
  }

  function refreshBasicInfo() {
    dispatch(coreEntities.gamification.actions.fillUserGamification(user.guid));
  }

  return { basicInfo, loading, users, userLoggedGuid: user?.guid };
};

export default useGamificationDialogRanking;
