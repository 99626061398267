import { useTranslate } from '_core/hooks/useTranslate';
import TextHtml from '_core/modules/atoms/TextHtml';
import PdfViewerFullScreen from '_core/modules/components/pdfViewers/PdfViewerFullScreen/PdfViewerFullScreen';
import * as coreEntities from '_core/store/index';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const PrivacyPolicy = () => {
  const { t } = useTranslate();
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const [privacy, setPrivacy] = useState('');
  const [showPdfViewer, setShowPdfViewer] = useState(false);

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      let privacyScope = scopes.filter((scope) => scope.scope === 'privacy');
      if (privacyScope && privacyScope.length > 0) {
        setPrivacy(privacyScope[0].json);
      }
    }
  }, [scopes]);

  useEffect(() => {
    if (privacy?.endsWith('pdf')) {
      setShowPdfViewer(true);
    } else {
      setShowPdfViewer(false);
    }
  }, [privacy]);

  return showPdfViewer ? (
    <div>
      <PdfViewerFullScreen url={privacy} />
    </div>
  ) : (
    <div style={{ padding: '40px' }}>
      <TextHtml text={privacy} />
    </div>
  );
};

export default PrivacyPolicy;
