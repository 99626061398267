import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-spinkit';
import Icon from './../Icon';

const Button = ({
  testid,
  text,
  variant,
  loading,
  onClick,
  to,
  disabled,
  icon,
  size,
  color,
  borderRadius,
  iconSize,
  onlyIcon,
  iconPosition,
  iconColor,
  className,
  type = 'button',
  theme,
}) => {
  const renderButton = () => (
    <button
      testid={testid}
      className={clsx(
        'button',
        `button--${variant}`,
        `button--size-${size}`,
        `button--color-${color}`,
        `button--border-radius-${borderRadius}`,
        `button--icon-position-${iconPosition}`,
        {
          'button--color-only-icon': onlyIcon,
          [`button--theme-${theme}`]: theme,
        },
        className
      )}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {loading ? (
        <div className="button__spinner">
          <Spinner name={'ball-clip-rotate'} color={'#fff'} fadeIn="none" />
        </div>
      ) : (
        <>
          {
            <div className="button__content">
              {icon && <Icon color={disabled ? 'default' : iconColor} type={icon} size={iconSize} />}
              {!onlyIcon && <div className="button__text">{text}</div>}
            </div>
          }
        </>
      )}
    </button>
  );

  return to ? <Link to={to}>{renderButton()}</Link> : renderButton();
};

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  variant: PropTypes.oneOf(['default', 'inline']),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'small', 'xs', 'large']),
  color: PropTypes.oneOf(['primary', 'transparent', 'transparent-primary', 'border', 'white', 'grey', 'grey-secondary', 'orange', 'primary-outlined', 'black']),
  iconPosition: PropTypes.oneOf(['default', 'left', 'right']),
  iconColor: PropTypes.oneOf(['default', 'white', 'primary', 'gradient', 'grey', 'grey-light']),
  iconSize: PropTypes.oneOf(['xs', 'default', 'big', 'xl', 'xxl']),
  borderRadius: PropTypes.oneOf(['default', 'big', 'small']),
  theme: PropTypes.oneOf(['default', 'google']),
  loading: PropTypes.bool,
};

Button.defaultProps = {
  text: 'Title',
  variant: 'default',
  size: 'default',
  type: 'button',
  loading: false,
  disabled: false,
  color: 'primary',
  iconPosition: 'default',
  borderRadius: 'default',
  iconColor: 'white',
  iconSize: 'default',
  theme: 'default',
};

export default Button;
