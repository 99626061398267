import { REDUCER_NOTIFICATIONS } from '_core/store/conf';
import { getDateFormatServer } from '_core/utils/date';
import { successResponse } from '_core/utils/utils';
import { notificationsSubtypes, notificationsTypes } from 'app/utils/notifications';
import { put, takeLatest } from 'redux-saga/effects';
import { consumedNotificationsByGuids, getAllNotifications, getAllNotificationsUnread, readNotificationsByGuids } from '../crud/notifications.crud';
import { actionTypes as actionAuth } from './auth.duck';
import { parseNotifications } from './parsers';

export const actionTypes = {
  GetNotifications: 'cms/GET_NOTIFICATIONS',
  GetNotificationsToDo: 'cms/GET_NOTIFICATIONS_TODO',
  SetNotifications: 'cms/SET_NOTIFICATIONS',
  GetMoreNotifications: 'cms/GET_MORE_NOTIFICATIONS',
  SetMoreNotifications: 'cms/SET_MORE_NOTIFICATIONS',
  GetNotificationsUnread: 'cms/GET_NOTIFICATIONS_UNREAD',
  GetNotificationsToDoUnread: 'cms/GET_NOTIFICATIONS_UNREAD_TODO',
  SetNotificationsUnread: 'cms/SET_NOTIFICATIONS_UNREAD',
  ReadNotifications: 'cms/READ_NOTIFICATIONS',
  SetReadNotifications: 'cms/SET_READ_NOTIFICATIONS',
  ConsumedNotifications: 'cms/CONSUMED_NOTIFICATIONS',
  SetConsumedNotifications: 'cms/SET_CONSUMED_NOTIFICATIONS',
  AddNotification: 'ADD_NOTIFICATION',
  AddNotificationTodo: 'ADD_NOTIFICATION_TODO',
};

const initialState = {
  notificationsData: { notifications: [], hasMore: false, showSpinner: false, offset: 0, pageSize: 10 },
  loading: false,
  notificationsUnread: 0,
  notificationsToDoUnread: 0,
  notificationsToDoData: { notifications: [] },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetNotifications:
      return { ...state, loading: true, notificationsData: { ...state.notificationsData, hasMore: true, showSpinner: true } };

    case actionTypes.SetNotifications:
      let notifications = [];
      if (action.payload.offset === 0) {
        notifications = action.payload.notifications;
      } else {
        if (action.payload.typeNotifications === notificationsTypes.TODO) {
          notifications = [...state.notificationsToDoData.notifications, ...action.payload.notifications];
        } else if (action.payload.typeNotifications === notificationsTypes.SOCIAL) {
          notifications = [...state.notificationsData.notifications, ...action.payload.notifications];
        }
      }

      let newData = { ...state, loading: true };
      if (action.payload.typeNotifications === notificationsTypes.TODO) {
        newData.notificationsToDoData = {
          ...action.payload,
          ...state.notificationsToDoData,
          notifications,
        };
      } else if (action.payload.typeNotifications === notificationsTypes.SOCIAL) {
        newData.notificationsData = {
          ...action.payload,
          ...state.notificationsData,
          notifications,
        };
      }
      return newData;

    case actionTypes.GetMoreNotifications:
      return { ...state, loading: true, notificationsData: { ...state.notificationsData, hasMore: false, showSpinner: true } };

    case actionTypes.SetMoreNotifications:
      let notificationsParse = [];
      if (action.payload.offset === 0) {
        notificationsParse = action.payload.notifications;
      } else {
        notificationsParse = [...state.notificationsData.notifications, ...action.payload.notifications];
      }

      return {
        ...state,
        loading: true,
        notificationsData: {
          ...action.payload,
          notifications: notificationsParse,
          hasMore: notificationsParse.length < action.payload.total,
          showSpinner: false,
          offset: state.notificationsData.offset + state.notificationsData.pageSize,
        },
      };

    case actionTypes.GetNotificationsUnread:
      return { ...state, loading: true };

    case actionTypes.SetNotificationsUnread:
      return {
        ...state,
        loading: true,
        notificationsUnread: action.payload.typeNotifications === notificationsTypes.SOCIAL ? action.payload.amount : state.notificationsUnread,
        notificationsToDoUnread: action.payload.typeNotifications === notificationsTypes.TODO ? action.payload.amount : state.notificationsToDoUnread,
      };

    case actionTypes.AddNotification:
      let data = {
        ...state,
        loading: true,
      };
      if (action.payload.typeNotifications === notificationsTypes.SOCIAL) {
        data.notificationsUnread = state.notificationsUnread + 1;
        data.notificationsData = {
          ...state.notificationsData,
          notifications: [action.payload.notification, ...state.notificationsData.notifications],
          total: state.notificationsData.total + 1,
        };
      } else if (action.payload.typeNotifications === notificationsTypes.TODO) {
        data.notificationsToDoUnread = state.notificationsToDoUnread + 1;
        data.notificationsToDoData = {
          ...state.notificationsToDoData,
          notifications: [action.payload.notification, ...state.notificationsToDoData.notifications],
        };
      }
      return data;

    case actionTypes.SetReadNotifications:
      return {
        ...state,
        loading: false,
        // notificationsUnread: state.notificationsUnread - action.payload.affectedRows,
        notificationsData:
          action.payload.typeNotifications === notificationsTypes.SOCIAL
            ? {
                ...state.notificationsData,
                notifications: state.notificationsData.notifications.map((item) => {
                  return { ...item, viewed_at: new Date() };
                }),
              }
            : state.notificationsData,
        notificationsToDoData:
          action.payload.typeNotifications === notificationsTypes.TODO
            ? {
                ...state.notificationsToDoData,
                notifications: state.notificationsToDoData.notifications.map((item) => {
                  return { ...item, viewed_at: new Date() };
                }),
              }
            : state.notificationsToDoData,
      };

    case actionTypes.SetConsumedNotifications:
      let notificationsConsumed = [];
      let notificationsTypeArrayOnState = [];
      if (action.payload.typeNotifications === notificationsTypes.TODO) {
        notificationsTypeArrayOnState = state.notificationsToDoData.notifications;
      } else if (action.payload.typeNotifications === notificationsTypes.SOCIAL) {
        notificationsTypeArrayOnState = state.notificationsData.notifications;
      }

      if (action.payload.id.length === 1 && action.payload.id[0] === 0) {
        notificationsConsumed = notificationsTypeArrayOnState.map((item) => {
          return { ...item, consumed_at: new Date() };
        });
      } else {
        notificationsConsumed = notificationsTypeArrayOnState.map((item) => {
          return {
            ...item,
            consumed_at: action.payload.id.find((id) => id === item.id) ? new Date() : item.consumed_at,
            viewed_at: action.payload.id.find((id) => id === item.id) ? new Date() : item.viewed_at,
          };
        });
      }

      let dataWithConsumed = { ...state, loading: false };
      if (action.payload.typeNotifications === notificationsTypes.TODO) {
        dataWithConsumed.notificationsToDoData = { ...state.notificationsToDoData, notifications: notificationsConsumed };
      } else if (action.payload.typeNotifications === notificationsTypes.SOCIAL) {
        dataWithConsumed.notificationsData = {
          ...state.notificationsData,
          notifications: notificationsConsumed,
        };
        dataWithConsumed = { ...dataWithConsumed, notificationsUnread: notificationsConsumed.filter((item) => !item.consumed_at).length };
      }
      return dataWithConsumed;

    case actionAuth.Logout: {
      //si logout, borrar estado redux
      return initialState;
    }

    default:
      return state;
  }
};

export const selectors = {
  getNotificationsData: (state) => {
    return state.entities && state.entities[REDUCER_NOTIFICATIONS]
      ? {
          ...state.entities[REDUCER_NOTIFICATIONS].notificationsData,
          notifications: parseNotifications(state.entities[REDUCER_NOTIFICATIONS].notificationsData.notifications),
        }
      : {};
  },

  getNotificationsToDoData: (state) => {
    return state.entities && state.entities[REDUCER_NOTIFICATIONS]
      ? {
          ...state.entities[REDUCER_NOTIFICATIONS].notificationsToDoData,
          notifications: parseNotifications(state.entities[REDUCER_NOTIFICATIONS].notificationsToDoData.notifications),
        }
      : {};
  },

  getNotificationsUnread: (state) => {
    return state.entities && state.entities[REDUCER_NOTIFICATIONS] ? state.entities[REDUCER_NOTIFICATIONS].notificationsUnread : 0;
  },
  getNotificationsToDoUnread: (state) => {
    return state.entities && state.entities[REDUCER_NOTIFICATIONS] ? state.entities[REDUCER_NOTIFICATIONS].notificationsToDoUnread : 0;
  },
};

export const actions = {
  getNotifications: (data) => ({ type: actionTypes.GetNotifications, payload: data }),
  getNotificationsToDo: (data) => ({ type: actionTypes.GetNotificationsToDo, payload: data }),
  getNotificationsUnread: (data) => ({ type: actionTypes.GetNotificationsUnread, payload: { ...data, typeNotifications: notificationsTypes.SOCIAL } }),
  getNotificationsUnreadToDo: (data) => ({ type: actionTypes.GetNotificationsToDoUnread, payload: { ...data, typeNotifications: notificationsTypes.TODO } }),
  getMoreNotifications: (data) => ({ type: actionTypes.GetMoreNotifications, payload: data }),
  setNotifications: (data, typeNotifications) => ({ type: actionTypes.SetNotifications, payload: { ...data, typeNotifications } }),
  setMoreNotifications: (data) => ({ type: actionTypes.SetMoreNotifications, payload: data }),
  setNotificationsUnread: (data) => ({ type: actionTypes.SetNotificationsUnread, payload: data }),
  readNotifications: (guids, type) => ({ type: actionTypes.ReadNotifications, payload: { guids, type } }),
  setReadNotifications: (guids) => ({ type: actionTypes.SetReadNotifications, payload: guids }),
  consumedNotifications: (guids, type) => ({ type: actionTypes.ConsumedNotifications, payload: { guids, type } }),
  setConsumedNotifications: (guids) => ({ type: actionTypes.SetConsumedNotifications, payload: guids }),
  addNotification: (notification, typeNotifications) => ({ type: actionTypes.AddNotification, payload: { notification, typeNotifications } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetNotifications, function* getNotificationsSaga(action) {
    let params = { ...action.payload };
    params.type = [
      notificationsSubtypes.SOCIAL.COMMENT,
      notificationsSubtypes.SOCIAL.POST,
      notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT,
      notificationsSubtypes.SOCIAL.JOBCOMMENT,
      notificationsSubtypes.SOCIAL.TENANT,
    ];
    const response = yield getAllNotifications(params);
    if (successResponse(response)) {
      yield put(actions.setNotifications(response.data.data, action.payload.typeNotifications));
    }
  });

  yield takeLatest(actionTypes.GetNotificationsToDo, function* getNotificationsSaga(action) {
    let params = { ...action.payload };
    params.type = [
      notificationsSubtypes.TODO.ASSIGNED,
      notificationsSubtypes.TODO.REASSIGNED,
      notificationsSubtypes.TODO.EVALUATED,
      notificationsSubtypes.TODO.ONEDAY,
      notificationsSubtypes.TODO.LATE,
      notificationsSubtypes.TODO.DELIVERATCOMPLETE,
      notificationsSubtypes.TODO.PUBLISHPENDING,
      notificationsSubtypes.TODO.STUDENTDELIVER,
      notificationsSubtypes.TODO.ALLDELIVER,
      notificationsSubtypes.TODO.RESOURCE_ASSIGNED,
      notificationsSubtypes.TODO.RESOURCE_DELIVERED,
      notificationsSubtypes.TODO.RESOURCE_EVALUATED,
      notificationsSubtypes.TODO.RECORD_DELIVERED,
    ];
    const response = yield getAllNotifications(params);
    if (successResponse(response)) {
      yield put(actions.setNotifications(response.data.data, action.payload.typeNotifications));
    }
  });

  yield takeLatest(actionTypes.GetMoreNotifications, function* getNotificationsSaga(action) {
    const { data } = yield getAllNotifications(action.payload);
    yield put(actions.setMoreNotifications(data.data));
  });

  yield takeLatest(actionTypes.GetNotificationsUnread, function* getNotificationsUnread(action) {
    const response = yield getAllNotificationsUnread({
      type: [
        notificationsSubtypes.SOCIAL.COMMENT,
        notificationsSubtypes.SOCIAL.POST,
        notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT,
        notificationsSubtypes.SOCIAL.JOBCOMMENT,
        notificationsSubtypes.SOCIAL.TENANT,
      ],
    });
    if (successResponse(response)) {
      yield put(actions.setNotificationsUnread({ ...response.data.data, typeNotifications: action.payload.typeNotifications }));
    }
  });

  yield takeLatest(actionTypes.GetNotificationsToDoUnread, function* getNotificationsUnreadToDo(action) {
    const { data } = yield getAllNotificationsUnread({
      type: [
        notificationsSubtypes.TODO.ASSIGNED,
        notificationsSubtypes.TODO.REASSIGNED,
        notificationsSubtypes.TODO.EVALUATED,
        notificationsSubtypes.TODO.ONEDAY,
        notificationsSubtypes.TODO.LATE,
        notificationsSubtypes.TODO.DELIVERATCOMPLETE,
        notificationsSubtypes.TODO.PUBLISHPENDING,
        notificationsSubtypes.TODO.STUDENTDELIVER,
        notificationsSubtypes.TODO.ALLDELIVER,
        notificationsSubtypes.TODO.RESOURCE_ASSIGNED,
        notificationsSubtypes.TODO.RESOURCE_EVALUATED,
        notificationsSubtypes.TODO.RESOURCE_DELIVERED,
        notificationsSubtypes.TODO.RECORD_DELIVERED,
      ],
    });
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setNotificationsUnread({ ...data.data, typeNotifications: action.payload.typeNotifications }));
    }
  });

  yield takeLatest(actionTypes.ReadNotifications, function* readNotifications(action) {
    const { data } = yield readNotificationsByGuids({ id: action.payload.guids, viewed_at: getDateFormatServer(new Date()) });
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setReadNotifications({ ...data.data, typeNotifications: action.payload.type }));
    }
  });

  yield takeLatest(actionTypes.ConsumedNotifications, function* consumedNotifications(action) {
    const { data } = yield consumedNotificationsByGuids({ id: action.payload.guids, consumed_at: getDateFormatServer(new Date()) });
    if (data && data.status === 'success' && data.data) {
      yield put(actions.setConsumedNotifications({ ...data.data, typeNotifications: action.payload.type }));
    }
  });
}
