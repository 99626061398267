/** Funciones utiles relacionadas con content */

import { createContentCms } from '../crud/contents.crud';
import { contentTypes } from '../store/contents.duck';

/**
 * Manejador de las creaciones de contenido
 */
export const createContentManager = {
  /**
   * Crea un contenido del tipo enviado por parametro typeGuid
   * Este metodo está enfocado a crear contenidos de tipo image, video, audio...
   * @param {string} name Nombre del contenido que se va a crear
   * @param {string} description Descripion del contenido, opcional.
   * @param {string} typeContentGuid Tipo de contenido mirar los posibles en => contentTypes from "../store/contents.duck";
   * @param {string} mimeType Campo mime del fichero que se va a guardar, por ejemplo "image/png". Campo opcional
   */
  createContentMultimedia(name, description = null, typeContentGuid = contentTypes.IMAGE, mimeType = null, onSucess, onError) {
    var params = {
      is_public: 1,
      is_teacher_only: 0,
      mobile_friendly: 1,
      name: name,
      status: 'active',
      type_guid: typeContentGuid,
    };
    if (description) params.description = description;
    if (mimeType) params['mime-type'] = mimeType;
    createContentCms(params)
      .then((response) => {
        const { data } = response;
        if (data && data.status === 'success' && data.data) {
          onSucess && onSucess(data.data);
        } else {
          onError && onError();
        }
      })
      .catch((err) => {
        onError && onError();
        console.log(err);
      });
  },
  /**
   * Crea un contenido de tipo Image
   * @param {string} name
   * @param {string} description
   * @param {string} mimeType Campo mime del fichero que se va a guardar, por ejemplo "image/png". Campo opcional
   * @param {string} onSucess
   * @param {string} onError
   */
  createImage(name, description = null, mimeType, onSucess, onError) {
    return createContentManager.createContentMultimedia(name, description, contentTypes.IMAGE, mimeType, onSucess, onError);
  },
};

export const getEducationYears = (educationLevels) => {
  let educationYears = [];

  educationLevels.forEach((element) => {
    element.educationYears.map((item) => {
      item.name = `${element.level}: ${item.year}`;
      item.education_year_name_simple = item.year;
      item.education_year_name = `${element.level}: ${item.year}`;
      item.education_year_guid = item.guid;

      return item;
    });

    educationYears.push(...element.educationYears);
  });

  return educationYears;
};

export const sendAssigmentGoogleClassroom = (url) => {
  window.open(`https://classroom.google.com/share?url=${url}`, 'Google Classroom Share', 'height=500,width=640');
};

export const sendAssigmentMicrosoftTeams = (url) => {
  window.open(`https://teams.microsoft.com/share?href=${url}`, 'Microsoft Teams Share', 'height=600,width=700');
};
