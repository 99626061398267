export const TYPESFORMAT = [
  {
    guid: 'CTTY_00',
    name: 'Dependency',
    description: 'Dependency, common resource, reader',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF41', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
      { guid: 'CTF42', format: 'zip', 'mime-type': 'application/zip' },
    ],
  },
  {
    guid: 'CTTY_01',
    name: 'Libro Digital Fixed Layout',
    description: 'Libro Digital Fixed Layout - páginas dinámica + paquete de recursos web (HTML, PDF, imágenes, videos, audios, ...)',
    custom_tags: [],
    is_book: 1,
    formats: [
      { guid: 'CTF14', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF18', format: 'pdf', 'mime-type': 'application/pdf' },
      { guid: 'CTF40', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_02',
    name: 'Libro Digital Responsive (Mint)',
    description: 'Libro Digital Responsive (Mint) - página dinamica + paquete de recursos (HTML, PDF, iágenes...)',
    custom_tags: [],
    is_book: 1,
    formats: [
      { guid: 'CTF15', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF19', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_03',
    name: 'Video',
    description: 'Video (video + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF48', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF49', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
      { guid: 'CTF7', format: 'mp4', 'mime-type': 'video/mp4' },
      { guid: 'CTF8', format: 'webm', 'mime-type': 'video/webm' },
      { guid: 'CTF9', format: 'ogg', 'mime-type': 'video/ogg' },
    ],
  },
  {
    guid: 'CTTY_04',
    name: 'Audio',
    description: 'Audio (audios + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF10', format: 'mp3', 'mime-type': 'audio/mp3' },
      { guid: 'CTF11', format: 'webm', 'mime-type': 'audio/webm' },
      { guid: 'CTF12', format: 'ogg', 'mime-type': 'audio/ogg' },
      { guid: 'CTF64', format: 'mp3', 'mime-type': 'audio/mpeg' },
    ],
  },
  {
    guid: 'CTTY_05',
    name: 'PDF',
    description: 'Documento (PDF) (pdf + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [{ guid: 'CTF6', format: 'pdf', 'mime-type': 'application/pdf' }],
  },
  { guid: 'CTTY_06', name: 'Link', description: 'Link (URL + comentarios + preguntas)', custom_tags: [], is_book: 0, formats: [] },
  {
    guid: 'CTTY_07',
    name: 'Imagen',
    description: 'Imagen (imagen + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF1', format: 'tiff', 'mime-type': 'image/tiff' },
      { guid: 'CTF2', format: 'jpeg', 'mime-type': 'image/jpeg' },
      { guid: 'CTF3', format: 'png', 'mime-type': 'image/png' },
      { guid: 'CTF4', format: 'gif', 'mime-type': 'image/gif' },
      { guid: 'CTF5', format: 'bmp', 'mime-type': 'image/bmp' },
      { guid: 'CTF6', format: 'jpg', 'mime-type': 'image/jpg' }, //añadido
    ],
  },
  {
    guid: 'CTTY_08',
    name: 'HTML Interactivo',
    description: 'HTML interactivo - paquete de recursos web',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF13', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF17', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  { guid: 'CTTY_09', name: 'Test', description: 'Test - examen, conjunto de preguntas tipo Question ', custom_tags: [], is_book: 0, formats: [] },
  {
    guid: 'CTTY_10',
    name: 'SCORM',
    description: 'SCORM - paquete de recursos web',
    custom_tags: [],
    is_book: 1,
    formats: [
      { guid: 'CTF16', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF43', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_11',
    name: 'SWF',
    description: 'Aplicación para flash player',
    custom_tags: [],
    is_book: 0,
    formats: [{ guid: 'CTF20', format: 'swf', 'mime-type': 'application/x-shockwave-flash' }],
  },
  {
    guid: 'CTTY_12',
    name: 'OFFICE',
    description: 'Documento office',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF21', format: 'doc', 'mime-type': 'application/msword' },
      { guid: 'CTF22', format: 'dot', 'mime-type': 'application/msword' },
      { guid: 'CTF23', format: 'docx', 'mime-type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
      { guid: 'CTF24', format: 'dotx', 'mime-type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
      { guid: 'CTF25', format: 'docm', 'mime-type': 'application/vnd.ms-word.document.macroEnabled.12' },
      { guid: 'CTF26', format: 'dotm', 'mime-type': 'application/vnd.ms-word.template.macroEnabled.12' },
      { guid: 'CTF27', format: 'ppt', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF28', format: 'pot', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF29', format: 'pps', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF30', format: 'ppa', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF31', format: 'pptx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { guid: 'CTF32', format: 'potx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.template' },
      { guid: 'CTF33', format: 'ppsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
      { guid: 'CTF34', format: 'ppam', 'mime-type': 'application/vnd.ms-powerpoint.addin.macroEnabled.12' },
      { guid: 'CTF35', format: 'pptm', 'mime-type': 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' },
      { guid: 'CTF36', format: 'potm', 'mime-type': 'application/vnd.ms-powerpoint.template.macroEnabled.12' },
      { guid: 'CTF37', format: 'ppsm', 'mime-type': 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12' },
      { guid: 'CTF51', format: 'xls', 'mime-type': 'application/vnd.ms-excel' },
      { guid: 'CTF52', format: 'xlsx', 'mime-type': 'application/vnd.ms-excel' },
      { guid: 'CTF53', format: 'xlsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      { guid: 'CTF54', format: 'ppt', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF55', format: 'pps', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF56', format: 'pptx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { guid: 'CTF57', format: 'ppsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
      { guid: 'CTF58', format: 'xls', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF59', format: 'xlsx', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF60', format: 'ppt', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF61', format: 'pptx', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF62', format: 'pps', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF63', format: 'ppsx', 'mime-type': 'application/octet-stream' },
    ],
  },
  {
    guid: 'CTTY_13',
    name: 'ZIP',
    description: 'Paquete comprimido zip',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF38', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF39', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_14',
    name: 'Question',
    description: 'Pregunta en formato Leamonade',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF46', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF47', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_15',
    name: 'Page HTML',
    description: 'Html page',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF44', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF45', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
];
export const TYPESVIEWER = [
  {
    guid: 'CTTY_00',
    name: 'Dependency',
    description: 'Dependency, common resource, reader',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF41', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
      { guid: 'CTF42', format: 'zip', 'mime-type': 'application/zip' },
    ],
  },
  {
    guid: 'CTTY_01',
    name: 'pdf',
    description: 'Libro Digital Fixed Layout - páginas dinámica + paquete de recursos web (HTML, PDF, imágenes, videos, audios, ...)',
    custom_tags: [],
    is_book: 1,
    formats: [
      { guid: 'CTF14', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF18', format: 'pdf', 'mime-type': 'application/pdf' },
      { guid: 'CTF40', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_02',
    name: 'Libro Digital Responsive (Mint)',
    description: 'Libro Digital Responsive (Mint) - página dinamica + paquete de recursos (HTML, PDF, iágenes...)',
    custom_tags: [],
    is_book: 1,
    formats: [
      { guid: 'CTF15', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF19', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_03',
    name: 'video',
    description: 'Video (video + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF48', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF49', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
      { guid: 'CTF7', format: 'mp4', 'mime-type': 'video/mp4' },
      { guid: 'CTF8', format: 'webm', 'mime-type': 'video/webm' },
      { guid: 'CTF9', format: 'ogg', 'mime-type': 'video/ogg' },
    ],
  },
  {
    guid: 'CTTY_04',
    name: 'audio',
    description: 'Audio (audios + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF10', format: 'mp3', 'mime-type': 'audio/mp3' },
      { guid: 'CTF11', format: 'webm', 'mime-type': 'audio/webm' },
      { guid: 'CTF12', format: 'ogg', 'mime-type': 'audio/ogg' },
      { guid: 'CTF64', format: 'mp3', 'mime-type': 'audio/mpeg' },
    ],
  },
  {
    guid: 'CTTY_05',
    name: 'pdf',
    description: 'Documento (PDF) (pdf + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [{ guid: 'CTF6', format: 'pdf', 'mime-type': 'application/pdf' }],
  },
  { guid: 'CTTY_06', name: 'link', description: 'Link (URL + comentarios + preguntas)', custom_tags: [], is_book: 0, formats: [] },
  {
    guid: 'CTTY_07',
    name: 'image',
    description: 'Imagen (imagen + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF1', format: 'tiff', 'mime-type': 'image/tiff' },
      { guid: 'CTF2', format: 'jpeg', 'mime-type': 'image/jpeg' },
      { guid: 'CTF3', format: 'png', 'mime-type': 'image/png' },
      { guid: 'CTF4', format: 'gif', 'mime-type': 'image/gif' },
      { guid: 'CTF5', format: 'bmp', 'mime-type': 'image/bmp' },
      { guid: 'CTF6', format: 'jpg', 'mime-type': 'image/jpg' }, //añadido
    ],
  },
  {
    guid: 'CTTY_08',
    name: 'HTML Interactivo',
    description: 'HTML interactivo - paquete de recursos web',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF13', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF17', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  { guid: 'CTTY_09', name: 'Test', description: 'Test - examen, conjunto de preguntas tipo Question ', custom_tags: [], is_book: 0, formats: [] },
  {
    guid: 'CTTY_10',
    name: 'scorm',
    description: 'SCORM - paquete de recursos web',
    custom_tags: [],
    is_book: 1,
    formats: [
      { guid: 'CTF16', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF43', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_11',
    name: 'SWF',
    description: 'Aplicación para flash player',
    custom_tags: [],
    is_book: 0,
    formats: [{ guid: 'CTF20', format: 'swf', 'mime-type': 'application/x-shockwave-flash' }],
  },
  {
    guid: 'CTTY_12',
    name: 'OFFICE',
    description: 'Documento office',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF21', format: 'doc', 'mime-type': 'application/msword' },
      { guid: 'CTF22', format: 'dot', 'mime-type': 'application/msword' },
      { guid: 'CTF23', format: 'docx', 'mime-type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
      { guid: 'CTF24', format: 'dotx', 'mime-type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
      { guid: 'CTF25', format: 'docm', 'mime-type': 'application/vnd.ms-word.document.macroEnabled.12' },
      { guid: 'CTF26', format: 'dotm', 'mime-type': 'application/vnd.ms-word.template.macroEnabled.12' },
      { guid: 'CTF27', format: 'ppt', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF28', format: 'pot', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF29', format: 'pps', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF30', format: 'ppa', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF31', format: 'pptx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { guid: 'CTF32', format: 'potx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.template' },
      { guid: 'CTF33', format: 'ppsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
      { guid: 'CTF34', format: 'ppam', 'mime-type': 'application/vnd.ms-powerpoint.addin.macroEnabled.12' },
      { guid: 'CTF35', format: 'pptm', 'mime-type': 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' },
      { guid: 'CTF36', format: 'potm', 'mime-type': 'application/vnd.ms-powerpoint.template.macroEnabled.12' },
      { guid: 'CTF37', format: 'ppsm', 'mime-type': 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12' },
      { guid: 'CTF51', format: 'xls', 'mime-type': 'application/vnd.ms-excel' },
      { guid: 'CTF52', format: 'xlsx', 'mime-type': 'application/vnd.ms-excel' },
      { guid: 'CTF53', format: 'xlsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      { guid: 'CTF54', format: 'ppt', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF55', format: 'pps', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF56', format: 'pptx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { guid: 'CTF57', format: 'ppsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
      { guid: 'CTF58', format: 'xls', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF59', format: 'xlsx', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF60', format: 'ppt', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF61', format: 'pptx', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF62', format: 'pps', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF63', format: 'ppsx', 'mime-type': 'application/octet-stream' },
    ],
  },
  {
    guid: 'CTTY_13',
    name: 'ZIP',
    description: 'Paquete comprimido zip',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF38', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF39', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_14',
    name: 'Question',
    description: 'Pregunta en formato Leamonade',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF46', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF47', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_15',
    name: 'Page HTML',
    description: 'Html page',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF44', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF45', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
];

export const TYPESCONTENT = [
  {
    guid: 'CTTY_03',
    name: 'Video',
    description: 'Video (video + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF48', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF49', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
      { guid: 'CTF7', format: 'mp4', 'mime-type': 'video/mp4' },
      { guid: 'CTF8', format: 'webm', 'mime-type': 'video/webm' },
      { guid: 'CTF9', format: 'ogg', 'mime-type': 'video/ogg' },
    ],
  },
  {
    guid: 'CTTY_04',
    name: 'Audio',
    description: 'Audio (audios + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF10', format: 'mp3', 'mime-type': 'audio/mp3' },
      { guid: 'CTF11', format: 'webm', 'mime-type': 'audio/webm' },
      { guid: 'CTF12', format: 'ogg', 'mime-type': 'audio/ogg' },
      { guid: 'CTF64', format: 'mp3', 'mime-type': 'audio/mpeg' },
    ],
  },
  {
    guid: 'CTTY_05',
    name: 'PDF',
    description: 'Documento (PDF) (pdf + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [{ guid: 'CTF6', format: 'pdf', 'mime-type': 'application/pdf' }],
  },
  //{ guid: 'CTTY_06', name: 'Link', description: 'Link (URL + comentarios + preguntas)', custom_tags: [], is_book: 0, formats: [] },
  {
    guid: 'CTTY_07',
    name: 'Imagen',
    description: 'Imagen (imagen + comentarios + preguntas)',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF1', format: 'tiff', 'mime-type': 'image/tiff' },
      { guid: 'CTF2', format: 'jpeg', 'mime-type': 'image/jpeg' },
      { guid: 'CTF3', format: 'png', 'mime-type': 'image/png' },
      { guid: 'CTF4', format: 'gif', 'mime-type': 'image/gif' },
      { guid: 'CTF5', format: 'bmp', 'mime-type': 'image/bmp' },
      { guid: 'CTF6', format: 'jpg', 'mime-type': 'image/jpg' }, //añadido
    ],
  },
  {
    guid: 'CTTY_08',
    name: 'HTML Interactivo',
    description: 'HTML interactivo - paquete de recursos web',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF13', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF17', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_12',
    name: 'OFFICE',
    description: 'Documento office',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF21', format: 'doc', 'mime-type': 'application/msword' },
      { guid: 'CTF22', format: 'dot', 'mime-type': 'application/msword' },
      { guid: 'CTF23', format: 'docx', 'mime-type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
      { guid: 'CTF24', format: 'dotx', 'mime-type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
      { guid: 'CTF25', format: 'docm', 'mime-type': 'application/vnd.ms-word.document.macroEnabled.12' },
      { guid: 'CTF26', format: 'dotm', 'mime-type': 'application/vnd.ms-word.template.macroEnabled.12' },
      { guid: 'CTF27', format: 'ppt', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF28', format: 'pot', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF29', format: 'pps', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF30', format: 'ppa', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF31', format: 'pptx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { guid: 'CTF32', format: 'potx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.template' },
      { guid: 'CTF33', format: 'ppsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
      { guid: 'CTF34', format: 'ppam', 'mime-type': 'application/vnd.ms-powerpoint.addin.macroEnabled.12' },
      { guid: 'CTF35', format: 'pptm', 'mime-type': 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' },
      { guid: 'CTF36', format: 'potm', 'mime-type': 'application/vnd.ms-powerpoint.template.macroEnabled.12' },
      { guid: 'CTF37', format: 'ppsm', 'mime-type': 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12' },
      { guid: 'CTF51', format: 'xls', 'mime-type': 'application/vnd.ms-excel' },
      { guid: 'CTF52', format: 'xlsx', 'mime-type': 'application/vnd.ms-excel' },
      { guid: 'CTF53', format: 'xlsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      { guid: 'CTF54', format: 'ppt', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF55', format: 'pps', 'mime-type': 'application/vnd.ms-powerpoint' },
      { guid: 'CTF56', format: 'pptx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { guid: 'CTF57', format: 'ppsx', 'mime-type': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
      { guid: 'CTF58', format: 'xls', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF59', format: 'xlsx', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF60', format: 'ppt', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF61', format: 'pptx', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF62', format: 'pps', 'mime-type': 'application/octet-stream' },
      { guid: 'CTF63', format: 'ppsx', 'mime-type': 'application/octet-stream' },
    ],
  },
  {
    guid: 'CTTY_13',
    name: 'ZIP',
    description: 'Paquete comprimido zip',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF38', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF39', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_15',
    name: 'Page HTML',
    description: 'Html page',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF44', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF45', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_10',
    name: 'SCORM',
    description: 'SCORM - paquete de recursos web',
    custom_tags: [],
    is_book: 1,
    formats: [
      { guid: 'CTF16', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF43', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
  {
    guid: 'CTTY_20',
    name: 'Tin-can xApi',
    description: 'xApi',
    custom_tags: [],
    is_book: 0,
    formats: [
      { guid: 'CTF38', format: 'zip', 'mime-type': 'application/zip' },
      { guid: 'CTF39', format: 'zip', 'mime-type': 'application/x-zip-compressed' },
    ],
  },
];

export const TYPES_QUESTIONS = [
  { guid: 'QT_1', name: 'mcq', label: 'Opción multiple' },
  { guid: 'QT_2', name: 'plaintext', label: 'Ensayo con texto plano' },
  { guid: 'QT_3', name: 'choicematrix ', label: 'Matriz de elección en línea' },
  { guid: 'QT_4', name: 'shorttext', label: 'Texto corto' },
  { guid: 'QT_5', name: 'clozetext', label: 'Texto Cloze' },
];
