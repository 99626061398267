import React from 'react';
import { DefaultToast } from 'react-toast-notifications';
import useGamifiedToast from './useGamifiedToast';

function GamifiedToast(props) {
  const { onDismiss } = props;
  const { image, subtitle, title } = useGamifiedToast(props);

  return (
    <div className="gamified-toast">
      <DefaultToast {...props} style={{ bottom: '60px' }}>
        <div className="gamified-toast__wrapper">
          {image && <img className="gamified-toast__img" src={image} />}

          <div className="gamified-toast__texts">
            <span className="gamified-toast__title">{title}</span>
            <span className="gamified-toast__subtitle">{subtitle}</span>
          </div>

          {/* <div className="gamified-toast__close" onClick={onDismiss}>
            <Icon size="big" type="close" />
          </div> */}
        </div>
      </DefaultToast>
    </div>
  );
}

export default GamifiedToast;
