import { GoogleLogin } from '@react-oauth/google';
import { recoverAccount } from '_core/crud/auth.crud';
import useScopes from '_core/hooks/useScopes';
import InputCheckbox from '_core/modules/atoms/InputCheckbox';
import Loading from '_core/modules/atoms/Loading';
import TextHtml from '_core/modules/atoms/TextHtml';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import ForgotPasswordForm from '_core/modules/components/forms/ForgotPasswordForm/ForgotPasswordForm';
import * as coreEntities from '_core/store/index';
import { successResponse } from '_core/utils/utils';
import { getClientIdMicrosoft } from 'app/config/config';
import { defaultConfig } from 'app/config/environment';
import i18next from 'i18next';
import * as Msal from 'msal';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import initLayoutConfig from '../../../../app/config/layout/LayoutConfig';
import Button from '../../atoms/Button';
import ErrorText from '../../atoms/ErrorText';
import InputText from '../../atoms/InputText';
import LineWithText from '../../atoms/LineWithText';
import LoginOraculo from '../ssoOraculo/LoginOraculo';

const POLITICS = { LEGAL: 'legal', PRIVACY: 'privacy' };
const FORGOT_PASSWORD_STATE = { INITIAL: 'initial', LOADING: 'loading', SUCCESS: 'success' };

const LoginForm = (props) => {
  const { addToast } = useToasts();
  const { systemRequirements } = useScopes();
  const { register, handleSubmit, watch, errors } = useForm();
  const { t, legal, privacy, onSubmit, onSSO, errorMsg, loading, showRememberMe, showForgotPassword } = props;
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));

  const [showLegal, setShowLegal] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showSystem, setShowSystem] = useState(false);
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);
  const [forgotPasswordState, setForgotPasswordState] = useState(FORGOT_PASSWORD_STATE.INITIAL);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [buttonWidth, setButtonWidth] = useState('300px'); // Ancho predeterminado
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      if (buttonRef.current !== null) {
        const width = buttonRef.current.offsetWidth;
        setButtonWidth(`${width}px`);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (buttonRef.current) {
      const width = buttonRef.current.offsetWidth;
      setButtonWidth(`${width}px`);
    }
  }, []);

  const onLogin = (data) => {
    onSubmit && onSubmit(data.email, data.password);
  };

  const onLoginSSO = (token) => {
    onSSO && onSSO(token, 'google');
  };

  const onLoginMicrosoft = (onSubmitSSO, onErrorSSO) => {
    const clientId = getClientIdMicrosoft(window.location.origin);
    const config = { auth: { clientId, redirectUri: window.location.origin } };

    let myMSALObj = new Msal.UserAgentApplication(config);
    let requestObj = { scopes: ['user.read'], prompt: 'select_account' };

    myMSALObj
      .loginPopup(requestObj)
      .then(async function (loginResponse) {
        myMSALObj
          .acquireTokenSilent(requestObj)
          .then(async (response) => {
            const user = {
              name: response.account.name,
              email: response.account.userName,
              sso_token: response.accessToken,
              identity_provider: 'microsoft',
            };
            //onSubmitSSO(user);
            onSSO && onSSO(user.sso_token, user.identity_provider);
          })
          .catch(function (error) {
            console.error('SSO ms error: ', error);
            myMSALObj
              .acquireTokenPopup(requestObj)
              .then(async function (response) {
                const user = {
                  name: response.account.name,
                  email: response.account.userName,
                  sso_token: response.accessToken,
                  identity_provider: 'microsoft',
                };
                //onSubmitSSO(user);
                onSSO && onSSO(user.sso_token, user.identity_provider);
              })
              .catch(function (error) {
                onErrorSSO && onErrorSSO(error);
              });
          });
      })
      .catch(function (error) {
        onErrorSSO && onErrorSSO(error);
      });
  };

  const closeShowForgotPasswordDialog = () => {
    setShowForgotPasswordDialog(false);
    setForgotPasswordState(FORGOT_PASSWORD_STATE.INITIAL);
  };

  async function onSendChangePassword(email) {
    setForgotPasswordState(FORGOT_PASSWORD_STATE.LOADING);
    const response = await recoverAccount(email, { link: defaultConfig.BASE_URL_ + 'recover-password?token=[TOKEN]&lang=[LANG]' });
    if (successResponse(response)) {
      setForgotPasswordState(FORGOT_PASSWORD_STATE.SUCCESS);
    } else {
      setForgotPasswordState(FORGOT_PASSWORD_STATE.INITIAL);
      addToast(t(`login:USER_NOT_FOUND`), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function onShowPolitics(selection) {
    const politics = selection === POLITICS.PRIVACY ? privacy : legal;
    if (politics?.endsWith('pdf')) {
      if (selection === POLITICS.PRIVACY) window.open(defaultConfig.BASE_URL_ + 'privacy', '_blank');
      if (selection === POLITICS.LEGAL) window.open(defaultConfig.BASE_URL_ + 'terms', '_blank');
    } else {
      if (selection === POLITICS.PRIVACY) setShowPrivacy(selection);
      if (selection === POLITICS.LEGAL) setShowLegal(selection);
    }
  }

  return (
    <div className="login-form">
      {forgotPasswordState === FORGOT_PASSWORD_STATE.LOADING && <Loading />}

      <div className="login-form__form-container">
        <form onSubmit={handleSubmit(onLogin)} className="login-form__form">
          <div className="login-form__item">
            <h3 className="login-form__title">{t('login:Log in')}</h3>
          </div>
          <div className="login-form__item">
            <InputText
              icon="user"
              iconLeft={true}
              placeholder={t('auth:Username')}
              error={errors.email && <ErrorText text={t('common:Required parameter')} />}
              label={t('auth:User')}
              name="email"
              referer={register({ required: true })}
            />
          </div>
          <div className="login-form__item">
            <InputText
              icon="lock"
              iconLeft={true}
              label={t('auth:Password')}
              type="password"
              placeholder={t('auth:Password')}
              error={errors.password && <ErrorText text={t('common:Required parameter')} />}
              name="password"
              referer={register({ required: true })}
            />
          </div>
          <div className="login-form__item login-form__item--subactions">
            {showRememberMe && (
              <div className="login-form__checkbox-container">
                <InputCheckbox label={t('Remember me')} />
              </div>
            )}
            {showForgotPassword && (
              <div className="login-form__text login-form__text--forget" onClick={() => setShowForgotPasswordDialog(true)}>
                {t('Forgot your password?')}
              </div>
            )}
          </div>
          <div className="login-form__item">
            <Button testid="btn-login" text={t('Log in')} loading={loading} type="submit" />
          </div>
          {
            <>
              <div className="login-form__item">
                <LineWithText text={t('Or')} />
              </div>
              <div className="login-form__third-parties">
                <div className="login-form__item login-form__item--google">
                  <GoogleLogin
                    width={buttonWidth}
                    text="signin_with"
                    onSuccess={(credentialResponse) => {
                      onLoginSSO(credentialResponse.credential);
                    }}
                    onError={(response) => {
                      console.log('google fail ', response);
                    }}
                    locale={i18next.language}
                  />
                </div>
                <div className="login-form__item" ref={buttonRef}>
                  <Button
                    testid="btn-sso-microsoft"
                    color="white"
                    text={t('login:Login with Microsoft')}
                    icon="microsoft"
                    iconPosition="left"
                    onClick={onLoginMicrosoft}
                  />
                </div>

                {initLayoutConfig.login.showOraculo && (
                  <div className="login-form__item">
                    <LoginOraculo text={t('login:Login with Polp')} />
                  </div>
                )}
              </div>
            </>
          }
          <Link to="/auth/signup">
            <div className="login-form__signup">
              <span className="login-form__text">
                {t('If you don’t have an account yet,')}
                <span className="login-form__link"> {t('register here')}</span>
              </span>
            </div>
          </Link>

          <div className="login-form__politics">
            {legal && (
              <div className="login-form__legal-advice" onClick={() => onShowPolitics(POLITICS.LEGAL)}>
                {t('login:Legal advice')}
              </div>
            )}
            {privacy && (
              <div className="login-form__privacy" onClick={() => onShowPolitics(POLITICS.PRIVACY)}>
                {t('login:Privacy')}
              </div>
            )}
            <div className="login-form__system-requirements" onClick={() => setShowSystem(true)}>
              {t('login:System Requirements')}
            </div>
          </div>
        </form>
      </div>

      <DialogDefault size="xl" className="dialog-terms" title={t('login:Legal advice').toUpperCase()} open={showLegal} onClose={() => setShowLegal(false)}>
        <TextHtml text={legal} />
      </DialogDefault>
      <DialogDefault size="xl" className="dialog-terms" title={t('login:Privacy').toUpperCase()} open={showPrivacy} onClose={() => setShowPrivacy(false)}>
        <TextHtml text={privacy} />
      </DialogDefault>
      <DialogDefault
        size="xl"
        className="dialog-terms"
        title={t('login:System Requirements').toUpperCase()}
        open={showSystem}
        onClose={() => setShowSystem(false)}
      >
        <TextHtml text={systemRequirements} />
      </DialogDefault>
      <DialogDefault
        size="xs"
        className="dialog-forgot-password"
        title={t('login:Recover password')}
        open={showForgotPasswordDialog}
        onClose={closeShowForgotPasswordDialog}
      >
        <ForgotPasswordForm t={t} state={forgotPasswordState} onCancel={closeShowForgotPasswordDialog} onSuccess={onSendChangePassword} />
      </DialogDefault>
    </div>
  );
};

export default withTranslation(['login', 'auth', 'common', 'recover'])(LoginForm);
