import { useRouter } from '_core/hooks/useRouter';
import { useUser } from '_core/lite/hooks/useUser';
import actionsSocketsProject from '_core/socket/modules/actions';
import SocketNotifications from '_core/socket/modules/notifications';
import SocketToProject from '_core/socket/modules/toProject';
import * as coreEntities from '_core/store/index';
import { notificationsSubtypes, notificationsTypes } from 'app/utils/notifications';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { frontoffice, mobileCheck } from '../../_core';
import { parseOneNotification } from '../utils/parses';

const SocketsContainer = () => {
  const authToken = useSelector((state) => frontoffice.auth.selectors.getAuthToken(state));
  const currentCourse = useSelector((state) => frontoffice.courses.selectors.getCurrentCourse(state));
  const currentCourseRef = useRef(currentCourse);
  currentCourseRef.current = currentCourse;
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useUser();
  const [socket, setSocket] = React.useState(null);
  const [socketBroadcast, setSocketBroadcast] = React.useState();
  const socketRef = useRef(socket);
  socketRef.current = socket;

  const socketBroadcastRef = useRef(socketBroadcast);
  socketBroadcastRef.current = socketBroadcast;

  useEffect(() => {
    if (authToken && user?.source !== 'lti') {
      const socketToNotifications = new SocketNotifications(authToken, onActionSocket);
      setSocket(socketToNotifications);
      socketToNotifications.connect();

      const socketToBroadcast = new SocketToProject(authToken, null, onBroadCast);
      setSocketBroadcast(socketToBroadcast);
      socketToBroadcast.connect();
      if (mobileCheck()) {
        socketToBroadcast.onPhoneConnected();
      }
    } else {
      disconnectSocket();
    }
  }, [authToken]);

  function disconnectSocket() {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    if (socketBroadcastRef.current) {
      socketBroadcastRef.current.changePhoneConnected(false);
      socketBroadcastRef.current.disconnect();
    }
    setSocket(undefined);
    setSocketBroadcast(undefined);
  }

  useEffect(() => {
    // window.addEventListener('beforeunload', (ev) => {
    //   disconnectSocket();
    //   ev.preventDefault();
    //   return null;
    // });
    return () => {
      disconnectSocket();
    };
  }, []);

  function onBroadCast(message) {
    if (
      mobileCheck() &&
      message.action === actionsSocketsProject.PING &&
      message.payload.socketType === 'toproject' &&
      !window.document.location.pathname.includes('remote')
    ) {
      router.history.push(`/remote`);
    }
    if (
      mobileCheck() &&
      authToken !== message.payload.token &&
      message.payload.socketType === 'toproject' &&
      !window.document.location.pathname.includes('remote')
    ) {
      router.history.push(`/remote`);
    }

    if (message.action === actionsSocketsProject.ON_PHONE_CONNECTED) {
      dispatch(coreEntities.projection.actions.setPhoneConnected({ isConnected: true }));
    } else if (message.action === actionsSocketsProject.CHANGE_PHONE_CONNECTED) {
      dispatch(coreEntities.projection.actions.setPhoneConnected({ isConnected: false }));
    }
  }

  function onActionSocket(message) {
    if (messageTypeIsComment(message?.type)) {
      handleCommentMessage(message);
    } else {
      dispatch(coreEntities.notifications.actions.addNotification(message, notificationsTypes.TODO));
    }
  }

  function handleCommentMessage(message) {
    let parseNotification = parseOneNotification(message);
    dispatch(coreEntities.notifications.actions.addNotification(message, notificationsTypes.SOCIAL));
    dispatch(coreEntities.courses.actions.getAllCoursesMessages());
    if (currentCourseRef.current.guid === message.course_guid) {
      if (parseNotification?.type === notificationsSubtypes.SOCIAL.TENANT) {
        dispatch(coreEntities.courses.actions.addOneGlobalMessage(parseNotification));
      } else {
        dispatch(
          coreEntities.courses.actions.addOneMessage({
            ...parseNotification?.data?.message,
            creator_name: parseNotification?.data?.user ? parseNotification?.data?.user?.name : parseNotification?.user?.name || 'Editorial',
            creator_lasname: parseNotification?.data?.user ? parseNotification?.data?.user?.lastName : parseNotification?.user?.lastname || '',
            creator_avatar: parseNotification?.data?.user ? parseNotification?.data?.user?.avatar : parseNotification?.user?.avatar || null,
            parent_message: parseNotification?.data?.message ? { ...parseNotification.data.message.parent_message } : null,
          })
        );
      }
    }
  }

  function messageTypeIsComment(type) {
    return (
      type === notificationsSubtypes.SOCIAL.COMMENT ||
      type === notificationsSubtypes.SOCIAL.POST ||
      type === notificationsSubtypes.SOCIAL.ASSESSMENTCOMMENT ||
      type === notificationsSubtypes.SOCIAL.JOBCOMMENT ||
      type === notificationsSubtypes.SOCIAL.TENANT
    );
  }

  return <></>;
};

export default SocketsContainer;
