import { normalize } from 'normalizr';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { getAssetsAudios, getAssetsImages, putAssetsAudio, putAssetsImage } from '../crud/DLO.crud';
import { assetsAudiosSchema } from '../crud/normalizers/schemas/assets.audios.schema';
import { assetsImagesSchema } from '../crud/normalizers/schemas/assets.images.schema copy';
import { PERSIST_ASSET, REDUCER_ASSET } from './conf';
import { defaultFilterAssetsAudiosImages } from './mixins/filter';

export const actionTypes = {
  GetAssetsAudios: 'ASSET/GET_ASSETS_AUDIOS',
  GetAssetsImages: 'ASSET/GET_ASSETS_IMAGES',
  SetAssetsAudios: 'ASSET/SET_ASSETS_AUDIOS',
  SetAssetsImages: 'ASSET/SET_ASSETS_IMAGES',
  FilterAssetsAudios: 'ASSET/FILTER_ASSETS_AUDIOS',
  FilterAssetsImages: 'ASSET/FILTER_ASSETS_IMAGES',
  SetFilterAssetsAudios: 'ASSET/SET_FILTER_ASSETS_AUDIOS',
  SetFilterAssetsImages: 'ASSET/SET_FILTER_ASSETS_IMAGES',
  SetPaginationaAssetsAudios: 'ASSET/SET_PAGINATION_ASSETS_AUDIOS',
  SetPaginationaAssetsImages: 'ASSET/SET_PAGINATION_ASSETS_IMAGES',
  SetUploadAssets: 'ASSET/SET_UPLOAD_ ASSETS',
  ClearUploadAssets: 'ASSET/CLEAR_UPLOAD_ ASSETS',
  PutAssetsAudios: 'ASSET/PUT_ASSETS_AUDIOS',
  PutAssetsImages: 'ASSET/PUT_ASSETS_IMAGES',
  PutAssetsAudiosSuccess: 'ASSET/PUT_ASSETS_AUDIOS_SUCCESS',
  PutAssetsImagesSuccess: 'ASSET/PUT_ASSETS_IMAGES_SUCCESS',
};

const namesState = {
  assetsAudios: 'assetsAudios',
  assetsImages: 'assetsImages',
  assetsUpload: 'assetsUpload',
};

const namesStateAssetsAudios = {
  audios: 'audios',
  currentSearch: 'currentSearch',
  searching: 'searching',
  pagination: 'pagination',
};

const namesStateAssetsAudiosAudios = {
  data: 'data',
  result: 'result',
  loading: 'loading',
  total: 'total',
};

const namesStateAssetsImages = {
  images: 'images',
  currentSearch: 'currentSearch',
  searching: 'searching',
  pagination: 'pagination',
};

const namesStateAssetsImagesImages = {
  data: 'data',
  result: 'result',
  loading: 'loading',
  total: 'total',
};

const namesStateCurrentSearch = {
  search: 'search',
  result: 'result',
};

const namesStatePagination = {
  pageActive: 'pageActive',
  total: 'total',
};

const initialState = {
  [namesState.assetsAudios]: {
    [namesStateAssetsAudios.audios]: {
      [namesStateAssetsAudiosAudios.data]: {},
      [namesStateAssetsAudiosAudios.result]: [],
      [namesStateAssetsAudiosAudios.loading]: false,
      [namesStateAssetsAudiosAudios.total]: 0,
    },
    [namesStateAssetsAudios.currentSearch]: { [namesStateCurrentSearch.search]: {}, [namesStateCurrentSearch.result]: [] },
    [namesStateAssetsAudios.searching]: false,
    [namesStateAssetsAudios.pagination]: { [namesStatePagination.pageActive]: 1, [namesStatePagination.total]: null },
  },
  [namesState.assetsImages]: {
    [namesStateAssetsImages.images]: {
      [namesStateAssetsImagesImages.data]: {},
      [namesStateAssetsImagesImages.result]: [],
      [namesStateAssetsImagesImages.loading]: false,
      [namesStateAssetsImagesImages.total]: 0,
    },
    [namesStateAssetsImages.currentSearch]: { [namesStateCurrentSearch.search]: {}, [namesStateCurrentSearch.result]: [] },
    [namesStateAssetsImages.searching]: false,
    [namesStateAssetsImages.pagination]: { [namesStatePagination.pageActive]: 1, [namesStatePagination.total]: null },
  },
  [namesState.assetsUpload]: [],
};

// TODO: PONER LA whitelist
export const reducer = persistReducer({ storage, key: PERSIST_ASSET, whitelist: [] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetAssetsAudios: {
      return {
        ...state,
        [namesState.assetsAudios]: {
          ...state[namesState.assetsAudios],
          [namesStateAssetsAudios.audios]: {
            ...state[namesState.assetsAudios][namesStateAssetsAudios.audios],
            [namesStateAssetsAudiosAudios.loading]: true,
          },
        },
      };
    }
    case actionTypes.GetAssetsImages: {
      return {
        ...state,
        [namesState.assetsImages]: {
          ...state[namesState.assetsImages],
          [namesStateAssetsImages.images]: {
            ...state[namesState.assetsImages][namesStateAssetsImages.images],
            [namesStateAssetsImagesImages.loading]: true,
          },
        },
      };
    }
    case actionTypes.SetAssetsAudios: {
      const { audios, result, total, page } = action.payload;
      return {
        ...state,
        [namesState.assetsAudios]: {
          ...state[namesState.assetsAudios],
          [namesStateAssetsAudios.audios]: {
            [namesStateAssetsAudiosAudios.data]: audios,
            [namesStateAssetsAudiosAudios.result]: result,
            [namesStateAssetsAudiosAudios.loading]: false,
            [namesStateAssetsAudiosAudios.total]: total,
          },
          [namesStateAssetsAudios.pagination]: {
            [namesStatePagination.pageActive]: page,
            [namesStatePagination.total]: total,
          },
        },
      };
    }
    case actionTypes.SetAssetsImages: {
      const { images, result, total, page } = action.payload;
      return {
        ...state,
        [namesState.assetsImages]: {
          ...state[namesState.assetsImages],
          [namesStateAssetsImages.images]: {
            [namesStateAssetsImagesImages.data]: images,
            [namesStateAssetsImagesImages.result]: result,
            [namesStateAssetsImagesImages.loading]: false,
            [namesStateAssetsImagesImages.total]: total,
          },
          [namesStateAssetsImages.pagination]: {
            [namesStatePagination.pageActive]: page,
            [namesStatePagination.total]: total,
          },
        },
      };
    }
    case actionTypes.FilterAssetsAudios: {
      return {
        ...state,
        [namesState.assetsImages]: {
          ...state[namesState.assetsImages],
          [namesStateAssetsImages.searching]: true,
        },
      };
    }
    case actionTypes.FilterAssetsImages: {
      return {
        ...state,
        [namesState.assetsAudios]: {
          ...state[namesState.assetsAudios],
          [namesStateAssetsAudios.searching]: true,
        },
      };
    }
    case actionTypes.SetFilterAssetsAudios: {
      const { audios, result, search, total, page } = action.payload;
      return {
        ...state,
        [namesState.assetsAudios]: {
          ...state[namesState.assetsAudios],
          [namesStateAssetsAudios.audios]: {
            ...state[namesState.assetsAudios][namesStateAssetsAudios.audios],
            [namesStateAssetsAudiosAudios.data]: {
              ...state[namesState.assetsAudios][namesStateAssetsAudios.audios][namesStateAssetsAudiosAudios.data],
              ...audios,
            },
          },
          [namesStateAssetsAudios.currentSearch]: {
            [namesStateCurrentSearch.search]: search,
            [namesStateCurrentSearch.result]: result,
          },
          [namesStateAssetsAudios.searching]: false,
          [namesStateAssetsAudios.pagination]: { [namesStatePagination.pageActive]: page, [namesStatePagination.total]: total },
        },
      };
    }
    case actionTypes.SetFilterAssetsImages: {
      const { images, result, search, total, page } = action.payload;
      return {
        ...state,
        [namesState.assetsImages]: {
          ...state[namesState.assetsImages],
          [namesStateAssetsImages.images]: {
            ...state[namesState.assetsImages][namesStateAssetsImages.images],
            [namesStateAssetsImagesImages.data]: {
              ...state[namesState.assetsImages][namesStateAssetsImages.images][namesStateAssetsImagesImages.data],
              ...images,
            },
          },
          [namesStateAssetsImages.currentSearch]: {
            [namesStateCurrentSearch.search]: search,
            [namesStateCurrentSearch.result]: result,
          },
          [namesStateAssetsImages.searching]: false,
          [namesStateAssetsImages.pagination]: { [namesStatePagination.pageActive]: page, [namesStatePagination.total]: total },
        },
      };
    }
    case actionTypes.SetPaginationaAssetsAudios: {
      return {
        ...state,
        [namesState.assetsAudios]: {
          ...state[namesState.assetsAudios],
          [namesStateAssetsAudios.pagination]: action.payload,
        },
      };
    }
    case actionTypes.SetPaginationaAssetsImages: {
      return {
        ...state,
        [namesState.assetsImages]: {
          ...state[namesState.assetsImages],
          [namesStateAssetsImages.pagination]: action.payload,
        },
      };
    }
    case actionTypes.SetUploadAssets: {
      const find = state[namesState.assetsUpload].find((item) => item.id === action.payload.id);

      if (find) {
        const assetsUpload = state[namesState.assetsUpload].map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        return { ...state, [namesState.assetsUpload]: [...assetsUpload] };
      }
      return { ...state, [namesState.assetsUpload]: [action.payload, ...state[namesState.assetsUpload]] };
    }
    case actionTypes.ClearUploadAssets: {
      return { ...state, [namesState.assetsUpload]: [] };
    }
    case actionTypes.PutAssetsAudios: {
      return { ...state };
    }
    case actionTypes.PutAssetsImages: {
      return { ...state };
    }
    case actionTypes.PutAssetsAudiosSuccess: {
      const { guid, data } = action.payload;
      const audios = {
        ...state[namesState.assetsAudios][namesStateAssetsAudios.audios][namesStateAssetsAudiosAudios.data],
        [guid]: {
          ...state[namesState.assetsAudios][namesStateAssetsAudios.audios][namesStateAssetsAudiosAudios.data][guid],
          ...data,
        },
      };

      return {
        ...state,
        [namesState.assetsAudios]: {
          ...state[namesState.assetsAudios],
          [namesStateAssetsAudios.audios]: {
            ...state[namesState.assetsAudios][namesStateAssetsAudios.audios],
            [namesStateAssetsAudiosAudios.data]: audios,
          },
        },
      };
    }
    case actionTypes.PutAssetsImagesSuccess: {
      const { guid, data } = action.payload;
      const images = {
        ...state[namesState.assetsImages][namesStateAssetsImages.images][namesStateAssetsImagesImages.data],
        [guid]: {
          ...state[namesState.assetsImages][namesStateAssetsImages.images][namesStateAssetsImagesImages.data][guid],
          ...data,
        },
      };

      return {
        ...state,
        [namesState.assetsImages]: {
          ...state[namesState.assetsImages],
          [namesStateAssetsImages.images]: {
            ...state[namesState.assetsImages][namesStateAssetsImages.images],
            [namesStateAssetsImagesImages.data]: images,
          },
        },
      };
    }
    default:
      return state;
  }
});

const getAssetsAudiosDataS = (state) =>
  state.entities ? state.entities[REDUCER_ASSET][namesState.assetsAudios][namesStateAssetsAudios.audios][namesStateAssetsAudiosAudios.data] : null;
const getAssetsImagesDataS = (state) =>
  state.entities ? state.entities[REDUCER_ASSET][namesState.assetsImages][namesStateAssetsImages.images][namesStateAssetsImagesImages.data] : null;
const getAssetsAudiosResultsS = (state) => {
  const { result, loading, total } = state.entities[REDUCER_ASSET][namesState.assetsAudios][namesStateAssetsAudios.audios];
  return { result, loading, total };
};
const getAssetsImagesResultsS = (state) => {
  const { result, loading, total } = state.entities[REDUCER_ASSET][namesState.assetsImages][namesStateAssetsImages.images];
  return { result, loading, total };
};
const getAudiosCurrentSearchS = (state) =>
  state.entities ? state.entities[REDUCER_ASSET][namesState.assetsAudios][namesStateAssetsAudios.currentSearch] : null;
const getImagesCurrentSearchS = (state) =>
  state.entities ? state.entities[REDUCER_ASSET][namesState.assetsImages][namesStateAssetsImages.currentSearch] : null;
const getAudiosCurrentSearchResultS = (state) =>
  state.entities ? state.entities[REDUCER_ASSET][namesState.assetsAudios][namesStateAssetsAudios.currentSearch][namesStateCurrentSearch.result] : null;
const getImagesCurrentSearchResultS = (state) =>
  state.entities ? state.entities[REDUCER_ASSET][namesState.assetsImages][namesStateAssetsImages.currentSearch][namesStateCurrentSearch.result] : null;
const getAudiosSearchingS = (state) => (state.entities ? state.entities[REDUCER_ASSET][namesState.assetsAudios][namesStateAssetsAudios.searching] : null);
const getImagesSearchingS = (state) => (state.entities ? state.entities[REDUCER_ASSET][namesState.assetsImages][namesStateAssetsImages.searching] : null);
const getAudiosPaginationS = (state) => (state.entities ? state.entities[REDUCER_ASSET][namesState.assetsAudios][namesStateAssetsAudios.pagination] : 1);
const getImagesPaginationS = (state) => (state.entities ? state.entities[REDUCER_ASSET][namesState.assetsImages][namesStateAssetsImages.pagination] : 1);

export const selectors = {
  getAssetsAudios: createSelector([getAssetsAudiosDataS, getAssetsAudiosResultsS], (data, info) => {
    const audios = info.result ? info.result.map((guid) => data[guid]) : [];
    return { audios: audios, loadingAudios: info.loading, total: info.total };
  }),
  getAssetsImages: createSelector([getAssetsImagesDataS, getAssetsImagesResultsS], (data, info) => {
    const images = info.result ? info.result.map((guid) => data[guid]) : [];
    return { images: images, loadingImages: info.loading, total: info.total };
  }),
  getAudiosCurrentSearch: createSelector(
    [getAssetsAudiosDataS, getAudiosCurrentSearchResultS, getAudiosCurrentSearchS, getAudiosSearchingS],
    (data, result, currentSearch, searching) => {
      const audios = result ? result.map((guid) => data[guid]) : [];
      return {
        currentSearch: {
          search: currentSearch ? currentSearch[namesStateCurrentSearch.search] : {},
          results: audios,
        },
        searching: searching,
      };
    }
  ),
  getImagesCurrentSearch: createSelector(
    [getAssetsImagesDataS, getImagesCurrentSearchResultS, getImagesCurrentSearchS, getImagesSearchingS],
    (data, result, currentSearch, searching) => {
      const images = result ? result.map((guid) => data[guid]) : [];
      return {
        currentSearch: {
          search: currentSearch ? currentSearch[namesStateCurrentSearch.search] : {},
          results: images,
        },
        searching: searching,
      };
    }
  ),
  getAudioPagination: (state) => {
    return getAudiosPaginationS(state);
  },
  getImagesPagination: (state) => {
    return getImagesPaginationS(state);
  },
  getUploadAssets: (state) => {
    return state.entities ? state.entities[REDUCER_ASSET][namesState.assetsUpload] : [];
  },
};

export const actions = {
  getAssetsAudios: (filters, page, pageSize) => ({ type: actionTypes.GetAssetsAudios, payload: { filters: filters, page: page, pageSize: pageSize } }),
  getAssetsImages: (filters, page, pageSize) => ({ type: actionTypes.GetAssetsImages, payload: { filters: filters, page: page, pageSize: pageSize } }),
  setAssetsAudios: (params) => ({ type: actionTypes.SetAssetsAudios, payload: params }),
  setAssetsImages: (params) => ({ type: actionTypes.SetAssetsImages, payload: params }),
  filterAssetsAudios: (filters, page, pageSize) => ({
    type: actionTypes.FilterAssetsAudios,
    payload: { filters: filters, page: page, pageSize: pageSize },
  }),
  filterAssetsImages: (filters, page, pageSize) => ({
    type: actionTypes.FilterAssetsImages,
    payload: { filters: filters, page: page, pageSize: pageSize },
  }),
  setFilterAssetsAudios: (params) => ({ type: actionTypes.SetFilterAssetsAudios, payload: params }),
  setFilterAssetsImages: (params) => ({ type: actionTypes.SetFilterAssetsImages, payload: params }),
  setPaginationAssetsAudios: (pageActive, total) => ({
    type: actionTypes.SetPaginationaAssetsAudios,
    payload: { [namesStatePagination.pageActive]: pageActive, [namesStatePagination.total]: total },
  }),
  setPaginationAssetsImages: (pageActive, total) => ({
    type: actionTypes.SetPaginationaAssetsImages,
    payload: { [namesStatePagination.pageActive]: pageActive, [namesStatePagination.total]: total },
  }),
  setUploadAssets: (upload) => ({ type: actionTypes.SetUploadAssets, payload: upload }),
  clearUploadAssets: () => ({ type: actionTypes.ClearUploadAssets, payload: null }),
  putAssetsAudios: (guid, params) => ({ type: actionTypes.PutAssetsAudios, payload: { guid, params } }),
  putAssetsImages: (guid, params) => ({ type: actionTypes.PutAssetsImages, payload: { guid, params } }),
  putAssetsAudiosSuccess: (guid, data) => ({ type: actionTypes.PutAssetsAudiosSuccess, payload: { guid, data } }),
  putAssetsImagesSuccess: (guid, data) => ({ type: actionTypes.PutAssetsImagesSuccess, payload: { guid, data } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetAssetsAudios, function* getAssetsAudiosSaga(action) {
    const { filters, page, pageSize } = action.payload;
    const params = {
      ...defaultFilterAssetsAudiosImages(filters, page, pageSize),
    };
    const { data } = yield getAssetsAudios(params);
    if (data && data.status === 'success' && data.data && data.data.texts) {
      let audiosNormalized = normalize(data.data.texts, [assetsAudiosSchema]);
      yield put(
        actions.setAssetsAudios({
          audios: audiosNormalized.entities.audios,
          result: audiosNormalized.result,
          total: data.data.total,
          page: page,
          pageSize: pageSize,
        })
      );
    } else yield put(actions.setAssetsAudios([]));
  });

  yield takeLatest(actionTypes.GetAssetsImages, function* getAssetsImagesSaga(action) {
    const { filters, page, pageSize } = action.payload;
    const params = {
      ...defaultFilterAssetsAudiosImages(filters, page, pageSize),
    };
    const { data } = yield getAssetsImages(params);
    if (data && data.status === 'success' && data.data && data.data.images) {
      let imagesNormalized = normalize(data.data.images, [assetsImagesSchema]);
      yield put(
        actions.setAssetsImages({
          images: imagesNormalized.entities.images,
          result: imagesNormalized.result,
          total: data.data.total,
          page: page,
          pageSize: pageSize,
        })
      );
    } else yield put(actions.setAssetsImages([]));
  });

  yield takeLatest(actionTypes.FilterAssetsAudios, function* filterAssetsAudios(action) {
    const { filters, page, pageSize } = action.payload;
    const params = {
      ...defaultFilterAssetsAudiosImages(filters, page, pageSize),
    };
    if (params.search) params.excludeApiSearch = 1;
    const { data } = yield getAssetsAudios(params);
    if (data && data.status === 'success' && data.data && data.data.texts) {
      let audiosNormalized = normalize(data.data.texts, [assetsAudiosSchema]);
      yield put(
        actions.setFilterAssetsAudios({
          audios: audiosNormalized.entities.audios,
          result: audiosNormalized.result,
          search: filters,
          total: data.data.total,
          page: page,
          pageSize: pageSize,
        })
      );
    } else yield put(actions.setFilterAssetsAudios([]));
  });

  yield takeLatest(actionTypes.FilterAssetsImages, function* filterAssetsImages(action) {
    const { filters, page, pageSize } = action.payload;
    const params = {
      ...defaultFilterAssetsAudiosImages(filters, page, pageSize),
    };
    if (params.search) params.excludeApiSearch = 1;
    const { data } = yield getAssetsImages(params);
    if (data && data.status === 'success' && data.data && data.data.images) {
      let imagesNormalized = normalize(data.data.images, [assetsImagesSchema]);
      yield put(
        actions.setFilterAssetsImages({
          images: imagesNormalized.entities.images,
          result: imagesNormalized.result,
          search: filters,
          total: data.data.total,
          page: page,
          pageSize: pageSize,
        })
      );
    } else yield put(actions.setFilterAssetsImages([]));
  });

  yield takeLatest(actionTypes.PutAssetsAudios, function* putAssetsAudiosSaga(action) {
    const { guid, params } = action.payload;

    const { data } = yield putAssetsAudio(guid, params);
    if (data && data.status === 'success') {
      yield put(actions.putAssetsAudiosSuccess(guid, data));
    }
  });

  yield takeLatest(actionTypes.PutAssetsImages, function* putAssetsImagesSaga(action) {
    const { guid, params } = action.payload;

    const { data } = yield putAssetsImage(guid, params);
    if (data && data.status === 'success') {
      yield put(actions.putAssetsImagesSuccess(guid, data));
    }
  });
}
