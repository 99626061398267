import React from 'react';

import Button from '_core/modules/atoms/Button';
import InputText from '_core/modules/atoms/InputText';

const ResetForm = () => {
  // deprecated

  return (
    <div className="login-form">
      <div className="login-form__item">
        <h3 className="login-form__title">Reset Password</h3>
      </div>
      <div className="login-form__item">
        <InputText label="Password" type="password" />
      </div>
      <div className="login-form__item">
        <InputText label="Repeat Password" type="password" />
      </div>
      <div className="login-form__item">
        <Button text="Reset Password" />
      </div>
    </div>
  );
};

export default ResetForm;
