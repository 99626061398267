import { defaultConfig } from 'app/config/environment';
import axios from 'axios';
import i18n from 'i18next';

export const EVENT = 'event';
export const BADGE = 'badge';
export const USER = 'user';

const instance = axios.create({
  baseURL: defaultConfig.BASE_URL_GAMIFICATION + process.env.REACT_APP_GAMIFICATION_PATH + '/',
  headers: {
    'x-api-key': process.env.REACT_APP_GAMIFICATION_API_KEY,
  },
});

export function sendEvent({ userGuid, eventTypeId, schoolScopeId, programScopeId, courseScopeId, unitScopeId, lessonScopeId }) {
  return instance.post(`${EVENT}`, {
    userId: userGuid,
    eventTypeId,
    schoolScopeId,
    programScopeId,
    courseScopeId,
    unitScopeId,
    lessonScopeId,
  });
}

export function getBasicUserInfo({ userGuid }) {
  return instance.get(`${USER}/${userGuid}/basic-info`, {
    params: {
      lang: i18n.language.split('-')[0],
    },
  });
}

export function resetUser({ userGuid }) {
  return instance.post(`${USER}/${userGuid}/reset`);
}

export function getUserBadges({ userGuid }) {
  return instance.get(`${BADGE}/status/${userGuid}`);
}

export function getLeaderBoard(params) {
  return axios.get('/gamification/leaderboard', params);
}
