import IconBubble from '_core/modules/atoms/IconBubble';
import ImageBubble from '_core/modules/atoms/ImageBubble';
import CoreImage from '_core/variables/images';
import PropTypes from 'prop-types';
import React from 'react';

const ProgramImage = ({ configProgramPicture, calendarTheme, iconType, gradient, course_picture, sizeBubble, className, themeImage }) => {
  return (
    <>
      {!configProgramPicture && !themeImage && (
        <div className="program-image__bubble">
          <IconBubble size={sizeBubble} typeIcon={iconType} gradient={gradient} className={className} variant="square" />
        </div>
      )}
      {!configProgramPicture && themeImage && (
        <div className="program-image__bubble">
          <ImageBubble size={sizeBubble} backgroundColor={gradient} imageUrl={themeImage} variant="square" />
        </div>
      )}
      {configProgramPicture && (
        <div
          className="program-image__picture-container"
          style={{ borderColor: calendarTheme ? calendarTheme.color : '', borderWidth: calendarTheme ? '2px' : '' }}
        >
          {
            <div
              className="program-image__picture"
              style={{ backgroundImage: `url(${course_picture ? course_picture : CoreImage.getImage('noPictureProgram')})` }}
            ></div>
          }
        </div>
      )}
    </>
  );
};

ProgramImage.propTypes = {
  sizeBubble: PropTypes.string,
};

ProgramImage.defaultProps = {
  sizeBubble: 'small',
};

export default ProgramImage;
