import { renderKatex } from '_core/utils/katex/renderKatex';
import { useEffect, useState } from 'react';

const mathClass = '.fr-math, .fr-math-v2, .fr-math-v3';

export function renderMath(element, onRender = () => {}) {
  if (element) {
    renderKatex(element, onRender);
  }
}

export default function useMathRender(rootElement, rules = [], onSuccess = null, init = false, onBegin = null) {
  const [isUpdated, setUpdate] = useState(0); // TODO: Ver si se usa

  useEffect(() => {
    onBegin && onBegin();
    const hasMath = rootElement?.current?.querySelector(mathClass);
    if (hasMath || init) {
      // Al cargar la lib en la config, perdemos el poder de ocultar y mostrar el elemento mientras está render
      renderMath(rootElement?.current, (nodes) => {
        setUpdate(1);
        setTimeout(() => {
          onSuccess && onSuccess();
        }, 100);
      });
    } else {
      onSuccess && onSuccess();
    }
  }, rules);
  return { isUpdated };
}
