import { loginOBO, loginPublisher, loginSSO } from '_core/crud/auth.crud';
import { useTranslate } from '_core/hooks/useTranslate';
import * as coreEntities from '_core/store/index';
import { useCallback, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

export function useAuthView() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { t } = useTranslate();

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    errorMsg: '',
    loading: false,
  });
  const [authData, setAuthData] = useState({});
  const [authError, setAuthError] = useState({});

  const enableLoading = () => {
    setState({ loading: true });
  };

  const disableLoading = () => {
    setState({ loading: false });
  };

  const submitLogin = useCallback(
    (email, pass) => {
      enableLoading();
      setTimeout(() => {
        loginPublisher(email, pass)
          .then((data) => {
            if (data.data && data.data.data && data.data.data.token) {
              disableLoading();
              dispatch(coreEntities.auth.actions.login(data.data.data));
            } else {
              showError(data);
            }
          })
          .catch((error) => {
            showError(error);
          });
      }, 1000);
    },
    [showError]
  );

  const onSSO = useCallback(
    (token, identity_provider) => {
      enableLoading();
      setTimeout(() => {
        loginSSO(token, identity_provider)
          .then((data) => {
            if (data.data.data.token) {
              disableLoading();
              dispatch(coreEntities.auth.actions.login(data.data.data));
            } else {
              showError(data);
            }
          })
          .catch((error) => {
            showError(error);
          });
      }, 1000);
    },
    [showError]
  );

  const onSSOForMSTeams = useCallback(
    (params) => {
      enableLoading();
      setTimeout(() => {
        loginOBO(params)
          .then((data) => {
            if (data.data && data.data.data && data.data.data.token) {
              setAuthData(data.data.data);
              disableLoading();
            } else {
              setAuthError(data);
            }
          })
          .catch((error) => {
            showError(error);
          });
      }, 1000);
    },
    [showError]
  );

  function showError(error) {
    const errorMsg = error.error && error.error.data ? t('login:' + error.error.data.error.errorKey) : t('login:Invalid login');
    disableLoading();
    setState({ loading: false });
    addToast(t(`login:${errorMsg}`), {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  return { form: 'login', loading: state.loading, submitLogin, onSSO, onSSOForMSTeams, authData, authError };
}
