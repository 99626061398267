export const usersMock = [
  {
    name: 'Mary',
    lastName: 'Docente',
    email: 'marydocente2020@mail.com',
    guid: '1',
    score: 8.1,
    thumbnail: 'https://www.pngitem.com/pimgs/m/78-785878_batman-icon-batman-avatar-icon-hd-png-download.png',
    turned: true,
  },
  {
    name: 'Laura',
    lastName: 'Vausini',
    email: 'marydocente2020@mail.com',
    guid: '2',
    score: 5.9,
    thumbnail: 'https://www.pngitem.com/pimgs/m/78-785878_batman-icon-batman-avatar-icon-hd-png-download.png',
    turned: true,
  },
  {
    name: 'Bulbasaur',
    lastName: 'Pausino',
    email: 'marydocente2020@mail.com',
    guid: '3',
    score: 2.8,
    thumbnail: 'https://assets.pokemon.com/assets/cms2/img/pokedex/full/001.png',
    turned: false,
  },
  {
    name: 'pikachu',
    lastName: 'Pausino',
    email: 'pikachu@mail.com',
    guid: '4',
    score: 2,
    thumbnail: 'https://assets.stickpng.com/images/580b57fcd9996e24bc43c325.png',
    turned: true,
  },
  {
    name: 'charmander',
    lastName: 'Mausino',
    email: 'charmander@mail.com',
    guid: '5',
    score: 2,
    thumbnail: 'https://w7.pngwing.com/pngs/410/1020/png-transparent-charmander-pokemon-snap-charizard-charmander-mammal-carnivoran-hand.png',
    turned: false,
  },
  {
    name: 'Squirtle',
    lastName: 'Pausino',
    email: 'squirtle@mail.com',
    guid: '6',
    score: 2,
    thumbnail: 'https://e7.pngegg.com/pngimages/185/724/png-clipart-pikachu-pokemon-go-pokemon-red-and-blue-squirtle-squirtle.png',
    turned: true,
  },
];

export const studentsMock = [
  {
    user: {
      name: 'Alex',
      lastName: 'Crement',
      email: 'alex@gmail.com',
      guid: '1',
      avatar: '/images/avatars/2.png',
    },
    days: {},
  },
  {
    user: {
      name: 'Ana',
      lastName: 'Carolina',
      email: 'ana@gmail.com',
      guid: '2',
      avatar: '/images/avatars/1.png',
    },
    days: {
      '09-21-2021': { status: 'good' },
      '09-23-2021': { status: 'good' },
      '09-24-2021': { status: 'good' },
      '09-27-2021': { status: 'good' },
    },
  },
  {
    user: {
      name: 'Armando',
      lastName: 'Muebles',
      email: 'armando@gmail.com',
      guid: '3',
      avatar: '/images/avatars/8.png',
    },
    days: {
      '09-22-2021': { status: 'star' },
      '09-27-2021': { status: 'good' },
    },
  },
  {
    user: {
      name: 'Benito',
      lastName: 'Magua',
      email: 'benito@gmail.com',
      guid: '4',
      avatar: '/images/avatars/4.png',
    },
    days: {
      '09-24-2021': { status: 'bad' },
    },
  },
  {
    user: {
      name: 'Casimiro',
      lastName: 'Noves',
      email: 'casimiro@gmail.com',
      guid: '5',
      avatar: '/images/avatars/5.png',
    },
    days: {
      '09-22-2021': { status: 'good' },
      '09-27-2021': { status: 'badge' },
    },
  },
  {
    user: {
      name: 'Elba',
      lastName: 'Surero',
      email: 'elba@gmail.com',
      guid: '6',
      avatar: '/images/avatars/6.png',
    },
    days: {
      '09-22-2021': { status: 'good' },
      '09-27-2021': { status: 'good' },
    },
  },
  {
    user: {
      name: 'Esteban',
      lastName: 'Quito',
      email: 'esteban@gmail.com',
      guid: '7',
      avatar: '/images/avatars/7.png',
    },
    days: {
      '09-24-2021': { status: 'warning', comment: 'Comment about this.... Lorem ipsum dolor sit amet, consectetuer adipiscing elit' },
      '09-27-2021': { status: 'bad' },
      '09-28-2021': {
        status: 'flag',
        comment:
          'Comment about this, aenean commodo ligula eget dolor.  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
      },
    },
  },
  {
    user: {
      name: 'Helen',
      lastName: 'Chufe',
      email: 'helen@gmail.com',
      guid: '8',
      avatar: '/images/avatars/3.png',
    },
    days: {},
  },
  {
    user: {
      name: 'Laura',
      lastName: 'Pausini',
      email: 'laura@gmail.com',
      guid: '9',
      avatar: '/images/avatars/9.png',
    },
    days: {
      '09-24-2021': { status: 'good' },
    },
  },
  {
    user: {
      name: 'Maximo',
      lastName: 'Power',
      email: 'maxima@gmail.com',
      guid: '10',
      avatar: '/images/avatars/10.png',
    },
    days: {
      '09-22-2021': { status: 'warning' },
      '09-27-2021': { status: 'good' },
    },
  },
  {
    user: {
      name: 'Susana',
      lastName: 'Horia',
      email: 'susana@gmail.com',
      guid: '11',
      avatar: '/images/avatars/11.png',
    },
    days: {
      '09-24-2021': { status: 'good' },
    },
  },
];
