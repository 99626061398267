import useGamification from '_core/hooks/useGamification';
import useGamificationSocket from '_core/hooks/useGamificationSocket.js';
import { useUser } from '_core/lite/hooks/useUser';
import * as coreEntities from '_core/store/index';
import { LOCALSTORAGES } from '_core/variables/localStorages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useGamificationBasicInfoFloat = () => {
  const { basicInfo, sendGamificationEvent, isStudentGamified } = useGamification();
  const { user } = useUser();
  const [openDialogBadge, setOpenDialogBadge] = useState();
  const [openDialogRanking, setOpenDialogRanking] = useState();
  const dispatch = useDispatch();
  const notificationsExp = useSelector((state) => coreEntities.gamification.selectors.getNotificationsExp(state));
  useGamificationSocket({ userGuid: user?.guid, isStudentGamified });

  useEffect(() => {
    notificationsExp.forEach((notification) => {
      setTimeout(() => {
        dispatch(coreEntities.gamification.actions.removeNotification(notification.id));
      }, 1500); // El tiempo que dura la notificación en pantalla
    });
  }, [notificationsExp, dispatch]);

  useEffect(() => {
    if (user?.guid && isStudentGamified) {
      loadGamification();
      sendEventAccessToGamification();
    }
  }, [user?.guid, isStudentGamified]);

  async function loadGamification() {
    dispatch(coreEntities.gamification.actions.fillUserGamification(user.guid));
  }

  // mandamos el evento al game engine solo una vez al día
  function sendEventAccessToGamification() {
    const lastEventDate = localStorage.getItem(LOCALSTORAGES.ACCESS_PLATFORM_ON);
    const currentDate = new Date().toISOString().split('T')[0];

    // Si las fechas no coinciden, envía el evento
    if (lastEventDate !== currentDate) {
      localStorage.setItem(LOCALSTORAGES.ACCESS_PLATFORM_ON, new Date().toISOString().split('T')[0]);
      setTimeout(() => {
        sendGamificationEvent(8);
      }, 2000);
    }
  }

  function onOpenDialogBadges() {
    setOpenDialogBadge(true);
    onCloseDialogRanking();
  }

  function onCloseDialogBadges() {
    setOpenDialogBadge(false);
  }

  function onOpenDialogRanking() {
    setOpenDialogRanking(true);
    onCloseDialogBadges();
  }

  function onCloseDialogRanking() {
    setOpenDialogRanking(false);
  }

  return {
    basicInfo,
    notificationsExp,
    onOpenDialogBadges,
    onCloseDialogBadges,
    openDialogBadge,
    onCloseDialogRanking,
    openDialogRanking,
    isStudentGamified: isStudentGamified,
    onOpenDialogRanking,
  };
};

export default useGamificationBasicInfoFloat;
