import React, { useReducer, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import Loading from '_core/modules/atoms/Loading';
import { useRouter } from '_core/hooks/useRouter';

import * as auth from '_core/store/auth.duck';
import LayoutDefault from '_core/layout/LayoutDefault';

function SamlCallback(props) {
  const { t } = props;
  let { token } = useParams(); //Ex: {token}&{application}
  const router = useRouter();
  let urlCallback = router.query.callback || '/';

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loading: true,
    authToken: false,
  });

  useEffect(() => {
    // setState({ loading: true });
    if (token !== 'error') props.login({ token: token });
  }, []);

  useEffect(() => {
    if (Boolean(state.authToken)) {
      window.location.href = urlCallback;
    }
  }, [state.authToken]);

  return (
    <>
      <LayoutDefault>
        <div>{state.loading && <Loading loadingText={t('Authenticating')} />}</div>
      </LayoutDefault>
    </>
  );
}

export default withTranslation('login')(connect(null, auth.actions)(SamlCallback));
