import { useFeatureFlagEnabled } from 'posthog-js/react';

export function useFlags(flag, defaultValue = false, user = {}) {
  const value = useFeatureFlagEnabled(flag);
  // const { value } = useFeatureFlag(flag, defaultValue, {
  //   identifier: user.guid,
  //   email: user.username,
  //   country: user.country_guid,
  //   custom: { client: window.tenant },
  // });

  return { value };
}
