import React from 'react';

function Exp() {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29031 16.1431C1.29031 14.7179 2.44571 13.5625 3.87096 13.5625H36.129C37.5543 13.5625 38.7097 14.7179 38.7097 16.1431V25.1754C38.7097 26.6007 37.5543 27.756 36.129 27.756H3.87096C2.44571 27.756 1.29031 26.6007 1.29031 25.1754V16.1431Z"
        fill="#00A66A"
      />
      <path
        d="M1.29031 14.8531C1.29031 13.4279 2.44571 12.2725 3.87096 12.2725H36.129C37.5543 12.2725 38.7097 13.4279 38.7097 14.8531V23.8854C38.7097 25.3106 37.5543 26.466 36.129 26.466H3.87096C2.44571 26.466 1.29031 25.3106 1.29031 23.8854V14.8531Z"
        fill="#00D789"
      />
      <path
        d="M17.9754 3.20775C19.2313 2.49586 20.7687 2.49586 22.0246 3.20775L34.2152 10.118C35.5013 10.8471 36.2962 12.2113 36.2962 13.6897V27.3807C36.2962 28.859 35.5013 30.2233 34.2152 30.9523L22.0246 37.8626C20.7687 38.5745 19.2313 38.5745 17.9754 37.8626L5.78481 30.9523C4.49868 30.2233 3.70384 28.859 3.70384 27.3807V13.6897C3.70384 12.2113 4.49868 10.8471 5.78481 10.118L17.9754 3.20775Z"
        fill="#1462A0"
      />
      <path
        d="M17.9754 1.83861C19.2313 1.12671 20.7687 1.12671 22.0246 1.83861L34.2152 8.74889C35.5013 9.47793 36.2962 10.8422 36.2962 12.3205V26.0115C36.2962 27.4899 35.5013 28.8541 34.2152 29.5832L22.0246 36.4935C20.7687 37.2053 19.2313 37.2053 17.9754 36.4935L5.78481 29.5832C4.49868 28.8541 3.70384 27.4899 3.70384 26.0115V12.3205C3.70384 10.8422 4.49868 9.47793 5.78481 8.74889L17.9754 1.83861Z"
        fill="#2D95E7"
      />
      <path
        d="M21.4345 3.39878L32.9517 9.88467C33.4236 10.1504 33.8022 10.5361 34.0575 10.9907C34.1586 11.1707 34.341 11.2971 34.5474 11.2971C34.8896 11.2971 35.134 10.9656 34.9788 10.6606C34.6396 9.99398 34.1068 9.42778 33.4299 9.0466L21.9127 2.5607C20.7262 1.89253 19.2737 1.89253 18.0872 2.5607L6.57009 9.0466C5.89321 9.42778 5.36037 9.99398 5.02114 10.6606C4.86596 10.9656 5.1104 11.2971 5.45255 11.2971C5.65893 11.2971 5.84139 11.1707 5.94246 10.9907C6.1978 10.5361 6.57637 10.1504 7.04828 9.88467L18.5654 3.39878C19.4553 2.89765 20.5447 2.89765 21.4345 3.39878Z"
        fill="#65BCFF"
      />
      <path
        d="M9.51065 28.8079C9.43839 28.7672 9.35685 28.7458 9.27392 28.7458C8.77759 28.7458 8.60472 29.4051 9.03719 29.6486L18.0872 34.7451C19.2737 35.4133 20.7262 35.4133 21.9127 34.7451L30.9628 29.6486C31.3952 29.4051 31.2224 28.7458 30.726 28.7458C30.6431 28.7458 30.5616 28.7672 30.4893 28.8079L21.4345 33.9071C20.5447 34.4082 19.4553 34.4082 18.5654 33.9071L9.51065 28.8079Z"
        fill="#65BCFF"
      />
      <path
        d="M18.6545 4.87212C19.4896 4.4007 20.5104 4.4007 21.3455 4.87212L31.9417 10.8539C32.8015 11.3392 33.3332 12.25 33.3332 13.2374V25.0952C33.3332 26.0826 32.8015 26.9934 31.9417 27.4787L21.3455 33.4605C20.5104 33.9319 19.4896 33.9319 18.6545 33.4605L8.05828 27.4787C7.1985 26.9934 6.66676 26.0826 6.66676 25.0952V13.2374C6.66676 12.25 7.1985 11.3392 8.05828 10.8539L18.6545 4.87212Z"
        fill="#1463A0"
      />
      <path
        d="M18.9925 6.6939C19.6179 6.34162 20.3819 6.34162 21.0074 6.6939L31.1026 12.3803C31.7484 12.744 32.148 13.4276 32.148 14.1688V25.4545C32.148 26.1957 31.7484 26.8794 31.1026 27.2431L21.0074 32.9295C20.3819 33.2817 19.6179 33.2817 18.9925 32.9295L8.89721 27.2431C8.25142 26.8794 7.85187 26.1957 7.85187 25.4545V14.1688C7.85187 13.4276 8.25142 12.744 8.89721 12.3803L18.9925 6.6939Z"
        fill="url(#paint0_linear_3438_9618)"
      />
      <path
        d="M25.9913 13.5635C27.5885 13.5635 28.3872 14.4136 28.3872 16.1139V18.6063C28.3872 20.3066 27.5885 21.1568 25.9913 21.1568H23.808V26.0644H21.103V13.5635H25.9913ZM25.6822 18.9155V15.8048C25.6822 15.7017 25.6435 15.6115 25.5662 15.5343C25.5018 15.457 25.4181 15.4183 25.3151 15.4183H23.808V19.3019H25.3151C25.4181 19.3019 25.5018 19.2633 25.5662 19.186C25.6435 19.1087 25.6822 19.0185 25.6822 18.9155Z"
        fill="#1462A0"
      />
      <path
        d="M19.6506 13.5635L17.6605 19.8043L19.9018 26.0644H17.0809L15.767 21.6978L14.4532 26.0644H11.613L13.8542 19.8043L11.9028 13.5635H14.5305L15.767 17.6016L17.0036 13.5635H19.6506Z"
        fill="#1462A0"
      />
      <defs>
        <linearGradient id="paint0_linear_3438_9618" x1="17.2629" y1="15.7061" x2="26.5004" y2="29.7335" gradientUnits="userSpaceOnUse">
          <stop offset="0.385317" stop-color="#FEFEFF" />
          <stop offset="0.385417" stop-color="#D4D5EA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Exp;
