import React from 'react';

const LevelUpBackground = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="190" height="190" viewBox="0 0 190 190" fill="none">
      <g filter="url(#filter0_f_3444_14566)">
        <path
          d="M95 4L103.996 61.4255L140.5 16.1917L119.578 70.4217L173.808 49.5L128.575 86.0037L186 95L128.575 103.996L173.808 140.5L119.578 119.578L140.5 173.808L103.996 128.575L95 186L86.0037 128.575L49.5 173.808L70.4217 119.578L16.1917 140.5L61.4255 103.996L4 95L61.4255 86.0037L16.1917 49.5L70.4217 70.4217L49.5 16.1917L86.0037 61.4255L95 4Z"
          fill="url(#paint0_radial_3444_14566)"
        />
      </g>
      <defs>
        <filter id="filter0_f_3444_14566" x="0" y="0" width="190" height="190" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_3444_14566" />
        </filter>
        <radialGradient
          id="paint0_radial_3444_14566"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(95 95) rotate(45) scale(128.693)"
        >
          <stop stop-color="#ECE1FB" />
          <stop offset="0.713542" stop-color="#ECE1FB" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default LevelUpBackground;
