/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
import * as microsoftTeams from '@microsoft/teams-js';
import { LayoutContextProvider, changeFavicon, frontoffice, i18next } from '_core';
import { getApiHealth } from '_core/crud/appInfo.crud';
import * as auth from '_core/store/auth.duck';
import * as coreEntities from '_core/store/index';
import * as routerHelpers from '_core/utils/RouterHelpers';
import { isTeams } from '_core/utils/teamsTab';
import { isStudent, isTeacher } from '_core/utils/user';
import { role } from '_core/variables/constant';
import { notificationsTypes } from 'app/utils/notifications';
import clsx from 'clsx';
import posthog from 'posthog-js';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { getLanguageByTenant, isLocalhost } from '../../_core/index.js';
import Loading from '../../_core/modules/atoms/Loading/Loading.js';
import { defaultConfig } from '../config/environment';
import Credits from '../pages/Credits';
import LoginPage from '../pages/LoginPage/LoginPage';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TabAuthEnd from '../pages/Teams/TabAuthEnd';
import TabAuthStart from '../pages/Teams/TabAuthStart';
import TermsOfUse from '../pages/TermsOfUse';
import SamlCallback from '../pages/saml/SamlCallback';
import SamlError from '../pages/saml/SamlError';
import { importLanguageResources } from '../utils/languages.js';
import { isEditorial } from '../utils/users';
import { isInPublisher } from '../utils/utils';

const ErrorView = React.lazy(() => import('app/modules/views/ErrorView/ErrorView'));
const NotFound = React.lazy(() => import('app/pages/ContentNotFoundPage/ContentNotFoundPage'));
// const BarMenuContainer = React.lazy(() => import('app/containers/BarMenuContainer'));
const HomePage = React.lazy(() => import('app/pages/HomePage/HomePage'));
const CoursePage = React.lazy(() => import('app/pages/CoursePage/CoursePage'));
const LessonPage = React.lazy(() => import('app/pages/LessonPage/LessonPage'));
const TasksStudentView = React.lazy(() => import('app/modules/views/TasksView/TasksStudentView/TasksStudentView'));
const DeepLinkView = React.lazy(() => import('_core/lite/views/DeepLinkView'));
const AddLicense = React.lazy(() => import('app/modules/views/AddLicenseView/AddLicenseView'));
const ResetPasswordView = React.lazy(() => import('app/modules/views/ResetPasswordView/ResetPasswordView'));
const TasksView = React.lazy(() => import('app/modules/views/TasksView/TasksView'));
const LogoutPage = React.lazy(() => import('../modules/containers/Logout'));
const ToolsProjectContainer = React.lazy(() => import('_core/lite/views/ToolsProjectView/ToolsProjectContainer'));
const AssessmentAnswerStudent = React.lazy(() => import('../pages/AssessmentAnswerStudent'));
const AssessmentResult = React.lazy(() => import('../pages/AssessmentResult'));
const AssessmentResultIndividual = React.lazy(() => import('../pages/AssessmentResultIndividual'));
const AssessmentResult2 = React.lazy(() => import('../pages/AssessmentResultToMock'));
const AuthPage = React.lazy(() => import('../pages/auth'));
const TokenCallback = React.lazy(() => import('../pages/auth/TokenCallback'));
const CalendarPage = React.lazy(() => import('../pages/CalendarPage/CalendarPage'));
const LemonTest = React.lazy(() => import('../pages/LemonTest'));
const NewAssessment = React.lazy(() => import('../pages/NewAssessment'));
const NewContentView = React.lazy(() => import('../pages/NewContent'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage/ProfilePage'));
const ProjectionMint = React.lazy(() => import('../pages/ProjectionMint'));
const Projection = React.lazy(() => import('../pages/Projection'));
const ProjectionPower = React.lazy(() => import('../pages/ProjectionPower'));
const RemotePage = React.lazy(() => import('../pages/RemotePage'));
const Viewer = React.lazy(() => import('../pages/viewers/ViewersContainer'));
const ScormResult = React.lazy(() => import('../pages/ScormResultPage/ScormResultPage'));
const XapiResult = React.lazy(() => import('../pages/XapiResultsPage/XapiResultsPage'));
const ScormIndividualResult = React.lazy(() => import('../pages/ScormIndividualResultPage/ScormIndividualResultPage'));
const XapiIndividualResult = React.lazy(() => import('../pages/XapiIndividualResultPage/XapiIndividualResultPage'));
const IndividualActivityPage = React.lazy(() => import('../pages/IndividualActivityPage/IndividualActivityPage'));
const RecoverPasswordPage = React.lazy(() => import('../pages/RecoverPasswordPage/RecoverPasswordPage'));
const TestComponentView = React.lazy(() => import('app/modules/views/TestComponentView/TestComponentView'));
const MSTeamsConfiguration = React.lazy(() => import('../pages/MSTeamsConfiguration.js'));
const GroupResourcesPage = React.lazy(() => import('../pages/GroupResourcesPage/GroupResourcesPage'));
const UiPage = React.lazy(() => import('../pages/UiPage/UiPage'));
const KanbanPage = React.lazy(() => import('app/pages/KanbanPage/KanbanPage'));

export const APP_IS_PUBLISHER = isInPublisher(defaultConfig) || false;
// http://localhost:3005/auth/login/{token}}/deeplink?link=course&38021190-17e6-11ed-a395-0f156a9b1207&program
let callbackToken = null;

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();

  const [teamsContentUrl, setTeamsContentUrl] = useState('');
  const [isTeamsDark, setIsTeamsDark] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useSelector((store) => frontoffice.ui.selectors.getTheme(store));
  const userRoleGuid = useSelector((state) => auth.selectors.getUserRoleGuid(state));
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));
  const user = useSelector((state) => auth.selectors.getUser(state));
  const lms = useSelector((state) => coreEntities.organization.selectors.getLms(state));
  checkIfCallbackTokenExists();

  // useEffect(() => {
  //   initAssistant(user, 'TANGERINE');
  // }, []);

  // useEffect(() => {
  //   modifyUser(user);
  // }, [user]);

  useEffect(() => {
    if (user?.guid && tenant?.guid) {
      // initUserForTracking(user, tenant.guid);
      initPosthog();
    }
  }, [user, tenant]);

  const { isAuthorized, country, isChangePassword } = useSelector(
    ({ auth }) => ({
      isAuthorized: callbackToken ? auth.user != null && auth.authToken === callbackToken : auth.user != null,
      hasAuthToken: Boolean(auth.authToken),
      roleUser: (auth.user && auth.user.role_guid) || null,
      userLastLocation: routerHelpers.getLastLocation(),
      country: auth.user?.country_guid,
      license: auth.license,
      // isChangePassword: Boolean(auth.changePassword),
      isChangePassword: false,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized) {
      dispatch(coreEntities.courses.actions.getAllCoursesMessages());
    }
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      checkApiHealth();
      dispatch(frontoffice.notifications.actions.getNotifications({ offset: 0, pageSize: 4, typeNotifications: notificationsTypes.SOCIAL }));
      dispatch(
        frontoffice.notifications.actions.getNotificationsToDo({
          offset: 0,
          pageSize: 4,
          typeNotifications: notificationsTypes.TODO,
        })
      );
      dispatch(frontoffice.notifications.actions.getNotificationsUnread());
      dispatch(frontoffice.notifications.actions.getNotificationsUnreadToDo());
      dispatch(frontoffice.learningObjectives.actions.getLearningObjectives());
      dispatch(frontoffice.ui.actions.getFonts());
    }
    dispatch(frontoffice.educationLevels.actions.getEducationLevels({ country }));
    dispatch(frontoffice.langs.actions.getLangs({ isFilter: true }));
    dispatch(frontoffice.langs.actions.getLangScopes());
  }, [isAuthorized]);

  useEffect(() => {
    if (!lms) dispatch(coreEntities.organization.actions.getOrganization());
  }, [lms]);

  useEffect(() => {
    if (scopes && scopes.length > 0) {
      setAppNameAndFavicon();
    }
  }, [scopes]);

  useEffect(() => {
    if (isTeams()) {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.app.getContext().then((context) => {
          setIsTeamsDark(context.app.theme === 'dark');
        });
        microsoftTeams.pages.getConfig().then((pageConfig) => {
          if (pageConfig.contentUrl) {
            setTeamsContentUrl(pageConfig.contentUrl.replace(window.location.origin, ''));
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      frontoffice.ui.actions.setUiColors({
        color: getComputedStyle(document.documentElement).getPropertyValue('--color-first'),
        colorAlpha: getComputedStyle(document.documentElement).getPropertyValue('--color-first-alpha'),
        colorGradient1: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-1'),
        colorGradient2: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-2'),
      })
    );

    dispatch(
      frontoffice.ui.actions.setUiFonts({
        fontFirst: getComputedStyle(document.documentElement).getPropertyValue('--font-first'),
        fontFirstSemi: getComputedStyle(document.documentElement).getPropertyValue('--font-first-semi'),
        fontSecond: getComputedStyle(document.documentElement).getPropertyValue('--font-second'),
        fontSecondBold: getComputedStyle(document.documentElement).getPropertyValue('--font-second-bold'),
      })
    );
  }, []);

  if (lastLocation && isAuthorized) {
    routerHelpers.saveLastLocation(lastLocation);
  }

  useEffect(() => {
    setLoading(true);
    if (tenant?.guid) {
      const defaultLanguage = getLanguageByTenant(tenant?.guid, user?.lang_id);
      const languageKeys = importLanguageResources(tenant, defaultLanguage);

      // Añadimos las traducciones al i18next
      Object.keys(languageKeys).forEach(function (lang) {
        languageKeys[lang].map((langScope) => {
          Object.keys(langScope).forEach(function (langScopeNs) {
            i18next.addResources(lang, langScopeNs, langScope[langScopeNs]);
          });
        });
      });
      i18next.changeLanguage(defaultLanguage);
      dispatch(coreEntities.i18n.actions.setLanguage(defaultLanguage));
      if (user?.lang_id) dispatch(coreEntities.auth.actions.editProfile({ lang_id: defaultLanguage }));
      setLoading(false);
    } else if (isLocalhost() && !tenant?.guid) {
      // Para evitar el loading infinito en localhost ya que...
      // si en localhost no apuntamos al puerto y dominio corecto la llamada org falla
      setLoading(false);
    }
  }, [tenant, user?.lang_id]);

  async function checkApiHealth() {
    const response = await getApiHealth();
    if (response?.status !== 200) {
      history.push('/error');
    }
  }

  //TODO: Añadir un else al title y dejar vacío por defecto para que no se vea mientras no llega scopes.
  function setAppNameAndFavicon() {
    let theName = scopes.filter((scope) => scope.scope === 'name');
    if (theName.length > 0) {
      document.title = theName[0].json;
    }
    let theFavicon = scopes.filter((scope) => scope.scope === 'favicon');
    if (theFavicon.length > 0) {
      changeFavicon(theFavicon[0].json);
    }
  }

  function checkIfCallbackTokenExists() {
    const path = window.location.pathname;
    // We check this in case it comes from the backoffice and we are already logged in with another user
    if (path.includes('/auth/login/')) {
      const splitted = path.split('/');
      callbackToken = splitted.length > 4 ? splitted[splitted.length - 2] : splitted[splitted.length - 1];
    }
    if (path === '/auth/login') {
      callbackToken = null;
    }
  }

  function redirectFromLoginWithToken() {
    const path = window.location.pathname;
    if (path.startsWith('/auth/login/')) {
      const splitted = path.split('/');
      if (splitted.length > 4) {
        const newPath = '/' + splitted[splitted.length - 1].replaceAll('&', '/');
        return newPath;
      }
    }
    return '/home';
  }

  function initPosthog() {
    posthog.identify(user.guid, {
      client: tenant.guid,
      email: user.username,
      name: user.name + ' ' + user.lastname,
      role: userRoleGuid === role.student ? 'student' : 'teacher',
    });

    // if (user.username.includes('@freetrial.com')) {
    //   console.log('inicio grabación');
    //   initSessionReplay();
    // }
  }

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <div
      className={clsx(`theme-${theme}`, `client--${client}`, 'app-content', {
        'app-content--publisher': isEditorial(userRoleGuid),
        'app-content--student': isStudent(userRoleGuid),
        'app-content--teacher': isTeacher(userRoleGuid),
        'app-content--msteams': isTeams(),
      })}
    >
      <LayoutContextProvider history={history} menuConfig={{}} isTeamsDark={isTeamsDark}>
        {/* {userRoleGuid && isEditorial(userRoleGuid) && <BarMenuContainer />} */}
        {loading ? (
          <Loading />
        ) : (
          <Switch>
            <Route path="/remote/:shortToken" component={RemotePage} />
            <Route path="/deeplink" render={() => <DeepLinkView />} />
            <Route path="/auth/login/:token/deeplink" render={() => <DeepLinkView />} />
            <Route path="/test-component" render={() => <TestComponentView />} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/saml/callback/error" component={SamlError} />
            {/* Static pages */}
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsOfUse} />
            <Route path="/credits" component={Credits} />
            {/* Teams configuration is required regardless if authorized or not to be able to bypass the popup */}
            <Route path="/config" component={MSTeamsConfiguration} />
            <Route path="/tab-auth-start" component={TabAuthStart} />
            <Route path="/tab-auth-end" component={TabAuthEnd} />

            {!isAuthorized && (
              /* Redirect to `/auth` when user is not authorized */
              <Switch>
                <Route path="/auth/login/:token" component={TokenCallback} />
                <Route path="/saml/callback/:token" component={SamlCallback} />
                <Route path="/oidc/callback/:token" component={SamlCallback} />
                <Route path="/auth/login" component={LoginPage} />
                <Route path="/auth/signup" component={AuthPage} />
                <Route path="/recover-password" component={RecoverPasswordPage} />
                <Redirect to="/auth/login" />
              </Switch>
            )}

            {isAuthorized && !isChangePassword && (
              <Switch>
                <Redirect exact from="/" to="/home" />
                {isTeams() && <Redirect exact from="/" to={teamsContentUrl} />}
                {isTeams() && <Redirect exact from="/home" to={teamsContentUrl} />}
                <Route path="/home" render={() => <HomePage />} />
                {window.origin.includes('localhost') && <Route path="/ui" render={() => <UiPage />} />}
                <Route
                  path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/question/:contentGuid"
                  render={(props) => <LemonTest {...props} />}
                />
                <Route
                  path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid/new-question/:lemonadeType"
                  render={(props) => <LemonTest {...props} />}
                />
                <Route path="/course/:courseGuid/lesson/:lessonGuid/results-assessment/:assessmentGuid" render={(props) => <AssessmentResult {...props} />} />
                <Route path="/course/:courseGuid/results-scorm/:itemGuid" render={() => <ScormResult />} />
                <Route path="/course/:courseGuid/results-xapi/:itemGuid" render={() => <XapiResult />} />
                <Route
                  path="/course/:courseGuid/lesson/:lessonGuid/assessment/:assessmentGuid"
                  render={(props) => (userRoleGuid === 'R01' ? <AssessmentAnswerStudent {...props} /> : <NewAssessment {...props} />)}
                />
                <Route path="/course/:courseGuid/lesson/:lessonGuid/new-content" render={(props) => <NewContentView {...props} />} />
                <Route path="/course/:courseGuid/lesson/:lessonGuid/content/:contentGuid" render={(props) => <NewContentView {...props} />} />
                <Route path="/course/:guid/kanban/:lessonGuid" render={() => <KanbanPage />} />
                <Route path="/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid/projection/:unitGuid" render={() => <ProjectionMint />} />
                <Route path="/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid/projection" render={() => <Projection />} />
                <Route path="/course/:courseGuid/lesson/:lessonGuid/projection/:unitGuid/power" render={() => <ProjectionPower />} />
                <Route path="/course/:courseGuid/lesson/:lessonGuid/projection/:unitGuid" render={() => <ProjectionMint />} />
                <Route path="/course/:courseGuid/lesson/:lessonGuid/projection" render={() => <Projection />} />
                <Route path="/course/:courseGuid/unit/:unitGuid/lesson/:lessonGuid" render={() => <LessonPage />} />
                <Route path="/course/:courseGuid/lesson/:lessonGuid" render={() => <LessonPage />} />
                <Route path="/course/:guid/grades" render={() => <CoursePage tabId="grades" />} />
                <Route path="/course/:guid/program" render={() => <CoursePage tabId="program" />} />
                <Route path="/course/:guid/resources/:idResourcesSection/:idResourcesGroup" render={() => <GroupResourcesPage />} />

                <Route path="/course/:guid/resources" render={() => <CoursePage tabId="resources" />} />
                <Route path="/course/:guid" render={() => <CoursePage tabId="stream" />} />
                <Route path="/viewerfull/:guid" render={(props) => <Viewer {...props} showHeader={false} />} />
                <Route path="/viewer/:guid" render={(props) => <Viewer {...props} showHeader={true} />} />
                <Route path="/calendar" render={() => <CalendarPage />} />
                <Route path="/calendar/:course" render={(props) => <CalendarPage {...props} />} />
                <Route path="/profile" render={() => <ProfilePage />} />
                <Route path="/tasks" render={() => (userRoleGuid === 'R01' ? <TasksStudentView /> : <TasksView />)} />
                <Route path="/lemon-test" render={() => <LemonTest />} />
                <Route path="/answer-test" render={() => <AssessmentAnswerStudent />} />
                <Route path="/results-assesment/:guid" render={() => <AssessmentResult2 />} />
                <Route path="/results-assesment-individual/course/:courseGuid/:assessmentGuid/user/:userGuid" render={() => <AssessmentResultIndividual />} />
                <Route path="/results-scorm-individual/:itemGuid/user/:userGuid" render={() => <ScormIndividualResult />} />
                <Route path="/results-xapi-individual/:itemGuid/user/:userGuid" render={() => <XapiIndividualResult />} />
                <Route path="/single-activity/:courseGuid/record/:contentGuid/:referenceGuid" render={() => <IndividualActivityPage record={true} />} />
                <Route path="/single-activity/:courseGuid/:lessonItemGuid/:contentGuid" render={() => <IndividualActivityPage />} />
                <Route path="/tools/:courseProjectGuid" render={() => <ToolsProjectContainer />} />
                <Route path="/remote" component={RemotePage} />
                <Route path="/error" component={ErrorView} />
                <Route path="/license" component={AddLicense} />

                <Redirect from="/auth/login/:token" to={redirectFromLoginWithToken()} />
                <Redirect from="/saml/callback/:token" to={redirectFromLoginWithToken()} />
                {/* <Redirect from="/auth/login" to={lastLocation?.pathname ? lastLocation.pathname : '/home'} /> */}
                <Redirect from="/auth/login" to={'/home'} />
                <Redirect from="/auth/signup" to={'/home'} />
                <Route path="*" component={NotFound} />
              </Switch>
            )}

            {isAuthorized && isChangePassword && (
              <Switch>
                <Route path="/reset-password" component={ResetPasswordView} />
                <Redirect to="/reset-password" />
              </Switch>
            )}
          </Switch>
        )}
      </LayoutContextProvider>
    </div>
  );
});
