import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './../Icon';

const InputText = React.forwardRef(
  (
    {
      background,
      disabled,
      error,
      icon,
      iconColor,
      iconLeft,
      iconSize,
      label,
      onChange,
      onClickIcon,
      onKeyPress,
      placeholder,
      referer,
      type,
      variant,
      className,
      ...props
    },
    ref
  ) => {
    const refInput = referer ? referer : ref;

    return (
      <div
        className={clsx(
          'input-text',
          `input-text--${variant}`,
          className,
          `input-text--background-${background}`,
          { 'input-text--icon': !!icon },
          { 'input-text--icon-click': onClickIcon !== InputText.defaultProps.onClickIcon },
          { 'input-text--icon-left': iconLeft },
          { 'input-text--error': !!error }
        )}
      >
        {label && (
          <label className="input-text__label">
            {label} {props.required && <sup>*</sup>}
          </label>
        )}

        <div className="input-text__input-container">
          <input
            className={clsx('input-text__input', { 'input-text__input--disabled': disabled })}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            ref={refInput}
            onKeyPress={onKeyPress}
            {...props}
          />
          {icon && (
            <div className="input-text__icon" onClick={onClickIcon}>
              <Icon type={icon} size={iconSize} color={iconColor} />{' '}
            </div>
          )}
        </div>
        {error && <span className="input-text__error">{error}</span>}
      </div>
    );
  }
);

InputText.propTypes = {
  background: PropTypes.oneOf(['default', 'grey']),
  icon: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconColor: PropTypes.string,
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'focus']),
};

InputText.defaultProps = {
  background: 'default',
  disabled: false,
  iconLeft: false,
  onChange: () => null,
  onClickIcon: () => null,
  onKeyPress: () => {},
  placeholder: 'Write here',
  type: 'text',
  variant: 'default',
  iconSize: 'big',
};

export default InputText;
