export function isTeacher(user) {
  return user.role_guid === 'R02' || user === 'R02';
}

export function isStudent(user) {
  return user.role_guid === 'R01' || user === 'R01';
}

export function isEditorial(user) {
  return (user && user.role_guid === 'R04') || user === 'R04';
}
