import { useUser } from '_core/lite/hooks/useUser';
import Button from '_core/modules/atoms/Button';
import ErrorText from '_core/modules/atoms/ErrorText';
import InputText from '_core/modules/atoms/InputText';
import TextHtml from '_core/modules/atoms/TextHtml/TextHtml';
import * as coreEntities from '_core/store/index';
import { CLIENT } from '_core/variables/constant';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AddLicenseForm = ({ onSubmit, updateValue, error, errorMsg, shouldHandleSubmit, t }) => {
  const { register, handleSubmit, watch, reset } = useForm();
  const { isStudent } = useUser();
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const [customError] = useState(t('course:This license code is from ByME Digital')); // lo pongo así por si algun día hay mas, que sea dinamico

  function showError() {
    if (errorMsg === 'Code already linked' || errorMsg === 'No left uses') return t('new-course:Code already linked');
    if (errorMsg === 'Code expired') return t('new-course:Code expired');
    if (errorMsg === 'License role constraint') return t('new-course:License role constraint');
    if (errorMsg === 'License country mismatch') return t('new-course:License country mismatch');
    else return isStudent ? t('new-course:Invalid license student') : t('new-course:Invalid license');
  }

  function getIsThereCustomError(value) {
    let isThereCustomError = false;
    if (value && client === CLIENT.EDELVIVES) {
      // Convertir la cadena a minúsculas para realizar una comparación sin distinción de mayúsculas y minúsculas
      const valueLower = value.toLowerCase();
      // Verificar si la cadena comienza con "st-" o "tr-"
      if (valueLower.startsWith('st-') || valueLower.startsWith('tr-')) {
        isThereCustomError = true;
      }
    }
    return isThereCustomError;
  }

  function handleFormSubmit(data) {
    onSubmit(data, reset); // Pasa reset como un callback para borrar el input después de una sumisión exitosa
  }

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="add-license-form">
        <div className="add-license-form__input">
          <InputText
            onChange={({ target: { value } }) => updateValue(value)}
            placeholder={t('Enter a valid code')}
            name="code"
            referer={register}
            error={error && <ErrorText text={errorMsg && showError()} />}
          />
        </div>
        {shouldHandleSubmit && (
          <div className="add-license-form__button">
            <Button text={t('actions:Activate')} type="submit" disabled={!watch('code') || getIsThereCustomError(watch('code'))} />
          </div>
        )}
      </form>
      {getIsThereCustomError(watch('code')) && (
        <div className="add-license-form__custom-error-container">
          <TextHtml text={customError} className="add-license-form__custom-error" />
        </div>
      )}
    </div>
  );
};

AddLicenseForm.propTypes = {
  onSubmit: PropTypes.func,
  updateValue: PropTypes.func,
  errorMsg: PropTypes.string,
  shouldHandleSubmit: PropTypes.bool,
  error: PropTypes.bool,
};

AddLicenseForm.defaultProps = {
  onSubmit: () => null,
  updateValue: () => null,
  errorMsg: 'Error message in AddLicenseForm',
  shouldHandleSubmit: true,
  error: false,
};

export default withTranslation(['new-course', 'actions', 'placeholder'])(AddLicenseForm);
