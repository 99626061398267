import axios from "axios";

export const GET_COLLECTIONS = "cms/collections/";
export const GET_COLLECTIONS_CONTENTS = "front/project/landing/";//"cms/collections-contents";

export function getCollections(params = { pageSize: 100, offset: 0 }) {
  return axios.get(GET_COLLECTIONS, { params: params });
}

export function getCollection(guid, params = {}) {
  return axios.get(GET_COLLECTIONS + "/" + guid, { params: params });
}

export function getCollectionsContents(params = { pageSize: 100, offset: 0 }, guid = null) {
  return axios.get(GET_COLLECTIONS_CONTENTS + (guid ? guid : ""), { params: params });
}
export function createCollection(params) {
  return axios.post(GET_COLLECTIONS, params);
}

export function updateCollection(params) {
  return axios.put(GET_COLLECTIONS + params.guid, params);
}
export function deleteCollection(params) {
  return axios.delete(GET_COLLECTIONS + params.guid, params);
}
