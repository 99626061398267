import { useTranslate } from '_core/hooks/useTranslate';
import ProgressBar from '_core/modules/atoms/ProgressBar';
import { getLevel } from '_core/modules/components/gamified/gamified.service';
import PropTypes from 'prop-types';
import React from 'react';

function GamifiedLevelProgress({ start, end, level, progress }) {
  const { t } = useTranslate();
  const isLevelRigth = start <= end;
  const text = isLevelRigth ? `${start}/${end} XP` : `${start} XP`;
  const LevelLeft = getLevel(level);
  const LevelRigth = getLevel(level + 1);

  return (
    <div className="gamified-level-progress">
      <div className="gamified-level-progress__bar">
        <ProgressBar progress={progress} text={isLevelRigth ? text : ''} withLabel={false} />
        <div className="gamified-level-progress__current-level">
          <div>{LevelLeft}</div>
        </div>
        <div className="gamified-level-progress__next-level">{(isLevelRigth || progress === 10) && <div>{LevelRigth}</div>}</div>
      </div>
    </div>
  );
}

GamifiedLevelProgress.propTypes = {
  end: PropTypes.number,
  level: PropTypes.number,
  start: PropTypes.number,
};

GamifiedLevelProgress.defaultProps = {
  end: 50,
  level: 1,
  start: 25,
};

export default GamifiedLevelProgress;
