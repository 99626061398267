import resources from 'app/translations/resources';

export function importLanguageResources(tenant, defaultLanguage) {
  // En allLanguages tenemos todas las claves del archivo resources, que contiene los idiomas origen y los específicos de cada cliente
  // ej ['es', 'en', 'es_MACMILLAN', 'en_GOTITBYME'....]
  const allLanguages = Object.keys(resources);
  // En availableLanguages tenemos los idiomas origen ['es', 'en']
  const availableLanguages = getAvailableLanguages();
  let languageKeys = {};
  const tenantName = tenant?.guid;

  availableLanguages.forEach((lang) => {
    // Si en allLanguages tenemos el idioma específico del tenant, lo añadimos, si no, añadimos el idioma origen
    if (allLanguages.includes(lang + '_' + tenantName)) {
      languageKeys[lang] = resources[lang + '_' + tenantName];
    } else if (defaultLanguage !== 'es' && allLanguages.includes(defaultLanguage)) {
      languageKeys[lang] = resources[defaultLanguage];
    } else {
      languageKeys[lang] = resources[lang];
    }
  });

  return languageKeys;
}

function getAvailableLanguages() {
  return Object.keys(resources).filter((lang) => !lang.includes('_'));
}
