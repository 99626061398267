import GamificationBasicInfoFloat from '_core/modules/components/gamified/GamificationBasicInfoFloat/GamificationBasicInfoFloat';
import GamificationDialogLevel from '_core/modules/components/gamified/GamificationDialogLevel/GamificationDialogLevel';
import * as coreEntities from '_core/store/index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const GamificationWrapperApp = () => {
  const dispatch = useDispatch();
  const dialogLevel = useSelector((state) => coreEntities.gamification.selectors.getOpenedDialogLevel(state));

  function onCloseDialogLevel() {
    dispatch(coreEntities.gamification.actions.setDialogLevel(false));
  }

  return (
    <>
      <GamificationBasicInfoFloat />
      <GamificationDialogLevel level={dialogLevel?.level} open={dialogLevel?.open} onAccept={onCloseDialogLevel} />
    </>
  );
};

export default GamificationWrapperApp;
