import { useTranslate } from '_core/hooks/useTranslate';

function useGamifiedToast(props) {
  const { t } = useTranslate();
  const { gamified } = props;
  const title = gamified.title;
  const subtitle = gamified.subtitle;
  const image = gamified.image;

  return { image, subtitle, title };
}

export default useGamifiedToast;
