import React from 'react';

function Position1() {
  return (
    <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_76_10706)">
        <rect width="28" height="32" rx="8" fill="#ECB314" shapeRendering="crispEdges" />
        <rect x="2" y="2" width="24" height="28" rx="6" fill="#E5A300" />
        <path d="M3 8C3 5.23858 5.23858 3 8 3H20C22.7614 3 25 5.23858 25 8V10L3 26V8Z" fill="#F8BC15" />
        <g filter="url(#filter1_d_76_10706)">
          <path
            d="M14.4534 23C14.1054 23 13.7814 22.928 13.4814 22.784C13.1934 22.64 13.0494 22.424 13.0494 22.136V12.974L12.5274 13.298C12.4434 13.346 12.3594 13.382 12.2754 13.406C12.2034 13.43 12.1374 13.442 12.0774 13.442C11.8134 13.442 11.5974 13.328 11.4294 13.1C11.2734 12.86 11.1954 12.602 11.1954 12.326C11.1954 12.134 11.2374 11.954 11.3214 11.786C11.4054 11.618 11.5374 11.48 11.7174 11.372L14.0934 9.932C14.1654 9.884 14.2434 9.854 14.3274 9.842C14.4234 9.818 14.5194 9.806 14.6154 9.806C14.9034 9.806 15.1794 9.884 15.4434 10.04C15.7194 10.196 15.8574 10.412 15.8574 10.688V22.136C15.8574 22.424 15.7074 22.64 15.4074 22.784C15.1194 22.928 14.8014 23 14.4534 23Z"
            fill="white"
          />
        </g>
        <rect x="1.5" y="1.5" width="25" height="29" rx="6.5" stroke="#FFC22D" strokeWidth="3" shapeRendering="crispEdges" />
      </g>
      <defs>
        <filter id="filter0_d_76_10706" x="0" y="0" width="28" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.901961 0 0 0 0 0.635294 0 0 0 0 0.164706 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_10706" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_10706" result="shape" />
        </filter>
        <filter id="filter1_d_76_10706" x="11.1954" y="9.80591" width="4.66199" height="15.1941" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_10706" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_10706" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default Position1;
