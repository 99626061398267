import { useUser } from '_core/lite/hooks/useUser';
import * as coreEntities from '_core/store/index';
import { FEATURE_FLAGS } from '_core/variables/constant';
import { useSelector } from 'react-redux';
import { sendEvent } from '../crud/gamified.crud';
import { useFlags } from './useFlags';

const useGamification = () => {
  const { user, isStudent } = useUser();
  const basicInfo = useSelector((state) => coreEntities.gamification.selectors.getUserInfoBasic(state));
  const { value: isGamified } = useFlags(FEATURE_FLAGS.IS_GAMIFIED, false, user);

  function sendGamificationEvent(eventTypeId) {
    if (isStudentGamified()) {
      sendEvent({ userGuid: user.guid, eventTypeId });
    }
  }

  function isStudentGamified() {
    return isGamified && isStudent;
  }

  return {
    sendGamificationEvent,
    basicInfo: basicInfo,
    isStudentGamified: isStudentGamified(),
    isGamified,
  };
};

export default useGamification;
