import i18next from 'i18next';

export const initLayoutConfig = {
  name: 'TANGERINE',
  login: {
    showSignup: true,
  },
  home: {
    headerAnimation: false, //The header animates to sticky when scroll down
    tabs: [
      //The number of tabs its going to show in the home
      {
        translateId: 'Classrooms',
        id: 'classrooms',
      },
      {
        translateId: 'Library',
        id: 'library',
        disabled: false,
      },
    ],
    tabsStudent: [],
    tabActive: 'classrooms', //The default active tab
    showLogo: true, //Show the logo in the top of home
    showHello: true, //Show hello and the name in the top of home
    onlyNewClass: false, //Show only the option to create new class (if false it shows a dropdown with other options)
  },
  createClassroom: {
    headerShowLogo: false, //Show the logo in the top
    headerShowStepsInCreate: true, //Show the steps to create a class,
    lessonItemStatus: 'published',
  },
  profile: {
    showSchool: true, //Show the school info in the first section of the profile
    showPreferences: true, //Show the section Preferences
  },
  calendar: {},
};

// así nos las devuelve con la traduccion
export function getInitLayoutTabs() {
  let tabs = [];
  if (initLayoutConfig.home && initLayoutConfig.home.tabs) {
    tabs = initLayoutConfig.home.tabs.map((item) => ({ ...item, name: i18next.t(`my-courses:${item.translateId}`) }));
  }
  return tabs;
}

export default initLayoutConfig;
