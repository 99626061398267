const actionsSocketsProject = {
  SET_TOOLS: 'setTools',
  CLOSE_TOOLS: 'closeTools',
  SEND_ITEM_SELECTED: 'sendItemSelected',
  CHANGE_ITEM_SELECTED: 'changeItemSelected',
  CHANGE_PHONE_CONNECTED: 'setPhoneConnected',
  ON_CLOSE_POPUP_TOOLS: 'onClosePopupTools',
  SET_USER_ACTIVE: 'setUserActive',
  SET_USERS_TALKED: 'setUsersTalked',
  SET_ANIMATION: 'setAnimation',
  SET_PLAY: 'setPlay',
  SET_RELOAD: 'setReload',
  SET_CHANGE_TIME_ACTION: 'setChangeTimeAction',
  ON_CLOSE_PROJECT: 'onCloseProject',
  ON_BACK: 'onBack',
  ON_NEXT: 'onNext',
  SEND_ITEMS: 'sendItems',
  SEND_STUDENTS: 'sendStudents',
  PING: 'ping',
  ON_PHONE_CONNECTED: 'phoneConnected',
  CURRENT_TAB_PROJECTING: 'currentTabProjecting',
  ON_MOVE_SCROLL: 'onMoveScroll',
  CHANGE_VISIBLE_SCROLL_CONTROL: 'changeVisibleScrollControl',
  SEND_BOOK: 'sendBook',
  ON_OPEN_LINK: 'onOpenLink',
  ON_PLAY: 'onPlay',
  ON_PAUSE: 'onPause',
  ON_PLAY_TO_REMOTE: 'onPlayToRemote',
  ON_PAUSE_TO_REMOTE: 'onPauseToRemote',
  ON_CHANGE_DURATION_MEDIA: 'onChangeDurationMedia',
  ON_CHANGE_PROGRESS_MEDIA: 'onChangeProgressMedia',
  ON_CHANGE_TIME_IN_REMOTE: 'onChangeTimeInRemote',
  ON_CHANGE_FULLSCREEN: 'onChangeFullScreen',
  ON_CHANGE_FULLSCREEN_TO_REMOTE: 'onChangeFullScreenToRemote',
  ON_CHANGE_MUTE: 'onChangeMute',
  ON_CHANGE_TIMEOUT: 'onChangeTimeout',
  ON_CHANGE_PAGE: 'onChangePage',
  ON_CHANGE_PAGE_TO_REMOTE: 'onChangePageToRemote',
  ON_NEXT_PAGE_VISOR: 'onNextPageVisor',
  ON_PREV_PAGE_VISOR: 'onPrevPageVisor',
  ON_CHANGE_SHOW_HINT: 'onChangeShowHint',
  ON_CHANGE_SHOW_HINT_REMOTE: 'onChangeShowHintRemote',
  ON_CHANGE_SHOW_SOLUTIONS: 'onChangeShowSolutions',
  SET_TYPE_VIEW_REMOTE: 'setTypeViewRemote',
  SEND_EXTRA_INFO: 'sendExtraInfo',
  CHANGE_LESSON: 'changeLesson',
};

export default actionsSocketsProject;
