import React from 'react';

const GamificationDialogBadgesTitle = ({ title }) => {
  return (
    <div className="gamification-dialog-badges-title">
      <span className="gamification-dialog-badges-title__line gamification-dialog-badges-title__line--left"></span>
      <span className="gamification-dialog-badges-title__text">{title}</span>
      <span className="gamification-dialog-badges-title__line gamification-dialog-badges-title__line--right"></span>
    </div>
  );
};

export default GamificationDialogBadgesTitle;
