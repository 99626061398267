import axios from 'axios';

export const GET_USERS = 'users/';
export const GET_EVA_USERS = 'users/eva';
export const GET_USERS_DEVICES = 'users/devices';

export function getUsers(roles, page = 1, pageSize = 20) {
  var paramOpcional = {};
  if (roles && roles.length > 0) paramOpcional['role'] = roles;
  return axios.get(GET_USERS, {
    params: {
      ...paramOpcional,
      pageSize: pageSize,
      offset: (page - 1) * pageSize,
      page: page,
    },
  });
}

export function deleteUser(userGuid) {
  return axios.delete(GET_USERS, {
    data: {
      guid: [userGuid],
    },
  });
}

export function editRole(guid, name, roleGuid) {
  return axios.put(GET_USERS + '/' + guid, { name: name, role_guid: roleGuid });
}

export function getDevices() {
  return axios.get(GET_USERS_DEVICES, {});
}

export function unlinkDevice(guid) {
  return axios.delete(`${GET_USERS_DEVICES}/${guid}`, {});
}

export function getEvaUsers(params) {
  return axios.get(GET_EVA_USERS, { params });
}
