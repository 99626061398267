import React from 'react';

function Position2() {
  return (
    <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_76_10723)">
        <rect width="28" height="32" rx="8" fill="#C0C2C4" shapeRendering="crispEdges" />
        <rect x="2" y="2" width="24" height="28" rx="6" fill="#8D8E91" />
        <path d="M3 8C3 5.23858 5.23858 3 8 3H20C22.7614 3 25 5.23858 25 8V10L3 26V8Z" fill="#9C9D9F" />
        <g filter="url(#filter1_d_76_10723)">
          <path
            d="M10.9886 23C10.7366 23 10.4666 22.922 10.1786 22.766C9.90259 22.61 9.76459 22.4 9.76459 22.136V20.39C9.76459 19.922 9.89659 19.484 10.1606 19.076C10.4366 18.656 10.7906 18.266 11.2226 17.906C11.6546 17.534 12.1106 17.174 12.5906 16.826C13.0826 16.478 13.5386 16.136 13.9586 15.8C14.3906 15.452 14.7446 15.104 15.0206 14.756C15.2966 14.408 15.4346 14.042 15.4346 13.658C15.4346 13.466 15.3866 13.274 15.2906 13.082C15.2066 12.878 15.0626 12.71 14.8586 12.578C14.6666 12.446 14.4026 12.38 14.0666 12.38C13.7066 12.38 13.3886 12.482 13.1126 12.686C12.8486 12.89 12.7166 13.238 12.7166 13.73C12.7166 13.994 12.5846 14.234 12.3206 14.45C12.0566 14.654 11.6966 14.756 11.2406 14.756C10.8806 14.756 10.5806 14.654 10.3406 14.45C10.1006 14.246 9.98059 13.862 9.98059 13.298C9.98059 12.566 10.1726 11.942 10.5566 11.426C10.9526 10.91 11.4626 10.52 12.0866 10.256C12.7106 9.98 13.3646 9.842 14.0486 9.842C14.7446 9.842 15.4106 9.986 16.0466 10.274C16.6946 10.562 17.2226 10.982 17.6306 11.534C18.0386 12.086 18.2426 12.764 18.2426 13.568C18.2426 14.216 18.1046 14.798 17.8286 15.314C17.5646 15.83 17.2226 16.298 16.8026 16.718C16.3826 17.126 15.9326 17.498 15.4526 17.834C14.9846 18.17 14.5406 18.482 14.1206 18.77C13.7006 19.046 13.3526 19.316 13.0766 19.58C12.8126 19.844 12.6806 20.114 12.6806 20.39V20.552H17.3966C17.6246 20.552 17.8226 20.672 17.9906 20.912C18.1586 21.152 18.2426 21.434 18.2426 21.758C18.2426 22.082 18.1586 22.37 17.9906 22.622C17.8226 22.874 17.6246 23 17.3966 23H10.9886Z"
            fill="white"
          />
        </g>
        <rect x="1.5" y="1.5" width="25" height="29" rx="6.5" stroke="#C0C2C4" strokeWidth="3" shapeRendering="crispEdges" />
      </g>
      <defs>
        <filter id="filter0_d_76_10723" x="0" y="0" width="28" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.466667 0 0 0 0 0.482353 0 0 0 0 0.486275 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_10723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_10723" result="shape" />
        </filter>
        <filter id="filter1_d_76_10723" x="9.76453" y="9.84204" width="8.47803" height="15.158" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_76_10723" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_76_10723" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default Position2;
