import * as microsoftTeams from '@microsoft/teams-js';
import { successResponse } from '_core';
import { checkUser, getUserByToken, validateLicense } from '_core/crud/auth.crud';
import { redeemLicense } from '_core/crud/contents.crud';
import { createCourse } from '_core/crud/courses.crud';
import { createGroup, getGroups } from '_core/crud/groups.crud';
import { useTranslate } from '_core/hooks/useTranslate';
import { useUser } from '_core/lite/hooks/useUser';
import { parsePrograms } from '_core/lite/parsers';
import { useAuthView } from '_core/lite/views/AuthView/useAuthView';
import * as coreEntities from '_core/store/index';
import { parseProgramsSignup } from '_core/utils/parses';
import { isEmptyObj } from '_core/utils/validation';
import { licenseType, role } from '_core/variables/constant';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

export const useTeamsData = () => {
  const [authorization, setAuthorization] = useState(null);
  const [userContext, setUserContext] = useState({});
  const [userData, setUserData] = useState({});
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [schoolGuid, setSchoolGuid] = useState();
  const [teamMembers, setTeamMembers] = useState([]);
  const [mode, setMode] = useState('login');
  const [licenses, setLicenses] = useState([]);
  const [licenseCodes, setLicenseCodes] = useState([]);
  const { addToast } = useToasts();
  const { t } = useTranslate();
  const [classrooms, setClassrooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isConfiguredTab, setIsConfiguredTab] = useState(false);
  const [shouldConsent, setShouldConsent] = useState(false);

  const [hasAuthenticated, setHasAuthenticated] = useState(false);

  const [user, setUser] = useState({
    name: null,
    surname: null,
    password: null,
    email: null,
    terms: false,
    role: null,
    birthdate: null,
    identity_provider: null,
    token: null,
  });
  const location = useLocation();
  const { onSSOForMSTeams, authData, authError } = useAuthView();
  const { user: authenticatedUser } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    microsoftTeams.app.getContext().then((context) => {
      setUserContext(context);
    });
  }, []);

  useEffect(() => {
    if (isEmptyObj(authError)) return;

    if (authError.error.data.error.code === 400) {
      setShouldConsent(true);
    } else {
      const errorMsg = authError.error && authError.error.data ? t(authError.error.data.error.errorKey) : t('login:Invalid login');
      addToast(t(`login:${errorMsg}`), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [authError]);

  useEffect(() => {
    if (licenses.length === 0) setUser({ ...user, role: null }); //IF we remove all license, remove also the role guid
  }, [licenses]);

  useEffect(() => {
    if (authenticatedUser) {
      setUser(authenticatedUser);
      getClassData();
      setHasAuthenticated(true);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    if (isEmptyObj(authData)) return;
    handleLogin();
  }, [authData]);

  const handleLogin = async () => {
    // authData shows that the user has been logged in.
    // Registered users will be populated via the authenticatedUser object and will trigger the [authenticatedUser] effect
    if (isConfiguredTab && authData.token) {
      let userRequest = await getUserByToken({ token: authData.token });
      if (successResponse(userRequest)) {
        // If student enroll in class if not enrolled then do login
        if (userRequest.data.data.role_guid === 'R01') {
          await redeemLicense({
            code: userContext?.team?.groupId,
            token: authData.token,
          });
        }
      }
      // If teacher do login because the teacher has previously configured this tab
      dispatch(coreEntities.auth.actions.login(authData));
    } else {
      getClassData();
      setHasAuthenticated(true);
    }
  };

  microsoftTeams.app.initialize().then(async () => {
    if (location.pathname !== '/config') {
      setIsConfiguredTab(true);
      return;
    }

    /**
     * When the user clicks "Save", save the url for your configured tab.
     * This allows for the addition of query string parameters based on
     * the settings selected by the user.
     */
    microsoftTeams.pages.config.registerOnSaveHandler(async (saveEvent) => {
      const baseUrl = `https://${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
      //Perform Azure AD single sign-on authentication
      microsoftTeams.pages.config.setConfig({
        suggestedDisplayName: 'Macmillan',
        entityId: selectedCourse,
        contentUrl: baseUrl + '/course/' + selectedCourse + '/program',
        websiteUrl: baseUrl + '/course/' + selectedCourse + '/program',
      });
      if (!authenticatedUser && authData) {
        dispatch(coreEntities.auth.actions.login(authData));
      }
      saveEvent.notifySuccess();
    });
  });

  const getError = (response) => {
    if (response.error && response.error.data && response.error.data.error && response.error.data.error.code === 404) {
      onError(t('signup:Code not found'));
    } else {
      onError(t('common:An error has occurred'));
    }
  };

  const onError = (text) => {
    addToast(text, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
    setIsLoading(false);
  };

  const onCheckLicense = async (data) => {
    if (!licenseCodes.includes(data.code)) {
      setIsLoading(true);
      const response = await validateLicense(`${data.code}`);
      setIsLoading(false);
      if (response && response.data && response.data.status === 'success') {
        if (response.data.data.type === licenseType.personal) getLicensesPrograms(response.data.data);
        else if (response.data.data.type === licenseType.school) getLicensesClassroom(response.data.data);
      } else {
        getError(response);
      }
    } else {
      addToast(t('signup:You already added this licence'), { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
    }
  };

  const onClearLicenses = () => {
    setLicenseCodes([]);
    setLicenses([]);

    setUser({
      name: null,
      surname: null,
      password: null,
      email: null,
      terms: false,
      role: null,
      birthdate: null,
      identity_provider: null,
      token: null,
    });
  };

  const getLicensesPrograms = async (licenseToParse) => {
    try {
      if (user.role && user.role !== licenseToParse.role_guid) {
        addToast(t('signup:You have a graduate with another profile'), { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
      } else {
        if (licenseToParse.programs.length === 0) {
          addToast(t('signup:It seems that this license does not have any associated content yet'), {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
        const licensesNew = parseProgramsSignup([...licenses, ...licenseToParse.programs]);
        setLicenses(licensesNew);
        setLicenseCodes([...licenseCodes, licenseToParse.code]);
        setUser({ ...user, role: licenseToParse.role_guid });
      }
    } catch (e) {
      onError(t('common:An error has occurred'));
    }
  };

  const getLicensesClassroom = async (licenseToParse) => {
    try {
      const licensesNew = [
        ...classrooms,
        { name: licenseToParse.name, code: licenseToParse.code, level: licenseToParse.education_year_name + ' ' + licenseToParse.education_level_name },
      ];
      setClassrooms(licensesNew);
      setLicenseCodes([...licenseCodes, licenseToParse.code]);
    } catch (e) {
      onError(t('common:An error has occurred'));
    }
  };

  const getTeamMembers = async (accessToken) => {
    // let members = await client.api('/me').get(); //(`/groups/${userContext.team.groupId}/members`).get();
    let payload = await fetch(`https://graph.microsoft.com/v1.0/groups/${userContext.team.groupId}/members`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    let json = await payload.json();
    setTeamMembers(json.value);
  };

  const getClassData = async () => {
    setIsLoading(true);
    let coursesRequest = await Axios.get('front/courses/', {
      headers: {
        Authorization: authData.token,
      },
    });

    if (successResponse(coursesRequest)) {
      setCourses(coursesRequest.data.data);
    }

    let tokenRequest = await getUserByToken({ token: authData.token });

    if (successResponse(tokenRequest)) {
      setSchoolGuid(tokenRequest.data.data.schools[0].guid);
    }

    if (coursesRequest.data.data.length > 0) {
      if (mode === 'register') {
        setSelectedCourse(coursesRequest.data.data[0].guid);
        microsoftTeams.pages.config.setValidityState(true);
      }
      setIsLoading(false);
      // return;
    }

    let programsResponse = await Axios.get('front/programs', {
      data: {
        pageSize: 100,
        offset: 0,
        isBook: 1,
        includeNumberOfSessions: 1,
        teacher_only: 1,
        orderBy: 't.name + asc ',
      },
      headers: {
        Authorization: authData.token,
      },
    });

    setBooks(parsePrograms(programsResponse.data.data.programs));

    setIsLoading(false);
  };

  const onLoginMicrosoft = async () => {
    dispatch(coreEntities.auth.actions.logout());
    setIsLoading(true);

    // Perform Azure AD single sign-on authentication via on-behalf-of flow
    let teamsToken = await microsoftTeams.authentication.getAuthToken().catch((error) => {
      console.log(error);
      setIsLoading(false);
    });

    const checkUserResponse = await checkUser({ users: [{ username: userContext?.user?.loginHint }] });

    if (successResponse(checkUserResponse)) {
      if (checkUserResponse.data.data[0].username_check.code === 409) {
        // user exists and can login
        onSSOForMSTeams({
          token: teamsToken,
          identity_provider: 'microsoft-obo',
          action: 'login',
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else {
        addToast('El usuario no existe. Por favor regístrese', {
          appearance: 'error',
          autoDismiss: true,
        });

        setIsLoading(false);
      }
    } else {
      const errorMsg = authError.error && authError.error.data ? t(authError.error.data.error.errorKey) : t('login:Invalid login');
      addToast(t(`login:${errorMsg}`), {
        appearance: 'error',
        autoDismiss: true,
      });

      setIsLoading(false);
    }
  };

  const onSignupMicrosoft = async () => {
    dispatch(coreEntities.auth.actions.logout());
    setIsLoading(true);

    // Perform Azure AD single sign-on authentication via on-behalf-of flow
    let teamsToken = await microsoftTeams.authentication.getAuthToken().catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
    onSSOForMSTeams({
      token: teamsToken,
      identity_provider: 'microsoft-obo',
      action: 'signup',
      role: user.role === role.student ? 'student' : 'teacher',
      licenses: licenseCodes,
    });

    setIsStudent(user.role !== role.teacher);
    setIsTeacher(user.role === role.teacher);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const onConsentRequest = () => {
    microsoftTeams.authentication
      .authenticate({
        url: window.location.origin + '/tab-auth-start',
        width: 600,
        height: 500,
      })
      .then(() => {
        setShouldConsent(false);
        onSignupMicrosoft();
      })
      .catch(() => {
        setShouldConsent(false);
      });
  };

  const createClassAndCourse = async (selectedBook) => {
    let groupsResponse = await getGroups({
      schoolGuid: schoolGuid,
      offset: 0,
      pageSize: 1000,
      orderBy: 'created_at desc',
      members: 'teachers',
      token: authData.token,
    });

    let schoolGroupGuid;

    if (
      successResponse(groupsResponse) &&
      groupsResponse.data.data.schoolGroups.length > 0 &&
      groupsResponse.data.data.schoolGroups.filter((group) => group.teams_team_id === userContext?.team?.groupId).length > 0
    ) {
      let selectedClass = groupsResponse.data.data.schoolGroups.filter((group) => group.teams_team_id === userContext?.team?.groupId);
      schoolGroupGuid = selectedClass[0].guid;
    } else {
      let classResponse = await createGroup({
        education_year_guid: selectedBook.education_year.guid,
        is_active: 1,
        name: `${userContext?.team?.displayName}`,
        schoolGuid: schoolGuid,
        school_year_guid: schoolGuid,
        stream: 'onlyComment',
        teaching_model: 'blended',
        teams_team_id: userContext?.team?.groupId,
        token: authData.token,
      });

      schoolGroupGuid = classResponse.data.data.guid;
    }

    let courseResponse = await createCourse({
      education_discipline_guid: selectedBook.discipline.guid,
      education_year_guid: selectedBook.education_year.guid,
      is_referenced: 1,
      lesson_item_status: 'published',
      name: `${selectedBook.program.name} - ${userContext?.channel?.displayName}`,
      programs: [selectedBook.program_guid],
      publication_guid: selectedBook.publication.guid,
      school_group_guid: schoolGroupGuid,
      teams_channel_id: userContext?.channel?.id,
      token: authData.token,
    });

    setSelectedCourse(courseResponse.data.data.guid);
    microsoftTeams.pages.config.setValidityState(true);
  };

  const addCourseToChannel = () => {
    microsoftTeams.pages.config.setValidityState(true);
  };

  return {
    mode,
    setMode,
    books,
    courses,
    licenses,
    isLoading,
    isTeacher,
    isStudent,
    userContext,
    shouldConsent,
    selectedCourse,
    onCheckLicense,
    onClearLicenses,
    onConsentRequest,
    onLoginMicrosoft,
    hasAuthenticated,
    onSignupMicrosoft,
    setSelectedCourse,
    addCourseToChannel,
    createClassAndCourse,
  };
};
