import axios from 'axios';

export function getGroups(params) {
  return axios.get(`front/school-admin/${params.schoolGuid}/groups`, {
    params: params,
    ...(params && params.token
      ? {
          headers: {
            Authorization: params.token,
          },
        }
      : {}),
  });
}

export function getGroupUsers(params) {
  return axios.get(`front/school-admin/${params.schoolGuid}/groups/${params.groupGuid}/users`, { params: params });
}

export function getMyGroups(params) {
  return axios.get(`front/groups`, { params: params });
}

export function createGroup(params) {
  if (params.token) {
    return axios.post(`front/school-admin/${params.school_guid || params.schoolGuid}/groups`, params, {
      headers: {
        Authorization: params.token,
      },
    });
  }
  return axios.post(`front/school-admin/${params.school_guid || params.schoolGuid}/groups`, params);
}

export function deleteGroup(params) {
  params.groups.removeRelatedCourses = 1;
  return axios.delete(`front/school-admin/${params.schoolGuid}/groups`, { data: params.groups });
}

export function updateGroup(params) {
  return axios.put(`front/school-admin/${params.schoolGuid}/groups`, params);
}

export function deleteUserFromGroup(params) {
  return axios.delete(`front/school-admin/${params.schoolGuid}/groups/${params.groupGuid}/users`, { data: params.users });
}

export function createDemoGroup(params) {
  return axios.post('front/demo-courses', params);
}

export function addUsersToGroup(params) {
  return axios.post(`front/school-admin-groups/${params.groupGuid}/users`, params.students);
}
